import React from 'react';

const clock = ({ colorStroke }) => ({
  viewBox: '0 0 20 20',
  data: (
    <g>
      <path
        d={`M10 19.1654C15.0627 19.1654 19.1667 15.0613 19.1667 
        9.9987C19.1667 4.93609 15.0627 0.832031 10 0.832031C4.93743 
        0.832031 0.833374 4.93609 0.833374 9.9987C0.833374 
        15.0613 4.93743 19.1654 10 19.1654Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 4.5V10L13.6667 11.8333"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default clock;
