import React from 'react';

const th = ({ colorStroke }) => ({
  viewBox: '0 0 23 22',
  data: (
    <g>
      <mask
        id="th-svg"
        fill="white"
      >
        <rect
          x="0.80957"
          y="0.0507812"
          width="22"
          height="22"
          rx="1"
        />
      </mask>
      <rect
        x="0.80957"
        y="0.0507812"
        width="22"
        height="22"
        rx="1"
        stroke={colorStroke}
        fill="none"
        strokeWidth="4"
        mask="url(#th-svg)"
      />
      <line
        x1="12"
        y1="0.078125"
        x2="12"
        y2="22"
        stroke={colorStroke}
        fill="none"
        strokeWidth="2"
      />
      <line
        x1="22"
        y1="11"
        x2="0.80843"
        y2="11"
        stroke={colorStroke}
        fill="none"
        strokeWidth="2"
      />
    </g>
  ),
});

export default th;
