import { gql } from '@apollo/client';

const fragment = gql`
  fragment AlbumFragment on Album {
    _id
    url
    name
    likes
    myLike
    owner_id
    artworksCount
    description
    artworks_id
    status
    pubLink
    owner {
      _id
      username
      name
      avatar {
        _id
        key
        fileName
      }
    }
    favorite {
      _id
    }
    avatar {
      _id
      key
      fileName
    }
    artworks {
      _id
      url
      name
      images {
        _id
        key
        fileName
      }
    }
  }
`;

const fragmentAll = gql`
  fragment AlbumAllFragment on AlbumsAll {
    total
    items {
      _id
      url
      name
      likes
      myLike
      pubLink
      artworks_id
      status
      artworksCount
      owner {
        _id
        username
        name
        avatar {
          _id
          key
          fileName
        }
      }
      favorite {
        _id
      }
      avatar {
        _id
        key
        fileName
      }
      artworks {
        _id
        url
        name
        images {
          _id
          key
          fileName
        }
      }
    }
  }
`;

export const GQL_UPDATE_ALBUM = gql`
  mutation UpdateAlbum($data: AlbumInput) {
    updateAlbum(data: $data) {
      ...AlbumFragment
    }
  }
  ${fragment}
`;

export const GQL_REMOVE_ALBUM = gql`
  mutation RemoveAlbum($_id: String!) {
    removeAlbum(_id: $_id)
  }
`;

export const GQL_PUBLISH_ALBUM = gql`
  mutation PublishAlbum($_id: String!, $status: Int) {
    publishAlbum(_id: $_id, status: $status)
  }
`;

export const GQL_GET_ALBUMS_ALL = gql`
  query GetAlbumAll($my: Boolean, $query: QueryItems, $owner_id: String, $all: Boolean) {
    albumsAll(my: $my, query: $query, owner_id: $owner_id, all: $all) {
      ...AlbumAllFragment
    }
  }
  ${fragmentAll}
`;

export const GQL_GET_ALBUM = gql`
  query GetAlbum($_id: ID, $url: String, $edit: Boolean, $pubLink: String) {
    album(_id: $_id, url: $url, edit: $edit, pubLink: $pubLink) {
      ...AlbumFragment
    }
  }
  ${fragment}
`;
