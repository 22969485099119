import React from 'react';

const logout = ({ colorStroke }) => ({
  viewBox: '0 0 16 16',
  data: (
    <g>
      <path
        d={`M6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 
        2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 
        2.14048 2.97971 2 3.33333 2H6`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.666 11.3332L13.9993 7.99984L10.666 4.6665"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 8H6"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default logout;
