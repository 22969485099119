import React from 'react';

const info = ({ colorStroke }) => ({
  viewBox: '0 0 24 24',
  data: (
    <g>
      <line
        x1="12"
        y1="7"
        x2="12"
        y2="9"
        stroke={colorStroke}
        strokeWidth="2"
      />
      <line
        x1="12"
        y1="11"
        x2="12"
        y2="17"
        stroke={colorStroke}
        strokeWidth="2"
      />
      <circle
        cx="12"
        cy="12"
        r="11"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="2"
      />
    </g>
  ),
});

export default info;
