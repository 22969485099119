export default {
  crumb: 'Галереи и музеи',
  share_name: 'Поделиться музеем',
  title: 'Институция - «{{ name }}» | Артократия',
  description: 'Институция - «{{ name }}». Социальная сеть для ценителей искусства - Артократия',
  address: 'Адрес',
  curator: 'Куратор',
  why_not: 'Вы не можете отправить на публикацию, так как у нее нет фото и необходимой информации',
  alert_delete_moderation: 'Вы не можете совершать действия над элементом, так как он на модерации',
  alert_publish: 'Вы не можете отправить на публикацию, так как нет фото или названия',
  alert_type: 'Необходимо выбрать тип институции!',
  alert_address: 'Необходимо задать адрес институции!',
  alert_save: 'Вы успешно сохранили институцию!',
  alert_published: 'Институция отправлена на модерацию, ожидайте одобрения!',
  sure: 'Вы уверены, что хотите {{ action }} институцию «{{ name }}»?',
  my_institutions: 'Мои институции',
  my_institution: 'Моя институция',
  publish: 'институция',
  update: 'Обновить институцию «{{ name }}»',
  add: 'Добавить мероприятие',
  empty_add: 'Добавление институции',
  field_name: 'Название',
  placeholder_name: 'Введите название',
  field_description: 'Описание институции',
  placeholder_description: 'Введите описание',
  field_type: 'Тип институции',
  placeholder_type: 'Выберите тип',
  field_address: 'Адрес институции',
  field_contacts: 'Контактная информация',
  field_curator: 'Отображение куратора',
  do_not_show: 'Не показывать',
  show: 'Показывать',
  logo: 'Логотип',
  logo_info: 'Логотип должен быть хорошего качества, не менее 512 х 512 px и не более 2 МБ',
  upload_logo: 'Загрузить изображения',
  upload_logo_info: 'Воспользуйтесь кнопкой или перетащите {:} файл в эту область',
  photos: 'Фотографии институции',
  photos_info: 'Каждая фотография должна быть хорошего качества, не менее 1 000 х 512 px и не более 5 МБ. Первое фото основное для карточек',
  moderation: 'Институция на модерации, после рассмотрения она станет доступна для редактирования',
  total_exhibitions: 'Выставки институции ({{ total }})',
  exhibitions_placeholder: 'Введите название',
  type_select_info: 'Вы можете выбрать из своих локаций или создать новую. Чтобы создать, введите название и нажмите «Добавить»',
  type_my: 'Выбрать из моих',
  to_publish: 'Чтобы опубликовать, необходимо',
  fill_logo: 'Загрузить «Логотип»',
  fill: 'Заполнить «{{ name }}»',
};
