import React from 'react';

const favorite = ({ colorStroke }) => ({
  viewBox: '0 0 12 14',
  data: (
    <path
      d={`M6.52172 9.21327C6.21226 8.97683 
      5.77876 8.97683 5.46931 9.21327L1.71214 
      12.0841V1.55383H10.2789V12.0841L6.52172 
      9.21327ZM0.769801 1.21641V12.773C0.769801 
      13.2666 1.3464 13.537 1.73761 13.2381L5.99551 
      9.98469L10.2534 13.2381C10.6446 13.537 11.2212 
      13.2666 11.2212 12.773V1.21641C11.2212 0.889353 
      10.9511 0.627223 10.6215 0.627223H1.36947C1.03996 
      0.627223 0.769801 0.889353 0.769801 1.21641Z`}
      fill={colorStroke}
      stroke={colorStroke}
      strokeWidth="0.171335"
      strokeLinejoin="round"
    />
  ),
});

export default favorite;
