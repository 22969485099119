import React from 'react';

const news = ({ colorStroke }) => ({
  viewBox: '0 0 17 16',
  data: (
    <g>
      <path
        d={`M2.5 3.33333L2.5 12.6667C2.5 13.403 3.09695 14 3.83333 14L13.1667 
        14C13.903 14 14.5 13.403 14.5 12.6667L14.5 3.33333C14.5 2.59695 13.903 
        2 13.1667 2L3.83333 2C3.09695 2 2.5 2.59695 2.5 3.33333Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.16602 9.3335L5.16602 11.3335L10.4993 11.3335L10.4993 9.3335L5.16602 9.3335Z"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.16602 4.6665L5.16602 6.6665L10.4993 6.6665L10.4993 4.6665L5.16602 4.6665Z"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default news;
