import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Cookies from 'universal-cookie';

import { MAIN_GRAY } from '../helpers/colors';
import { useTranslation } from './hooks/useTranslation';

const HelloModal = ({ model }) => {
  const { t } = useTranslation();
  const cookies = new Cookies();
  const history = useHistory();
  const setViewed = () => {
    sessionStorage.removeItem('viewStartWindow');
    const goto = cookies.get('goto');
    if (goto) {
      cookies.remove('goto');
      history.push(goto);
    }
  };
  return (
    <Window>
      <View>
        <LeftPart>
          <div>
            <H1>{t('hello_name', { ns: 'signup', name: _.get(model, 'name', 'User') })}</H1>
            <Text>{t('hello_text', { ns: 'signup' })}</Text>
            <Button variant="primary" size="lg" onClick={setViewed}>
              {t('hello_go', { ns: 'signup' })}
            </Button>
          </div>
        </LeftPart>
        <RightPart />
      </View>
    </Window>
  );
};

const H1 = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  margin-bottom: 20px;
  @media screen and (max-width: 1024px) {
    font-size: 32px;
  }
  @media screen and (max-width: 500px) {
    font-size: 24px;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  margin-bottom: 30px;
  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
`;

const Window = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(5, 4, 54, 0.95);
  z-index: 1101;
  padding: 10px;
  overflow: auto;
`;

const View = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 1101;
  max-width: 1000px;
  background-color: ${MAIN_GRAY};
  overflow: scroll;
  margin-top: 65px;
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const LeftPart = styled.div`
  display: flex;
  justify-content: center;
  padding: 100px 50px;
  @media screen and (max-width: 1024px) {
    padding: 60px 40px;
  }
  @media screen and (max-width: 768px) {
    padding: 30px 20px;
  }
  @media screen and (max-width: 500px) {
    padding: 20px;
    button {
      width: 100%;
    }
  }
`;

const RightPart = styled.div`
  background-image: url('/images/hello.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 500px) {
    min-height: 40vh;
  }
`;

HelloModal.propTypes = {
  model: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
};

export default HelloModal;
