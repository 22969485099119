import React from 'react';

const verified = ({ colorFill }) => ({
  viewBox: '0 0 19 18',
  data: (
    <g>
      <circle
        cx="9.16797"
        cy="9"
        r="8.175"
        fill={colorFill}
        stroke={colorFill}
        strokeWidth="1.65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.168 7L7.66797 12L5.16797 9.72727"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default verified;
