import React from 'react';

const star = ({ colorFill }) => ({
  viewBox: '0 0 31 31',
  data: (
    <path
      d={`M15.6239 30.1785L14.0958 17.2641C14.0545 16.91 13.7677 16.6232 13.4136 16.5819L0.494025 15.0553C0.216277
            15.0232 0.204648 14.618 0.481004 14.6017L13.3359 13.8166C13.6883 13.7956 13.9591 13.5248 13.9801 13.1725L14.7668
            0.315864C14.7831 0.0395086 15.1866 0.0528203 15.2205 0.328886L16.7454 13.2501C16.7867 13.6043 17.0752 13.8928
            17.4294 13.9341L30.3675 15.4457C30.6453 15.4778 30.6569 15.883 30.3806 15.8993L17.5068 16.6925C17.1545 16.7135
            16.8837 16.9843 16.8627 17.3366L16.0758 30.1898C16.0595 30.4661 15.6561 30.4563 15.6239 30.1785Z`}
      fill={colorFill}
    />
  ),
});

export default star;
