import React from 'react';

const museum = ({ colorStroke }) => ({
  viewBox: '0 0 14 14',
  data: (
    <path
      d={`M3.57895 6.35H2.21053V10.9H3.57895V6.35ZM7.68421 
      6.35H6.31579V10.9H7.68421V6.35ZM13.5 12.2H0.5V13.5H13.5V12.2ZM11.7895 
      6.35H10.4211V10.9H11.7895V6.35ZM7 1.969L10.5647 3.75H3.43526L7 
      1.969ZM7 0.5L0.5 3.75V5.05H13.5V3.75L7 0.5Z`}
      fill={colorStroke}
    />
  ),
});

export default museum;
