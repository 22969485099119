export default {
  offers: 'Заявки',
  notes_input: 'Внести заметки',
  notes_label: 'Внести заметки для «{{ name }}»',
  notes_placeholder: 'Введите заметки',
  notes_exists: 'Есть заметки',
  status: 'Статус',
  edit_info: 'Это приватная информация, касающаяся работы: артикул, стоимость, состояние и пр. Видна она будет только Вам.',
  additional: 'Дополнительные данные',
  additional_sm: 'Дополнительно',
  no_name: 'Без названия',
  no_artist: 'Автор неизвестен',
  publish_name: 'работу',
  no_items: 'Ничего не найдено',
  no_request_result: 'По запросу «{{ request }}» ничего не найдено',
  no_filter_result: 'По выбранной фильтрации ничего не найдено',
  no_request_filter_result: 'По выбранной фильтрации и запросу «{{ request }}» ничего не найдено',
  remove_alert: 'Восстановление работы невозможно',
  no_item: 'У вас еще нет созданной коллекции',
  create: 'Желаете создать?',
  add: 'Добавить работу',
  title_collection: 'КАБИНЕТ - МОЯ КОЛЛЕКЦИЯ',
  my_collection: 'Моя коллекция',
  works: 'Работы',
  description: 'Описание',
  import: 'Импорт работ',
  title_artist_collection: 'КАБИНЕТ - МОИ РАБОТЫ',
  my_artworks: 'Мои работы',
  description_artist: 'Об авторе',
  order_info: 'Чтобы вы могли публиковать свои работы, необходимо',
  order_request: 'подать заявку',
  order_and: 'и',
  order_tariff: 'оформить тариф',
  order: 'Подать заявку',
  no_artist_item: 'У вас нет работ',
  attach: 'Добавлено',
  select_exhibitions: 'Выбрать выставку',
  go_exhibition: 'Выберите выставку',
  no_selected: 'Не выбраны работы',
  set_exhibition: 'Задайте выставку',
  show_all: 'Показать все',
  show_attached: 'Показать выбранные',
  market: 'Работы на маркете',
  main_market: 'Маркет',
  market_artists: 'Художники',
  market_dealers: 'Арт-Дилеры',
  market_collectors: 'Коллекционеры',
  market_galleries: 'Преддставители галерей',
  market_top: 'Топ работ',
  market_new: 'Новые работы',
  market_available: 'Доступные работы',
};
