export default {
  name: 'Пользователи',
  title: 'КАБИНЕТ - ПОЛЬЗОВАТЕЛИ',
  no_items: 'Нет пользователей',
  no_by_letter: 'По букве «{{ letter }}» пользователей не найдено',
  no_by_letter_and_request: 'По запросу «{{ request }}» и букве «{{ letter }}» ничего не найдено',
  go_artworks: 'Перейти к работам',
  market: 'Работы на маркете',
  main_market: 'Маркет',
};
