import React from 'react';

const send = ({ colorStroke }) => ({
  viewBox: '0 0 16 16',
  data: (
    <path
      d="M15.25 1.25586L7.275 8.67446M15.25 1.25586L10.175 14.7442L7.275 8.67446M15.25 1.25586L0.75 5.97679L7.275 8.67446"
      stroke={colorStroke}
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});

export default send;
