import { gql } from '@apollo/client';

const fragment = gql`
  fragment OfferWorkFragment on OfferWork {
    _id
    owner_id
    message
    user {
      _id
      name
      username
    }
    exhibition_id
    artworks_id
    status
    artworks {
      _id
      name
      url
      images {
        _id
        key
        fileName
      }
    }
  }
`;

export const GQL_GET_OFFER_WORKS_ALL = gql` 
  query GetOfferWorksAll($exhibition_id: ID) {
    offerWorksAll(exhibition_id: $exhibition_id) {
      ...OfferWorkFragment
    }
  }
  ${fragment}
`;

export const GQL_GET_OFFER_WORK = gql`
  query GetOfferWork($exhibition_id: ID) {
    offerWork(exhibition_id: $exhibition_id) {
      ...OfferWorkFragment
    }
  }
  ${fragment}
`;

export const GQL_UPDATE_OFFER_WORK = gql`
  mutation UpdateOfferWork($data: OfferWorkInput) {
    updateOfferWork(data: $data) {
      ...OfferWorkFragment
    }
  }
  ${fragment}
`;

export const GQL_CONFIRM_OFFER_WORK = gql`
  mutation ConfirmOfferWork($_id: String!) {
    confirmOfferWork(_id: $_id) {
      ...OfferWorkFragment
    }
  }
  ${fragment}
`;

// delete current for exhibition
export const GQL_REMOVE_OFFER_WORK = gql`
  mutation RemoveOfferWork($_id: String!) {
    removeOfferWork(_id: $_id)
  }
`;

// delete artwork from offer
export const GQL_REMOVE_OFFER_ONE_WORK = gql`
  mutation RemoveOfferOneWork($artwork_id: ID!, $offer_id: ID!) {
    removeOfferOneWork(offer_id: $offer_id, artwork_id: $artwork_id)
  }
`;
