import React from 'react';

const column = ({ colorStroke }) => ({
  viewBox: '0 0 23 22',
  data: (
    <g>
      <mask
        id="column-svg"
        fill="white"
      >
        <rect
          x="0.890625"
          y="0.0507812"
          width="22"
          height="22"
          rx="1"
        />
      </mask>
      <rect
        x="0.890625"
        y="0.0507812"
        width="22"
        height="22"
        rx="1"
        stroke={colorStroke}
        fill="none"
        strokeWidth="4"
        mask="url(#column-svg)"
      />
      <line
        y1="22"
        x1="9"
        y2="0.889484"
        x2="9"
        stroke={colorStroke}
        fill="none"
        strokeWidth="2"
      />
    </g>
  ),
});

export default column;
