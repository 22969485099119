import { gql } from '@apollo/client';

export const GQL_GET_SLIDERS = gql`
  query GetSliders($section: Int) {
    sliders(section: $section) {
      _id
      name
      section
      description
      button
      button_url
      button_color
      text_color
      status
      order
      mainImg
      mobileImg
    }
  }
`;
