import React from 'react';

const abuse = ({ colorStroke }) => ({
  viewBox: '0 0 14 14',
  data: (
    <g>
      <circle
        r="0.6"
        transform="matrix(1 0 0 -1 7.00059 9.83437)"
        fill={colorStroke}
        stroke={colorStroke}
        strokeWidth="0.115631"
      />
      <line
        x1="0.6"
        y1="-0.6"
        x2="4.17843"
        y2="-0.6"
        transform="matrix(4.37113e-08 1 1 -4.37113e-08 7.55432 3.5083)"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        r="5.94545"
        transform="matrix(1 0 0 -1 6.99998 6.99996)"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default abuse;
