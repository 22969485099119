/* eslint-disable react/no-unstable-nested-components,react/jsx-props-no-spreading,react/prop-types,no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import styled from 'styled-components';
import _ from 'lodash';
import {
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import Select, { components as ComponentSelect } from 'react-select';

import {
  ACTIVE,
  ACTIVE_LIGHT,
  DARK,
  DEFAULT_EXTRA,
  MAIN_GRAY,
  WARNING,
  WARNING_LIGHT,
} from '../../helpers/colors';
import { useTranslation } from '../hooks/useTranslation';
import ListWidget from '../ListWidget';
import {
  REG_ALL_CAPITALIZE,
  REG_FIRST_CAPITALIZE,
  REG_LOWCASE,
  REG_NORMAL,
  REG_UPPERCASE,
} from '../../helpers/main';

const ArtField = ({
  field,
  form: { touched, errors, setFieldValue },
  noOptionsMessage,
  loadingMessage,
  label,
  placeholder,
  disabled,
  isLoading,
  groupClassName,
  prepend,
  className,
  fieldAs,
  column,
  options,
  multiple,
  isClearable,
  selectCreate,
  onCreateOption,
  onInputChange,
  loadOptions,
  setChange,
  onInArray,
  listWidget,
  register,
  withImage,
  placeholderDark,
  async,
  defaultOptions,
}) => {
  const { t } = useTranslation();
  if (!noOptionsMessage) {
    noOptionsMessage = t('no_variants', { ns: 'action' });
  }
  if (!loadingMessage) {
    loadingMessage = t('loading', { ns: 'index' });
  }
  const CustomOption = (props) => {
    const { isSelected, innerProps, data } = props;
    const type = !!data.type && data.type === 'user';
    let color = (isSelected ? '#FFFFFF' : false);
    if (typeof data.sts !== 'undefined') {
      color = data.sts ? color : DEFAULT_EXTRA;
    }
    return (
      <Option
        {...props}
        list={listWidget ? 1 : 0}
        style={{
          backgroundColor: (isSelected ? ACTIVE_LIGHT : false),
          color,
        }}
        {...innerProps}
      >
        {withImage
        && typeof data.label[0] !== 'undefined'
        && typeof data.label[0] === 'string'
        && (data.image ? (
          <SelectImage
            rounded={type}
            src={`/files/${data.image}`}
          />
        ) : (
          <SelectLetter rounded={type}>
            {data.label[0].toUpperCase()}
          </SelectLetter>
        ))}
        {data.label}
      </Option>
    );
  };

  const MultiValueLabel = (props) => {
    const { data } = props;
    if (typeof data.sts !== 'undefined' && !data.sts) {
      return (
        <span className="moderation" title={t('moderation', { ns: 'action' })}>
          <ComponentSelect.MultiValueLabel {...props} />
        </span>
      );
    }
    return (<ComponentSelect.MultiValueLabel {...props} />);
  };

  const SingleValue = ({ children, ...props }) => {
    const { data } = props;
    if (typeof data.sts !== 'undefined' && !data.sts) {
      return (
        <span className="moderation" title={t('moderation', { ns: 'action' })}>
          <ComponentSelect.SingleValue {...props}>{children}</ComponentSelect.SingleValue>
        </span>
      );
    }
    return (<ComponentSelect.SingleValue {...props}>{children}</ComponentSelect.SingleValue>);
  };

  let transform = REG_NORMAL;
  let isFirstCapital = 0;

  switch (register) {
    case REG_LOWCASE:
      transform = REG_LOWCASE;
      break;
    case REG_UPPERCASE:
      transform = REG_UPPERCASE;
      break;
    case REG_FIRST_CAPITALIZE:
      transform = REG_NORMAL;
      isFirstCapital = 1;
      break;
    case REG_ALL_CAPITALIZE:
      transform = REG_ALL_CAPITALIZE;
      break;
    default:
      transform = REG_NORMAL;
      break;
  }

  const getColor = (data, single = false) => {
    let bg = '#FFFFFF';
    let color = single ? 'inherit' : `${ACTIVE} !important`;
    let border = single ? 0 : `1px solid ${ACTIVE}`;
    if (typeof data.sts !== 'undefined') {
      bg = data.sts ? bg : WARNING_LIGHT;
      color = data.sts ? color : `${WARNING} !important`;
      border = data.sts ? border : `1px solid ${WARNING}`;
    }
    return { bg, color, border };
  };

  const colourStyles = {
    control: (provided, state) => ({
      borderBottom: state.isFocused ? `1px solid ${ACTIVE}` : `1px solid ${MAIN_GRAY}`,
      borderRadius: 0,
      fontSize: 20,
      fontWeight: 300,
      marginTop: 0,
      marginRight: 10,
      minWidth: 60,
      padding: '0 0 13px',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
        padding: '0 0 2px',
        marginTop: -5,
      },
    }),
    multiValueRemove: (base) => ({
      ...base,
      backgroundColor: '#FFFFFF',
      border: 0,
      '&:hover, &:focus': {
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        border: 0,
        color: '#000',
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      fontWeight: 400,
      fontSize: 20,
      padding: 0,
    }),
    option: (provided, { isSelected }) => ({
      color: isSelected ? '#FFFFFF' : DARK,
      background: isSelected ? ACTIVE_LIGHT : MAIN_GRAY,
      fontSize: 20,
      padding: '25px 6px',
      textTransform: transform,
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
      textTransform: transform,
    }),
    multiValue: (styles, { data }) => ({
      ...styles,
      backgroundColor: '#FFFFFF',
      border: getColor(data).border,
      borderRadius: 2,
      color: getColor(data).color,
      padding: 0,
      fontSize: 20,
      fontWeight: 400,
      textTransform: transform,
      ' .moderation > div': {
        color: getColor(data).color,
      },
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
      },
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      padding: '0 3px',
      fontWeight: 400,
      fontSize: 20,
      borderRadius: 4,
      color: getColor(data, true).color,
      textTransform: transform,
      '> .moderation > div': {
        color: getColor(data, true).color,
      },
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
      },
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      padding: 0,
      fontSize: 20,
      fontWeight: 400,
      color: ACTIVE,
      textTransform: transform,
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      fontWeight: 300,
      fontSize: 20,
      color: placeholderDark ? DARK : '#B8BFCF',
      position: 'absolute',
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
      },
    }),
    indicatorSeparator: () => null,
    dropdownIndicator: (provided, state) => ({
      color: state.isFocused ? ACTIVE : DARK,
    }),
  };

  const components = listWidget ? {
    Option: CustomOption,
    MenuList: ListWidget,
    MultiValueLabel,
    SingleValue,
  } : {
    Option: CustomOption,
    MultiValueLabel,
    SingleValue,
  };

  let selectOptions = [];

  if (!_.isEmpty(options) && _.isArray(options)) {
    selectOptions = options.filter((o) => (
      (multiple || onInArray)
        ? (field.value || []).includes(o.value)
        : field.value === o.value));
  }

  const letChange = (val, action) => {
    if (val) {
      if (multiple) {
        setFieldValue(field.name, (val || []).map((v) => v.value));
      } else {
        setFieldValue(field.name, (onInArray ? [val.value] : val.value));
      }
    } else {
      setFieldValue(field.name, '');
    }
    setChange(action, val);
  };

  const customFilter = (option, searchText) => {
    let data = _.get(option, 'label') || _.get(option, 'name') || _.get(option, 'text');
    if (typeof data === 'string' && searchText) {
      data = data.trimStart();
      const query = searchText.split(' ');
      if (query.length > 1) {
        const reverse = searchText.toLowerCase().split(' ').reverse().join(' ');
        return data.toLowerCase().includes(searchText.toLowerCase())
          || data.toLowerCase().includes(reverse)
          || (data.toLowerCase().includes(_.get(query, '[0]').toLowerCase())
          && data.toLowerCase().includes(_.get(query, '[1]').toLowerCase()));
      }
      return data.toLowerCase().includes(searchText.toLowerCase());
    }
    return true;
  };

  const Component = async ? AsyncSelect : Select;

  const ComponentCreatable = async ? AsyncCreatableSelect : CreatableSelect;

  return (
    <Form.Group
      as={Row}
      controlId={field.name}
      className={`${column ? 'flex-column' : 'flex-row'} ${groupClassName}`}
    >
      {!!label && (
        <Form.Label
          column
          md={column ? 12 : 3}
        >
          {label}
        </Form.Label>
      )}

      <ColStyle transform={isFirstCapital} md={label && !column ? 9 : 12}>
        <InputGroup className={disabled ? 'disabled' : ''}>
          {!!prepend && (
            <InputGroup>
              <InputGroup.Text>
                {prepend}
              </InputGroup.Text>
            </InputGroup>
          )}
          {!selectCreate && (
            <Component
              components={components}
              onInputChange={onInputChange}
              placeholder={placeholder}
              loadOptions={loadOptions}
              disabled={disabled}
              isLoading={isLoading}
              isClearable={isClearable}
              isDisabled={isLoading || disabled}
              name={field.name}
              value={selectOptions}
              options={options}
              className={`${className} ${multiple ? 'multi' : ''} ${disabled && 'disabled'}`}
              as={fieldAs}
              isInvalid={touched[field.name] && errors[field.name]}
              isMulti={multiple}
              defaultOptions={defaultOptions}
              onChange={letChange}
              noOptionsMessage={() => noOptionsMessage}
              loadingMessage={() => loadingMessage}
              // inputValue={inputValue}
              styles={colourStyles}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#D7E3EB',
                  primary: ACTIVE,
                },
              })}
            />
          )}

          {selectCreate && (
            <ComponentCreatable
              onInputChange={onInputChange}
              components={components}
              placeholder={placeholder}
              loadOptions={loadOptions}
              isClearable={isClearable}
              disabled={disabled}
              isLoading={isLoading}
              isDisabled={isLoading || disabled}
              name={field.name}
              value={selectOptions}
              defaultOptions={defaultOptions}
              options={options}
              className={`${className} ${multiple ? 'multi' : ''} ${disabled && 'disabled'}`}
              as={fieldAs}
              isInvalid={touched[field.name] && errors[field.name]}
              isMulti={multiple}
              onChange={letChange}
              filterOption={customFilter}
              noOptionsMessage={() => noOptionsMessage}
              loadingMessage={() => loadingMessage}
              onCreateOption={onCreateOption}
              formatCreateLabel={(inputText) => (
                <Add>
                  <Plus />
                  {t('add', { ns: 'action' })}
                  {' "'}
                  {inputText}
                  {'"? '}
                </Add>
              )}
              isValidNewOption={(inputOption) => !!inputOption}
              styles={colourStyles}
              // inputValue={inputValue}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#D7E3EB',
                  primary: ACTIVE,
                },
              })}
            />
          )}

          <Form.Control.Feedback type="invalid">
            {errors[field.name]}
          </Form.Control.Feedback>
        </InputGroup>
      </ColStyle>
    </Form.Group>
  );
};

const ColStyle = styled(Col)`
${({ transform }) => transform && (`
div[type="option"] {
    display: block;
}
div {
  text-transform:lowercase;
  &:first-letter {text-transform:uppercase}
}
`)}
`;

const Add = styled.div`
display: flex;
align-items: center;
justify-content: center;
`;

const Plus = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 30px;
height: 30px;
border-radius: 50%;
background-color: #C4C4C4;
margin-right: 10px;
&:before, &:after {
  z-index: 2;
  content: " ";
  position: relative;
  display: block;
  width: 2.5px;
  height: 10px;
  background-color: ${ACTIVE};
  border-radius: 3px;
}
&:before {
  width: 2.5px;
  height: 10px;
  right: -5px;
}
&:after {
  height: 2.5px;
  width: 10px;
  left: -1px;
}
`;

const Option = styled.div`
color: inherit;
cursor: default;
display: flex;
height: ${({ list }) => (list ? 60 : 40)}px;
flex-direction: row;
align-items: center;
font-size: 20px;
padding: ${({ list }) => (list ? '20px 10px' : '4px 12px')};
width: 100%;
user-select: none;
-webkit-tap-highlight-color: rgba(0,0,0,0);
box-sizing: border-box;
&:hover{
   background-color: #D7E3EB;
}
&:focus{
   background-color: ${ACTIVE_LIGHT};
}
@media screen and (max-width: 500px) {
  font-size: 14px;
  line-height: 1.1;
}
`;

const SelectLetter = styled.div`
display: flex;
align-items: center;
justify-content: center;
border-radius: ${({ rounded }) => (rounded ? '50%' : '0')};
background: #C4C4C4;
position: relative;
z-index: 100;
font-size: 12px;
margin-right: 10px;
width: 30px;
height: 30px;
min-width: 30px;
min-height: 30px;
`;

const SelectImage = styled.img`
background-position: center;
background-repeat: no-repeat;
border-radius: ${({ rounded }) => (rounded ? '50%' : '0')};
position: relative;
z-index: 100;
margin-right: 10px;
width: 30px;
height: 30px;
`;

ArtField.defaultProps = {
  register: REG_NORMAL,
  noOptionsMessage: '',
  loadingMessage: '',
  disabled: false,
  onInArray: false,
  label: '',
  isLoading: false,
  groupClassName: '',
  prepend: '',
  className: 'w-100 select-control',
  setChange: () => {},
  onCreateOption: () => {},
  onInputChange: () => {},
  loadOptions: () => {},
  isClearable: false,
  fieldAs: 'div',
  selectCreate: false,
  multiple: false,
  options: [],
  listWidget: false,
  withImage: false,
  placeholderDark: false,
  async: false,
  defaultOptions: [],
};

ArtField.propTypes = {
  placeholderDark: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  listWidget: PropTypes.bool,
  register: PropTypes.string,
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  form: PropTypes.object.isRequired,
  noOptionsMessage: PropTypes.string,
  loadingMessage: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  groupClassName: PropTypes.string,
  prepend: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  fieldAs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  onInArray: PropTypes.bool,
  column: PropTypes.bool.isRequired,
  options: PropTypes.array,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  selectCreate: PropTypes.bool,
  onCreateOption: PropTypes.func,
  onInputChange: PropTypes.func,
  loadOptions: PropTypes.func,
  setChange: PropTypes.func,
  withImage: PropTypes.bool,
  async: PropTypes.bool,
  defaultOptions: PropTypes.array,
};

export default ArtField;
