export default {
  title: 'КАБИНЕТ - ЛОКАЦИИ',
  label: 'Локации',
  my: 'Мои локации',
  create_name: 'Создание новой локации',
  placeholder_search: 'Начните вводить название',
  show: 'Указать местоположение',
  showed: 'Указанное местоположение',
  type_selected: 'Выбранная локация',
  type_my: 'Выбрать из моих',
  type_my_info: 'Вы можете записать в свободной форме только название местоположения. Место сохранится в Ваших локациях',
  type_required_info: 'Название и описание только для вас. Адрес необходим для определения местоположения мероприятия',
  type_select_info: 'Вы можете выбрать из своих локаций или создать новую. Чтобы создать, введите название и нажмите «Добавить»',
  change: 'Изменить локацию',
  create: 'Задать новую локацию',
  name: 'Название места',
  name_required: 'Название обяязательно!',
  name_placeholder: 'Введите название',
  address: 'Адрес',
  address_placeholder: 'Начните вводить адрес (необязательно)',
  address_placeholder_require: 'Начните вводить адрес',
  updated: 'Ваше место успешно обновлено!',
  added: 'Ваше место успешно добавлено!',
  description: 'Описание',
  description_placeholder: 'Краткое описание места (необязательно)',
  sure_delete: 'Вы уверены, что хотите удалить локацию {{ name }}?',
  sure_delete_info: 'Она также будет удалена из всех работ к которым привязана',
  empty_text: 'У вас еще нет созданной локации',
  empty_create: 'Желаете создать?',
  empty_add: 'Добавить локацию',
  by_request_empty: 'Поиск по запросу «{{ search }}» не дал результатов',
  no_address: 'Без адреса',
  error_coordinates: 'Выберите локацию поиска',
  all_places_near: 'Все места',
  distances: 'Радиус км.',
  browser_place: 'Ваше место положение по браузеру',
  default_place: 'Ваше текущее положение не определено, по умолчанию задана Москва',
  selected_place: 'Место которое вы указали',
  on_map: 'на карте',
  hide_map: 'скрыть карту',
  reset_result: 'Сбросить текущий результат',
};
