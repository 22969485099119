import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Form, Row } from 'react-bootstrap';
import TimePicker from 'rc-time-picker';

import { useTranslation } from '../hooks/useTranslation';

const ArtWorktime = ({
  field,
  form: { errors, setFieldValue },
  label,
  groupClassName,
  className,
  column,
}) => {
  const { t } = useTranslation();
  return (
    <Form.Group
      as={Row}
      controlId={field.name}
      className={`d-flex ${column ? 'flex-column' : 'flex-row'} ${groupClassName}`}
    >
      {!!label && (
        <Form.Label
          column
          md={column ? 12 : 3}
        >
          {label}
        </Form.Label>
      )}

      <Col md={label && !column ? 9 : 12} className="d-flex">
        <TimePicker
          prefixCls="date-range"
          clearIcon="&times;"
          autoComplete="off"
          name={`${field.name}[0]`}
          value={_.get(field, 'value[0]', 0) ? moment.unix(+field.value[0], 'HH:mm') : null}
          minutesStep={5}
          placeholder={t('time_from', { ns: 'form' })}
          className={className}
          showSecond={false}
          onChange={(val) => setFieldValue(`${field.name}[0]`, val.unix())}
        />
        <TimePicker
          prefixCls="date-range"
          clearIcon="&times;"
          autoComplete="off"
          placeholder={t('time_to', { ns: 'form' })}
          name={`${field.name}[1]`}
          value={_.get(field, 'value[1]', 0) ? moment.unix(+field.value[1], 'HH:mm') : null}
          minuteStep={5}
          className={className}
          showSecond={false}
          onChange={(val) => setFieldValue(`${field.name}[1]`, val.unix())}
        />
        <Form.Control.Feedback type="invalid">
          {errors[field.name]}
        </Form.Control.Feedback>
      </Col>

    </Form.Group>
  );
};

ArtWorktime.defaultProps = {
  label: null,
  groupClassName: '',
  column: false,
  className: '',
};

ArtWorktime.propTypes = {
  field: PropTypes.object.isRequired,
  className: PropTypes.string,
  form: PropTypes.shape({
    errors: PropTypes.object,
    setFieldValue: PropTypes.func,
  }).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  groupClassName: PropTypes.string,
  column: PropTypes.bool,
};

export default ArtWorktime;
