import { gql } from '@apollo/client';

export const GQL_GET_MENU = gql`
  query GetMenu($code: String, $location: String) {
    menu(code: $code, location: $location) {
      top_text
      children {
        _id
        name
        url
        order
        style
        status
        class
        items {
           _id
           name
           url
           order
           style
           status
           class
        }
      }
    }
  }
`;
