import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import * as yup from 'yup';
import { Button, Modal } from 'react-bootstrap';
import { Field as FormikField, Form, Formik } from 'formik';

import styled from 'styled-components';
import { ArtField } from './forms';
import ModalCloseButton from './ModlaCloseButton';
import { yEvent } from './Tracker';

const FeedbackModal = ({
  show, onHide, onSubmit, t,
}) => {
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    description: '',
  };

  const phoneRegExp = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/;

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(t('required_field', { ns: 'form' })),
    phone: yup
      .string()
      .matches(phoneRegExp, t('error_number_format', { ns: 'form' }))
      .nullable(),
    email: yup
      .string()
      .email(t('error_email_format', { ns: 'form' }))
      .nullable()
      .required(t('required_field', { ns: 'form' })),
    description: yup
      .string()
      .nullable()
      .required(t('required_field', { ns: 'form' })),
  });

  const metrica = (target) => {
    yEvent(target);
  };

  return (
    <Modal size="lg" backdrop="static" show={show} onHide={onHide} centered>
      <div className="p-2 px-md-5 py-md-3">
        <ModalHeader>
          <CardName>{t('name_feedback', { ns: 'form' })}</CardName>
          <ModalCloseButton onHide={onHide} />
        </ModalHeader>
        <Modal.Body className="p-3">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="d-flex flex-column">
                <FormikField
                  name="name"
                  label={<H2>{t('name', { ns: 'form' })}</H2>}
                  className="form-control-lg"
                  placeholder={t('input_name', { ns: 'form' })}
                  column
                  component={ArtField}
                />

                <FormikField
                  name="email"
                  label={<H2>Email</H2>}
                  className="form-control-lg"
                  placeholder={t('input_email', { ns: 'form' })}
                  column
                  component={ArtField}
                />

                <FormikField
                  name="phone"
                  label={<H2>{t('phone', { ns: 'form' })}</H2>}
                  className="form-control-lg"
                  placeholder={t('input_format_phone', { ns: 'form', phone: '+7 000 000 00 00' })}
                  column
                  component={ArtField}
                />
                <div className="mb-3">
                  <FormikField
                    name="description"
                    label={<H2>{t('text', { ns: 'form' })}</H2>}
                    className="form-control-lg w-100"
                    fieldAs={TextareaAutosize}
                    placeholder={t('input_feedback', { ns: 'form' })}
                    component={ArtField}
                    column
                  />
                </div>
                <Button
                  size="lg"
                  className="float-end"
                  variant="primary"
                  type="submit"
                  onClick={() => metrica('send_footer')}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? t('await', { ns: 'action' }) : t('send', { ns: 'action' })}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </div>
    </Modal>
  );
};

const ModalHeader = styled.div`
  display: flex;
  padding: 1rem;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 500px) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

const CardName = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 120%;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    text-align: center;
  }
`;

const H2 = styled.h2`
  font-weight: bold;
  display: block;
  font-size: 24px;
  line-height: 120%;
  margin-top: 15px;
  @media screen and (max-width: 1024px) {
    font-size: 20px;
  }
  @media screen and (max-width: 812px) {
    font-size: 16px;
  }
`;

FeedbackModal.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FeedbackModal;
