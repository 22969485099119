/* eslint-disable no-restricted-syntax */
import _ from 'lodash';

import { LANG_RU } from '../locales';
import { ACTIVE, MAIN_GRAY } from './colors';
import {
  ARTWORK_SECTION, CURRENCY_DOL, CURRENCY_EUR, CURRENCY_RUB,
} from './main';

export const ARTWORK_PRICE = 120;

export const SYSTEM_YANDEX = 'yandex';
export const SYSTEM_STRIPE = 'stripe';
export const SYSTEM_TINKOFF = 'tinkoff';
export const SYSTEM_SELF = 'self';

export const PAYMENT_WAITING = 'waiting_for_capture';
export const PAYMENT_PENDING = 'pending';
export const PAYMENT_SUCCEEDED = 'succeeded';
export const PAYMENT_CANCELED = 'canceled';
export const PAYMENT_ERROR = 'error';

export const MODE_MONTH = 'month';
export const MODE_YEAR = 'year';
export const MODE_SINGLE = 'single_pay';
export const MODE_YEAR_INSTALLMENTS = 'year_installments';
export const MODE_INSTALLMENTS = 'installments';
export const MODE_NORMAL = 'normal';
export const MODE_MODULES = 'modules';

export const PERIOD_TYPE = 'period';
export const EDUCATION_TYPE = 'education';

export const FULL_DISCOUNT = 'full_discount';

export const EXTEND_PAYMENT = 'extend_payment';
export const NEXT_PAYMENT = 'next_payment';
export const FULL_PAYMENT = 'early_full_repayment';
export const ONE_TIME_PAYMENT = 'one_time_payment';

export const INSTALLMENT_0_0_3_4 = 'installment_0_0_3_4,34';
export const INSTALLMENT_0_0_4_5 = 'installment_0_0_4_5,38';
export const INSTALLMENT_0_0_6_7 = 'installment_0_0_6_7';
export const INSTALLMENT_10_13_42 = 'installment_0_0_10_13,42';
export const INSTALLMENT_12_15_59 = 'installment_0_0_12_15,59';

export const INSTALLMENTS_TYPES = [
  { value: INSTALLMENT_0_0_3_4, label: '3 месяца', count: 3 },
  { value: INSTALLMENT_0_0_4_5, label: '4 месяца', count: 4 },
  { value: INSTALLMENT_0_0_6_7, label: '6 месяцев', count: 6 },
  { value: INSTALLMENT_10_13_42, label: '10 месяцев', count: 10 },
  { value: INSTALLMENT_12_15_59, label: '12 месяцев', count: 12 },
];

/**
 * @name paymentProcess
 * @param {string}   token
 * @param {array}    refetchQueries refetch query after payment
 * @param {function} payStatus call mutation
 * @param {function} onSuccess callback on success
 * @param {function} onCancel callback on canceled
 * @param {function} onFiled callback on error
 * @param {string}   pathGql path get status from gql
 *
 * @example Put to upper component
 * const [payStatus] = useMutation(GQL_PAY_STATUS);
 * Put to render
 * <div id="payment-form" />
 * */

export const paymentProcess = ({
  token,
  refetchQueries,
  payStatus = () => {},
  onSuccess = () => {},
  onFiled = () => {},
  onCancel = () => {},
  pathGqlData = 'data.payStatus',
}) => {
  // eslint-disable-next-line no-undef
  const checkout = new YooMoneyCheckoutWidget({
    language: LANG_RU,
    confirmation_token: token,
    // return_url,
    payment_methods: ['yoo_money', 'bank_card', 'sberbank'],
    customization: {
      modal: true,
      colors: {
        controlPrimary: ACTIVE,
        controlPrimaryContent: '#FFFFFF',
        background: MAIN_GRAY,
        border: 'rgba(0,0,0,0.3)',
      },
    },
    error_callback(error) {
      // eslint-disable-next-line no-console
      console.log(error);
    },
  });
  checkout.on('success', async () => {
    const resStatus = await payStatus({
      variables: {
        status: PAYMENT_SUCCEEDED,
        token,
      },
      refetchQueries,
    });
    const status = _.get(resStatus, pathGqlData, null);
    if (status) {
      if (status === PAYMENT_PENDING) {
        const res = await payStatus({
          variables: {
            status: PAYMENT_SUCCEEDED,
            token,
          },
          refetchQueries,
        });
        const firstStatus = _.get(res, pathGqlData, null);
        if (firstStatus === PAYMENT_SUCCEEDED) {
          onSuccess();
          checkout.destroy();
          return true;
        }
        const timer = setTimeout(async () => {
          const reStatus = await payStatus({
            variables: {
              status: PAYMENT_SUCCEEDED,
              token,
            },
            refetchQueries,
          });
          const nextStatus = _.get(reStatus, pathGqlData, null);
          if (nextStatus === PAYMENT_SUCCEEDED) {
            onSuccess();
            clearInterval(timer);
            checkout.destroy();
            return true;
          }
          return true;
        }, 1000);
      } else if (status === PAYMENT_SUCCEEDED) {
        onSuccess();
        checkout.destroy();
        return true;
      } else {
        onFiled();
        return false;
      }
    } else {
      onFiled();
      return false;
    }
    return true;
  });
  checkout.on('fail', async () => {
    await payStatus({
      variables: {
        status: PAYMENT_ERROR,
        token,
      },
      refetchQueries,
    });
    checkout.destroy();
    onFiled();
    return false;
  });
  checkout.on('modal_close', async () => {
    await payStatus({
      variables: {
        status: PAYMENT_CANCELED,
        token,
      },
      refetchQueries,
    });
    onCancel();
    return true;
  });
  checkout.render('payment-form');
};

export const financial = (rawPrice) => (Number.parseFloat(rawPrice).toFixed(2) * 100) / 100;

export const getCurrencyName = (currency) => {
  const currencyRubLang = 'рублей';
  const currencyEurLang = 'евро';
  return currency === CURRENCY_RUB ? currencyRubLang : currencyEurLang;
};

export const getCurrencyIcon = (currency) => {
  const currencyRub = '₽';
  const currencyEur = '€';
  const currencyDol = '$';
  switch (currency) {
    case CURRENCY_DOL:
      return currencyDol;
    case CURRENCY_EUR:
      return currencyEur;
    default:
      return currencyRub;
  }
};

export const isTariffPaid = (payments, setIsPaid) => {
  let isPaid = false;
  if (!_.isEmpty(payments)) {
    for (const payment of payments) {
      if (_.get(payment, 'items') && !_.isEmpty(payment.items)) {
        for (const pay of payment.items) {
          if (pay.model !== ARTWORK_SECTION) {
            const unixTimeNow = Math.floor(Date.now() / 1000);
            if (pay.expired > unixTimeNow) {
              isPaid = pay;
              break;
            }
          }
        }
      }
    }
  }
  setIsPaid(isPaid);
};
