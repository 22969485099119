import React from 'react';

const email = ({ colorFill }) => ({
  viewBox: '0 0 15 18',
  data: (
    <g>
      <mask
        id="pathMask2"
        fill="white"
      >
        <rect
          y="0.695312"
          width="18"
          height="14.2105"
          rx="0.915837"
        />
      </mask>
      <rect
        y="0.695312"
        width="18"
        height="14.2105"
        rx="0.915837"
        stroke={colorFill}
        strokeWidth="3.51579"
        mask="url(#pathMask2)"
      />
      <path
        d="M16.7683 2.58984L8.87731 9.49445L0.986328 2.58984"
        stroke={colorFill}
        strokeWidth="1.75789"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default email;
