export default {
  picker: {
    search: 'Поиск',
    clear: 'Очистить',
    notfound: 'Ничего не найдено',
    skintext: 'Выбирите цвет умолчанию',
    categories: {
      search: 'Результаты поиска',
      recent: 'Часто используемые',
      smileys: 'Смайлы',
      people: 'Смайлы',
      nature: 'Животные и природа',
      foods: 'Еда и напитки',
      activity: 'Активности',
      places: 'Путешествия и места',
      objects: 'Объекты',
      symbols: 'Символы',
      flags: 'Флаги',
      custom: 'Пользовательские',
    },
    categorieslabel: 'Категории',
    skintones: {
      1: 'Стандартный',
      2: 'Светлый',
      3: 'Полусветлый',
      4: 'Средний',
      5: 'Полутемный',
      6: 'Темный',
    },
  },
};
