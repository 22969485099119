import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, InputGroup, Row,
} from 'react-bootstrap';
import _ from 'lodash';

const ArtField = ({
  id,
  field,
  form,
  label,
  placeholder,
  type,
  disabled,
  groupClassName,
  // defaultValue,
  inputGroupClass,
  prepend,
  append,
  readOnly,
  required,
  fieldAs,
  column,
  onBlur,
  onFocus,
  rows,
  colClass,
  onChange,
  className,
}) => {
  // const touched = _.get(form, 'touched', {});
  const errors = _.get(form, 'errors', {});
  return (
    <Form.Group
      as={Row}
      controlId={id || field.name}
      className={`d-flex ${column ? 'flex-column' : 'flex-row'} ${groupClassName}`}
    >
      {!!label && (
        <Form.Label
          column
          md={column ? 12 : 3}
        >
          {label}
        </Form.Label>
      )}

      <div className={`col-lg-${label && !column ? 9 : 12} ${colClass}`}>
        {['text', 'password', 'textarea'].includes(type) && (
          <>
            <InputGroup bsPrefix={`input-group ${inputGroupClass}`} hasValidation>
              {!!prepend && (
                <InputGroup.Text className="bg-transparent border-0">
                  {prepend}
                </InputGroup.Text>
              )}
              <Form.Control
                name={field.name}
                type={type}
                onBlur={onBlur}
                onFocus={onFocus}
                className={className}
                placeholder={placeholder}
                autoComplete="new-password"
                disabled={disabled}
                readOnly={readOnly}
                value={field.value || ''}
                // defaultValue={defaultValue}
                as={fieldAs}
                rows={rows}
                onChange={(e) => form.setFieldValue(field.name, e.target.value) && onChange(e)}
                isInvalid={errors[field.name] && required}
              />

              {!!append && (
                <InputGroup.Text className="bg-transparent border-0">
                  {append}
                </InputGroup.Text>
              )}

              {/* TODO: move this error message outside InputGroup */}
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
            </InputGroup>

            <Form.Control.Feedback type="invalid">
              {errors[field.name]}
            </Form.Control.Feedback>
          </>
        )}
      </div>
    </Form.Group>
  );
};

ArtField.defaultProps = {
  id: null,
  label: '',
  placeholder: '',
  type: 'text',
  disabled: false,
  groupClassName: '',
  inputGroupClass: '',
  readOnly: false,
  required: true,
  prepend: false,
  append: false,
  fieldAs: 'input',
  column: false,
  rows: 3,
  colClass: '',
  className: '',
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
};

ArtField.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  colClass: PropTypes.string,
  type: PropTypes.string,
  // defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  groupClassName: PropTypes.string,
  inputGroupClass: PropTypes.string,
  prepend: PropTypes.any,
  append: PropTypes.any,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  fieldAs: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  column: PropTypes.bool,
  rows: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default ArtField;
