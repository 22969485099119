import { gql } from '@apollo/client';

const fragment = gql`
  fragment ViewingRoomFragment on ViewingRoom {
    _id
    name
    description
    created_at
    updated_at
    header
    url
    attach_price
    rent_price
    cover {
      _id
      key
      fileName
    }
    images {
      _id
      type
      ownerId
      rank
      description
      name
    }
    meta {
      title
      description
    }
  }
`;

export const GQL_GET_VIEWING_ROOM_ALL = gql`
  query GetViewingRoomAll($query: QueryItems) {
    viewingRoomAll(query: $query) {
      total
      items {
        _id
        url
        name
        cover {
          _id
          key
          fileName
        }
      }
    }
  }
`;

export const GQL_GET_VIEWING_ROOM = gql`
  query GetViewingRoom($url: String) {
    viewingRoom(url: $url) {
      ...ViewingRoomFragment
    }
  }
  ${fragment}
`;
