export default {
  title: 'КАБИНЕТ - НОВОСТИ',
  title_post: 'КАБИНЕТ - НОВОСТЬ',
  name: 'Пост',
  updated: 'Ваш пост обновлен',
  published: 'Ваш пост опубликован',
  sure_delete: 'Вы уверены, что хотите удалить Вашу публикацию?',
  recovery_impossible: 'Восстановление невозможно',
  send_abuse: 'Вы уверены, что хотите подать жалобу на пост?',
  recive_abuse: 'Жалоба на пост получена!',
  text_required: '{{ name }}, для публикации заполните текст',
  user: 'Пользователь',
  news: 'Новости',
  empty: 'Постов друзей больше нет',
  create: 'Создать публикацию',
  share_post: '{{ user }}, поделитесь с друзьями новостями',
  publish_info: 'Будет опубликовано вместе с вашим комментарием',
  remove: 'Удалить пост',
  abuse: 'Подать жалобу',
  open: 'Открыть отдельно',
  link: 'ссылка',
  user_empty: 'У пользователя нет публикаций',
  user_end: 'У пользователя больше нет публикаций',
  interesting: 'Интересно?',
  interesting_text: 'Просмотр контента доступен только для зарегистрированных пользователей Артократии. Авторизуйтесь на платформе и получите неограниченный доступ к интересным материалам об искусстве.',
  login_register: 'Войти или зарегистрироваться',
};
