import { gql } from '@apollo/client';

const userFragment = gql`
  fragment UserFragment on User {
    _id
    artist_id
    username
    name
    countArtworks
    attitude
    likes
    myLike
    description
    about_collection
    collection_date
    status_page
    role
    role_name
    socials
    created_at
    updated_at
    is_real
    last_seen
    favorite {
      _id
    }
    artist {
      _id
      url
      name
      avatar {
        _id
        key
        fileName
      }
    }
    artworks {
      _id
      url
      likes
      name
      age
      images {
        _id
        key
        fileName
        format
      }
    }
    wrapper {
      _id
      key
      fileName
    }
    cover {
      _id
      key
      fileName
    }
    avatar {
      _id
      key
      fileName
    }
  }
`;

const meFragment = gql`
  fragment MeFragment on Me {
    _id
    artist_id
    username
    signed_up
    name
    countArtworks
    email
    new_email
    attitude
    notification_email
    likes
    myLike
    phone
    description
    about_collection
    collection_date
    status_page
    status
    role
    trust
    load_on_login
    visibility
    first_me
    deleted_at
    new_login
    new_offers
    new_news
    new_messages
    new_comments
    new_friendships
    new_posts
    art_likes
    socials
    created_at
    updated_at
    json_link
    language
    is_real
    verified
    unread_messages_count
    unread_notifications
    favorite {
      _id
    }
    artist {
      _id
      url
      name
      avatar {
        _id
        key
        fileName
      }
    }
    artworks {
      _id
      url
      likes
      name
      age
      images {
        _id
        key
        fileName
        format
      }
    }
    tariff {
      priority
    }
    wrapper {
      _id
      key
      fileName
    }
    cover {
      _id
      key
      fileName
    }
    avatar {
      _id
      key
      fileName
    }
  }
`;

const userAllFragment = gql`
  fragment UserAllFragment on UsersAll {
    total
    meta {
      title
      description
      created_at
      updated_at
      page_name
      page_description
    }
    items {
      ...UserFragment
    }
  }
  ${userFragment}
`;

export const GQL_LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      ...MeFragment
    }
  }
  ${meFragment}
`;

export const GQL_SIGNUP = gql`
  mutation Signup($name: String!, $email: String!, $phone: String!, $password: String!) {
    signup(name: $name, email: $email, phone: $phone, password: $password) {
      ...MeFragment
    }
  }
  ${meFragment}
`;

export const GQL_SIGNUP_COMPLETE = gql`
  mutation SignupComplete($token: String!, $role: String!) {
    signupComplete(token: $token, role: $role) {
      ...MeFragment
    }
  }
  ${meFragment}
`;

export const GQL_USER_RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export const GQL_USER_RESET_PASSWORD_COMPLETE = gql`
  mutation ResetPasswordComplete($token: String!, $password: String!) {
    resetPasswordComplete(token: $token, password: $password)
  }
`;

export const GQL_USER_CHANGE_PASSWORD = gql`
  mutation UserChangePassword($password_old: String!, $password_new: String!) {
    userChangePassword(password_old: $password_old, password_new: $password_new)
  }
`;

export const GQL_USER_TOGGLE_DELETED = gql`
  mutation UserToggleDeleted {
    userToggleDeleted
  }
`;

export const GQL_ME_UPDATE = gql`
  mutation MeUpdate($me: UserInput) {
    meUpdate(me: $me) {
      ...MeFragment
    }
  }
  ${meFragment}
`;

export const GQL_GET_ME = gql`
  query GetMe {
    me {
      ...MeFragment
    }
  }
  ${meFragment}
`;

export const GQL_GET_USER_PAYMENTS = gql`
  query GetUserPayments($with_card: Boolean) {
    userPayments(with_card: $with_card) {
      _id
      limit {
        can
        count
        published
      }
      card {
        first6
        last4
        expiry_year
        expiry_month
        card_type
      }
      items {
        expired
        model
        mode
        tariff {
          _id
          name
          name_for
          status
          price_month
          price_year
          price_euro_month
          price_euro_year
          discount
          discount_from
          discount_to
          discount_month
          discount_month_from
          discount_month_to
        }
        artwork {
          _id
          name
          url
        }
      }
    }
  }
`;

export const GQL_GET_USERS = gql`  
  query GetUsers($role: String, $query: QueryItems, $all: Boolean, $collectors: Boolean, $user_id: ID, $section: String) {
    usersAll(role: $role, query: $query, all: $all, collectors: $collectors, user_id: $user_id, section: $section) {
      ...UserAllFragment
    }
  }
  ${userAllFragment}
`;

export const GQL_GET_USER_INFO = gql`
  query GetUserInfo($_id: ID) {
    userInfo(_id: $_id) {
      count_artworks
      count_market
      count_friends
      count_subscribers
      friends {
        _id
        name
        username
        avatar {
          key
        }
      }
      subscribers {
        _id
        name
        username
        avatar {
          key
        }
      }
    }
  }
`;

export const GQL_GET_USER = gql`
  query GetUser($_id: ID, $username: String, $collection: Boolean, $redirect: Boolean) {
    user(_id: $_id, username: $username, collection: $collection, redirect: $redirect) {
      ...UserFragment
    }
  }
  ${userFragment}
`;

export const GQL_CHANGE_EMAIL_TOKEN = gql`
 mutation UserChangeEmail($token: String!) {
    userChangeEmail(token: $token)
 }
`;

export const GQL_RESEND_ACTIVATION = gql`
  mutation ResendActivation($email: String!) {
    resendActivation(email: $email)
  }
`;

export const GQL_GET_USER_EMAIL_TOKEN = gql`
  query GetUserEmailToken($token: String) {
    userEmailToken(token: $token) {
      ...MeFragment
    }
  }
  ${meFragment}
`;

export const GQL_GET_USER_RESET_TOKEN = gql`
  query GetUserResetToken($token: String) {
    userResetToken(token: $token)
  }
`;

export const GQL_USER_FRIENDSHIP_PROCESS = gql`
  mutation ProcessFriendship($userId: ID, $action: String) {
     processFriendship(userId: $userId, action: $action)
  }
`;

export const GQL_GET_POPULAR_USERS = gql`
  query GetPopularUsers($model: String!) {
    popularUsers(model: $model) {
      _id
      username
      name
      avatar {
        _id
        key
        fileName
      }
      countArtworks
    }
  }
`;
