/* eslint-disable no-shadow,react/no-array-index-key  */
import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { Field } from 'formik';
import _ from 'lodash';
import TextareaAutosize from 'react-textarea-autosize';

import { HISTORY_TYPES } from '../../helpers/atworks';
import { useTranslation } from '../hooks/useTranslation';
import ArtSelect from './ArtSelect';
import {
  CURRENCIES,
} from '../../helpers/main';
import ArtField from './ArtField';
import {
  ButtonControl,
  DefaultLabel,
  H3,
} from '../../helpers/accountStyles';

const ArtHistory = ({
  form,
  form: { setFieldValue },
  name,
  push,
  remove,
  label,
  labelOutside,
  className,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({});
  const handleChangeFor = (propertyName, value, isNumber = false) => {
    if (isNumber) {
      if (value) {
        const newText = value.trim().replace(',', '.');
        const is = /^[\d]{0,10}?[.]?([\d]{0,2})$/.test(newText);
        if (is) {
          setValues({ ...values, [propertyName]: newText });
          setFieldValue(propertyName, newText);
          return true;
        }
        return false;
      }
      setValues({ ...values, [propertyName]: '' });
      setFieldValue(propertyName, '');
      return true;
    }
    setValues({ ...values, [propertyName]: value });
    setFieldValue(propertyName, value);
    return true;
  };

  useEffect(() => {
    setValues(_.get(form, 'values'));
  }, [_.get(form, 'values')]);

  return (
    <div id="history">
      {labelOutside && label}
      <Form.Group controlId={name}>
        {_.get(values, `${name}`, []).map((item, index) => {
          let errors = null;
          if (!!form.errors
            && !!form.errors[name]
            && !!form.errors[name][index]) {
            errors = form.errors[name][index];
          }
          return (
            <div
              key={`artwork${name}-${index}`}
              className={className}
            >
              {_.get(errors, 'main', false) && (
                <div className="badge badge-pill position-absolute mt-n4 badge-danger">
                  {errors.main}
                </div>
              )}

              <Field
                label={(<H3 start={1}>{t('history_type', { ns: 'secret' })}</H3>)}
                component={ArtSelect}
                column
                placeholder={t('history_type_placeholder', { ns: 'secret' })}
                name={`${name}.${index}.type`}
                defaultValue={
                      Object
                        .keys(HISTORY_TYPES)
                        .map((value) => ({ label: HISTORY_TYPES[value], value }))
                        .filter((o) => _.get(values, `${name}[${index}].type`) === o.value)
                    }
                options={Object.keys(HISTORY_TYPES).map((value) => ({
                  label: HISTORY_TYPES[value], value,
                }))}
                isMulti={false}
                onChange={(val) => handleChangeFor(`${name}.${index}.type`, val.value)}
                className={`form-control border-bottom-0 form-control-lg 
                ${_.get(errors, 'type') ? 'is-invalid' : ''}`}
              />

              {_.get(errors, 'type') && (
                <div className="badge position-absolute mt-n4 badge-pill badge-danger">
                  {_.get(errors, 'type')}
                </div>
              )}

              <Row>
                <Col lg={6}>
                  <H3 start={0}>
                    {t('history_date', { ns: 'secret' })}
                    <DefaultLabel>
                      {t('not_necessary', { ns: 'index' })}
                    </DefaultLabel>
                  </H3>
                  <Field
                    name={`${name}[${index}].dates`}
                    placeholder={t('period_placeholder', { ns: 'secret' })}
                    className="form-control-lg"
                    component={ArtField}
                  />
                </Col>
                <Col lg={6}>
                  <H3 start={0}>
                    {t('history_price', { ns: 'secret' })}
                    <DefaultLabel>
                      {t('not_necessary', { ns: 'index' })}
                    </DefaultLabel>
                  </H3>
                  <InputGroup>
                    <Form.Control
                      placeholder={t('history_price_placeholder', { ns: 'secret' })}
                      name={`${name}.${index}.price`}
                      value={_.get(values, `${name}[${index}].price`)}
                      onChange={(e) => handleChangeFor(
                        `${name}[${index}].price`,
                        _.get(e, 'target.value', ''),
                        true,
                      )}
                      className={`form-control-lg ${_.get(errors, 'price') ? 'is-invalid' : ''}`}
                    />
                    <Field
                      component={ArtSelect}
                      placeholder={t('history_currency', { ns: 'secret' })}
                      options={CURRENCIES}
                      name={`${name}.${index}.currency`}
                      value={_.get(values, `${name}.${index}.currency`)}
                      onChange={(e) => handleChangeFor(
                        `${name}.${index}.currency`,
                        _.get(e, 'target.value', ''),
                      )}
                      column
                      className={`form-control-lg border-bottom-0 ${!!form.errors
                          && !!form.errors[name]
                          && !!form.errors[name][index]
                          && !!form.errors[name][index].currency ? 'is-invalid' : ''}`}
                    />
                  </InputGroup>
                </Col>
              </Row>

              {_.get(errors, 'price') && (
                <div className="badge badge-pill position-absolute mt-n4 badge-danger">
                  {_.get(errors, 'price')}
                </div>
              )}
              {_.get(errors, 'currency') && (
                <div className="badge badge-pill position-absolute mt-n4 badge-danger">
                  {_.get(errors, 'currency')}
                </div>
              )}

              <div className="my-5">
                <H3 start={0}>
                  {t('history_location', { ns: 'secret' })}
                  <DefaultLabel>
                    {t('not_necessary', { ns: 'index' })}
                  </DefaultLabel>
                </H3>

                <Field
                  component={ArtField}
                  placeholder={t('history_location_placeholder', { ns: 'secret' })}
                  name={`${name}.${index}.place`}
                  defaultValue={_.get(values, `${name}[${index}].place`)}
                  value={_.get(values, `${name}[${index}].place`)}
                  onChange={(e) => handleChangeFor(
                    `${name}[${index}].place`,
                    _.get(e, 'target.value', ''),
                  )}
                  className={`form-control-lg ${_.get(errors, 'place') ? 'is-invalid' : ''}`}
                />

                {_.get(errors, 'place') && (
                  <div className="badge badge-pill position-absolute mt-n4 badge-danger">
                    {_.get(errors, 'place')}
                  </div>
                )}
              </div>
              <H3 start={0}>
                {t('history_description', { ns: 'secret' })}
                <DefaultLabel>
                  {t('not_necessary', { ns: 'index' })}
                </DefaultLabel>
              </H3>
              <Form.Control
                as={TextareaAutosize}
                placeholder={t('history_description_placeholder', { ns: 'secret' })}
                name={`${name}.${index}.notes`}
                defaultValue={_.get(values, `${name}[${index}].notes`)}
                value={_.get(values, `${name}[${index}].notes`)}
                onChange={(e) => handleChangeFor(
                  `${name}.${index}.notes`,
                  _.get(e, 'target.value', ''),
                )}
                className={`d-flex flex-2 mt-3 mb-3 form-control form-control-lg 
                ${_.get(errors, 'notes') ? 'is-invalid' : ''}`}
              />

              {_.get(errors, 'notes') && (
                <div className="badge badge-pill position-absolute mt-n4 badge-danger">
                  {_.get(errors, 'notes')}
                </div>
              )}

              <Button
                type="button"
                variant="outline-black"
                onClick={() => remove(index)}
                className="mt-2 rounded-0 px-5 col-md-auto"
                size="wsm"
              >
                {t('history_delete', { ns: 'secret' })}
              </Button>
            </div>
          );
        })}
      </Form.Group>

      <ButtonControl>
        <Button
          type="button"
          onClick={() => push({ type: null })}
          size="xl"
          className="px-5 my-3 my-md-0"
          variant="outline-primary"
        >
          {t('history_add', { ns: 'secret' })}
        </Button>
      </ButtonControl>
    </div>
  );
};

ArtHistory.defaultProps = {
  label: '',
  className: '',
  labelOutside: true,
};

ArtHistory.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  className: PropTypes.string,
  labelOutside: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default ArtHistory;
