export default {
  present: 'Презентация нового продукта',
  phone: 'Номер телефона',
  placeholder: 'Ваш номер телефона',
  name: 'ФИО',
  placeholder_name: 'Ваше ФИО',
  email: 'E-mail',
  placeholder_email: 'Ваш e-mail',
  send: 'Купить запись вебинара',
  no: 'Нет, спасибо',
  success: 'Спасибо! Вы успешно зарегистрированы!',
  thanks: 'Спасибо! Ваша заявка отправлена.',
  redirect: 'Сейчас вы будете перенаправлены в группу в телеграм. В группу будет отправлена ссылка на событие.',
  go: 'Перейти в телеграм',
  phone_required: 'Телефон обязательно!',
  email_require: 'E-mail обязательно!',
  name_required: 'ФИО обязательно!',
  was_sent: 'Спасибо! Вы успешно зарегистрированы, если еще этого не сделали, то перейдите по ссылке и подключитесь к телеграмм каналу',
  fill_form: 'Заполняйте форму, присоединяйтесь к встрече и получите новые возможности на платформе Артократия самыми первыми.',
  required_phone: 'Телефон - обязательное поле',
  discount: 'Зарегистрированным пользователям скидка {{ discount }} %',
  success_payed: 'Успешная оплата!',
  error: 'Ошибка! Оплата не прошла',
  no_close: 'Не закрывайте страницу!',
  send_price: 'Купить запись вебинара и оплатить {{ price }} руб',
};
