import React from 'react';

const video = ({ colorStroke }) => ({
  viewBox: '0 0 25 24',
  data: (
    <g>
      <g clipPath="url(#video-svg)">
        <path
          d="M23.5 7L16.5 12L23.5 17V7Z"
          stroke={colorStroke}
          fill="transparent"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d={`M14.5 5H3.5C2.39543 5 1.5 5.89543 1.5 7V17C1.5 
          18.1046 2.39543 19 3.5 19H14.5C15.6046 19 16.5 
          18.1046 16.5 17V7C16.5 5.89543 15.6046 5 14.5 5Z`}
          stroke={colorStroke}
          fill="transparent"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="video-svg">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </g>
  ),
});

export default video;
