import React from 'react';

const burger = ({ colorStroke }) => ({
  viewBox: '0 0 24 24',
  data: (
    <g>
      <path
        d="M17 10H3"
        stroke={colorStroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 6H3"
        stroke={colorStroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 14H3"
        stroke={colorStroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 18H3"
        stroke={colorStroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default burger;
