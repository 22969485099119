import React from 'react';

const notification = ({ colorStroke }) => ({
  viewBox: '0 -2 36 45',
  data: (
    <g>
      <path
        d={`M31 13C31 9.8174 29.7357 6.76516 27.4853 
        4.51472C25.2348 2.26428 22.1826 1 19 1C15.8174 
        1 12.7652 2.26428 10.5147 4.51472C8.26428 6.76516 
        7 9.8174 7 13C7 27 1 31 1 31H37C37 31 31 27 31 13Z`}
        stroke={colorStroke}
        strokeWidth="3.5"
        fill="transparent"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={`M22.46 39C22.1084 39.6062 21.6037 40.1093 
        20.9965 40.4591C20.3892 40.8088 19.7008 40.9929 
        19 40.9929C18.2992 40.9929 17.6108 40.8088 17.0035 
        40.4591C16.3963 40.1093 15.8916 39.6062 15.54 39`}
        stroke={colorStroke}
        strokeWidth="3.5"
        fill="transparent"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default notification;
