import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ACTIVE } from '../../helpers/colors';

function useDebounce(initialVal, delay, callback) {
  // state to store value of the input
  const [val, setValue] = useState(initialVal);
  // state to store the timer
  const [timer, setTimer] = useState(null);
  // function to be called on every change of input
  const handleInputChange = useCallback(
    (event) => {
      // assigning the value to a local variable
      // Or you can make the event persist if you want to pass around the event obj
      const inputVal = event.target.value;
      // setting the value for immediate use
      setValue(inputVal);
      // the same old code checking & clearing if a callback is already scheduled
      if (timer) {
        clearTimeout(timer);
      }
      // setting a new callback to execute
      const timerId = setTimeout(() => callback(inputVal), delay);
      setTimer(timerId);

      // if components unmounts when there is a scheduled callback
      // then clearing out the callback
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    },
    [callback, delay, timer],
  );

  // returning the val and the debounceChange which can be assigned
  // to a input onchange handler

  return [val, handleInputChange];
}

function ArtDebounce({
  id,
  label,
  placeholder,
  inputGroupClass,
  defaultValue,
  prepend,
  append,
  readOnly,
  fieldAs,
  rows,
  colClass,
  className,
  delay,
  onDebounce,
  disabled,
}) {
  const [value, setValue] = useState('');
  const [val, handleInputChange] = useDebounce(
    null,
    delay,
    onDebounce,
  );
  useEffect(() => {
    setValue(val);
  }, [val]);
  useEffect(() => {
    if (defaultValue === null) {
      setValue('');
    } else {
      setValue(defaultValue || '');
    }
  }, [defaultValue]);
  return (
    <Group
      id={id}
      className={colClass}
    >
      {!!label && label}

      <FormGroup className={inputGroupClass}>
        {!!prepend && (
          <Prepend>
            {prepend}
          </Prepend>
        )}
        <Input
          type="text"
          value={value}
          onChange={handleInputChange}
          className={className}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          as={fieldAs}
          rows={rows}
        />

        {!!append && (
          <Append>
            {append}
          </Append>
        )}
      </FormGroup>
    </Group>
  );
}

const Input = styled(({ as }) => as)`
height: calc(2.7em + 0.75rem + -12px);
flex: 1;
`;

const Append = styled.div`
flex: 0;
`;

const Prepend = styled.div`
flex: 0;
`;

const FormGroup = styled.div`
display: flex;
align-items: center;
width: 100%;
border: 1px solid #ddd;
padding: 0 0 0 15px;
border-radius: 3px;
transition: all 300ms linear;
input, textarea {
   border: 0;
   margin: 0 20px;
   &:hover, &:focus, &:active {
      border: 0;
   }
}
&:hover, &:focus, &:active {
  transition: all 300ms linear;
  border: 1px solid ${ACTIVE};
  input, textarea {
    border: 0;
  }
}
`;

const Group = styled.div`
display: flex;
align-items: center;
width: 100%;
flex: 0.5;
`;

ArtDebounce.defaultProps = {
  id: null,
  label: '',
  placeholder: '',
  disabled: false,
  inputGroupClass: '',
  defaultValue: '',
  readOnly: false,
  prepend: false,
  append: false,
  fieldAs: 'input',
  rows: 3,
  colClass: '',
  className: '',
  delay: 500,
};

ArtDebounce.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  colClass: PropTypes.string,
  defaultValue: PropTypes.string,
  inputGroupClass: PropTypes.string,
  prepend: PropTypes.any,
  append: PropTypes.any,
  readOnly: PropTypes.bool,
  fieldAs: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  rows: PropTypes.number,
  delay: PropTypes.number,
  onDebounce: PropTypes.func.isRequired,
};

export default ArtDebounce;
