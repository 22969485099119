import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form,
} from 'react-bootstrap';
import _ from 'lodash';
import styled from 'styled-components';
import { Field } from 'formik';
import ArtColumnDates from './ArtColumnDates';

const ArtEducation = ({
  form, name, push, remove,
}) => (
  <Education>
    {_.get(form, `values.${name}`, []).map((item, index) => (
      <ItemArea
        key={`artist${name}-${index + 1}`}
      >
        <H3 start={1}>
          Учебное заведение/курсы
        </H3>
        <Form.Control
          className="w-100 form-control-lg"
          name={`${name}.${index}.name`}
          value={_.get(form, `values.${name}[${index}].name`)}
          onChange={(e) => form.setFieldValue(`${name}.${index}.name`, e.target.value)}
          placeholder="Название учебного учреждения"
        />

        <div className="mt-5 mb-5">
          <Field
            name={`${name}.${index}`}
            form={form}
            column
            className="mt-3"
            labelStart={(<H3 start={1}>Начало обучения</H3>)}
            labelEnd={(<H3 start={0}>Окончание обучения</H3>)}
            labelFree={(<H3 start={1}>Свободная дата</H3>)}
            labelCentury={(<H3 start={1}>Век</H3>)}
            placeholderStart="Выберите год"
            placeholderEnd="Выберите год"
            component={ArtColumnDates}
            formatDefault="YYYY"
          />
        </div>

        <Button
          type="button"
          variant="outline-primary-link"
          onClick={() => remove(index)}
        >
          Удалить элемент
        </Button>
      </ItemArea>
    ))}

    <ButtonControl>
      <Button
        type="button"
        className="add"
        onClick={() => push({ name: '', started_at: '', ended_at: '' })}
        variant="primary"
      >
        Добавить элемент
      </Button>
    </ButtonControl>

    <Form.Control.Feedback type="invalid">
      {form.errors[name]}
    </Form.Control.Feedback>
  </Education>
);

const H3 = styled.div`
font-size: 26px;
font-weight: bold;
margin-top: ${({ start }) => (start ? 0 : 3.6)}rem;
margin-bottom: 20px;
@media screen and (max-width: 1024px) {
  font-size: 18px;
  line-height: 20px;
}
@media screen and (max-width: 812px) {
  font-size: 16px;
  line-height: 20px;
}
`;

const Education = styled.div`
width: 100%;
@media screen and (max-width: 812px) {
  button {
    width: 100%;
  }
}
`;

const ButtonControl = styled.div`
width: 100%;
@media screen and (max-width: 812px) {
  padding: 0 20px;
}
`;

const Label = styled.div`
font-style: normal;
font-weight: bold;
font-size: 24px;
margin-bottom: 15px;
@media screen and (max-width: 812px) {
  font-size: 16px;
}
`;

const ItemArea = styled.div`
background: white;
padding: 50px;
margin-bottom: 30px;
@media screen and (max-width: 812px) {
  padding: 20px;
}
`;

ArtEducation.defaultProps = {
  name: '',
};

ArtEducation.propTypes = {
  form: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  name: PropTypes.string,
};

export default ArtEducation;
