import React from 'react';

const book = ({ colorStroke }) => ({
  viewBox: '0 0 17 16',
  data: (
    <g>
      <path
        d={`M1.83398 2H5.83398C6.54123 2 7.21951 2.28095 7.7196 
        2.78105C8.2197 3.28115 8.50065 3.95942 8.50065 4.66667V14C8.50065 
        13.4696 8.28994 12.9609 7.91486 12.5858C7.53979 12.2107 
        7.03108 12 6.50065 12H1.83398V2Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={`M15.1667 2H11.1667C10.4594 2 9.78115 2.28095 9.28105 
        2.78105C8.78095 3.28115 8.5 3.95942 8.5 4.66667V14C8.5 13.4696 
        8.71071 12.9609 9.08579 12.5858C9.46086 12.2107 9.96957 
        12 10.5 12H15.1667V2Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default book;
