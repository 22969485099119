/* eslint-disable react/no-array-index-key */
import { Field } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';

import { ACTIVE, ACTIVE_DARK } from '../../helpers/colors';
import { iconParser } from '../../helpers/main';
import { useTranslation } from '../hooks/useTranslation';

import ArtField from './ArtField';

const ArtContacts = ({
  form, name, push, remove, label, labelOutside, className,
}) => {
  const { t } = useTranslation();
  // This is fast input text when many fields
  const handleChangeFor = (propertyName, event) => {
    const newValue = event.target.value;
    form.setFieldValue(propertyName, newValue);
  };

  return (
    <Container id="socials">
      {labelOutside && label}

      <Form.Group controlId={name}>
        {_.get(form, `values.${name}`, []).map((item, index) => (
          <div key={`artwork${name}-${index}`} className={className}>
            <Contact className="contact">
              <Icon>{iconParser(_.get(form, `values.${name}[${index}]`))}</Icon>

              <Field
                component={ArtField}
                groupClassName="w-100"
                placeholder={t('contacts_placeholder', { ns: 'user' })}
                name={`${name}.${index}`}
                value={_.get(form, `values.${name}[${index}]`)}
                onChange={(e) => handleChangeFor(`${name}.${index}`, e)}
                className="form-control form-control-lg ps-5"
              />

              <CloseButton type="button" onClick={() => remove(index)}>
                &times;
              </CloseButton>
            </Contact>
          </div>
        ))}
        <Form.Control.Feedback type="invalid">{form.errors[name]}</Form.Control.Feedback>
      </Form.Group>
      <Button
        type="button"
        className="top-btn d-flex align-items-center mt-4"
        onClick={() => push('')}
        size="lg"
        variant="outline-secondary"
      >
        {t('add_contact', { ns: 'user' })}
      </Button>
    </Container>
  );
};

const CloseButton = styled.button`
  padding: 0;
  color: ${ACTIVE};
  position: relative;
  font-size: 2rem;
  height: 0;
  border: none;
  left: -15px;
  z-index: 0;
  line-height: 0;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: ${ACTIVE_DARK} !important;
  }
`;

const Container = styled.div`
  .top-btn {
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    @media screen and (max-width: 900px) {
      width: 100%;
      justify-content: center;
    }
  }
`;

const Icon = styled.div`
  z-index: 1;
  position: relative;
  top: -2px;
  margin-right: -30px;
`;

const Contact = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  input {
    font-size: 18px !important;
    line-height: 130%;
    color: black;
    height: 45px;
  }
  div.form-group {
    display: flex;
    flex-direction: row;
    width: 80%;
    @media screen and (max-width: 900px) {
      width: 110%;
    }
  }
`;

ArtContacts.defaultProps = {
  label: '',
  className: '',
  labelOutside: true,
};

ArtContacts.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  className: PropTypes.string,
  labelOutside: PropTypes.bool, // ?? why this???
  label: PropTypes.oneOfType([
    // need only this like {label && label}
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default ArtContacts;
