export default {
  sell_label: 'Хочу продать свою работу',
  sell_description: 'Разместите работу в Маркете Артократии, чтобы получить больше возможностей для продажи. Вашу работу увидят все пользователи Артократии, а алгоритмы платформы будут её продвигать.',
  collect_label: 'Хочу добавить работу в мою коллекцию',
  collect_description: 'Если вы не планируете продавать работу, разместите ее в своей личной коллекции на Артократии. Вы можете настроить видимость и показывать ее только своим друзьям или всем пользователям сайта. Также ваша работа может быть отобрана куратором для размещения в Онлайн-музее Артократии.',
  next: 'Далее',
  moderation: 'Ваша заявка на рассмотрении',
  text_top: 'Статус рассмотрения вашей заявки можно увидеть в',
  orders: 'Заявки и тарифы',
  text_bottom: 'Также мы пришлем уведомление по одобрению. Если мы не одобрили вашу заявку, попробуйте отправить ее еще раз.',
  close: 'Закрыть',
  back: 'Назад',
  by_tariff: 'Разместить по тарифу',
  by_tariff_description: 'Тариф — это комплексный подход к продаже вашего искусства. Вы можете выставить несколько работ на продажу и продвигать своё искусство. Также вы получаете доступ к закрытому Телеграм-каналу для общения с единомышленниками и коллегами. ',
  one_time: 'Разместить разово за {{ price }} {{ currency }}',
  one_time_description: 'Разовое размещение даёт возможность загрузить только одну работу на продажу, публикация осуществляется через модерацию и последующую оплату',
  amateur: 'Пользователи роли “Любитель” не могут выкладывать работы',
  unpublish_label: 'Снять с публикации',
  unpublish_description: 'Работа будет видна только вам в кабинете',
  admin_change_tariff: 'Здравствуйте! У меня закончился тариф, хочу обсудить варианты изменения подписки',
  limit_tariff_info: 'Если вы хотите продолжить загрузку работ, мы можем пересмотреть условия по вашему тарифу или изменить его на более подходящий. Также вы можете выложить работу разово за {{ count }} {{ currency }}',
  change_tariff: 'Сменить тариф',
  sell_one: 'Выложить разово за {{ count }} {{ currency }}',
  price_range: 'Стоимость "от" должна быть меьше стоимости "до"',
  price_digit: 'Допускаются только цифры',
  tariff_limit: 'У вас закончился лимит работ по тарифу',
};
