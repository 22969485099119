export default {
  required: 'Обязательное поле',
  text_num_space: 'Только текст цифры и пробелы',
  email_format: 'Проверьте формат почты',
  minimum_length: 'Минимальное количество знаков {{ count }}',
  maximum_length: 'Максимальное количество знаков {{ count }}',
  need_select: 'Необходимо выбрать',
  only_text: 'Только текст',
  only_url: 'Только URL',
  only_digits: 'Только цифры',
  only_number: 'Только число',
  only_latin_digits: 'Только латинскте буквы, цифры и нижние подчеркивание',
  email: 'Email должен содержать знак @ и точку',
  required_fields: 'Необходимо заполнить обязательные поля',
  currency_type: 'Укажите тип валюты',
  date: 'Корректные форматы: 01.07.1999, 07.1999 или 1999, через / или -',
  phone: 'Некорректный номер телефона (пример: +79000000000)',
  phone_required: 'Телефон обязательно!',
  max_count: 'Максимально можно прикрепить {{ count }} файлов за раз!',
  required_check: 'Обязательно выбрать',
};
