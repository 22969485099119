import React from 'react';

const phone = ({ colorStroke, colorFill }) => ({
  viewBox: '0 0 15 20',
  data: (
    <g>
      <rect
        y="0.695312"
        width="13.2353"
        height="18"
        rx="0.789996"
        stroke={colorStroke}
        strokeWidth="2"
        fill={colorFill}
      />
      <ellipse
        cx="6.61725"
        cy="14.4598"
        rx="0.945378"
        ry="0.983193"
        fill={colorStroke}
      />
    </g>
  ),
});

export default phone;
