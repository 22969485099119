export default {
  status: 'Статус',
  link: 'Общая ссылка',
  in_side: 'В альбоме {{ count }} {{ name }}',
  publish_name: 'альбом',
  sure_delete: 'Вы уверены, что хотите удалить альбом {{ name }}?',
  recovery_impossible: 'Восстановление невозможно',
  empty_text: 'У вас еще нет созданного альбома',
  empty_create: 'Желаете создать?',
  empty_add: 'Добавить альбом',
  my: 'Мои альбомы',
  title: 'КАБИНЕТ - МОИ АЛЬБОМЫ',
  name: 'Мои Альбомы',
  add: 'Добавить альбом',
  no_items: 'Ничего не найдено',
  no_request_result: 'По запросу «{{ request }}» ничего не найдено',
  no_filter_result: 'По выбранной фильтрации ничего не найдено',
  no_request_filter_result: 'По выбранной фильтрации и запросу «{{ request }}» ничего не найдено',
  search_placeholder: 'Введите название',
};
