import { gql } from '@apollo/client';

const exhibitionFragment = gql`
  fragment ExhibitionFragment on Exhibition {
    _id
    url
    likes
    myLike
    allow_offer
    allow_me
    access
    dates
    days
    online
    worktime
    status
    name
    myWorks
    pubLink
    moderated
    description
    location_id
    institution_id
    updated_at
    created_at
    contacts
    owner_id
    type
    artworks {
      _id
      offered
      confirmed
      removed
    }
    exhibitions {
      _id
      url
      name
      dates
      days
      worktime
      images {
        _id
        key
        fileName
        format
      }
    }
    offerWorks {
      _id
      status
      message
      user {
        _id
        name
        username
      }
      artworks {
        _id
        name
        url
        ex_name
        ex_artist
        artist {
          _id
          url
          name
        }
        images {
          _id
          key
          fileName
          format
        }
      }
    }
    favorite {
      _id
    }
    location {
      _id
      name
      address
      coordinates
      place_id
      user_id
    }
    institution {
      _id
      name
      description
      variant
      allow_me
      contacts
      url
      status
      curator {
        _id
        name
        username
        avatar {
          _id
          key
          fileName
        }
      }
      avatar {
        _id
        key
        fileName
      }
      location {
        _id
        address
      }
    }
    files {
      _id
      key
      fileName
    }
    curator {
      _id
      name
      username
      avatar {
        _id
        key
        format
      }
    }
    images {
      _id
      key
      fileName
      format
      order
    }
  }
`;

const fragmentAll = gql`
  fragment ExhibitionAllFragment on ExhibitionsAll {
    total
    tariffs {
      count
      color
      name
      close_text
    }
    meta {
      title
      description
      created_at
      updated_at
      page_name
      page_description
    }
    items {
      _id
      url
      likes
      myLike
      allow_offer
      moderated
      pubLink
      online
      allow_me
      myWorks
      access
      dates
      days
      worktime
      status
      name
      location_id
      institution_id
      owner_id
      type
      created_at
      artworks {
        _id
        offered
        confirmed
        removed
      }
      curator {
        _id
        name
        username
        avatar {
          _id
          key
          fileName
        }
      }
      offerWorks {
        _id
        status
        message
        user {
          _id
          name
          username
        }
        artworks {
          _id
          name
          url
          ex_name
          ex_artist
          artist {
            _id
            url
            name
          }
          images {
            _id
            key
            fileName
            format
          }
        }
      }
      favorite {
        _id
      }
      images {
        _id
        key
        fileName
        format
        order
      }
      location {
        _id
        name
        address
        coordinates
        place_id
        user_id
      }
      institution {
        _id
        name
        status
        images {
          _id
          key
          fileName
          format
        }
        url
      }
    }
  }
`;

export const GQL_UPDATE_EXHIBITION = gql`
  mutation UpdateExhibition($data: ExhibitionInput) {
    updateExhibition(data: $data) {
      ...ExhibitionFragment
    }
  }
  ${exhibitionFragment}
`;

export const GQL_REMOVE_EXHIBITION = gql`
  mutation RemoveExhibition($_id: String!) {
    removeExhibition(_id: $_id)
  }
`;

export const GQL_GET_EXHIBITIONS_ALL = gql`
  query GetExhibitionsAll(
    $isAll: Boolean
    $my: Boolean
    $actual: Boolean
    $query: QueryItems
    $institution_id: String
    $owner_id: String
    $coordinates: String
    $distance: Float
  ) {
    exhibitionsAll(
      isAll: $isAll
      my: $my
      actual: $actual
      query: $query
      institution_id: $institution_id
      owner_id: $owner_id
      coordinates: $coordinates
      distance: $distance
    ) {
      ...ExhibitionAllFragment
    }
  }
  ${fragmentAll}
`;

export const GQL_PUBLISH_EXHIBITION = gql`
  mutation PublishExhibition($_id: String!, $action: String!) {
    publishExhibition(_id: $_id, action: $action)
  }
`;

export const GQL_GET_EXHIBITION = gql`
  query GetExhibition(
    $_id: ID
    $url: String
    $edit: Boolean
    $pubLink: String
    $my: Boolean
    $redirect: Boolean
  ) {
    exhibition(_id: $_id, url: $url, edit: $edit, pubLink: $pubLink, my: $my, redirect: $redirect) {
      ...ExhibitionFragment
    }
  }
  ${exhibitionFragment}
`;

export const GQL_SEND_COLLECTORS_OFFER = gql`
  mutation SendCollectorsOffer($_id: String!) {
    sendCollectorsOffer(_id: $_id)
  }
`;

export const GQL_SEND_REPLY_OFFER_CURATOR = gql`
  mutation SendReplyOfferCurator($_id: String!, $selected: [String], $action: Int!) {
    sendReplyOfferCurator(_id: $_id, selected: $selected, action: $action)
  }
`;
