import jwt from 'jsonwebtoken';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

import config from './config/config';

const updateStatus = async (client, data) => {
  const me = _.get(data, 'me', null);
  const cookies = new Cookies();
  const token = cookies.get('token');
  const decoded = token ? jwt.decode(token) : {};
  const current_time = new Date().getTime() / 1000;
  if (_.get(me, '_id') && (!token || (current_time > +decoded.exp))) {
    // if user login but no have token or it expired
    // Reload with logout and clear storage
    window.location.href = `${config.api}auth/logout`;
    client.resetStore();
    return true;
  }
  return true;
};

updateStatus.defaultProps = {
  closeTab: false,
};

updateStatus.propTypes = {
  closeTab: PropTypes.bool,
  client: PropTypes.object.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]).isRequired,
};

export default updateStatus;
