import React from 'react';

const exhibition = ({ colorStroke }) => ({
  viewBox: '0 0 15 16',
  data: (
    <g>
      <path
        d={`M11.0148 3.2334H2.25185C1.56047 3.2334 1 3.79387 
        1 4.48525V13.2482C1 13.9396 1.56047 14.5001 2.25185 
        14.5001H11.0148C11.7062 14.5001 12.2667 13.9396 12.2667 
        13.2482V4.48525C12.2667 3.79387 11.7062 3.2334 11.0148 3.2334Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={`M12.7472 12.7667C13.4386 12.7667 13.9991 12.2062 
        13.9991 11.5148V2.75185C13.9991 2.06047 13.4386 
        1.5 12.7472 1.5H3.98427C3.29289 1.5 2.73242 
        2.06047 2.73242 2.75185`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default exhibition;
