import React from 'react';

const clock = ({ colorStroke }) => ({
  viewBox: '0 0 48 48',
  data: (
    <g>
      <path
        d={`M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 
        4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 24H44"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={`M24 4C29.0026 9.47671 31.8455 16.5841 32 24C31.8455 31.4159 
        29.0026 38.5233 24 44C18.9974 38.5233 16.1545 31.4159 
        16 24C16.1545 16.5841 18.9974 9.47671 24 4V4Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default clock;
