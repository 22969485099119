export default {
  title: 'КАБИНЕТ - ОСНОВНЫЕ ДАННЫЕ',
  title_about: 'КАБИНЕТ - О МОЕЙ КОЛЛЕКЦИИ',
  name_about: 'О коллекции',
  profile_data: 'Данные профиля',
  security: 'Безопасность',
  companies: 'Компании',
  notifications: 'Уведомления',
  change_type: 'Изменить тип учётной записи',
  type: 'Текущий тип {{ type }}',
  main: 'Основные данные',
  label_main: 'Главная',
  label_artworks: 'Работы',
  label_albums: 'Альбомы',
  label_artists: 'Художники',
  label_exhibitions: 'Выставки',
  label_institutions: 'Музеи',
  label_contacts: 'Контакты',
  label_favourites: 'Избранное',
  label_locations: 'Локации',
  label_messages: 'Сообщения',
  label_events: 'События',
  label_posts: 'Новости',
  upload_photo: 'Загрузить фотографию',
  remove_photo: 'Удалить фотографию',
  change_type_btn: 'Сменить тип учётной записи',
  name: 'Имя Фамилия',
  name_info: 'Так будет отображаться ваше имя публично',
  name_placeholder: 'Введите имя фамилию',
  username: 'Логин - ссылка',
  username_info: 'Логин используется для входа и для создания ссылки на ваш профиль',
  username_example: 'Например, если ввести логин `artocratia`, ссылка на ваш профиль будет иметь вид {{ link }}',
  username_link: 'Ссылка на ваш профиль',
  username_placeholder: 'Введите латинскими символами',
  about: 'О себе',
  about_info: 'Для публичного описания вашего профиля в коллекции',
  about_placeholder: 'Введите описание',
  default_section: 'Главная страница',
  default_section_info: 'Тут вы можете выбрать, какую страницу загружать первой при входе',
  default_section_placeholder: 'Выберите раздел по умолчанию',
  contact_data: 'Данные для связи',
  contact_info: 'Укажите контакты для бизнес-предложений. Это может быть рабочая почта, мессенджеры или социальные сети. Указанные контакты будут видны всем зарегистрированным пользователям',
  json: 'Ссылка для импорта работ из JSON файла',
  json_info: 'Эта ссылка с ресурса, который можете передавать данные о ваших работах в требуемом формате',
  json_download: 'Скачать пример передаваемых данных в формате JSON',
  label_artworks_artist: 'Работы художника',
  language: 'Язык приложения',
  language_info: 'Выберите приоритетный язык на котором будут приходить уведомления',
  language_placeholder: 'Выберите язык',
  payments: 'Платежи',
  auto_renewal_on: 'Автопродление',
  auto_renewal_info: 'По этой карте будут автоматически списываться платежи за следующие услуги:',
  renewal_off: 'Удалить карту и отключить автопродление',
  renewal_item_off: 'Отключить автопродление',
  card_name: 'Карта',
  card_number: 'Номер карты',
  card_expiry: 'Срок действия',
  no_payments: 'У вас пока нет данных для этого раздела',
  success_delete: 'Карта успешно удалена, авто-платёж отключён',
  error_delete: 'Ошибка удаления, обратитесь к администратору!',
  collection: 'О коллекции',
  collection_placeholder: 'Расскажите кратко о коллекции',
  collection_en: 'О коллекции на английском',
  collection_placeholder_en: 'Расскажите о коллекции на английском',
  start: 'Дата старта',
  start_placeholder: 'Введите год',
  wrap: 'Обложка коллекции',
  wrap_info: 'Задайте своё, индивидуальное изображение коллекции. </br> По умолчанию это изображение последней доступной работы из коллекции',
  upload: 'Загрузить',
  remove: 'Удалить',
  security_name: 'Безопасность',
  required: 'Обязательно',
  recommended: 'Рекомендательно',
  minimum_password: 'Длина пароля не менее 6 символов',
  eq_password: 'Пароли совпадают',
  lowercase: 'Наличие строчных символов',
  uppercase: 'Наличие заглавных символов',
  special_characters: 'Наличие цифр и спецсимволов',
  main_email: 'Основной email',
  accept_email: 'Чтобы применить новый email {{ email }}, активируйте его по ссылке отправленной на него',
  change_email: 'При смене email, вам на почту придёт ссылка активации',
  email_placeholder: 'Ваш email',
  change_password: 'Смена пароля',
  old_password: 'Старый пароль',
  old_password_placeholder: 'Введите текущий пароль',
  new_password: 'Новый пароль',
  new_password_placeholder: 'Введите новый пароль',
  confirm_password: 'Подтверждение пароля',
  confirm_password_placeholder: 'Повторите новый пароль',
  remove_account: 'Удаление аккаунта',
  remove_account_info: 'Я уведомлен о том, что при удалении аккаунта все настройки будут сброшены; сообщения, работы и иной контент, а также сервисы сайта станут недоступны',
  remove_account_review: 'Ваше обращение об удалении аккаунта будет рассмотрено в течение {{ days }} {{ declination }}',
  remove_reset: 'Отменить удаление аккаунта',
  remove_action: 'Удалить мой аккаунт навсегда',
  wrong_password: 'Неправильный пароль',
  eq_error_password: 'Пароли должны совпадать',
  save: 'Сохранить',
  await: 'Подождите...',
  forgot_password: 'Забыли пароль?',
  password_changed: 'Пароль успешно изменён!',
  email_renew: 'Чтобы изменить email подтвердите его смену по ссылке в письме',
  collection_saved: 'Информация о коллекции успешно сохранена',
  verification: 'Подать на верификацию',
  verification_loading: 'Подождите, отправляем',
  real_attention: 'Внимание!',
  real_question: 'На верификацию можно подать аккаунт с настоящими, максимально заполненными данными о себе. \n\nВы уверены, что всё соответстует требованиям?',
  sent_success: 'Запрос успешно отправлен, ожидайте когда с вами свяжется наш менеджер',
  auto_renewal_promotion: 'Размещение работы «{{ name }}» в маркете за {{ price }} рублей / месяц',
  auto_renewal_tariff: 'Тариф «{{ name }}» за {{ price }} рублей',
  auto_renewal_expired: 'Списание {{ time }}',
  status: 'Ваш статус',
  status_info: 'Статус, короткое сообщение всем, будет отображаться на вашей личной странице',
  status_placeholder: 'Введите свой статус',
};
