import React from 'react';

const document = ({ colorStroke }) => ({
  viewBox: '0 0 25 24',
  data: (
    <g>
      <path
        d={`M13.167 2H6.16699C5.63656 2 5.12785 2.21071 4.75278 2.58579C4.37771 
        2.96086 4.16699 3.46957 4.16699 4V20C4.16699 20.5304 4.37771 21.0391 
        4.75278 21.4142C5.12785 21.7893 5.63656 22 6.16699 22H18.167C18.6974 
        22 19.2061 21.7893 19.5812 21.4142C19.9563 21.0391 20.167 20.5304 20.167 
        20V9L13.167 2Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.167 2V9H20.167"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default document;
