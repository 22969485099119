export default {
  title: 'КАБИНЕТ - ИЗБРАННОЕ',
  albums: 'Альбомы',
  artists: 'Художники',
  works: 'Работы',
  collections: 'Коллекции',
  compilations: 'Подборки',
  institutions: 'Институции',
  exhibitions: 'Выставки',
  journal: 'Журнал',
  friends: 'Друзья',
  my_connections: 'Мои связи',
  album_placeholder: 'Введите название альбома',
  artists_placeholder: 'Введите имя художника',
  artworks_placeholder: 'Введите название или автора',
  collections_placeholder: 'Введите ФИО коллекционера',
  compilations_placeholder: 'Введите название подборки',
  exhibitions_placeholder: 'Введите название выставки',
  friends_placeholder: 'Начните вводить ФИО',
  institutions_placeholder: 'Введите название',
  journal_placeholder: 'Введите название',
  my: 'Моё избранное',
};
