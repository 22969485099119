import { gql } from '@apollo/client';

const fragment = gql`
  fragment MetaspaceFragment on Metaspace {
    _id
    name
    description
    created_at
    updated_at
    header
    url
    cover {
      _id
      key
      fileName
    }
    avatar {
      _id
      key
      fileName
    }
    meta {
      title
      description
    }
  }
`;

export const GQL_GET_METASPACE_ALL = gql`
  query GetMetaspaceAll($query: QueryItems) {
    metaspaceAll(query: $query) {
      total
      items {
        _id
        url
        name
        cover {
          _id
          key
          fileName
        }
      }
    }
  }
`;

export const GQL_GET_METASPACE = gql`
  query GetMetaspace($url: String) {
    metaspace(url: $url) {
      ...MetaspaceFragment
    }
  }
  ${fragment}
`;
