import React from 'react';

const education = ({ colorStroke }) => ({
  viewBox: '0 0 30 46',
  data: (
    <g>
      <path
        d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.42 26.78L5 45L15 39L25 45L22.58 26.76"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default education;
