import React from 'react';
import ym, { YMInitializer } from 'react-yandex-metrika';

import config from '../config/config';

const code = config.METRIC_YANDEX;

const Tracker = () => (
  <YMInitializer
    options={{
      defer: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    }}
    accounts={[code]}
    version="2"
  />
);

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 */
export const yEvent = (action, category = 'reachGoal') => ym(category, action);

export default Tracker;
