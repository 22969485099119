import React from 'react';

const google = ({ colorFill }) => ({
  viewBox: '0 0 18 18',
  data: (
    <path
      d={`M9.00214 7.49169V10.7668H13.6631C13.0536 12.7511 11.3972 14.1714 9.00214 14.1714C6.14995 14.1714
            3.83789 11.8563 3.83789 9.00111C3.83789 6.14597 6.14995 3.83083 9.00214 3.83083C10.2844 3.83083 11.4554
            4.3014 12.3585 5.07626L14.7673 2.66426C13.2462 1.27654 11.2226 0.429688 9.00214 0.429688C4.2736 0.429688
            0.44043 4.26712 0.44043 9.00111C0.44043 13.7351 4.2736 17.5725 9.00214 17.5725C16.1891 17.5725 17.7753
            10.844 17.0708 7.50283L9.00214 7.49169Z`}
      fill={colorFill}
    />
  ),
});

export default google;
