import React from 'react';

const calendar = ({ colorStroke }) => ({
  viewBox: '0 0 22.7 22.7',
  data: (
    <g>
      <path
        fill="none"
        stroke={colorStroke}
        strokeWidth="2"
        strokeLinecap="round"
        d={`M21.154,20.055 c0,0.501-0.423,0.907-0.944,0.907H2.912c-0.522,0-0.945-0.406-0.945-0.907V5.022c0-0.501,
        0.423-0.907,0.945-0.907H20.21 c0.521,0,0.944,0.406,0.944,0.907V20.055z`}
      />
      <line
        fill="none"
        stroke={colorStroke}
        strokeWidth="2"
        x1="1.529"
        y1="14.462"
        x2="21.717"
        y2="14.462"
      />
      <line
        fill="none"
        stroke={colorStroke}
        strokeWidth="2"
        strokeLinecap="round"
        x1="7.529"
        y1="1.962"
        x2="7.529"
        y2="6.337"
      />
      <line
        fill="none"
        stroke={colorStroke}
        strokeWidth="2"
        strokeLinecap="round"
        x1="16.029"
        y1="1.837"
        x2="16.029"
        y2="6.212"
      />
    </g>
  ),
});

export default calendar;
