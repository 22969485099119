export default {
  no_items: 'Ничего не найдено',
  placeholder_search: 'Искать среди {{ total }}',
  no_request_result: 'По запросу «{{ request }}» ничего не найдено',
  no_filter_result: 'По выбранной фильтрации ничего не найдено',
  no_request_filter_result: 'По выбранной фильтрации и запросу «{{ request }}» ничего не найдено',
  sure_delete: 'Вы уверены, что хотите удалить институцию {{ name }}?',
  recovery_impossible: 'Восстановление невозможно',
  published: 'институцию',
  empty_text: 'У вас еще нет созданной институции',
  empty_create: 'Желаете создать?',
  empty_add: 'Добавить институцию',
  my: 'Мои институции',
  title: 'КАБИНЕТ - МОИ ИНСТИТУЦИИ',
  my_label: 'Мои музеи и галереи',
  create: 'Создать институцию',
  search_placeholder: 'Начните вводить название',
  close_add: 'Публикуйте институции с помощью тарифа <em>{{ name }}</em> подписки на Артократию',
  hidden_tariff: 'Изучить тарифы',
};
