export default {
  message: 'Сообщение',
  subscribe_reply: 'Подписаться ответно',
  add_to_friends: 'Добавить в друзья',
  unsubscribe: 'Отписаться',
  block: 'Заблокировать',
  unblock: 'Разблокировать',
  not_familiar: 'Вы не знакомы',
  subscribed_to_you: 'Подписан на Вас',
  your_friend: 'Ваш друг',
  you_track: 'Вы отслеживаете',
  you_block: 'Заблокирован',
};
