import { gql } from '@apollo/client';

export const GQL_GET_PAGE = gql`
  query GetPage($url: String) {
    page(url: $url) {
      _id
      name
      anons
      description
      created_at
      updated_at
      attach_price
      rent_price
      registration
      blocks {
        name
        color
        horizontal
        description
        section
        button
        button_url
        order
        image {
          key
        }
        cover {
          key
        }
      }
      faq {
        name
        description
      }
      cover {
        _id
        key
        fileName
      }
      avatar {
        _id
        key
        fileName
      }
      meta {
        title
        description
      }
    }
  }
`;
