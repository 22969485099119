export default {
  name: 'Генератор ссылки',
  list_1: 'Эта ссылка делает ваш неопубликованный альбом открытым тому, у кого она есть.',
  list_2: 'Если альбом будет опубликован, ссылка будет удалена.',
  list_3: 'При генерации новой ссылки, доступ по старой будет закрыт.',
  list_4: 'При удалении ссылки, доступ будет закрыт.',
  break: 'Ссылка недоступна',
  by_link: 'Доступ по ссылке',
  remove: 'Удалить доступ по ссылке',
};
