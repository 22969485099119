import React from 'react';

const settings = ({ colorStroke }) => ({
  viewBox: '0 0 16 16',
  data: (
    <g>
      <g clipPath="url(#setting-icon)">
        <path
          d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
          stroke={colorStroke}
          fill="transparent"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d={`M12.9327 9.99984C12.8439 10.2009 12.8175 10.424 12.8567 10.6402C12.8959 
          10.8565 12.999 11.0561 13.1527 11.2132L13.1927 11.2532C13.3167 11.377 
          13.415 11.5241 13.4821 11.6859C13.5492 11.8478 13.5837 12.0213 13.5837 
          12.1965C13.5837 12.3717 13.5492 12.5452 13.4821 12.7071C13.415 12.869 13.3167 
          13.016 13.1927 13.1398C13.0689 13.2638 12.9218 13.3622 12.7599 13.4293C12.5981 
          13.4963 12.4246 13.5309 12.2493 13.5309C12.0741 13.5309 11.9006 13.4963 11.7388 
          13.4293C11.5769 13.3622 11.4298 13.2638 11.306 13.1398L11.266 13.0998C11.1089 
          12.9461 10.9093 12.843 10.6931 12.8038C10.4768 12.7646 10.2538 12.7911 10.0527 
          12.8798C9.8555 12.9643 9.68734 13.1047 9.56889 13.2835C9.45044 13.4624 9.38687 
          13.672 9.38602 13.8865V13.9998C9.38602 14.3535 9.24554 14.6926 8.99549 14.9426C8.74544 
          15.1927 8.4063 15.3332 8.05268 15.3332C7.69906 15.3332 7.35992 15.1927 7.10987 
          14.9426C6.85983 14.6926 6.71935 14.3535 6.71935 13.9998V13.9398C6.71419 13.7192 
          6.64276 13.5052 6.51436 13.3256C6.38595 13.1461 6.20651 13.0094 5.99935 12.9332C5.79827 
          12.8444 5.57522 12.818 5.35896 12.8572C5.14269 12.8964 4.94314 12.9995 4.78602 
          13.1532L4.74602 13.1932C4.62218 13.3171 4.47513 13.4155 4.31327 13.4826C4.15141 
          13.5497 3.9779 13.5842 3.80268 13.5842C3.62746 13.5842 3.45396 13.5497 3.2921 
          13.4826C3.13023 13.4155 2.98318 13.3171 2.85935 13.1932C2.73538 13.0693 2.63704 
          12.9223 2.56994 12.7604C2.50284 12.5986 2.4683 12.4251 2.4683 12.2498C2.4683 12.0746 
          2.50284 11.9011 2.56994 11.7393C2.63704 11.5774 2.73538 11.4303 2.85935 11.3065L2.89935 
          11.2665C3.05304 11.1094 3.15614 10.9098 3.19535 10.6936C3.23456 10.4773 3.20809 10.2542 
          3.11935 10.0532C3.03484 9.85599 2.89452 9.68783 2.71566 9.56938C2.5368 9.45093 2.32721 
          9.38736 2.11268 9.3865H1.99935C1.64573 9.3865 1.30659 9.24603 1.05654 8.99598C0.806491 
          8.74593 0.666016 8.40679 0.666016 8.05317C0.666016 7.69955 0.806491 7.36041 1.05654 
          7.11036C1.30659 6.86031 1.64573 6.71984 1.99935 6.71984H2.05935C2.28001 6.71468 2.49402 
          6.64325 2.67355 6.51484C2.85308 6.38644 2.98983 6.20699 3.06602 5.99984C3.15476 5.79876 
          3.18123 5.57571 3.14202 5.35945C3.10281 5.14318 2.99971 4.94362 2.84602 4.7865L2.80602 
          4.7465C2.68205 4.62267 2.5837 4.47562 2.5166 4.31376C2.4495 4.15189 2.41497 3.97839 
          2.41497 3.80317C2.41497 3.62795 2.4495 3.45445 2.5166 3.29258C2.5837 3.13072 2.68205 
          2.98367 2.80602 2.85984C2.92985 2.73587 3.0769 2.63752 3.23876 2.57042C3.40063 2.50333 
          3.57413 2.46879 3.74935 2.46879C3.92457 2.46879 4.09807 2.50333 4.25994 2.57042C4.4218 
          2.63752 4.56885 2.73587 4.69268 2.85984L4.73268 2.89984C4.8898 3.05353 5.08936 3.15663 
          5.30562 3.19584C5.52189 3.23505 5.74494 3.20858 5.94602 3.11984H5.99935C6.19653 3.03533 
          6.36469 2.89501 6.48314 2.71615C6.60159 2.53729 6.66516 2.3277 6.66602 2.11317V1.99984C6.66602 
          1.64622 6.80649 1.30708 7.05654 1.05703C7.30659 0.80698 7.64573 0.666504 7.99935 0.666504C8.35297 
          0.666504 8.69211 0.80698 8.94216 1.05703C9.19221 1.30708 9.33268 1.64622 9.33268 
          1.99984V2.05984C9.33354 2.27436 9.3971 2.48395 9.51555 2.66281C9.634 2.84167 9.80217 
          2.982 9.99935 3.0665C10.2004 3.15525 10.4235 3.18172 10.6397 3.14251C10.856 3.10329 
          11.0556 3.0002 11.2127 2.8465L11.2527 2.8065C11.3765 2.68254 11.5236 2.58419 11.6854 
          2.51709C11.8473 2.44999 12.0208 2.41546 12.196 2.41546C12.3712 2.41546 12.5447 2.44999 
          12.7066 2.51709C12.8685 2.58419 13.0155 2.68254 13.1393 2.8065C13.2633 2.93033 13.3617 
          3.07739 13.4288 3.23925C13.4959 3.40111 13.5304 3.57462 13.5304 3.74984C13.5304 3.92506 
          13.4959 4.09856 13.4288 4.26042C13.3617 4.42229 13.2633 4.56934 13.1393 4.69317L13.0993 
          4.73317C12.9457 4.89029 12.8426 5.08985 12.8033 5.30611C12.7641 5.52237 12.7906 5.74543 
          12.8793 5.9465V5.99984C12.9639 6.19702 13.1042 6.36518 13.283 6.48363C13.4619 6.60208 
          13.6715 6.66565 13.886 6.6665H13.9993C14.353 6.6665 14.6921 6.80698 14.9422 7.05703C15.1922 
          7.30708 15.3327 7.64622 15.3327 7.99984C15.3327 8.35346 15.1922 8.6926 14.9422 8.94265C14.6921 
          9.19269 14.353 9.33317 13.9993 9.33317H13.9393C13.7248 9.33403 13.5152 9.39759 13.3364 9.51604C13.1575 
          9.63449 13.0172 9.80266 12.9327 9.99984V9.99984Z`}
          stroke={colorStroke}
          fill="transparent"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="setting-icon">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </g>
  ),
});

export default settings;
