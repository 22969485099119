import { gql } from '@apollo/client';

const fragment = gql`
  fragment LocationFragment on Location {
    _id
    name
    description
    coordinates
    place_id
    address
    country
    city
    street
    house
  }
`;

export const GQL_GET_LOCATIONS_ALL = gql`
  query GetLocationsAll($query: QueryItems, $all: Boolean, $withAddress: Boolean) {
     locationsAll(query: $query, all: $all, withAddress: $withAddress) {
        total
        items {
           ...LocationFragment
        }
     }
  }
   ${fragment}
`;

export const GQL_GET_LOCATION = gql`
  query GetLocation($_id: ID) {
    location(_id: $_id) {
       ...LocationFragment
     }
  }
  ${fragment}
`;

export const GQL_UPDATE_LOCATION = gql`
  mutation UpdateLocation($data: LocationInput) {
    updateLocation(data: $data) {
      ...LocationFragment
    }
  }
  ${fragment}
`;

export const GQL_REMOVE_LOCATION = gql`
  mutation RemoveArtwork($_id: String!) {
    removeLocation(_id: $_id)
  }
`;
