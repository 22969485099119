export default {
  register_to_liked: 'Чтобы оценить войдите или зарегистрируйтесь',
  register_to_favorites: 'Чтобы добавить в избранное войдите или зарегистрируйтесь',
  error: 'Произошла ошибка, попробуйте позже',
  url_copy: 'URL успешно скопирован',
  device_not_support: 'Ваше устройство не поддерживает данную функцию',
  message_send_success: 'Спасибо! Ваше сообщение успешно отправлено!',
  success_add_favorites: 'Успешно добавлено в избрнное!',
  success_remove_favorites: 'Успешно удалено из избранного!',
  offer_updated: 'Предложение обновлено!',
  offer_deleted: 'Предложение удалено!',
  offer_sent_curator: 'Предложение успешно направлено куратору!',
  attach_artworks: 'Необходимо выбрать работы!',
  offer_artwork_remove: 'Предложение успешно удалено! Но куратор уже уведомлен и может связаться с Вами',
  offered_exhibition: 'Предложение успешно направлено коллекционеру!',
  select_exhibition: 'Необходимо выбрать мероприятие',
  token_not_exist: 'Такого токена не существует!',
  email_changed: 'Email успешно изменен',
  password_cleared: 'Пароль сброшен, проверьте почту',
  password_changed: 'Пароль успешно изменен!',
  admin_error: 'Что-то пошло не так. Обратитесь к администратору!',
  send_instructions: 'Проверьте указанную почту, вам высланы дальнейшие инструкции',
  registration_complete: 'Вы успешно зарегистрированы!',
  account_changed: 'Ваша учетная запись успешно изменена',
  data_save: 'Данные сохранены',
  error_update_profile: 'Ошибка обновления профиля!',
  on_moderation: 'На модерации, редактирование запрещено!',
  you_not_auth: 'Вы не авторизованы для данных действий',
  you_owner: 'Вы владелец',
  abuse_send: 'Жалоба успешно отправлена!',
  test_mode: 'Вы в режиме просмотра, чтобы вернуться к персональному виду нажмите «Венуться к редактированию»',
};
