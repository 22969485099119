import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Datetime from 'react-datetime';
import { isMobile } from 'react-device-detect';
import { Field } from 'formik';
import {
  ButtonGroup,
  Form,
  ToggleButton,
} from 'react-bootstrap';
import _ from 'lodash';

import moment from 'moment/moment';
import { CENTURIES } from '../../helpers/atworks';
import ArtSelect from './ArtSelect';
import ArtField from './ArtField';
import { DARK } from '../../helpers/colors';
import Icon from '../Icon';
import Select from '../Select';

const ArtColumnDates = ({
  field,
  form,
  form: { errors, setFieldValue },
  period,
  century,
  free,
  date,
  labelStart,
  labelEnd,
  placeholderStart,
  placeholderEnd,
  btnGroupClass,
  formatDefault,
  labelFree,
  labelCentury,
}) => {
  const dates = (_.get(form, `values.${field.name}`, ''));
  const [variant, setVariant] = useState(!!dates && !!dates.variant ? dates.variant : date);
  const switchType = (variantSet) => {
    setVariant(variantSet);
    form.setFieldValue(`${field.name}.started_at`, '');
    form.setFieldValue(`${field.name}.ended_at`, '');
    form.setFieldValue(`${field.name}.free`, '');
    form.setFieldValue(`${field.name}.century`, '');
    form.setFieldValue(`${field.name}.variant`, variantSet);
  };
  // This is fast input text when many fields
  const [values, setValues] = useState([]);
  const handleChangeFor = (propertyName, event) => {
    const typeText = event.target.value;
    const newValue = {
      ...values,
      [propertyName]: typeText,
    };
    setValues(newValue);
  };
  const select = [
    { label: 'Точная дата', value: date },
    { label: 'Период', value: period },
    { label: 'Свободная дата', value: free },
    { label: 'Век', value: century },
  ];
  const fieldProcess = () => {
    const keys = Object.keys(values);
    keys.forEach((key) => setFieldValue(key, values[key]));
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => fieldProcess(), 1000);
    return () => clearTimeout(timeoutId);
  }, [values]);
  const normalizeDate = (fieldName, dateFormat) => {
    if (dateFormat) {
      setFieldValue(fieldName, dateFormat.format(formatDefault));
    }
  };
  const validation = (currentDate) => currentDate.isBefore(moment(new Date().getTime()));
  const renderInput = (props, openCalendar, fieldName) => {
    const clear = () => {
      // eslint-disable-next-line react/prop-types
      props.onChange({ target: { value: '' } });
      setFieldValue(fieldName, '');
    };
    return (
      <div className="d-inline-flex wm-100 d-md-flex align-items-center">
        <input {...props} />
        <div className="d-flex align-items-center">
          <Button className="close" type="button" onClick={clear}>&times;</Button>
          <Button type="button" onClick={openCalendar}>
            <Icon
              icon="calendar"
              colorFill={DARK}
              width={20}
              height={20}
              noHover
            />
          </Button>
        </div>
      </div>
    );
  };
  const renderButtons = () => (
    <ButtonGroup toggle className={`wm-100 mb-4 mb-lg-0 ${btnGroupClass}`}>
      <ToggleButton
        type="radio"
        className="px-1 px-md-4"
        name={`${field.name}.variant`}
        onChange={() => switchType(date)}
        id={`${field.name}-radio-date`}
        variant={variant === date ? 'primary' : 'outline-secondary'}
        defaultValue={_.get(form, `values.${field.name}.variant`)}
        value={variant}
        checked={variant === date}
      >
        Точная дата
      </ToggleButton>

      <ToggleButton
        type="radio"
        className="px-1 px-md-4"
        name={`${field.name}.variant`}
        onChange={() => switchType(free)}
        id={`${field.name}-radio-free`}
        variant={variant === free ? 'primary' : 'outline-secondary'}
        defaultValue={_.get(form, `values.${field.name}.variant`)}
        value={variant}
        checked={variant === free}
      >
        Свободная форма
      </ToggleButton>

      <ToggleButton
        type="radio"
        className="px-1 px-md-4"
        name={`${field.name}.variant`}
        onChange={() => switchType(century)}
        variant={variant === century ? 'primary' : 'outline-secondary'}
        id={`${field.name}-radio-century`}
        defaultValue={_.get(form, `values.${field.name}.variant`)}
        value={variant}
        checked={variant === century}
      >
        Век
      </ToggleButton>

      <ToggleButton
        type="radio"
        className="px-1 px-md-4"
        name={`${field.name}.variant`}
        onChange={() => switchType(period)}
        variant={variant === period ? 'primary' : 'outline-secondary'}
        id={`${field.name}-radio-period`}
        defaultValue={_.get(form, `values.${field.name}.variant`)}
        value={variant}
        checked={variant === period}
      >
        Период
      </ToggleButton>
    </ButtonGroup>
  );
  const renderSelect = () => (
    <Select
      placeholder="Выберите"
      options={select}
      value={select.filter((item) => (item.value === _.get(form, `values.${field.name}.variant`)))}
      className="form-control-lg w-100 "
      onChange={(e) => switchType(e.value)}
    />
  );
  return (
    <MainForm>
      <Fields>
        {(variant === date || variant === period) && (
        <>
          {!isMobile && labelStart && labelStart}
          <Datetime
            locale="ru"
            timeFormat={false}
            isValidDate={validation}
            renderInput={(props, openCalendar) => renderInput(props, openCalendar, `${field.name}.started_at`)}
            inputProps={{
              className: 'form-control-lg w-100',
              placeholder: placeholderStart || (period === variant ? 'Дата начала' : 'Введите дату'),
              readOnly: true,
              name: `${field.name}.started_at`,
            }}
            dateFormat={formatDefault}
            id={`${field.name}.started_at`}
            className="w-75 mb-2 wm-100"
            name={`${field.name}.started_at`}
            value={_.get(form, `values.${field.name}.started_at`)}
            onChange={(e) => normalizeDate(`${field.name}.started_at`, e)}
          />
        </>
        )}
        {variant === period && (
        <>
          {!isMobile && labelEnd && labelEnd}
          <Datetime
            locale="ru"
            isValidDate={validation}
            timeFormat={false}
            renderInput={(props, openCalendar) => renderInput(props, openCalendar, `${field.name}.ended_at`)}
            inputProps={{
              className: 'form-control-lg w-100',
              placeholder: placeholderEnd || 'Дата окончания',
              readOnly: true,
              name: `${field.name}.ended_at`,
            }}
            dateFormat={formatDefault}
            id={`${field.name}.ended_at`}
            className="w-75 mb-2 wm-100"
            name={`${field.name}.ended_at`}
            value={_.get(form, `values.${field.name}.ended_at`)}
            onChange={(e) => normalizeDate(`${field.name}.ended_at`, e)}
          />
        </>
        )}
        {variant === free && (
        <>
          {!isMobile && labelFree && labelFree}
          <Field
            component={ArtField}
            placeholder="Свободная форма"
            name={`${field.name}.free`}
            defaultValue={_.get(form, `values.${field.name}.free`)}
            value={_.get(values, `${field.name}.free`)}
            onChange={(e) => handleChangeFor(`${field.name}.free`, e)}
            className="form-control-lg w-75 wm-100"
            inputGroupClass="mb-0"
          />
        </>
        )}
        {variant === century && (
        <>
          {!isMobile && labelCentury && labelCentury}
          <Field
            component={ArtSelect}
            column
            groupClassName="mb-0 w-75 wm-100"
            name={`${field.name}.century`}
            placeholder="Выберите век"
            options={CENTURIES.map((item) => ({
              label: item.label,
              value: item.value,
            }))}
            value={_.get(form, `values.${field.name}.century`)}
            onChange={(e) => form.setFieldValue(`${field.name}.century`, e.target.value)}
          />
        </>
        )}
        <Form.Control.Feedback type="invalid">
          {errors[field.name]}
        </Form.Control.Feedback>
      </Fields>
      <Buttons lg={7} md={12}>
        {!isMobile && renderButtons()}
        {isMobile && renderSelect()}
      </Buttons>
    </MainForm>
  );
};

const MainForm = styled.div`
display: flex;
width: 100%;
@media screen and (max-width: 1024px) {
  flex-direction: column-reverse;
  .wm-100 {
    width: 100% !important;
  }
}
`;

const Fields = styled.div`
flex: 1;
`;

const Buttons = styled.div`
flex: 1;
`;

const Button = styled.button`
padding: 0;
&.close {
    font-size: 30px;
    font-weight: 100;
    margin-right: 7px;
}
`;

ArtColumnDates.defaultProps = {
  period: 'period',
  century: 'century',
  free: 'free',
  date: 'date',
  label: '',
  outNameClass: '',
  className: '',
  btnGroupClass: '',
  outName: false,
  lightLabel: false,
  formatDefault: 'DD.MM.YYYY',
  first: false,
  labelStart: null,
  labelEnd: null,
  labelFree: null,
  labelCentury: null,
  placeholderStart: null,
  placeholderEnd: null,
};

ArtColumnDates.propTypes = {
  labelStart: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  labelEnd: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  labelFree: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  labelCentury: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  placeholderStart: PropTypes.string,
  placeholderEnd: PropTypes.string,
  lightLabel: PropTypes.bool,
  first: PropTypes.bool,
  formatDefault: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  outNameClass: PropTypes.string,
  period: PropTypes.string,
  century: PropTypes.string,
  free: PropTypes.string,
  date: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  btnGroupClass: PropTypes.string,
  outName: PropTypes.bool,
};

export default ArtColumnDates;
