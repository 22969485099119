import React from 'react';

const copy = ({ colorFill }) => ({
  viewBox: '0 0 15 15',
  data: (
    <g>
      <path
        d={`M13.1437 6.07129H7.35798C6.6479 6.07129
        6.07227 6.64692 6.07227 7.357V13.1427C6.07227
        13.8528 6.6479 14.4284 7.35798 14.4284H13.1437C13.8538
        14.4284 14.4294 13.8528 14.4294 13.1427V7.357C14.4294
        6.64692 13.8538 6.07129 13.1437 6.07129Z`}
        fill={colorFill}
      />
      <path
        d={`M3.49986 9.92892H2.857C2.51601 9.92892 2.18898
        9.79346 1.94787 9.55234C1.70675 9.31123 1.57129 8.9842
        1.57129 8.64321V2.85749C1.57129 2.5165 1.70675 2.18947
        1.94787 1.94835C2.18898 1.70724 2.51601 1.57178 2.857
        1.57178H8.64272C8.98371 1.57178 9.31074 1.70724 9.55186
        1.94835C9.79297 2.18947 9.92843 2.5165 9.92843 2.85749V3.50035`}
        stroke={colorFill}
        fill="transparent"
        strokeWidth="1.80385"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default copy;
