export default {
  anew: 'заново',
  loading: 'Загрузка',
  unsupported_file: 'Неподдерживаемый файл',
  size_too_big: 'Размер слишком большой',
  maximum_size: 'Максимальный размер {{ maxFileSize }}',
  error: 'Ошибка',
  delete: 'Удалить',
  download: 'Скачать',
  upload: 'Загрузить',
  payment: 'Расчет',
  reset: 'Сбросить',
  maximum_mb: 'Максимальный размер файла {{ maxFileSize }}мб',
  minimum_mb: 'Минимальный размер файла {{ minFileSize }}кб',
  file_format: 'Поддерживаемый формат файлов {{ fileTypes }}',
  maximum_number_files: 'Максимальное количество файлов',
  maximum_total_size_exceeded: 'Превышен максимальный общий размер',
  was_reset: 'Сброшено',
  completed: 'Завершено',
  cancel: 'Отмена',
  return: 'Вернуть',
  upload_error: 'Ошибка загрузки',
  select_file: 'Выберите файл',
  select_photo: 'Выберите фото',
  photo_preview: 'Предпросмотр фото',
  action: 'Действие',
  name: 'Название',
  remove: 'Удалить',
  preparation_img: 'Подготовка фото',
  rotate: 'Поворот',
  size_1: 'Размер 1',
  cut_save: 'Обрезать и сохранить',
  upload_image: 'Загрузить фотографии',
  upload_file: 'Загрузить файлы',
  upload_info: 'Воспользуйтесь кнопкой или перетащите',
  file_in_area: 'файлы в эту область',
  image_in_area: 'фотографию в эту область',
};
