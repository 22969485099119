export default {
  select_status: 'Выберите статус',
  search_sort_name: 'Сортировка и поиск',
  sort: 'Сортировать по',
  filter: 'Фильтр',
  select_institution: 'Выберите тип институции',
  select_technique: 'Выберите технику',
  select_type_exhibition: 'Выберите тип мероприятия',
  select_location: 'Выберите локацию',
  select_album: 'Выберите альбом',
  select_artist: 'Начните вводить художника',
  reset: 'Сбросить фильтры',
  by_time: 'Времени',
  by_start_time: 'Времени начала',
  by_end_time: 'Времени окончания',
  by_name: 'Имени',
  by_artist: 'Автору',
  search_from: 'Искать среди {{ count }}',
  by_online: 'Типу',
  by_notes: 'Заметкам',
  select_artworks: 'Выбрать работы для выставки',
  select_process: 'Режим выбора работ',
  select_online: 'Выберите вариант',
  select_actual: 'Выберите актуальность',
  no_request_result: 'По запросу «{{ request }}» ничего не найдено',
  no_filter_result: 'По выбранной фильтрации ничего не найдено',
  no_request_filter_result: 'По выбранной фильтрации и запросу «{{ request }}» ничего не найдено',
  select_education: 'Вариант лекций',
  tags_education: 'Теги лекций',
  market: 'Маркет',
  collection: 'Онлайн-музей',
  wrap: 'Свернуть',
  unwrap: 'Развернуть',
  category: 'По формату/категории',
  small_size: 'Малые',
  small_size_help: 'До {{ size }} см',
  medium_size: 'Средние',
  medium_size_help: '{{ from }} - {{ to }} см',
  big_size: 'Большие',
  big_size_help: 'От {{ size }} см',
  loading: 'Загружаем...',
  not_found: 'Не найдено',
};
