export default {
  name: 'Образование',
  bye: 'Купить',
  about: 'Описание',
  program: 'Программа',
  authors: 'Авторы',
  author: 'Автор',
  who_need: 'Кому подойдет',
  will_learn: 'Чему вы научитесь',
  alumni_reviews: 'Отзывы выпускников',
  cost: 'Стоимость',
  auth_pay: 'Зарегистрироваться и купить',
  pay_installments: 'Купить в рассрочку',
  login_pay: 'Чтобы приобрести войдите или зарегистрируйтесь!',
  success_payed: 'Успешно приобретено',
  error_payment: 'Ошибка оплаты, в случае если деньги списаны, но ничего не произошло, обратитесь к администратору',
  go_my_education: 'Перейти к изучению',
  send_review: 'Оставить отзыв',
  review_description: 'Комментарий',
  review_description_placeholder: 'Введите комментарий',
  review_send: 'Отправить отзыв',
  review_sending: 'Отправляем',
  review_success: 'Спасибо, ваш отзыв успешно отправлен. После проверки он станет доступен',
  have_promo: 'У меня есть промокод',
  promo_code: 'Промо код',
  apply: 'Применить',
  checking: 'Проверяем',
  promo_success: 'Промокод успешно применён',
  lecture: 'лекция',
  lectures: 'лекций',
  lectures_2: 'лекции',
  lesson_lenght: 'Длина курса',
  course_lectors: 'Лекторы курса',
  course_lector: 'Лектор курса',
  close: 'Закрыть',
  credit: 'Купить в рассрочку',
  lecturer: 'Спикер',
  lecturers: 'Спикеры',
  back: 'Назад',
  next: 'Вперёд',
  materials: 'Доп. материалы',
  video: 'Видео',
  anons: 'Описание',
  to_contents: 'К оглавлению',
  allow_tariff: 'Доступно для тарифа',
  allow_tariffs: 'Доступно для тарифов',
  faq: 'FAQ',
  alumni_video_reviews: 'Видео отзывы',
  pay: 'Оплатить российской картой',
  pay_all: 'Оплатить полностью',
  pay_european: 'Оплатить иностранной картой',
  start_date: 'Дата старта',
  price: 'Стоимость',
  all_course: 'Весь курс',
  select_payment: 'Выбрать вариант оплаты',
  by_module: 'Помодульно',
  one_module: 'Один модуль на выбор',
  select_module: 'Выберите любой модуль из курса, если хотите изучить только его.',
  select_info: 'При покупке всего курса в дальнейшем стоимость этого модуля вычитается.',
  price_modules: 'Цена с учётом уже купленных ранее модулей. Скидка учитывается только на половину купленных модулей',
  paid_modules_on: 'Стоимость уже купленных ранее модулей составляет',
  is_read: 'Пройдено',
  is_process: 'В процессе',
  all: 'Смотрите все образовательные продукты в Академии',
  info_module: 'При покупке модулей стоимость может быть дороже чем по тарифу. При последующей покупке тарифа будет учтена стоимость только половины ранее купленных модулей',
  admin_message: 'Здравствуйте! У меня есть вопросы по курсу «{{ name }}»',
  have_questions: 'Есть вопросы?',
  can_bye_module: 'Этот модуль можно приобрести отдельно за',
  go_educations: 'Перейти ко всем курсам',
  not_started: 'Курс ещё не начался!',
  hidden_tariff: 'Изучить тарифы',
  select_variant: 'Выбор варианта оплаты тарифа «{{ name }}»',
  variant_modules: 'Выбрать вариант оплаты для приобретения следующих модулей',
  total: 'Итого',
};
