import React from 'react';

const file = ({ colorStroke }) => ({
  viewBox: '0 0 25 29',
  data: (
    <g>
      <mask id="file-svg" fill="transparent" stroke={colorStroke}>
        <rect width="25" height="28.7362" rx="4" />
      </mask>
      <rect
        width="25"
        height="28.7362"
        rx="1"
        stroke={colorStroke}
        strokeWidth="6"
        mask="url(#file-svg)"
      />
      <line
        x1="6.87891"
        y1="14.1934"
        x2="12.2324"
        y2="14.1934"
        stroke={colorStroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="6.87891"
        y1="20.0762"
        x2="13.7031"
        y2="20.0762"
        stroke={colorStroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="6.87891"
        y1="8.31152"
        x2="16.6445"
        y2="8.31152"
        stroke={colorStroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
  ),
});

export default file;
