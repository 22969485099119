import React from 'react';

const loader = ({ colorStroke }) => ({
  viewBox: '0 0 100 100',
  data: (
    <>
      <circle
        cx="50"
        cy="50"
        r="0"
        fill="none"
        stroke={colorStroke}
        strokeWidth="11"
      >
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.7s"
          values="0;40"
          keyTimes="0;1"
          keySplines="0 0.2 0.8 1"
          calcMode="spline"
          begin="-0.8s"
        />
        <animate
          attributeName="opacity"
          repeatCount="indefinite"
          dur="1.7s"
          values="1;0"
          keyTimes="0;1"
          keySplines="0.2 0 0.8 1"
          calcMode="spline"
          begin="-0.8s"
        />
      </circle>
      <circle
        cx="50"
        cy="50"
        r="0"
        fill="none"
        stroke={colorStroke}
        strokeWidth="11"
      >
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.7s"
          values="0;40"
          keyTimes="0;1"
          keySplines="0 0.2 0.8 1"
          calcMode="spline"
        />
        <animate
          attributeName="opacity"
          repeatCount="indefinite"
          dur="1.7s"
          values="1;0"
          keyTimes="0;1"
          keySplines="0.2 0 0.8 1"
          calcMode="spline"
        />
      </circle>
    </>
  ),
});

export default loader;
