import baseConfig from './config.base';

const config = {
  ...baseConfig,
  environment: 'production',
  schema: 'https',
  hostname: 'artocratia.ru',
  api: 'https://api.artocratia.ru/',
  RAZZLE_API_GRAPHQL_PATH: 'https://api.artocratia.ru/graphql',
  RAZZLE_API_GRAPHQL_SSR_PATH: 'http://127.0.0.1:3001/graphql',
  YANDEX_MAPS_KEY: 'a4a4e508-5b4c-43a4-aa2d-8cc5c5b637e1',
  YANDEX_MAPS_SUGGEST_KEY: 'c27e790b-d64c-4ab1-aa74-653dd711f451',
  METRIC_YANDEX: 89674274,
  STRIPE_PUB_KEY: 'pk_live_51MhXStJaB3GRpNiAayzN0HuMyehz7V0L2XlV8UCRJuWpu4brkrq7wpMwHVDE4toBwNxCizWhTKvbyzwTNVT6bEJ900AS38f6Nx',
  tinkoff: {
    shopId: '7d194832-967d-4b0b-ba6d-135d97922b91',
    showcaseId: 'c36637e6-8a04-42ee-a245-b9386f70b9f0',
  },
};

export default config;
