export default {
  name: 'Статус',
  for_friends: 'Опубликовано для друзей',
  for_market_friends: 'Опубликовано для друзей в маркете',
  published: 'Опубликовано для всех',
  published_only: 'Опубликовано для всех',
  published_market: 'Опубликовано для всех в маркете',
  unpublish_info: 'Нажмите чтобы снять с публикации',
  publish_info: 'Нажмите чтобы опубликовать',
  publish_not: 'Вы не можете отправить на публикацию, так как нехватает необходимой информации: {{ info }}',
  no_images: 'нет фотографий',
  no_artworks: 'нет работ',
  moderation: 'На модерации',
  moderation_info: 'После модерации станет доступно редактирование и снятие с публикации',
  edit: 'Видно мне',
  not_paid: 'Не оплачено. Работа видна только вам',
  not_paid_info: 'Чтобы работу было видно в маркете, необходимо приобрести новый или продлить уже имеющийся тариф',
};
