import React from 'react';

const apple = ({ colorFill }) => ({
  viewBox: '0 0 15 18',
  data: (
    <path
      d={`M13.7148 6.27689C13.6386 6.32464 11.8253 7.33235 11.8253 9.56667C11.9108 12.1148 14.1137 13.0084
            14.1514 13.0084C14.1137 13.0561 13.8189 14.2257 12.9456 15.4517C12.2526 16.5078 11.4835 17.5725
            10.3155 17.5725C9.20444 17.5725 8.80561 16.8687 7.52365 16.8687C6.14694 16.8687 5.7574 17.5725
            4.70335 17.5725C3.53534 17.5725 2.70919 16.4507 1.97842 15.4046C1.02902 14.0353 0.222074 11.8866
            0.193586 9.82341C0.174387 8.73012 0.383711 7.65543 0.915073 6.7426C1.66505 5.46824 3.00398 4.60316
            4.46615 4.57463C5.58647 4.5368 6.58355 5.34483 7.26726 5.34483C7.92248 5.34483 9.14746 4.57463
            10.5335 4.57463C11.1317 4.57525 12.727 4.75571 13.7148 6.27689ZM7.17251 4.35635C6.97309 3.35794
            7.52365 2.35953 8.03643 1.72266C8.69166 0.952457 9.72651 0.429688 10.6189 0.429688C10.6759 1.4281
            10.3148 2.40728 9.66954 3.12043C9.09049 3.89063 8.09341 4.47045 7.17251 4.35635Z`}
      fill={colorFill}
    />
  ),
});

export default apple;
