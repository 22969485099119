import { gql } from '@apollo/client';

const fragment = gql`
  fragment MessageFragment on Message {
    _id
    dialog_id
    user {
      _id
      name
      avatar {
        _id
        key
        fileName
      }
    }
    files {
      _id
      key
      format
      fileName
    }
    user_id
    text
    created_at
    read
    is_read
  }
`;

export const GQL_CREATE_MESSAGE = gql`
  mutation CreateMessage(
    $receiver_id: ID!,
    $user_id: ID!,
    $text: String!,
    $created_at: Int!
    $dialog_id: ID
  ) {
    createMessage(
      receiver_id: $receiver_id,
      user_id: $user_id,
      text: $text,
      created_at: $created_at
      dialog_id: $dialog_id
    ) {
      _id
      user_id
      text
      created_at
      dialog_id
    }
  }
`;

export const GQL_READ_MESSAGES = gql`
  mutation UpdateUnreadMessages($dialog_id: ID!) {
    readMessages(dialog_id: $dialog_id)
  }
`;

export const GQL_DELETE_MESSAGE = gql`
  mutation DeleteMessageQuery($dialog_id: ID!, $message_id: ID!) {
    deleteMessage(dialog_id: $dialog_id, message_id: $message_id)
  }
`;

export const GQL_UPDATE_MESSAGE = gql`
  mutation UpdateMessageQuery($message_id: ID!, $dialog_id: ID!, $text: String!) {
    updateMessage(message_id: $message_id, dialog_id: $dialog_id, text: $text)
  }
`;

export const GQL_SEARCH_MESSAGE = gql`
  query GetMessages($dialog_id: ID, $text: String!) {
    searchMessages(dialog_id: $dialog_id, text: $text) {
      _id
      dialog_id
      text
      user {
        name
        avatar {
          _id
          key
          fileName
        }
      }
      created_at
    }
  }
`;

export const GQL_GET_MESSAGES_ALL = gql`
  query GetMessagesAll($dialog_id: ID, $temporary_id: ID)  {
    messagesAll(dialog_id: $dialog_id, temporary_id: $temporary_id) {
      _id
      dialog_id
      user_id
      text
      read
      created_at
      files {
        _id
        key
        format
        fileName
      }
    }
  }
`;

export const GQL_UNREAD_MESSAGES_COUNT = gql`
    query GetUnreadMessages($user_id: ID) {
      unreadMessagesCount(user_id: $user_id)
    }
`;

export const GQL_LOAD_PREV_MESSAGES = gql`
    query loadPrev(
      $dialog_id: ID,
      $created_at_before: String,
      $per_page: Int
    ) {
      loadPrevMessages(
        dialog_id: $dialog_id,
        created_at_before: $created_at_before,
        per_page: $per_page
      ) {
        _id
        dialog_id
        user_id
        text
        created_at
        read
        is_read
      }
    }
`;

export const GQL_LOAD_NEXT_MESSAGES = gql`
  query loadNext(
    $dialog_id: ID,
    $created_at_before: String,
    $per_page: Int
  ) {
    loadNextMessages(
      dialog_id: $dialog_id,
      created_at_before: $created_at_before,
      per_page: $per_page
    ) {
      _id
      dialog_id
      user_id
      text
      created_at
      read
      is_read
    }
  }
`;

export const GQL_MESSAGE_NEW = gql`
  subscription GetNewMessage($receiver_id: ID, $dialog_id: ID) {
    messageNew(receiver_id: $receiver_id, dialog_id: $dialog_id) {
      ...MessageFragment
    }
  }
  ${fragment}
`;

export const GQL_MESSAGE_UPDATED = gql`
  subscription GetUpdatedMessage($receiver_id: ID, $dialog_id: ID) {
    messageUpdated(receiver_id: $receiver_id, dialog_id: $dialog_id) {
      ...MessageFragment
    }
  }
  ${fragment}
`;

export const GQL_UPDATE_UNREAD_MESSAGE_COUNT = gql`
  subscription updateCount($user_id: ID) {
    unreadMessagesCount(user_id: $user_id)
  }
`;

export const GQL_UPDATE_TYPING_MESSAGE = gql`
  mutation TypingMessage($receiver_id: ID, $dialog_id: ID, $typing: Boolean) {
    typingMessage(receiver_id: $receiver_id, dialog_id: $dialog_id, typing: $typing)
  }
`;

export const GQL_SUBSCRIBE_TYPING_MESSAGE = gql`
  subscription GetUserTyping($receiver_id: ID, $dialog_id: ID) {
    userTyping(receiver_id: $receiver_id, dialog_id: $dialog_id)
  }
`;

export const GQL_MESSAGE_DELETED = gql`
    subscription GetDeletedMessage($dialog_id: ID!) {
      messageDeleted(dialog_id: $dialog_id)
    }
`;

export const GQL_GET_MESSAGE_COUNT = gql`
  query GetMessagesCount {
    messagesCount
  }
`;
