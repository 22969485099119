/* eslint-disable no-extra-boolean-cast */
import moment from 'moment';
import React from 'react';
import _ from 'lodash';

import {
  ACTIVE_DARK,
  ACTIVE_LIGHT,
  DEFAULT,
  DEFAULT_LIGHT,
  ERROR,
  SUCCESS,
  SUCCESS_DARK,
  WARNING,
} from './colors';
import {
  STATUS_MODERATION,
  STATUS_PUBLISHED,
  stringRegister,
  USER_GUEST,
} from './main';
import { getCurrencyIcon } from './payment';

export const CENTURIES = [
  /// TODO DELETE
  { label: 'Выберите век', value: '' },
  { label: 'I', value: '1' }, { label: 'II', value: '2' },
  { label: 'III', value: '3' }, { label: 'IV', value: '4' },
  { label: 'V', value: '5' }, { label: 'VI', value: '6' },
  { label: 'VII', value: '7' }, { label: 'VIII', value: '8' },
  { label: 'IX', value: '9' }, { label: 'X', value: '10' },
  { label: 'XI', value: '11' }, { label: 'XII', value: '12' },
  { label: 'XIII', value: '13' }, { label: 'XIV', value: '14' },
  { label: 'XV', value: '15' }, { label: 'XVI', value: '16' },
  { label: 'XVII', value: '17' }, { label: 'XVIII', value: '18' },
  { label: 'XIX', value: '19' }, { label: 'XX', value: '20' },
  { label: 'XXI', value: '21' },
];

export const CONDITION = [
  { label: 'Плохое', value: 'bad' },
  { label: 'Нормальное', value: 'normal' },
  { label: 'Отличное', value: 'good' },
];

export const SIZE_TYPES = {
  disabled: 'Без размера',
  flexible: 'Размер варьируется',
};

export const TYPES = {
  clear: 'Чистый',
  frame: 'С рамой',
  package: 'В упаковке',
  free: 'Свободный',
};

export const FIGURES = {
  circle: 'Круг',
  oval: 'Овал',
  square: 'Квадрат',
  rectangle: 'Прямоугольник',
};

export const DIMENSIONS = {
  cm: 'см',
  mm: 'мм',
  m: 'м',
};

export const UNITS = [
  { label: 'Килограмм', value: 'kg' },
  { label: 'Грамм', value: 'g' },
  { label: 'Фунт', value: 'lb' },
];

export const UNITS_SHORT = [
  { label: 'Кг', value: 'kg' },
  { label: 'Гр', value: 'g' },
  { label: 'Фт', value: 'lb' },
];

export const FRAGILITY = [
  /// TODO DELETE
  { label: 'Высокая', value: 'bad' },
  { label: 'Средняя', value: 'normal' },
  { label: 'Низкая', value: 'good' },
];

export const ACTIVITY_TYPES = {
  auction: 'Аукцион',
  exhibition: 'Выставка',
  publication: 'Публикация',
};

export const HISTORY_TYPES = {
  frame: 'Багет',
  estimate: 'Оценка',
  digitization: 'Оцифровка',
  buy: 'Покупка',
  sale: 'Продажа',
  restoration: 'Реставрация',
  insurance: 'Страхование',
  transfer: 'Транспорт',
  customs: 'Таможня',
  storage: 'Хранение',
  expertise: 'Экспертиза',
};

/**
 * @function getDate
 * @description this function returns date value in string
 * @param {object} data
 * @param {string} data.variant of date param ['period', 'century', 'free', 'date']
 * @param {string} data.century number of century['1', '2', '3' ..... ]
 * @param {string} data.started_at string time when started
 * @param {string} data.ended_at string time when ended
 * @param {string} data.free this string free field
 * @returns string
 */
let century = '';

export const getDate = (data) => {
  if (_.get(data, 'variant')) {
    switch (_.get(data, 'variant')) {
      case 'date':
        if (!!data.started_at) {
          return data.started_at;
        }
        break;
      case 'century':
        CENTURIES.map((item) => {
          if (item.value === data.century) {
            century = `${item.label} Век`;
          }
          return century;
        });
        return century;
      case 'period':
        if (!!data.started_at && !!data.ended_at) {
          return `${data.started_at} - ${data.ended_at}`;
        }
        break;
      case 'free':
        if (!!data.free) {
          return data.free;
        }
        break;
      default:
        return '';
    }
  }
  return '';
};

/**
 * @function validateInside
 * @description We need this because we should already have the history, mentions and
 * provenance forms are open during initialization, and they are in the array and also
 * validated, this function validates index[0] only if some field is filled in
 *
 * @param {object} currentVal the param from YUP of current item
 * @param {object} context  the param from YUP of current item
 * @param {array} fields if fielded by which you need to check whether something is
 * filled in the 0 index so that it starts validating
 * @param {string} required field that required in block
 *
 * @example in YUM validationSchema look .test('history'....
 */
export const validateInside = (currentVal, context, required, fields = []) => {
  const { path, type } = context;
  if (path === `${type}[0]`) {
    return !(!_.isEmpty(fields.filter(
      // If not, empty some of the fields
      (field) => !!currentVal[field],
      // and empty required field set error for index 0
    )) && !currentVal[required]);
  }
  // if empty required field set error for all others indexes on mount
  return !!currentVal[required];
};

/**
 * @function getSizes
 * @description this function get an array of sizes and return string value
 * @param {array} data
 * @param {string} data.dimension name of param ['cm', 'mm', 'm']
 * @param {string} data.string type name of type ['clear', 'frame', 'package', 'free]
 * @param {boolean} coma before sizes
 * @param {string} data.string height param
 * @param {string} data.string length param
 * @param {string} data.string width param
 * @param {boolean} first If we need only first value
 * @return {any[]}
 *
 */
export const getSizes = (data, coma = false, first = false) => {
  if (data) {
    let newData = data;
    if (first) {
      // Get only first size
      newData = data.filter((d) => (d.type !== 'free' && !d.free));
      if (!_.isEmpty(newData)) {
        newData = [newData[0]];
      }
    }
    return newData.map((size, i) => {
      if (!size.length
        && !size.width
        && !size.height
        && !size.free
        && !size.wh
        && !size.diameter) {
        return '';
      }
      if (size.type !== 'free') {
        if (size.figure === 'circle') {
          return (
            <span key={`${size.type}-${i + 1}`}>
              {newData.length > 1 && i + 1 !== 1 ? ', ' : ''}
              {size.diameter && `Ø ${size.diameter}`}
              {size.depth && `${size.diameter ? ' x ' : ''}${size.depth}`}
              {' '}
              {DIMENSIONS[size.dimension]}
            </span>
          );
        }
        if (size.figure === 'square') {
          return (
            <span key={`${size.type}-${i + 1}`}>
              {newData.length > 1 && i + 1 !== 1 ? ', ' : ''}
              {size.wh && size.wh}
              {' x '}
              {size.wh && size.wh}
              {size.depth && `${size.wh ? ' x ' : ''}${size.depth}`}
              {' '}
              {DIMENSIONS[size.dimension]}
            </span>
          );
        }
        return (
          <span key={`${size.type}-${i + 1}`}>
            {newData.length > 1 && i + 1 !== 1 ? ', ' : ''}
            {size.height && size.height}
            {size.width && `${size.height ? ' x ' : ''}${size.width}`}
            {size.depth && `${size.height || size.width ? ' x ' : ''}${size.depth}`}
            {' '}
            {DIMENSIONS[size.dimension]}
          </span>
        );
      }
      if (size.type === 'free' && !first) {
        return (
          <span key={`${size.type}-${i + 1}`}>
            {newData.length > 1 && i + 1 !== 1 ? ', ' : ''}
            {stringRegister(size.free, !coma ? 'capital' : 'lowercase')}
          </span>
        );
      }
      return '';
    });
  }
  return '';
};

/**
 * @description Get price for artwork
 * @param values Artwork values
 * @return {string}
 */
export const priceGenerator = (values) => {
  if (!_.get(values, 'currency')) {
    return '';
  }
  if (_.get(values, 'price')) {
    return `${_.get(values, 'price')} ${getCurrencyIcon(_.get(values, 'currency'))}`;
  }
  if (_.get(values, 'price_from') && _.get(values, 'price_to')) {
    return `${_.get(values, 'price_from')} - ${_.get(values, 'price_to')} ${getCurrencyIcon(_.get(values, 'currency'))}`;
  }
  return '';
};

export const getCondition = (data) => CONDITION.map((item) => {
  if (item.value === data) {
    return !!item.label && item.label;
  }
  return '';
});

export const getCentury = (data) => CENTURIES.map((item) => {
  if (item.value === data) {
    return !!item.label && (
    <>
      {item.label}
      {' '}
      Век
    </>
    );
  }
  return '';
});

export const getFragility = (data) => FRAGILITY.map((item) => {
  if (item.value === data) {
    return !!item.label && item.label;
  }
  return '';
});

export const getHistoryType = (type) => HISTORY_TYPES[type];

export const getActivityType = (type) => ACTIVITY_TYPES[type];

export const getUnits = (type, short = false) => {
  const unit = (short ? UNITS_SHORT : UNITS).find((item) => (item.value === type));
  return unit.label;
};

/**
 * @name statusGenerator
 * @description generate statuses for user who can edit his item
 * @param {Object} item Item like artwork, exhibition, institution
 * @param {Boolean} isPaid if user paid for one artwork or tariff
 * @param {Function} t i18n translate
 * @return {Object}
 */
export const statusGenerator = ({ item, isPaid, t }) => {
  let leftTime = null;
  let color = null;
  let infoColor = false;
  let message = null;
  let tooltip = null;
  const unixTimeNow = Math.floor(Date.now() / 1000);
  if (item.status === null || typeof item.status === 'undefined') {
    return {
      message, color, infoColor, tooltip, leftTime,
    };
  }
  const status = +_.get(item, 'status', 0);
  switch (status) {
    case STATUS_PUBLISHED:
      if (item.on_trust && item.moderated) {
        color = ERROR;
        message = t('not_paid', { ns: 'status' });
        tooltip = t('not_paid_info', { ns: 'status' });
      } else if (_.get(item, 'access') && _.isArray(item.access) && item.access.includes(USER_GUEST)) {
        color = item.is_market ? ACTIVE_DARK : ACTIVE_LIGHT;
        message = item.is_market
          ? t('published_market', { ns: 'status' })
          : t('published', { ns: 'status' });
        tooltip = t('unpublish_info', { ns: 'status' });
      } else {
        color = item.is_market ? SUCCESS_DARK : SUCCESS;
        message = item.is_market
          ? t('for_market_friends', { ns: 'status' })
          : t('for_friends', { ns: 'status' });
        tooltip = t('unpublish_info', { ns: 'status' });
      }
      break;
    case STATUS_MODERATION:
      color = WARNING;
      message = t('moderation', { ns: 'status' });
      tooltip = t('moderation_info', { ns: 'status' });
      break;
    default:
      color = item.images && item.images.length ? DEFAULT : DEFAULT_LIGHT;
      message = t('edit', { ns: 'status' });
      tooltip = item.images && item.images.length
        ? t('publish_info', { ns: 'status' })
        : t('publish_not', { ns: 'status', info: t('no_images', { ns: 'status' }) });
  }
  if (item.is_market && status === STATUS_PUBLISHED && !isPaid) {
    color = ERROR;
    message = t('not_paid', { ns: 'status' });
    tooltip = t('not_paid_info', { ns: 'status' });
  }
  if (item.is_market && _.get(item, 'expired')) {
    if (unixTimeNow < item.expired) {
      color = STATUS_PUBLISHED;
      message = t('published_market', { ns: 'status' });
      tooltip = t('unpublish_info', { ns: 'status' });
      leftTime = moment.unix(item.expired).endOf('day').fromNow();
      const daysToEnd = Math.floor((item.expired - unixTimeNow) / 60 / 60 / 24);
      if (daysToEnd <= 15 && daysToEnd > 5) {
        infoColor = WARNING;
      } else if (daysToEnd <= 5) {
        infoColor = ERROR;
      }
    }
  }
  /**
   *   @param {String} message Message of status
   *   @param {String} color Color of status message
   *   @param {String} infoColor Color of info leftTime
   *   @param {String} tooltip Tooltip help message
   *   @param {String} leftTime left time when pay will be end
   * */
  return {
    message, color, infoColor, tooltip, leftTime,
  };
};
