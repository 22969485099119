import React from 'react';
import _ from 'lodash';
import { Redirect, useLocation } from 'react-router-dom';

export default (Component, requireAuth = false, me = null) => (masterProps) => {
  const location = useLocation();
  if (!requireAuth) {
    return (<Component props={masterProps} ssr />);
  }
  if (!_.get(me, '_id')) {
    // eslint-disable-next-line max-len
    return <Redirect to={`/login?returnUrl=${encodeURIComponent(location.pathname + location.search)}`} />;
  }
  return (<Component props={masterProps} ssr={false} />);
};
