export default {
  about: 'Об альбоме',
  about_author: 'Об авторе альбома',
  collections: 'Коллекции',
  name: 'Альбом {{ name }}',
  title: '{{ name }} - Альбом из частной коллекции {{ owner }}',
  description: 'Альбом «{{ name }}» из частной коллекции произведения искусств {{ owner }}. Социальная сеть для ценителей искусства - Артократия',
  write_message: 'Написать сообщение',
  works_in_album: 'Работы в альбоме ({{ total }})',
  placeholder: 'Введите название или автора',
  share_label: 'Поделиться альбомом',
  share_description: 'Посмотрите коллекцию {{ name }}',
  access_message: 'Доступ к альбому предоставлен по ссылке',
  saved: 'Вы успешно сохранили альбом!',
  my: 'Мой альбом',
  all: 'Мои альбомы',
  publish_name: 'альбом',
  update: 'Обновить альбом «{{ name }}»',
  add: 'Добавить альбом',
  update_artworks: 'Добавить работы в альбом «{{ name }}»',
  add_artworks: 'Добавить работы в альбом',
  you_can: 'Вы можете создать альбом и добавить в него работы',
  attached: 'В альбом добавлено {{ count }} {{ name }}',
  field_name: 'Название альбома',
  field_name_placeholder: 'Введите название',
  field_about: 'Об альбоме',
  field_about_placeholder: 'Введите описание',
  field_image: 'Фото обложки',
  image_info: 'Фотография должна быть хорошего качества, не менее 512х512 px и не более 2 мб. Если фото не загружено, то обложка будет формироваться из фотографий последних 10 добавленных работ',
  upload: 'Загрузить изображения',
  upload_info: 'Воспользуйтесь кнопкой {:} или перетащите файл в эту область',
  selected: 'Выбрано {{ count }}',
  to_select: 'К добавлению работ',
  close: 'Закрыть',
  created: 'Альбом создан!',
  show_all: 'Показать все',
  show_selected: 'Показать выбранное',
  albums: 'Альбомы',
};
