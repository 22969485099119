import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, InputGroup, Row, } from 'react-bootstrap';

const Field = ({
  name,
  label,
  placeholder,
  value,
  type,
  error,
  disabled,
  groupClassName,
  className,
  inputGroup,
  prepend,
  onChange,
  column,
  append,
  ...props
}) => (
  <Form.Group
    as={!column ? Row : undefined}
    controlId={name}
    className={groupClassName}
  >
    {!!label && (
      <Form.Label column={!column} md={!column ? 3 : undefined}>{label}</Form.Label>
    )}

    <Col md={label && !column ? 9 : 12}>
      {['text', 'textarea', 'password'].includes(type) && (
        <InputGroup className={inputGroup}>
          {!!prepend && (
            <InputGroup.Text className="bg-transparent border-0">
              {prepend}
            </InputGroup.Text>
          )}

          <Form.Control
            name={name}
            type={type}
            className={`${className} ${prepend ? 'prepend' : ''} ${append ? 'append' : ''}`}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
            {...props}
          />

          {!!append && (
            <InputGroup.Text className="bg-transparent border-0">
                {append}
            </InputGroup.Text>
          )}
        </InputGroup>
      )}

      {!!error && (
        <Form.Text className="text-danger">{error}</Form.Text>
      )}
    </Col>
  </Form.Group>
);

Field.defaultProps = {
  type: 'text',
  label: '',
  disabled: false,
  groupClassName: '',
  placeholder: '',
  className: '',
  inputGroup: '',
  prepend: false,
  append: false,
  column: false,
  error: '',
};

Field.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  groupClassName: PropTypes.string,
  className: PropTypes.string,
  inputGroup: PropTypes.string,
  prepend: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  onChange: PropTypes.func.isRequired,
  column: PropTypes.bool,
  append: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default Field;
