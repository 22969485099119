import { gql } from '@apollo/client';

const fragment = gql`
  fragment NotificationFragment on Notification {
    _id
    is_read
    created_at
    action
    model
    sender {
      _id
      name
      attitude
      username
      role
      avatar {
        _id
        key
        fileName
      }
    }
    item {
      name
      text
      artist_name
      artist_url
      artwork_name
      artwork_url
      url
    }
  }
`;

export const GQL_GET_NOTIFICATIONS_ALL = gql`
  query GetNotificationsAll($query: QueryItems, $section: String) {
    notificationsAll(query: $query, section: $section) {
      total
      items {
        ...NotificationFragment,
      }
    }
  }
  ${fragment}
`;

export const GQL_REMOVE_NOTIFICATION = gql`
  mutation RemoveNotification($_id: String!) {
    removeNotification(_id: $_id)
  }
`;

export const GQL_UPDATE_MANY_NOTIFICATIONS = gql`
  mutation UpdateManyNotifications($ids: [String], $process: String!) {
    updateManyNotifications(ids: $ids, process: $process)
  }
`;

export const GQL_UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($_id: String!, $process: String!) {
    updateNotification(_id: $_id, process: $process)
  }
`;

export const GQL_UPDATE_UNREAD_NOTIFICATIONS_COUNT = gql`
  subscription UnreadNotifications($user_id: ID) {
    unreadNotifications(user_id: $user_id)
  }
`;

export const GQL_GET_NOTIFICATIONS_COUNT = gql`
  query GetNotificationsCount {
    notificationsCount
  }
`;
