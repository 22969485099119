export default {
  name: 'Гайды',
  label: 'Журнал',
  all_news: 'Все новости',
  archive: 'Архив новостей',
  editor_choice: 'Выбор редакции',
  look_all: 'Смотреть всё',
  share_label: 'Поделиться новостью',
  no_items: 'Ничего не найдено',
  no_request_result: 'По запросу «{{ request }}» ничего не найдено',
  search_placeholder: 'Начните вводить название',
  share_description: 'Материал {{ name }} социальной сети для ценителей искусства - Артократия',
  interesting: 'Интересно?',
  interesting_text: 'Чтобы прочитать статью полностью, зарегистрируйтесь на нашей платформе. Это бесплатно и займет меньше 3 минут. В подарок мы пришлем вам на почту доступ к лекции «Бизнес в искусстве и искусство как бизнес».',
  login_register: 'Войти или зарегистрироваться',
  journal_placeholder: 'Введите название',
  all: 'Читайте все статьи в Журнале',
  more: 'Читать далее',
  author: 'Автор',
  authors: 'Авторы',
  read_theme: 'Читайте статьи по этой теме',
  comments: 'Комментарии',
  back: 'Назад ко всем статьям',
  hidden_tariff: 'Изучить тарифы',
};
