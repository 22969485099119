import { gql } from '@apollo/client';

export const GQL_SEND_ABUSE = gql`
  mutation SendAbuse($_id: String!, $modelName: String!, $url: String!, $text: String) {
    sendAbuse(_id: $_id, modelName: $modelName, url: $url, text: $text)
  }
`;

export const GQL_SEND_MODIFY = gql`
  mutation SendModify($_id: String!, $modelName: String!, $modify: String!) {
    sendModify(_id: $_id, modelName: $modelName, modify: $modify)
  }
`;

export const GQL_SEND_FEEDBACK = gql`
  mutation SendFeedback($data: Feedback) {
    sendFeedback(data: $data)
  }
`;

export const GQL_SEND_REAL = gql`
  mutation SendReal {
    sendReal
  }
`;

export const GQL_SEND_MODERATION = gql`
  mutation SendModeration($_id: String!, $modelName: String!) {
    sendModeration(_id: $_id, modelName: $modelName)
  }
`;

export const GQL_SEND_PROMO_REQUEST = gql`
  mutation SendRequestPromo($confirmed: String!, $email: String, $phone: String, $name: String) {
    sendRequestPromo(confirmed: $confirmed, email: $email, phone: $phone, name: $name)
  }
`;

export const GQL_SEND_METASPACE_REQUEST = gql`
  mutation SendRequestMetaspace($phone: String, $name: String, $message: String!, $role: String!) {
    sendRequestMetaspace(phone: $phone, name: $name, message: $message, role: $role)
  }
`;

export const GQL_SEND_VIEWING_ROOM_REQUEST = gql`
  mutation SendRequestViewingRoom($phone: String, $name: String, $message: String!, $room_id: String!) {
    sendRequestViewingRoom(phone: $phone, name: $name, message: $message, room_id: $room_id)
  }
`;

export const GQL_PAY_REQUEST = gql`
  mutation PayRequest($data: InputPay) {
    payRequest(data: $data) {
       token
    }
  }
`;

export const GQL_PAY_STATUS = gql`
  mutation PayStatus($token: String!, $status: String!) {
    payStatus(token: $token, status: $status)
  }
`;

export const GQL_NEXT_PAY_STATUS = gql`
  mutation NextPayStatus($token: String!, $status: String!) {
    nextPayStatus(token: $token, status: $status)
  }
`;

export const GQL_DELETE_AUTO_PAY = gql`
  mutation deleteAutoPay($model: String!, $_id: String) {
    deleteAutoPay(model: $model, _id: $_id)
  }
`;

export const GQL_TRY_BYE = gql`
  mutation TryBye($user_id: String!, $item_id: String!, $model: String!, $dialog_id: String!) {
     tryBye(user_id: $user_id, item_id: $item_id, model: $model, dialog_id: $dialog_id)
  }
`;

export const GQL_CREATE_PAYMENT = gql`
  mutation SetCreatePayment($model: String!, $mode: String!, $tariff_id: String!, $order_id: String, $promo: String) {
    createPayment(tariff_id: $tariff_id, order_id: $order_id, model: $model, mode: $mode, promo: $promo)
  }
`;

export const GQL_PAY_FREE = gql`
  mutation SetItemFree($item_id: String!, $model: String!) {
    itemFree(item_id: $item_id, model: $model)
  }
`;

export const GQL_GET_EXCHANGE_RATE = gql`
  query GetExchangeRate {
    exchangeRate
  }
`;

export const GQL_FAST_PAYMENT = gql`
  query GetFastPayment($token: String!, $model: String!) {
    fastPayment(token: $token, model: $model) {
      _id
      name
      artist {
        _id
        name
        status
        url
      }
      owner {
        _id
        name
        username
      }
      url
      price
      currency
    }
  }
`;
