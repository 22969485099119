import React from 'react';

const edit = ({ colorStroke }) => ({
  viewBox: '0 0 18 18',
  data: (
    <g>
      <path
        d={`M12.4958 3.21895L14.4476 5.16988L12.4958 3.21895ZM13.7509
       1.50024L8.47318 6.77799C8.20048 7.05031 8.0145 7.39726 7.93867
        7.77512L7.45117 10.2154L9.89145 9.72697C10.2693 9.6514 10.6158
         9.46617 10.8886 9.19339L16.1663 3.91564C16.3249 3.75704 16.4507
          3.56876 16.5366 3.36154C16.6224 3.15433 16.6666 2.93223 16.6666
           2.70794C16.6666 2.48365 16.6224 2.26156 16.5366 2.05434C16.4507
            1.84712 16.3249 1.65884 16.1663 1.50024C16.0077 1.34165 15.8194
             1.21584 15.6122 1.13001C15.405 1.04418 15.1829 1 14.9586 1C14.7343
              1 14.5122 1.04418 14.305 1.13001C14.0978 1.21584 13.9095 1.34165
               13.7509 1.50024V1.50024Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={`M14.8233 12.0583V14.823C14.8233 15.3118 14.6291 15.7806
       14.2835 16.1263C13.9378 16.4719 13.469 16.6661 12.9802
        16.6661H2.84311C2.35429 16.6661 1.88548 16.4719 1.53983
         16.1263C1.19418 15.7806 1 15.3118 1 14.823V4.68588C1
          4.19706 1.19418 3.72826 1.53983 3.38261C1.88548
           3.03696 2.35429 2.84277 2.84311 2.84277H5.60778`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default edit;
