import { gql } from '@apollo/client';

export const GQL_SEARCH = gql`
    query Search($name: String) {
      search(name: $name) {
        artists {
          _id
          name
          url
          dates {
            variant
            kind
            started_at
            ended_at
            location_birth
            location_live
            location_death
          }
          avatar {
            _id
            format
            fileName
            key
          }
        }
        users {
          _id
          username
          name
          avatar {
            _id
            fileName
            format
            key
          }
        }
        artworks {
          _id
          name
          url
          ex_artist
          ex_name
          artist {
            _id
            url
            name
            status
          }
          images {
            _id
            format
            fileName
            key
          }
        }
        educations {
          _id
          name
          url
          status
          free_date
          ended_at
          started_at
          created_at
          type
          tags {
            _id
            name
            url
          }
          cover {
            _id
            format
            fileName
            key
          }
        }
        exhibitions {
          _id
          name
          url
          dates
          days
          worktime
          status
          images {
            _id
            format
            fileName
            key
          }
        }
        institutions {
          _id
          name
          url
          avatar {
            _id
            fileName
            format
            key
          }
          images {
            _id
            format
            fileName
            key
          }
        }
      }
    }
`;
