import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary as Error } from 'react-error-boundary';
import { useTranslation } from './components/hooks/useTranslation';
import { ACTIVE } from './helpers/colors';

const ErrorBoundary = ({ children, ssr }) => {
  const { t } = useTranslation();
  const refreshPage = () => {
    window.location.reload();
  };

  const renderError = ({ error, resetErrorBoundary }) => (
    <div style={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      zIndex: 1501,
      top: 20,
      left: 0,
    }}
    >
      <button
        type="button"
        onClick={resetErrorBoundary}
        style={{
          border: 0,
          borderRadius: 4,
          fontSize: 14,
          textDecoration: 'none',
          background: '#D5E3FE',
          color: '#000',
          padding: '10px 20px',
          maxWidth: 340,
          textAlign: 'left',
        }}
      >
        <b style={{
          color: ACTIVE,
          display: 'block',
          fontSize: 15,
          marginBottom: 10,
        }}
        >
          {t('alert_information', { ns: 'index' })}
        </b>
        {error.name === 'ChunkLoadError' ? t('error_chunk', { ns: 'index' }) : t('error_all', { ns: 'index' })}
      </button>
    </div>
  );

  return ssr ? (
    <Error
      FallbackComponent={renderError}
      onReset={refreshPage}
    >
      {children}
    </Error>
  ) : children;
};

ErrorBoundary.defaultProps = {
  ssr: true,
};

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
  ssr: PropTypes.bool,
};

export default ErrorBoundary;
