import React from 'react';

const stars = ({ colorStroke, colorFill }) => ({
  viewBox: '0 0 37 44',
  data: (
    <g>
      <path
        d="M7.44141 19.7383L28.8438 35.4486"
        stroke={colorStroke}
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M22.5889 9.375L7.11328 16.6173"
        stroke={colorStroke}
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d={`M7.17134 12.9961L7.78932 15.9006L14.0215 16.4212L8.53824 19.4205L9.53345 24.098L6.33647 20.6249L0.203125 23.9799L3.96559
        18.0492L1.50459 15.3757L5.45252 15.7054L7.17134 12.9961Z`}
        fill={colorFill}
      />
      <path
        d={`M23.0303 33.6311L25.9125 34.3463L29.1075 28.9702L29.2487 35.1743L34.0466 36.3649L29.3082 37.7848L29.3777
        40.8409L27.2343 38.4062L22.6905 39.7678L25.0047 35.8737L23.0303 33.6311Z`}
        fill={colorFill}
      />
      <path
        d={`M26.4217 1.83583L27.0659 7.29866L31.8843 8.47152L27.3661 9.84376L28.0594 15.723L24.0625 10.8471L21.2751 11.6937L22.6297
        9.09908L19.6802 5.50087L23.9639 6.54357L26.4217 1.83583Z`}
        fill={colorFill}
      />
    </g>
  ),
});

export default stars;
