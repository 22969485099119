/* eslint-disable react/no-danger,react/prop-types */
import * as React from 'react';
import _ from 'lodash';
import { Container } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import {
  withRouter,
} from 'react-router-dom';

import Footer from '../Footer';
import { GQL_GET_SETTING } from '../../graphql/queries';
import ErrorBoundary from '../../ErrorBoundary';
import TopMenu from '../TopMenu';

export default (Component, props = '') => {
  const query = useQuery(GQL_GET_SETTING, {
    fetchPolicy: 'cache-first',
    variables: {
      code: 'scripts',
    },
  });
  const query2 = useQuery(GQL_GET_SETTING, {
    fetchPolicy: 'cache-first',
    variables: {
      code: 'scripts_bottom',
    },
  });
  const scriptsTop = _.get(query, 'data.setting.value');
  const scripts = _.get(query2, 'data.setting.value');

  const Master = (masterProps) => {
    let container = true;
    let footer = true;
    if (_.get(props, 'container', null) && props.container === 'false') {
      container = false;
    }
    if (_.get(props, 'footer', null) && props.footer === 'false') {
      footer = false;
    }

    const mergeProps = { ...props, ...masterProps };

    if (container) {
      return (
        <ErrorBoundary {...mergeProps}>
          {scriptsTop && (<div dangerouslySetInnerHTML={{ __html: scriptsTop }} />)}
          <div id="payment-form" />
          <div id="top" className={footer ? 'min-vh-100' : ''}>
            <TopMenu {...mergeProps} />
            <div id="content">
              <Container>
                <Component {...mergeProps} />
              </Container>
            </div>
          </div>
          {footer && (<Footer />)}
          {scripts && (<div dangerouslySetInnerHTML={{ __html: scripts }} />)}
          <div id="loaded" />
        </ErrorBoundary>
      );
    }

    return (
      <ErrorBoundary {...mergeProps}>
        {scriptsTop && (<div dangerouslySetInnerHTML={{ __html: scriptsTop }} />)}
        <div id="payment-form" />
        <div id="top" className={footer ? 'min-vh-100' : ''}>
          <TopMenu {...mergeProps} />
          <div id="content">
            <Component {...mergeProps} />
          </div>
        </div>
        {footer && (<Footer />)}
        {scripts && (<div dangerouslySetInnerHTML={{ __html: scripts }} />)}
        <div id="loaded" />
      </ErrorBoundary>
    );
  };
  return withRouter(Master);
};
