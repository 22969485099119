import React from 'react';

const eyeClose = ({ colorFill }) => ({
  viewBox: '0 0 20 16',
  data: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={`M15.2933 3.70664C13.7581 2.74329 11.9438 2 9.99995 2C5.53622 2 1.75557 5.91942 0.4697
        7.42267C0.181975 7.75903 0.181975 8.24097 0.4697 8.57733C1.34502 9.60063 3.3764 11.7436 5.99234
        13.0076L7.24036 11.7596C6.4579 11.4367 5.7021 11.0009 4.991 10.5018C3.79458 9.66213 2.81436 8.70604
        2.16367 8C2.81436 7.29396 3.79458 6.33787 4.991 5.49818C6.5146 4.42886 8.24344 3.65 9.99995
        3.65C11.4128 3.65 12.8077 4.15391 14.0924 4.90756L15.2933 3.70664ZM16.5429 4.57836L15.3651
        5.75613C16.4002 6.52869 17.2513 7.36536 17.8362 8C17.1855 8.70604 16.2053 9.66213 15.0089
        10.5018C13.4853 11.5711 11.7565 12.35 9.99995 12.35C9.62282 12.35 9.24696 12.3141 8.87414
        12.2471L7.5116 13.6097C8.30714 13.8548 9.14089 14 9.99995 14C14.4637 14 18.2443 10.0806
        19.5302 8.57733C19.8179 8.24097 19.8179 7.75903 19.5302 7.42267C18.9651 6.76199 17.918
        5.6346 16.5429 4.57836Z`}
        fill={colorFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={`M12.5619 6.43813C12.0349 5.57566 11.0847 5 10 5C8.34315 5 7 6.34315 7 8C7 9.08469 7.57566
        10.0349 8.43813 10.5619L9.68657 9.31343C9.09213 9.1721 8.65 8.63768 8.65 8C8.65 7.25442 9.25442
        6.65 10 6.65C10.6377 6.65 11.1721 7.09213 11.3134 7.68657L12.5619 6.43813ZM10.1238 10.9975C11.6822
        10.9342 12.9342 9.68219 12.9975 8.12383L10.1238 10.9975Z`}
        fill={colorFill}
      />
      <line
        x1="17"
        y1="1.16673"
        x2="3.16673"
        y2="15"
        stroke={colorFill}
        strokeWidth="1.65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default eyeClose;
