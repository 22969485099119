import React from 'react';

const share = ({ colorFill, colorFillIn }) => ({
  viewBox: '0 0 21 20',
  data: (
    <g>
      <path
        fill={colorFill}
        d="M4.265,5.386c0-0.619,0.502-1.121,1.121-1.121h4.117c0.414,0,0.75-0.336,0.75-0.75
        c0-0.415-0.336-0.75-0.75-0.75H5.386c-1.448,0-2.622,1.174-2.622,2.622v11.228c0,1.449,1.174,2.622,2.622,2.622h11.228
        c1.449,0,2.622-1.173,2.622-2.622v-4.116c0-0.415-0.335-0.751-0.75-0.751s-0.751,0.336-0.751,0.751v4.116
        c0,0.619-0.502,1.121-1.121,1.121H5.386c-0.619,0-1.121-0.502-1.121-1.121V5.386z"
      />
      <path
        fill={colorFill}
        d="M12.871,2.764c-0.414,0-0.75,0.336-0.75,0.75c0,0.414,0.336,0.75,0.75,0.75h3.803l-5.269,5.269
        c-0.293,0.293-0.293,0.768,0,1.061c0.293,0.293,0.769,0.293,1.062,0l5.268-5.269v3.802c0,0.415,0.336,0.75,0.751,0.75
        s0.751-0.336,0.751-0.75V3.515c0-0.415-0.336-0.75-0.751-0.75H12.871z"
      />
      <path
        id="fillIn"
        fill={colorFillIn}
        d="M19.256,12.538c0-0.29-0.164-0.542-0.404-0.667l-2.123-2.123c-0.391-0.391-1.023-0.391-1.414,0l-1.879,1.879
        c-0.781,0.781-2.048,0.781-2.829,0l-0.172-0.172c-0.781-0.781-0.781-2.047,0-2.829l1.88-1.878c0.391-0.391,0.391-1.024,0-1.415
        L10.189,3.21c-0.125-0.24-0.376-0.405-0.666-0.405H5.406c-1.448,0-2.622,1.174-2.622,2.622v11.228c0,1.449,1.174,2.622,2.622,2.622
        h11.229c1.449,0,2.621-1.173,2.621-2.622V12.538z"
      />
    </g>
  ),
});

export default share;
