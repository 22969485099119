import React from 'react';

const reply = ({ colorFill }) => ({
  viewBox: '0 0 13 13',
  data: (
    <g>
      <path
        d={`M0.398476 0.765475C4.24014 0.652563 8.02693 0.793703 11.8686 0.906615C12.2528 0.906615
              12.2528 0.285599 11.8686 0.285599C8.02693 0.172687 4.24014 0.00331875 0.398476 0.144459C0.0143099
              0.144459 0.0143099 0.793703 0.398476 0.765475Z`}
        fill={colorFill}
      />
      <path
        d={`M0.0966104 0.454597C0.398455 3.41854 0.64542 6.4107 1.13935 9.37464C1.22167 9.76984 1.79792
              9.60047 1.74304 9.20527C1.24911 6.29779 1.02959 3.36208 0.727741 0.454597C0.67286 0.0594051 0.06917 0.0594051
              0.0966104 0.454597Z`}
        fill={colorFill}
      />
      <path
        d={`M1.44108 9.60154C3.25215 9.77091 5.17299 9.94028 6.98406 9.71446C7.36822 9.658 7.36822 9.03698
              6.98406 9.09344C5.17299 9.31926 3.25215 9.1499 1.44108 8.98053C1.02947 8.92407 1.05692 9.57332 1.44108 9.60154Z`}
        fill={colorFill}
      />
      <path
        d={`M11.6494 0.70819C11.567 3.53099 11.6219 6.38202 11.2378 9.17659C11.1829 9.57178 11.814 9.57178
              11.8414 9.17659C12.1982 6.38202 12.1433 3.53099 12.2531 0.70819C12.2805 0.28477 11.6768 0.28477 11.6494 0.70819Z`}
        fill={colorFill}
      />
      <path
        d={`M6.68188 9.31861C6.4898 10.3066 6.40748 11.3228 6.16051 12.2826C6.07819 12.6213 6.43492 12.7907
              6.68188 12.5931C7.80694 11.6898 8.73991 10.6171 9.72777 9.54444C10.0022 9.26216 9.56313 8.81051
              9.28872 9.09279C8.30087 10.1372 7.34045 11.2381 6.24283 12.1414C6.40748 12.2543 6.59956 12.339
              6.7642 12.4519C6.98372 11.4639 7.06605 10.476 7.28557 9.48798C7.34045 9.06456 6.7642 8.89519
              6.68188 9.31861Z`}
        fill={colorFill}
      />
      <path
        d={`M9.6182 9.57259C10.2768 9.60082 10.9079 9.57259 11.5665 9.57259C11.9506 9.57259 11.9781 8.92335
              11.5665 8.95158C10.9079 8.95158 10.2768 8.97981 9.6182 8.95158C9.23404 8.92335 9.23404 9.57259
              9.6182 9.57259Z`}
        fill={colorFill}
      />
      <path
        d={`M0.617627 0.398269C0.398103 2.00726 0.590187 3.61626 0.617627 5.22526C0.617627 5.62045 1.24876
              5.64868 1.22132 5.22526C1.19388 3.70094 1.00179 2.12018 1.22132 0.567637C1.2762 0.172445 0.672508
              0.00307752 0.617627 0.398269Z`}
        fill={colorFill}
      />
      <path
        d={`M11.4025 9.28981C12.0336 8.21715 12.0885 6.60815 12.1433 5.36612C12.1982 3.81358 12.1708 2.26104
              12.2531 0.708503C12.2806 0.313311 11.6494 0.313311 11.6494 0.708503C11.5671 2.06345 11.5945 3.41839 11.5397
              4.77333C11.5122 6.07182 11.5122 7.85019 10.8536 8.97931C10.6616 9.31804 11.2104 9.62855
              11.4025 9.28981Z`}
        fill={colorFill}
      />
      <path
        d={`M2.40238 2.71262C3.30792 2.62793 4.21345 2.68439 5.11899 2.71262C5.50315 2.74084 5.50315 2.0916
              5.11899 2.0916C4.21345 2.06337 3.30792 2.00692 2.40238 2.0916C2.01822 2.11983 2.01822
              2.74084 2.40238 2.71262Z`}
        fill={colorFill}
      />
      <path
        d={`M6.95707 2.68539C7.3138 2.68539 7.67052 2.71362 7.99981 2.71362C8.38397 2.71362 8.38397 2.0926
              7.99981 2.0926C7.67052 2.06437 7.3138 2.06437 6.95707 2.06437C6.5729 2.03614 6.5729
              2.68539 6.95707 2.68539Z`}
        fill={colorFill}
      />
      <path
        d={`M2.09965 4.77352C4.59673 4.63238 7.09381 4.94289 9.61833 4.97112C10.0025 4.97112 10.0025 4.3501
              9.61833 4.3501C7.12125 4.32188 4.62417 4.0396 2.09965 4.15251C1.71548 4.15251 1.71548
              4.77352 2.09965 4.77352Z`}
        fill={colorFill}
      />
    </g>
  ),
});

export default reply;
