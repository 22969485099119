import React from 'react';

const users = ({ colorStroke }) => ({
  viewBox: '0 0 16 16',
  data: (
    <g>
      <path
        d={`M11.3327 14V12.6667C11.3327 11.9594 11.0517 11.2811 
        10.5516 10.781C10.0515 10.281 9.37326 10 8.66602 
        10H3.33268C2.62544 10 1.94716 10.281 1.44706 10.781C0.946967 
        11.2811 0.666016 11.9594 0.666016 12.6667V14`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={`M6.00065 7.33333C7.47341 7.33333 8.66732 6.13943 
        8.66732 4.66667C8.66732 3.19391 7.47341 2 6.00065 
        2C4.52789 2 3.33398 3.19391 3.33398 4.66667C3.33398 6.13943 
        4.52789 7.33333 6.00065 7.33333Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={`M15.334 13.9998V12.6664C15.3335 12.0756 15.1369 
        11.5016 14.7749 11.0346C14.4129 10.5677 13.9061 
        10.2341 13.334 10.0864`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={`M10.666 2.08643C11.2396 2.23329 11.748 2.56689 
        12.1111 3.03463C12.4742 3.50237 12.6712 4.07765 
        12.6712 4.66976C12.6712 5.26187 12.4742 5.83715 12.1111 
        6.30489C11.748 6.77262 11.2396 7.10623 10.666 7.25309`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default users;
