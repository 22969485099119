import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GQL_GET_DIALOGS_ALL = gql`
  query GetDialogsAll  {
    dialogsAll {
      _id
      companion {
        _id
        name
        role
        username
        last_seen
        avatar {
          _id
          key
          fileName
        }
      }
      last_message {
        text
        created_at
        user_id
        read
        is_read
      }
    }
  }
`;

export const GQL_GET_DIALOG = gql`
  query GetDialog($dialog_id: ID, $companion_id: ID) {
    dialog(dialog_id: $dialog_id, companion_id: $companion_id) {
      _id
      companion {
        _id
        name
        role
        last_seen
        username
        attitude
        avatar {
          _id
          key
          fileName
        }
      }
      messages {
        _id
        text
        user_id
        created_at
        dialog_id
        read
        is_read
      }
    }
  }
`;

export const GQL_CREATE_DIALOG = gql`
  mutation CreateNewDialog($companion_id: ID!, $temporary_id: ID) {
    createDialog(companion_id: $companion_id, temporary_id: $temporary_id) {
      _id
      temporary_id
    }
  }
`;

export const GQL_DELETE_DIALOG = gql`
    mutation DeleteDialog($dialog_id: ID!) {
      deleteDialog(dialog_id: $dialog_id)
    }
`;

export const GQL_DIALOG_NEW = gql`
  subscription GetNewDialog($user_id: ID!) {
    dialogNew(user_id: $user_id) {
      _id
      users {
        _id
        name
        last_seen
        avatar {
          _id
          key
          fileName
        }
      }
      last_message {
        text
        created_at
        user_id
        read
        is_read
      }
    }
  }
`;

export const GQL_DIALOG_UPDATED = gql`
  subscription GetUpdatedDialog($user_id: ID!) {
    dialogUpdated(user_id: $user_id) {
      _id
      last_message {
        text
        created_at
        user_id
        read
        is_read
      }
    }
  }
`;

export const GQL_DIALOG_DELETED = gql`
  subscription GetDeletedDialog($user_id: ID!) {
    dialogDeleted(user_id: $user_id)
  }
`;
