/* eslint-disable max-len */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from '../hooks/useTranslation';

/**
 * @name ArtArtistDates
 * @param {object} field from Formik
 * @param {object} form from Formik
 * @param {object} errors of Formik
 * @param {string} period type of date
 * @param {string} single type of date
 * @param {any} label any label
 * @param {bool} column is column or line
 * @param {bool} noDie hide die checkbox
 * @return string
 */

const ArtArtistDates = ({
  field,
  form,
  form: { errors },
  period,
  single,
  label,
  column = false,
  noDie,
}) => {
  const { t } = useTranslation();
  const kind = _.get(form, `values.${field.name}.kind`);
  return (
    <DatesContainer position={column}>
      <Row className="flex-column flex-lg-row">
        <Col xl={12} className="d-flex flex-row align-items-center mb-4">
          {label && label}
          {!noDie && (
            <Form.Check
              type="checkbox"
              bsPrefix="mb-0 form-check ms-3"
              name={`${field.name}.kind`}
              id={`${field.name}.kind`}
              label={t('check_death', { ns: 'artist' })}
              value={kind}
              checked={kind === period}
              onChange={() =>
                form.setFieldValue(`${field.name}.kind`, kind === period ? single : period)
              }
            />
          )}
        </Col>
        <Col lg={6} className="mb-3">
          <Form.Group
            hasValidation={!!_.get(errors, `${field.name}.started_at`)}
            className="position-relative"
          >
            <Label>{t('label_birth', { ns: 'artist' })}</Label>
            <Form.Control
              className={`form-control-lg mb-2 w-100 ${
                _.get(errors, `${field.name}.started_at`) ? 'is-invalid' : ''
              }`}
              name={`${field.name}.started_at`}
              isInvalid={!!_.get(errors, `${field.name}.started_at`)}
              placeholder={t('placeholder_birth', { ns: 'artist' })}
              value={_.get(form, `values.${field.name}.started_at`, '')}
              onChange={(e) => form.setFieldValue(`${field.name}.started_at`, e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {_.get(errors, `${field.name}.started_at`, '')}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xl={6} className="mb-3">
          {kind === period && (
            <Form.Group
              hasValidation={!!_.get(errors, `${field.name}.ended_at`)}
              className="position-relative"
            >
              <Label>{t('label_death', { ns: 'artist' })}</Label>
              <Form.Control
                className={`form-control-lg mb-2 w-100 ${
                  _.get(errors, `${field.name}.ended_at`) ? 'is-invalid' : ''
                }`}
                name={`${field.name}.ended_at`}
                placeholder={t('placeholder_death', { ns: 'artist' })}
                isInvalid={!!_.get(errors, `${field.name}.ended_at`)}
                value={_.get(form, `values.${field.name}.ended_at`, '')}
                onChange={(e) => form.setFieldValue(`${field.name}.ended_at`, e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {_.get(errors, `${field.name}.ended_at`, '')}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </Col>
        <Col lg={6}>
          <Label>{t('label_birth_in', { ns: 'artist' })}</Label>
          <Form.Control
            className="form-control-lg mb-2"
            name={`${field.name}.location_birth`}
            placeholder={t('placeholder_birth_in', { ns: 'artist' })}
            value={_.get(form, `values.${field.name}.location_birth`)}
            onChange={(e) => form.setFieldValue(`${field.name}.location_birth`, e.target.value)}
          />
        </Col>
        <Col xl={6}>
          {kind !== period && (
            <>
              <Label>{t('label_live_in', { ns: 'artist' })}</Label>
              <Form.Control
                className="form-control-lg mb-2"
                name={`${field.name}.location_live`}
                placeholder={t('placeholder_live_in', { ns: 'artist' })}
                value={_.get(form, `values.${field.name}.location_live`)}
                onChange={(e) => form.setFieldValue(`${field.name}.location_live`, e.target.value)}
              />
            </>
          )}
          {kind === period && (
            <>
              <Label>{t('label_death_in', { ns: 'artist' })}</Label>
              <Form.Control
                className="form-control-lg mb-2"
                name={`${field.name}.location_death`}
                placeholder={t('placeholder_death_in', { ns: 'artist' })}
                value={_.get(form, `values.${field.name}.location_death`)}
                onChange={(e) => form.setFieldValue(`${field.name}.location_death`, e.target.value)}
              />
            </>
          )}
        </Col>
      </Row>
    </DatesContainer>
  );
};

const Label = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: 60px;
  &:first-child {
    margin-top: 0;
  }
  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
  @media screen and (max-width: 812px) {
    font-size: 16px;
    margin-top: 40px;
  }
`;

const DatesContainer = styled.div`
  margin-top: 4rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: ${(props) => (!props.position ? 'column' : 'column-reverse')};
  .switch-group {
    ${(props) => (!props.position ? 'margin-top: 0;' : 'margin-top: -15px; margin-bottom: 10px;')};
  }
  @media screen and (min-width: 1024px) {
    margin-top: 3rem;
  }
  @media screen and (max-width: 812px) {
    .w-75 {
      width: 100% !important;
    }
  }
`;

ArtArtistDates.defaultProps = {
  period: 'period',
  single: 'single',
  column: false,
  label: false,
  noDie: false,
};

ArtArtistDates.propTypes = {
  label: PropTypes.any,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  period: PropTypes.string,
  single: PropTypes.string,
  column: PropTypes.bool,
  noDie: PropTypes.bool,
};

export default ArtArtistDates;
