import { gql } from '@apollo/client';

const fragment = gql`
  fragment ArtistFragment on Artist {
    _id
    url
    name
    status
    likes
    myLike
    description
    owner_id
    exhibitions_id
    myArtworksCount
    totalArtworksCount
    owner {
      _id
      name
      username
      avatar {
        _id
        key
        fileName
        format
      }
    }
    exhibitions {
       _id
       name
    }
    favorite {
      _id
    }
    avatar {
      _id
      key
      fileName
    }
    artworks {
      _id
      name
      url
      age
      ex_name
      ex_artist
      technique
      circulation
      dates
      images {
        _id
        key
        fileName
      }
      favorite {
        _id
      }
      subcategories {
       _id
       name
      }
      weight {
        value
        dimension
      }
      sizes {
        type
        dimension
        depth
        height
        width
        free
      }
    }
    exhibitions {
       _id
       name
       url
       dates
       days
       worktime
       images {
         _id
         key
         fileName
         format
       }
    }
    dates {
      variant
      kind
      started_at
      ended_at
      location_birth
      location_live
      location_death
    }
    educations {
      name
      variant
      century
      started_at
      ended_at
      free
    }
    awards {
      name
      description
      date
    }
  }
`;

const fragmentAll = gql`
fragment ArtistsAllFragment on ArtistsAll {
   total
   meta {
      title
      description
      created_at
      updated_at
      page_name
      page_description
   }
   items {
      _id
      url
      name
      status
      favorite {
        _id
      }
      owner_id
      myArtworksCount
      totalArtworksCount
      avatar {
        _id
        key
        fileName
      }
      dates {
        variant
        kind
        started_at
        ended_at
        location_birth
        location_live
        location_death
      }
      artworks {
        _id
        name
        url
        ex_name
        ex_artist
        technique
        circulation
        dates
        images {
          _id
          key
          fileName
        }
        favorite {
          _id
        }
        subcategories {
         _id
         name
        }
        weight {
          value
          dimension
        }
        sizes {
          type
          dimension
          depth
          height
          width
          free
        }
      }
   }
}`;

export const GQL_UPDATE_ARTIST = gql`
  mutation UpdateArtist($data: ArtistInput) {
    updateArtist(data: $data) {
      ...ArtistFragment
    }
  }
  ${fragment}
`;

export const GQL_PUBLISH_ARTIST = gql`
  mutation PublishArtist($_id: String!, $status: Int) {
    publishArtist(_id: $_id, status: $status)
  }
`;

export const GQL_REMOVE_ARTIST = gql`
  mutation RemoveArtist($_id: String!, $artwork_id: String) {
    removeArtist(_id: $_id, artwork_id: $artwork_id)
  }
`;

export const GQL_GET_ARTISTS_ALL = gql`
  query GetArtistsAll($_id: String, $all: Boolean, $my: Boolean, $query: QueryItems) {
    artistsAll(_id: $_id, all: $all, my: $my, query: $query) {
      ...ArtistsAllFragment
    }
  }
  ${fragmentAll}
`;

export const GQL_GET_ARTIST = gql`
  query GetArtist($_id: ID, $url: String, $edit: Boolean, $artist: Boolean, $redirect: Boolean) {
    artist(_id: $_id, url: $url, edit: $edit, artist: $artist, redirect: $redirect) {
      ...ArtistFragment
    }
  }
  ${fragment}
`;

export const GQL_GET_SELLING_ARTISTS = gql`
query GetSellingArtists {
    sellingArtists {
       _id
       url
       name
       status
       avatar {
         _id
         key
         fileName
       }
       dates {
         variant
         kind
         started_at
         ended_at
       }
       totalArtworksCount
    }
}
`;
