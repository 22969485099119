import React from 'react';

const fb = ({ colorFill }) => ({
  viewBox: '0 0 10 19',
  data: (
    <path
      fill={colorFill}
      d={`M3.092,10.211v7.875h3.43v-7.875H9.08l0.487-3.258H6.522V4.839c0-0.891,0.425-1.76,1.789-1.76h1.384V0.306
            c0,0-1.256-0.22-2.457-0.22c-2.507,0-4.146,1.56-4.146,4.385v2.482H0.305v3.258H3.092z`}
    />
  ),
});

export default fb;
