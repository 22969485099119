import React from 'react';

const heart = ({ colorFillIn, colorFill }) => ({
  viewBox: '0 0 21 20',
  data: (
    <g>
      <path
        fill={colorFill}
        d={`M7.97,17.636l0.001,0.002c0.648,0.569,1.381,1.213,2.142,1.897c0.244,0.221,0.559,0.344,0.887,0.344
        c0.327,0,0.643-0.123,0.887-0.343c0.762-0.687,1.496-1.332,2.146-1.901l0.014-0.013c1.889-1.658,3.541-3.11,4.695-4.545
        c1.301-1.618,1.922-3.172,1.922-4.881c0-1.648-0.549-3.179-1.556-4.305c-1.021-1.141-2.424-1.771-3.948-1.771
        c-1.143,0-2.191,0.374-3.109,1.105C11.668,3.531,11.317,3.892,11,4.302c-0.317-0.411-0.668-0.771-1.051-1.076
        c-0.918-0.731-1.966-1.105-3.11-1.105c-1.524,0-2.927,0.63-3.948,1.771C1.884,5.018,1.336,6.548,1.336,8.196
        c0,1.708,0.621,3.263,1.922,4.882c1.155,1.435,2.809,2.887,4.698,4.545l0.011,0.01l0.001,0.002L7.97,17.636z M11.803,5.949
        c0.24-0.413,0.645-0.996,1.218-1.453c0.64-0.51,1.357-0.765,2.14-0.765c1.078,0,2.064,0.442,2.785,1.246
        c0.732,0.819,1.143,1.959,1.143,3.219c0,1.314-0.47,2.5-1.563,3.859c-1.063,1.323-2.65,2.717-4.515,4.354l-0.005,0.005
        c-0.606,0.533-1.29,1.133-2.006,1.773c-0.712-0.64-1.394-1.238-2-1.771l-0.002-0.001l-0.002-0.003
        c-1.865-1.638-3.454-3.034-4.519-4.357c-1.094-1.36-1.564-2.545-1.564-3.859c0-1.26,0.411-2.4,1.144-3.219
        c0.72-0.804,1.707-1.246,2.784-1.246c0.783,0,1.5,0.255,2.14,0.765c0.574,0.457,0.979,1.04,1.218,1.453
        C10.365,6.239,10.666,6.417,11,6.417S11.635,6.239,11.803,5.949z`}
      />
      <path
        id="fillIn"
        fill={colorFillIn}
        d={`M7.97,17.636l-0.002-0.001l-0.001-0.002l-0.011-0.01l0,0c-1.889-1.658-3.543-3.11-4.698-4.545
        c-1.301-1.619-1.922-3.173-1.922-4.882c0-1.648,0.548-3.179,1.556-4.305c1.021-1.141,2.424-1.771,3.948-1.771
        c1.144,0,2.192,0.374,3.11,1.105C10.332,3.531,10.684,3.892,11,4.302c0.316-0.411,0.668-0.771,1.051-1.076
        c0.918-0.731,1.967-1.105,3.109-1.105c1.524,0,2.928,0.63,3.948,1.771c1.007,1.126,1.556,2.656,1.556,4.305
        c0,1.708-0.621,3.263-1.922,4.881c-1.154,1.435-2.807,2.887-4.695,4.545l-0.014,0.013c-0.65,0.569-1.385,1.215-2.146,1.901
        c-0.244,0.22-0.56,0.343-0.887,0.343c-0.328,0-0.643-0.123-0.887-0.344c-0.761-0.685-1.494-1.328-2.142-1.897L7.97,17.636z`}
      />
    </g>
  ),
});

export default heart;
