import jwt from 'jsonwebtoken';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import Cookies from 'universal-cookie';

import Navigator from './Navigator';
import AlertProvider from './AlertProvider';
import Tracker from './components/Tracker';

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/fonts.css';
import './css/main.css';

const App = ({ client }) => {
  useEffect(() => {
    const jwtCheck = setInterval(() => {
      const cookies = new Cookies();
      const token = cookies.get('token');
      if (!_.isEmpty(token)) {
        const decoded = jwt.decode(token);
        if (decoded && moment().isBefore(moment.unix(+decoded.exp))) {
          return false;
        }
      }
      return false;
    }, 10000);
    return () => {
      clearInterval(jwtCheck);
    };
  }, []);

  return (
    <ApolloProvider client={client}>
      <Tracker />
      <AlertProvider>
        <Navigator client={client} />
      </AlertProvider>
    </ApolloProvider>
  );
};

App.propTypes = {
  client: PropTypes.object.isRequired,
};

export default App;
