import React from 'react';

const location = ({ colorStroke }) => ({
  viewBox: '0 0 14 20',
  data: (
    <g>
      <path
        d={`M13.25 7C13.25 9.17286 12.2064 11.6686 10.873 13.9453C9.55238
        16.2001 8.00929 18.1377 7.13468 19.1703C7.09189 19.2208 7.04312
        19.2377 7 19.2377C6.95688 19.2377 6.90811 19.2208 6.86533
        19.1703C5.99071 18.1377 4.44762 16.2001 3.12705 13.9453C1.79356
        11.6686 0.75 9.17286 0.75 7C0.75 3.54822 3.54822 0.75 7
        0.75C10.4518 0.75 13.25 3.54822 13.25 7Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.5"
      />
      <circle
        fill="transparent"
        cx="7.0009"
        cy="7.0009"
        r="2.69231"
        stroke={colorStroke}
        strokeWidth="1.5"
      />
    </g>
  ),
});

export default location;
