export default {
  artist: 'Художник',
  collector: 'Коллекционер',
  art_dealer: 'Арт-дилер',
  gallery: 'Галерея',
  month: 'в месяц',
  year: 'за год',
  month_short: 'Месяц',
  year_short: 'Год',
  pay_installments: 'Оплатить в рассрочку',
  success_payed: 'Спасибо! Платёж успешно принят, ваша подписка активна',
  success_payed_education: 'Спасибо! Платёж принят. Курс доступен в личном кабинете.',
  pending_payed: 'Ваш платёж в обработке. Мы сообщим как деньги поступят на счёт и функции будут активны',
  wrong_payed: 'Ваш платеж не прошел, попробуйте еще раз',
  error_payment: 'При оплате возникла ошибка, если были списаны деньги обратитесь к администрации',
  tariff: 'Тариф',
  has_expired: 'Внимание! Ваш тариф заканчивается через {{ count }} дней. Если не продлить, то работы перестанут отображаться в маркете. Чтобы исправить это, продлите выбранный тариф или выберите друугой после окончания текущего',
  go_another: 'перейдите на новый',
  description: 'Оплата тарифа',
  no_close: 'Не закрывайте страницу, мы проверяем платёж',
  your_plan: 'Ваш {{ type }} тарифный план',
  paid_to: 'Оплачено до',
  attention: 'Обратите внимание!',
  extend: 'Продлить',
  is_paid: 'Вы не можете оплатить тариф для типа «{{ current }}», так как у вас уже есть купленный тариф «{{ tariff }}». В случае, если вы ошиблись в выборе или хотите сменить вариант размещения, обратитесь к администратору',
  info_btn: 'Индивидуальный тариф',
  proposal_name: 'Не нашли подходящий тариф или остались вопросы?',
  proposal_desc: 'Артократия всегда на связи! Мы поможем подобрать тариф индивидуально под ваши потребности и ответим на все вопросы. Для связи с менеджером нажмите на кнопку снизу и мы вам напишем.',
  dialog_btn: 'Подобрать тариф',
  admin_message: 'Здравствуйте! Я хочу уточнить информацию по тарифу «{{ name }}»',
  promotion_time: 'Время акции с {{ from }} до {{ to }}',
  payment_month: 'Продлите тариф на месяц за {{ price }} {{ currency }}',
  payment_year: 'Продлите тариф на год за {{ price }} {{ currency }} в месяц. Общая стоимость составит {{ price_year }} {{ currency }}',
  payment_month_discount: 'Акция! Продлите тариф на месяц со скидкой {{ discount }}% за {{ price }} {{ currency }}. Предложение действительно c {{ from }} по {{ to }}',
  payment_year_discount: 'Акция! Продлите тариф на год со скидкой {{ discount }}% за {{ price_year }} {{ currency }} с ежемесячным платежом {{ price }} {{ currency }}. Предложение действительно c {{ from }} по {{ to }}',
  monthly: 'месячный',
  yearly: 'годовой',
  name: 'Тариф',
  name_for: ' Для',
  period: 'Период',
  price: 'Стоимость',
  price_discount: 'Стоимость со скидкой',
  know_rules: 'Оплачивая покупку вы соглашаетесь с',
  payment_rules: 'правилами оплаты',
  and_rules: 'ознакомлены с',
  offer_rules: 'офертой',
  and_more: 'и',
  policy_rules: 'политикой конфиденциальности',
  payment: 'Оплата заказа',
  pay_by_stripe: 'Оплатить иностранной картой',
  pay: 'Оплатить полностью',
  want_pay_rubles: 'Оплатить рублями',
  go_art: 'Перейти к созданию работ',
  go_education: 'Перейти к изучеию',
  promo_code: 'Стоимость с учётом промокода',
  change_role: 'Изменить тип учётной записи',
  learn: 'Изучить тарифы',
  bye: 'Купить',
  know_more: 'Изучить подробнее',
  select_tariff: 'Выберите подходящий тариф',
  register: 'Зарегистрироваться',
  auth_google: 'Войти с помощью Google',
  free: 'Бесплатно',
  ask_manager: 'Задать вопрос менеджеру',
  learn_price: 'Изучить цены тарифа {{ name }}',
  pay_tariff: 'Приобрести тариф {{ name }}',
  select_payment: 'Выбрать вариант оплаты',
  total_amount: 'Тариф - {{ name }}. Полная стоимость за {{ for }} составляет',
  back: 'Назад к выбору',
  current_tariff: 'Ваш текущий тариф',
  no_params: 'Нет обязательных параметров',
  installment_time: 'Выберите срок рассрочки',
  select_method: 'Выберите чем оплачивать',
  select_mode: 'Выберите режим оплаты',
};
