import { gql } from '@apollo/client';

const fragment = gql`  
  fragment PostFragment on Post {
    _id
    text
    modelId
    attachModel
    attachId
    likes
    status
    created_at
    updated_at
    files {
      _id
      key
      fileName
      format
      mimeType
    }
    owner {
      _id
      name
      role
      username
      avatar {
        _id
        key
        fileName
      }
    }
    user {
      _id
      name
      username
      role
      avatar {
        _id
        key
        fileName
      }
    }
    album {
      _id
      name
      url
      avatar {
        _id
        key
        fileName
      }
    }
    news {
      _id
      name
      url
      image {
        _id
        type
        ownerId
        rank
        description
        name
      }
    }
    artwork {
      _id
      name
      url
      dates
      subcategories {
        _id
        name
        status
        user_id
      }
      sizes {
        type
        dimension
        depth
        height
        width
      }
      artist {
        name
        url
        _id
      }
      images {
        _id
        key
        fileName
        format
        width
        height
      }
    }
    artist {
      _id
      name
      url
      avatar {
        _id
        key
        fileName
      }
    }
    exhibition {
      _id
      name
      dates
      days
      url
      worktime
      images {
        _id
        key
        fileName
        format
        width
        height
      }
    }
    institution {
      _id
      name
      url
    }
  }
`;

export const GQL_GET_POSTS_ALL = gql`
  query GetPostsAll($query: QueryItems, $_id: ID) {
    postsAll(query: $query, _id: $_id) {
      total
      items {
        ...PostFragment
      }
    }
  }
  ${fragment}
`;

export const GQL_GET_POST = gql`
  query GetPost($_id: ID!) {
    post(_id: $_id) {
      ...PostFragment
    }
  }
  ${fragment}
`;

export const GQL_UPDATE_POST = gql`
  mutation UpdatePost($data: PostInput) {
    updatePost(data: $data) {
     ...PostFragment
    }
  }
  ${fragment}
`;

export const GQL_LIKE_PROCESS_POST = gql`
  mutation UpdatePostLike($_id: String!) {
    updatePostLike(_id: $_id)
  }
`;

export const GQL_REMOVE_POST = gql`
  mutation RemovePost($_id: String!) {
    removePost(_id: $_id)
  }
`;

export const GQL_POST_NEW = gql`
  subscription PostNew($receiver_id: ID!) {
    postNew(receiver_id: $receiver_id) {
      _id
    }
  }
`;
