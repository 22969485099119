export default {
  no_items: 'Вы не проходите ни одного курса.',
  want_started: 'Хотите начать?',
  education: 'курса',
  education_2: 'курсов',
  educations: 'курсов',
  in_progress: 'В процессе прохождения',
  was_passed: 'Законченные',
  at_future: 'Еще не начавшиеся',
  from: 'от',
  new: 'NEW',
  done: 'Выполнено',
};
