import React from 'react';

const tg = ({ colorFill }) => ({
  viewBox: '0 0 23 16',
  data: (
    <path
      fill={colorFill}
      d={`M17.949,4.133l-2.704,12.751c-0.204,0.9-0.737,1.125-1.492,0.701L9.634,14.55l-1.989,1.911
            c-0.22,0.22-0.404,0.405-0.828,0.405l0.296-4.196l7.635-6.9c0.332-0.296-0.071-0.461-0.515-0.165l-9.44,
            5.944l-4.064-1.272 c-0.884-0.276-0.9-0.884,0.184-1.308l15.896-6.125C17.546,2.569,18.189,3.01,17.949,4.133z`}
    />
  ),
});

export default tg;
