import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Col,
  Form,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';

import { DAYS } from '../../helpers/exhibitions';

const ArtDays = ({
  field,
  form: { errors, setFieldValue },
  label,
  groupClassName,
  column,
}) => {
  const [value, setValue] = useState([]);
  const handleChange = (val) => {
    setValue(val);
    setFieldValue(field.name, val);
  };

  return (
    <Form.Group
      as={Row}
      controlId={field.name}
      className={`d-flex ${column ? 'flex-column' : 'flex-row'} ${groupClassName}`}
    >
      {!!label && (
        <Form.Label
          column
          md={column ? 12 : 3}
        >
          {label}
        </Form.Label>
      )}

      <Col md={label && !column ? 9 : 12}>
        <ToggleButtonGroup
          className="mt-4"
          type="checkbox"
          value={_.isEmpty(value) ? field.value : value}
          onChange={handleChange}
        >
          {DAYS.map((day, i) => (
            <ToggleButton
              key={day}
              id={`tbg-btn-${i}`}
              variant="outline-primary"
              size="check"
              value={i}
            >
              {day}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <Form.Control.Feedback type="invalid">
          {errors[field.name]}
        </Form.Control.Feedback>
      </Col>

    </Form.Group>
  );
};

ArtDays.defaultProps = {
  label: null,
  groupClassName: '',
  column: false,
};

ArtDays.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    setFieldValue: PropTypes.func,
  }).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  groupClassName: PropTypes.string,
  column: PropTypes.bool,
};

export default ArtDays;
