/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';

class ListWidget extends PureComponent {
  render() {
    const height = 60;
    const {
      options,
      children,
      maxHeight,
      getValue,
    } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;
    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => (
          <div style={style}>
            {children[index]}
          </div>
        )}
      </List>
    );
  }
}

ListWidget.propTypes = {
  options: PropTypes.array.isRequired,
  children: PropTypes.array.isRequired,
  maxHeight: PropTypes.number.isRequired,
  getValue: PropTypes.func.isRequired,
};

export default ListWidget;
