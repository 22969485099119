import React from 'react';

const educationHead = ({ colorStroke }) => ({
  viewBox: '0 0 19 14',
  data: (
    <g>
      <path
        d={`M17.5 4.59941L9.5 1.39941L1.5 
        4.59941L9.5 7.79941L17.5 4.59941ZM17.5 
        4.59941V9.39941`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={`M4.67969 5.87988V10.1999C4.67969 
        10.8364 5.1854 11.4469 6.08558 11.8969C6.98575 
        12.347 8.20665 12.5999 9.47969 12.5999C10.7527 
        12.5999 11.9736 12.347 12.8738 11.8969C13.774 
        11.4469 14.2797 10.8364 14.2797 10.1999V5.87988`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default educationHead;
