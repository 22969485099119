export default {
  conversation: 'Обсуждение',
  push_reply: 'Ответил на Ваш комментарий',
  push_wright: 'Оставил новый комментарий',
  remove: 'Вы уверены, что хотите удалить Ваш комментарий?',
  recovery_impossible: 'Восстановление невозможно',
  send_abuse: 'Вы уверены, что хотите подать жалобу на комментарий?',
  recive_abuse: 'Жалоба на комментарий получена!',
  updated: 'Ваш комментарий обновлен',
  published: 'Ваш комментарий опубликован',
  empty: 'Нет комментариев',
  let_comment: 'Прокомментируйте публикацию',
  info_alert: 'Чтобы прокомментировать войдите или зарегистрируйтесь',
  text_required: '{{ name }}, для публикации комментария заполните текст',
  user: 'Пользователь',
  placeholder: '{{ user }}, прокомментируйте публикацию',
  abuse: 'Подать жалобу',
  you_reply: 'Вы отвечаете {{ who }}',
  me: 'себе',
  to_user: 'пользователю {{ name }}',
  answer: 'отвечает пользователю {{ name }}',
  answer_self: 'отвечает себе',
};
