import React from 'react';

const download = ({ colorStroke, colorFill }) => ({
  viewBox: '0 0 20 20',
  data: (
    <g>
      <path
        d={`M1 10V17.2C1 17.6774 1.22509 18.1352 1.62574 18.4728C2.0264 18.8104 2.5698 19 3.13641
              19H15.9549C16.5215 19 17.0649 18.8104 17.4656 18.4728C17.8662 18.1352 18.0913 17.6774 18.0913 17.2V10`}
        stroke={colorStroke}
        strokeWidth="2"
        fill={colorFill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8191 9.1002L9.54626 12.7002L5.27344 9.1002"
        stroke={colorStroke}
        fill={colorFill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.54492 12.7002V1.0002"
        stroke={colorStroke}
        fill={colorFill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default download;
