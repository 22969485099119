import React from 'react';

const user = ({ colorStroke }) => ({
  viewBox: '0 0 16 16',
  data: (
    <g>
      <path
        d={`M13.3327 14V12.6667C13.3327 11.9594 13.0517 
        11.2811 12.5516 10.781C12.0515 10.281 11.3733 10 10.666 
        10H5.33268C4.62544 10 3.94716 10.281 3.44706 10.781C2.94697 
        11.2811 2.66602 11.9594 2.66602 12.6667V14`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={`M8.00065 7.33333C9.47341 7.33333 10.6673 
        6.13943 10.6673 4.66667C10.6673 3.19391 9.47341 2 8.00065 
        2C6.52789 2 5.33398 3.19391 5.33398 4.66667C5.33398 6.13943 
        6.52789 7.33333 8.00065 7.33333Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default user;
