import { gql } from '@apollo/client';

export const GQL_GET_COMPILATIONS = gql`
  query GetCompilations($main: Boolean, $query: QueryItems, $section: Int) {
    compilations(main: $main, query: $query, section: $section) {
      total
      meta {
        title
        description
        created_at
        updated_at
        page_name
        page_description
      }
      items {
        _id
        name
        status
        section
        description
        image
        artworks {
          created_at
        }
        favorite {
          _id
        }
        likes
        myLike
        url
      }
    }
  }
`;

export const GQL_GET_COMPILATION = gql`
  query GetCompilation($_id: ID, $url: String) {
    compilation(_id: $_id, url: $url) {
      _id
      name
      section
      description
      artworks_id
      bgImage
      mobileImage
      url
      likes
      myLike
      status
      favorite {
        _id
      }
    }
  }
`;
