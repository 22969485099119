export default {
  label: 'Коллекции',
  name: 'Коллекционер {{ name }}',
  about: 'О коллекции',
  start: 'Начало коллекционирования',
  about_owner: 'Об авторе коллекции',
  send_message: 'Написать сообщение',
  title: '{{ name }} - Частная коллекция',
  description: 'Владелец частной коллекции произведений искусств - {{ name }}',
  title_ac: 'Художник {{ name }}',
  description_ac: '{{ name }} - информация, работы и коллекция художника',
  in_collection: 'Работы в коллекции ({{ total }})',
  in_sell: 'Работы на продажу ({{ total }})',
  in_all: 'Все работы ({{ total }})',
  search_placeholder: 'Введите название или автора',
  share_label: 'Поделиться коллекцией',
  share_description: 'Посмотрите коллекцию {{ name }}',
};
