import React from 'react';

const tw = ({ colorFill }) => ({
  viewBox: '0 0 24 19',
  data: (
    <g>
      <path
        fill={colorFill}
        d={`M23.033,0.096c-0.96,0.677-2.023,1.196-3.149,1.535c-0.604-0.695-1.407-1.187-2.3-1.411
              c-0.893-0.223-1.834-0.167-2.693,0.161c-0.86,0.328-1.599,0.913-2.115,1.674C12.258,2.817,11.987,3.72,12,4.64v1.003
              c-1.763,0.045-3.51-0.346-5.085-1.138c-1.575-0.793-2.93-1.963-3.943-3.406c0,0-4.013,9.028,5.016,13.042
              c-2.066,1.402-4.527,2.105-7.022,2.006c9.028,5.016,20.062,0,20.062-11.536c0-0.28-0.027-0.559-0.08-0.833
              C21.971,2.768,22.693,1.493,23.033,0.096z`}
      />
    </g>
  ),
});

export default tw;
