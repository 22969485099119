export default {
  name: 'Действие',
  edit: 'Редактировать',
  delete: 'Удалить',
  send: 'Отправить',
  save: 'Сохранить',
  save_draft: 'Сохранить для меня',
  await: 'Подождите...',
  reset: 'Отмена',
  send_moderation: 'Отправить на модерацию',
  remove_favorites: 'Удалить из избранного',
  save_changes: 'Сохранить изменения',
  change: 'Изменить',
  hide: 'Скрыть',
  remove_email: 'Удалить email',
  publish: 'Опубликовать',
  liked: 'Понравилось',
  reply: 'Ответить',
  publish_variant: 'Варианты публикации',
  unpublish: 'Снять с публикации',
  unpublished: 'Не опубликовано',
  push_unpublish: 'Нажмите чтобы снять с публикации',
  moderation: 'Модерация',
  moderation_info: 'После модерации станет доступно редактирование и снятие с публикации',
  push_publish: 'Нажмите чтобы опубликовать',
  cant_publish: 'Вы не можете отправить на публикацию, так как {{ why }}',
  share: 'Поделиться',
  add: 'Добавить',
  select: 'Выбрать',
  set: 'Задать',
  clear: 'Очистить',
  clear_form: 'Очистить форму',
  no_variants: 'Нет вариантов',
  alert_action: 'Материал {{ action }}',
  alert_removed: 'снят с публикации',
  alert_sent: 'отправлен на модерацию, ожидайте одобрения!',
  published: 'опубликован',
  error_published: 'Ошибка публикации, попробуйте обновить страницу',
  sure: 'Вы уверены, что хотите {{ action }} {{ type }} «{{ name }}»',
  sure_no_name: 'Вы уверены, что хотите {{ action }} {{ type }}',
  view: 'Просмотр',
  published_on: 'Опубликовано',
  publish_all: 'Для всех',
  publish_friends: 'Для друзей',
  publish_market: 'В маркете по тарифу',
  publish_collection: 'В музее',
  select_artworks: 'Добавить работы',
  publish_link: 'Доступ по ссылке',
  access_publish_link: 'предоставить доступ по ссылке для',
  info_publish_link: 'Доступ будет предоставлен по ссылке, но при смене варианта публикации ссылка будет удалена',
  published_by_link: 'доступен по ссылке',
  choice: 'Выберите, что сделать с «{{ name }}»',
  for_choice: 'Для кого вы хотите опубликовать работу «{{ name }}»?',
  where_choice: 'Где вы хотите опубликовать работу «{{ name }}»?',
  pay_publish: 'Выставить в маркет одну работу',
  pay_update: 'Продлить размещение',
  left: 'Окончание {{ left }}',
  one_pay: 'Вы собираетесь опубликовать одну работу «{{ name }}» в маркете',
  one_pay_desc: 'Стоимость размещения на один месяц составляет {{ count }} рублей',
  one_pay_info: 'Нажимая кнопку «опубликовать», вы соглашаетесь с',
  one_pay_offer: 'условиями оферты',
  next_pay: 'Вы собираетесь продлить размещение работы «{{ name }}» в маркете',
  next_pay_desc: 'Стоимость продления на один месяц составляет {{ count }} рублей',
  next_publish: 'Продлить',
  success_payed: 'Оплата прошла успешно!',
  error_payment: 'Ошибка при формировании оплаты, если такое повторяется, обратитсь к администрации',
  one_publish_market: 'В маркете за {{ count }} рублей',
  need_to_pay: 'Требуется оплата',
  contact_moderator: 'Связаться с менеджером',
  wait_pay: 'Ожидает оплаты в {{ count }} {{ currency }}',
};
