import React from 'react';

const feather = ({ colorStroke }) => ({
  viewBox: '0 0 42 43',
  data: (
    <g>
      <path
        d={`M37.48 22.4799C39.7317 20.2283 40.9967 17.1743 40.9967 
        13.9899C40.9967 10.8056 39.7317 7.75164 37.48 5.49995C35.2283 
        3.24826 32.1744 1.98328 28.99 1.98328C25.8056 1.98328 22.7517 
        3.24826 20.5 5.49995L7 19V36H24L37.48 22.4799Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 14L1 42"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 28H15"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default feather;
