import React from 'react';

const remove = ({ colorStroke }) => ({
  viewBox: '0 0 13 13',
  data: (
    <path d="M1 1L12 12M12 1L1 12" stroke={colorStroke} strokeWidth="1.5" strokeLinecap="round" />
  ),
});

export default remove;
