export default {
  update: 'Обновить',
  send_request: 'Запросить',
  offered_works: 'Вы предложили работы на выставку',
  offer_works: 'Предложить работы на выставку',
  select_artworks: 'Выберите произведения',
  placeholder_message: 'Введите сообщение куратору',
  remove: 'Удалить предложение',
  offered_exhibition: 'Вы запросили работу на выставку',
  offer_exhibition: 'Запросить работу на выставку',
  select_exhibitions: 'Выберите мероприятия',
  message_collector: 'Сообщение коллекционеру',
  delete: 'Заявка успешно удалена',
  delete_one: 'Произведение успешно удалено из списка',
  confirm_curator: 'Заявка подтверждена, ожидайте обратной связи от коллекционера',
  remove_from: 'Убрать из заявки',
  remove_info: 'Чтобы удалить, нажмите (отклонить все)',
  delete_all: 'Отклонить все',
  confirm_all: 'Подтвердить имеющиеся',
  artworks_info: 'Вы подтвердили заявку, ожидайте обратной связи от коллекционера. В случае, если Вы нажмёте удалить, коллекционер все равно сможет связаться с Вами, так как он уведомлен об этом. Не рекомендуем удалять заявку до переговоров. Восстановить предложение невозможно.',
  delete_exhibition: 'Мероприятие успешно удалено из списка',
  confirm_collector: 'Заявка подтверждена, ожидайте обратной связи от куратора',
};
