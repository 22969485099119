export default {
  in_your_collection: 'У вас в коллекции {{ count }} {{ declination }} этого художника',
  birth_date: 'Дата рождения',
  birth_death: 'Дата смерти',
  place_residence: 'Место жительства',
  education: 'Образование',
  award: 'Награды',
  award_date: 'Дата',
  award_description: 'Описание',
  no_artworks: 'У художника пока нет общедоступных произведений искусства',
  no_request_result: 'По запросу «{{ request }}» ничего не найдено',
  no_filter_result: 'По выбранной фильтрации ничего не найдено',
  no_request_filter_result: 'По выбранной фильтрации и запросу «{{ request }}» ничего не найдено',
  works: 'Работы художника ({{ total }})',
  placeholder_search: 'Введите название или автора',
  share_label: 'Поделиться',
  share_description: 'Художник {{ name }} и его работы в коллекциях',
  meta_title: 'Художник {{ name }} | Артократия',
  meta_description: 'Художник {{ name }} и его аторские работы в коллекциях и на продажу в социальной сети для ценнителей искусства Артократия',
  info: 'Информацию об авторе можно будет дополнить, предложив изменения в карточке автора в разделе кабинета «Художники»',
  alert_success: 'Спасибо, за информацию, после модерации художник станет доступен всем',
  add: 'Добавить художника',
  label_name: 'Укажите ФИО художника',
  help_name: 'Желательно записывать художника в следующем порядке: Имя Фамилия Отчество',
  placeholder_name: 'Введите имя художника',
  label_live: 'Данные о жизни художника',
  check_death: 'Указать дату смерти',
  label_birth: 'Дата рождения',
  placeholder_birth: 'Введите дату',
  label_death: 'Дата смерти',
  placeholder_death: 'Введите дату',
  label_birth_in: 'Родился в',
  placeholder_birth_in: 'Укажите, где родился художник',
  label_live_in: 'Жил/живет в',
  placeholder_live_in: 'Укажите, место проживания художника',
  label_death_in: 'Умер в',
  placeholder_death_in: 'Укажите, место смерти художника',
  label_about: 'О художнике',
  placeholder_about: 'Введите описание',
  label_photo: 'Фото художника',
  help_photo: 'Фотография должна быть хорошего качества, не менее 512х512 px',
  upload: 'Загрузить изображение',
  upload_info: 'Воспользуйтесь кнопкой {:} или перетащите файл в эту область',
  info_edit: 'Здесь вы сможете создать или обновить информацию о себе как о художнике, к этой карточке будут прикреплены ваши работы',
  save_success: 'Успешно обновлён',
  need_select: 'Необходимо выбрать художника',
  send_connect: 'Заявка на управление успешно отправлена. Ожидайте ответа',
  info_new: 'Сначала вам необходимо создать или подключить художника в карточке которого будут ваши работы. Если вы уже есть на Артократии как художник, выберите его из списка и отправьте нам запрос или создайте новую карточку. Ваш профиль может отличаться от карточки художника',
  as_user: 'Карточка под управлением пользователя',
  can_bye: 'Работы можно приобрести',
  in_collections: 'Работы в коллекциях',
  all: 'Все работы',
  all_artist: 'Все художники',
};
