import React from 'react';

const zipper = ({ colorStroke }) => ({
  viewBox: '0 0 38 42',
  data: (
    <path
      d="M21 1L1 25H19L17 41L37 17H19L21 1Z"
      stroke={colorStroke}
      fill="transparent"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});

export default zipper;
