import React from 'react';

const points = ({ colorFill }) => ({
  viewBox: '0 0 28 6',
  data: (
    <g>
      <circle cx="3" cy="3" r="3" fill={colorFill} />
      <circle cx="14" cy="3" r="3" fill={colorFill} />
      <circle cx="25" cy="3" r="3" fill={colorFill} />
    </g>
  ),
});

export default points;
