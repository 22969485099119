import React from 'react';

const layouts = ({ colorStroke }) => ({
  viewBox: '0 0 17 16',
  data: (
    <g>
      <path
        d="M8.50065 1.3335L1.83398 4.66683L8.50065 8.00016L15.1673 4.66683L8.50065 1.3335Z"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.83398 11.3335L8.50065 14.6668L15.1673 11.3335"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.83398 8L8.50065 11.3333L15.1673 8"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default layouts;
