import React from 'react';

const market = ({ colorStroke }) => ({
  viewBox: '0 0 17 16',
  data: (
    <g>
      <path
        d={`M4.5 1.3335L2.5 4.00016V13.3335C2.5 13.6871 2.64048 14.0263 
        2.89052 14.2763C3.14057 14.5264 3.47971 14.6668 3.83333 
        14.6668H13.1667C13.5203 14.6668 13.8594 14.5264 14.1095 
        14.2763C14.3595 14.0263 14.5 13.6871 14.5 13.3335V4.00016L12.5 1.3335H4.5Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 4H14.5"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={`M11.1673 6.6665C11.1673 7.37375 10.8864 8.05202 10.3863 
        8.55212C9.88617 9.05222 9.20789 9.33317 8.50065 9.33317C7.79341 
        9.33317 7.11513 9.05222 6.61503 8.55212C6.11494 8.05202 
        5.83398 7.37375 5.83398 6.6665`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default market;
