import React from 'react';

const plus = ({ colorStroke }) => ({
  viewBox: '0 0 25 24',
  data: (
    <g>
      <path
        d={`M12.834 22C18.3568 22 22.834 17.5228 
        22.834 12C22.834 6.47715 18.3568 2 12.834 
        2C7.31114 2 2.83398 6.47715 2.83398 12C2.83398 
        17.5228 7.31114 22 12.834 22Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.834 8V16"
        stroke={colorStroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.83398 12H16.834"
        stroke={colorStroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default plus;
