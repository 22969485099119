import React from 'react';

const smile = ({ colorStroke }) => ({
  viewBox: '0 0 26 26',
  data: (
    <g>
      <path
        d={`M12.6667 24.3333C19.11 24.3333 24.3333 
        19.11 24.3333 12.6667C24.3333 6.22334 19.11 
        1 12.6667 1C6.22334 1 1 6.22334 1 12.6667C1 
        19.11 6.22334 24.3333 12.6667 24.3333Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 16C8 16 9.75 18.3333 12.6667 18.3333C15.5833 18.3333 17.3333 16 17.3333 16"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9H9.01167"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 9H16.0117"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default smile;
