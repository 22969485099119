import React from 'react';

const eye = ({ colorStroke }) => ({
  viewBox: '0 0 24 18',
  data: (
    <g>
      <path
        d="M22.5932 7.81371C23.1356 8.52229 23.1356 9.47886 22.5932 10.1863C20.8847 12.4137 16.7857
        17 12.0002 17C7.21461 17 3.11563 12.4137 1.40716 10.1863C1.14326 9.84701 1 9.42962 1 9C1
        8.57038 1.14326 8.15299 1.40716 7.81371C3.11563 5.58629 7.21461 1 12.0002 1C16.7857
        1 20.8847 5.58629 22.5932 7.81371V7.81371Z"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0011 12.4284C13.8971 12.4284 15.4341 10.8934 15.4341 8.99986C15.4341 7.10631 13.8971
        5.57129 12.0011 5.57129C10.1051 5.57129 8.56812 7.10631 8.56812 8.99986C8.56812
        10.8934 10.1051 12.4284 12.0011 12.4284Z"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default eye;
