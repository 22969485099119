import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  ACTIVE,
  INFO_ALERT,
  MAIN_GRAY,
  WARNING,
  WARNING_ALERT,
} from './colors';

export const AccountContent = styled.div`
  @media screen and (max-width: 812px) {
    padding: 0 15px;
  }
`;

export const Help = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #050436;
  opacity: 0.4;
  margin: -10px 0 20px;
`;

export const Plus = styled.div`
  height: 30px;
  width: 30px;
  position: relative;
  &:before,
  &:after {
    border-radius: 4px;
    border: 3px solid ${ACTIVE};
    background-color: ${ACTIVE};
    display: block;
    position: absolute;
    content: '';
    top: 12px;
    left: 4px;
    width: 23px;
    height: 4px;
  }
  &:after {
    transform: rotate(90deg);
  }
  @media screen and (max-width: 500px) {
    width: 10px;
    top: -8px;
    height: 10px;
    margin-right: 10px;
    &:before,
    &:after {
      background-color: white;
      border-radius: 5px;
      border: 0;
      width: 10px;
      height: 3px;
    }
  }
`;

export const AddBox = styled.button`
  background-color: ${MAIN_GRAY};
  padding: 35px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 24px;
  line-height: 110%;
  @media screen and (max-width: 500px) {
    padding: 10px;
    background-color: ${ACTIVE};
    color: white;
    font-weight: 300;
    font-size: 14px;
    line-height: 1;
  }
`;

export const AddItem = styled.button`
  background-color: ${MAIN_GRAY};
  min-height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Circle = styled.div`
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  min-width: 210px;
  min-height: 210px;
  max-width: 210px;
  max-height: 210px;
  font-weight: bold;
  font-size: 24px;
  line-height: 110%;
`;

export const RadioArea = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const RadioLabel = styled.label`
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  font-size: 20px;
  margin-bottom: 0;
  &:last-of-type {
    margin-left: 100px;
  }
  .check {
    margin-right: 20px;
  }
  @media screen and (max-width: 1170px) {
    font-size: 80%;
    &:last-of-type {
      margin-left: 80px;
    }
  }
  @media screen and (max-width: 768px) {
    font-size: 90%;
    &:last-of-type {
      margin-left: 70px;
    }
  }
  @media screen and (max-width: 500px) {
    font-size: 80%;
    margin-bottom: 10px;
    &:last-of-type {
      margin-left: 0;
    }
  }
`;

export const Status = styled.div`
  font-size: 10px;
  line-height: 120%;
  color: #9291b2;
`;

export const Color = styled.div`
  font-weight: 500;
  font-size: 11px;
  line-height: 100%;
  margin-top: 5px;
  max-width: 100px;
  color: ${({ color }) => color};
  @media screen and (max-width: 320px) {
    font-size: 10px;
    line-height: 100%;
  }
`;

export const StatusButton = styled.button`
  display: flex;
  flex-direction: column;
  padding: 0;
  text-align: left;
  @media screen and (max-width: 1170px) {
    font-size: 90%;
  }
  @media screen and (max-width: 768px) {
    font-size: 80%;
  }
  @media screen and (max-width: 320px) {
    white-space: normal;
    line-height: 1;
    text-align: left;
  }
`;

const h1Style = css`
  font-weight: bold;
  line-height: 120%;
  font-size: 40px;
  display: block;
  margin-bottom: ${({ bottom }) => (bottom ? 50 : 0)}px;
  color: black;
  @media screen and (max-width: 1024px) {
    font-size: 24px;
    padding: 10px 0 0;
  }
  @media screen and (max-width: 812px) {
    font-size: 24px;
    padding: 10px 15px 0;
    margin-bottom: 50px;
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    padding: 0;
    margin-bottom: 25px;
  }
`;

export const H1 = styled.h1`
  ${h1Style};
`;

export const H1Link = styled(Link)`
  ${h1Style};
`;

export const H3 = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-top: ${({ start }) => (start ? 0 : 24)}px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1024px) {
    font-size: 18px;
    line-height: 20px;
  }
  @media screen and (max-width: 812px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const HelpOut = styled.div`
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 20px;
  @media screen and (max-width: 812px) {
    font-size: 12px;
    padding: 0 20px;
    line-height: 130%;
  }
`;

export const ButtonControl = styled.div`
  width: 100%;
  background-color: white;
  padding: 60px 50px;
  @media screen and (max-width: 812px) {
    padding: 0 20px;
    button {
      width: 100%;
    }
  }
`;

export const DefaultLabel = styled.div`
  color: #b9bfce;
  margin-left: 10px;
  font-weight: 400;
  font-size: 20px;
  @media screen and (max-width: 812px) {
    font-size: 14px;
    line-height: 20px;
  }
  @media screen and (max-width: 420px) {
    font-size: 15px;
  }
`;

export const Step = styled.div`
  @media screen and (max-width: 812px) {
    padding: 0 15px;
  }
  @media screen and (max-width: 420px) {
    padding: 0;
  }
`;

export const StepInfo = styled.div`
  padding: 50px;
  margin-bottom: 70px;
  background-color: ${MAIN_GRAY};
  @media screen and (max-width: 1024px) {
    padding: 40px 37px;
  }
  @media screen and (max-width: 812px) {
    padding: 30px 40px;
    margin-bottom: 0;
  }
  @media screen and (max-width: 500px) {
    padding: 15px 17px;
  }
`;

export const StepArea = styled.div`
  padding: 50px;
  margin-bottom: 60px;
  background-color: white;
  @media screen and (max-width: 1024px) {
    padding: 40px 37px;
  }
  @media screen and (max-width: 812px) {
    padding: 30px 40px;
    margin-bottom: 0;
  }
  @media screen and (max-width: 500px) {
    padding: 15px 17px;
  }
`;

export const H2Style = styled.h2`
  padding: 20px 83px 25px 30px;
  background: ${MAIN_GRAY};
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  justify-content: ${({ centred }) => (centred ? 'center' : 'flex-start')};
  svg {
    width: 50px;
    margin-right: 30px;
    margin-top: 5px;
  }
  @media screen and (max-width: 1024px) {
    padding: 25px 30px;
    font-size: 20px;
    line-height: 28px;
    svg {
      width: 60px;
      margin-right: 26px;
    }
  }
  @media screen and (max-width: 500px) {
    padding: 30px 13px;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    flex-direction: column;
    svg {
      width: 20px;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

export const H3Out = styled.div`
  font-style: normal;
  margin-top: 60px;
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 1.5rem;
  display: flex;
  @media screen and (max-width: 1024px) {
    font-size: 22px;
    line-height: 120%;
  }
  @media screen and (max-width: 812px) {
    font-weight: bold;
    margin-top: 40px;
    font-size: 22px;
    line-height: 120%;
  }
  @media screen and (max-width: 420px) {
    padding: 0;
    margin-top: 30px;
    font-size: 16px;
  }
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  border: 1px solid
  ${({ update }) => {
    if (update === 1) {
      return INFO_ALERT;
    }
    if (update === 2) {
      return ACTIVE;
    }
    return WARNING_ALERT;
  }};
  color: ${({ update }) => (update ? ACTIVE : WARNING)};
  font-size: 10px;
  margin-left: 15px;
  padding: 8px 10px;
  &:hover,
  &:focus {
    background-color: ${({ update }) => (update ? INFO_ALERT : WARNING_ALERT)};
  }
  @media screen and (max-width: 410px) {
    padding: 3px 5px;
    margin-left: 8px;
    font-size: 7px;
    svg {
      width: 15px;
    }
  }
`;

export const DropdownButton = styled.button`
  background-color: ${ACTIVE};
  color: white;
  white-space: nowrap;
  margin-right: 15px;
  padding: 1.2rem 2rem;
  text-transform: none;
  font-size: 1rem;
  position: relative;
  z-index: 0;
  &:before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: '';
    left: 0;
    top: 0;
  }
  @media screen and (max-width: 1024px) {
    padding: 1rem 1.3rem;
  }
  @media screen and (max-width: 812px) {
    width: 100%;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 500px) {
    display: block;
    margin: 0 auto;
  }
  @media screen and (max-width: 420px) {
    width: 100%;
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 66px;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  left: 0;
  border-radius: 5px;
  width: max-content;
  z-index: 1000;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  background-color: white;
  list-style: none;
  @media screen and (max-width: 812px) {
    width: 100%;
    align-items: center;
  }
`;

export const DropItem = styled.button`
  padding: 10px 15px;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: ${MAIN_GRAY};
  }
`;

export const DropCornerWhite = styled.div`
  border: 2px solid white;
  transform: rotate(45deg);
  border-top: 0 solid transparent;
  border-left: 0 solid transparent;
  width: 10px;
  position: relative;
  top: -2px;
  height: 10px;
  display: inline-block;
  margin-left: 10px;
`;

export const DropArea = styled.div`
  position: relative;
  @media screen and (max-width: 500px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const postImagesRender = (count) => {
  if (count === 2) {
    return css`
      grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
      grid-auto-flow: dense;
      button > div {
        height: 500px;
      }
      @media screen and (max-width: 900px) {
        button > div {
          height: 300px;
        };
      }
      @media screen and (max-width: 700px) {
        button > div {
          height: 250px;
        };
      }
      @media screen and (max-width: 500px) {
        button > div {
          height: 200px;
        };
      }
      @media screen and (max-width: 400px) {
        button > div {
          height: 160px;
        };
      }
    `;
  }
  if (count === 3) {
    return css`
      .f0 {
        grid-column: 1;
        grid-row: span 2;
        > div {
          height: 500px;
        }
      }
      .f1 {
        grid-column: 2;
        grid-row: 1 / 1;
      }
      .f2 {
        grid-column: 2;
        grid-row: 2 / 2;
      }
      .f1, .f2 {
        > div {
          height: 246px;
        }
      }
      @media screen and (max-width: 900px) {
        .f0 {
          grid-column: 1 / span 2;
          grid-row: 1;
        }
        .f1 {
          grid-column: 1;
          grid-row: 2;
        }
        .f2 {
          grid-column: 2;
          grid-row: 2;
        }
        .f0, .f1, .f2 {
          > div {
            height: 250px;
          }
        }
      }
      @media screen and (max-width: 700px) {
        .f0, .f1, .f2 {
          > div {
            height: 200px;
          }
        }
      }
      @media screen and (max-width: 500px) {
        .f0, .f1, .f2 {
          > div {
            height: 150px;
          }
        }
      }
      @media screen and (max-width: 400px) {
        .f0, .f1, .f2 {
          > div {
            height: 100px;
          }
        }
      }
    `;
  }
  if (count === 4) {
    return css`
      grid-template-columns: repeat(auto-fill,minmax(45%,1fr));
      button > div {
        height: 250px;
      };
      @media screen and (max-width: 500px) {
        button > div {
          height: 160px;
        };
      }
      @media screen and (max-width: 400px) {
        button > div {
          height: 110px;
        };
      }
    `;
  }
  if (count === 5) {
    return css`
      grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
      grid-auto-flow: dense;
      .f4 {
        grid-column-end: span 2;
      }
      button > div {
        height: 250px;
      }
      @media screen and (max-width: 900px) {
        button > div {
          height: 130px;
        }
      }
      @media screen and (max-width: 500px) {
        button > div {
          height: 100px;
        }
      }
      @media screen and (max-width: 400px) {
        button > div {
          height: 80px;
        }
      }
    `;
  }
  if (count === 6) {
    return css`
      grid-template-columns: repeat(3, 1fr);
      button > div {
        height: 250px;
      }
      @media screen and (max-width: 900px) {
        button > div {
          height: 130px;
        }
      }
      @media screen and (max-width: 500px) {
        button > div {
          height: 100px;
        }
      }
      @media screen and (max-width: 410px) {
        button > div {
          height: 80px;
        }
      }
    `;
  }
  if (count === 7) {
    return css`
      grid-template-columns: repeat(auto-fill,minmax(20%, 1fr));
      grid-auto-flow: dense;
      .f5 {
        grid-column-end: span 2;
      };
      button > div {
        height: 220px;
      };
      @media screen and (max-width: 900px) {
        button > div {
          height: 200px;
        }
      }
      @media screen and (max-width: 700px) {
        button > div {
          height: 140px;
        }
      }
      @media screen and (max-width: 500px) {
        button > div {
          height: 100px;
        }
      }
      @media screen and (max-width: 400px) {
        button > div {
          height: 70px;
        }
      }
    `;
  }
  if (count === 8) {
    return css`
      grid-template-columns: repeat(4, 1fr);
      button > div {
        height: 200px;
      }
      @media screen and (max-width: 900px) {
        button > div {
          height: 150px;
        }
      }
      @media screen and (max-width: 500px) {
        button > div {
          height: 100px;
        }
      }
      @media screen and (max-width: 410px) {
        button > div {
          height: 80px;
        }
      }
    `;
  }
  if (count === 9) {
    return css`
      grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
      grid-auto-flow: dense;
      .f5,
      .f7,
      .f8 {
        grid-column-end: span 2;
      }
      button > div {
        height: 160px;
      }
      @media screen and (max-width: 900px) {
        button > div {
          height: 130px;
        }
      }
      @media screen and (max-width: 500px) {
       button > div {
         height: 100px;
       }
      }
      @media screen and (max-width: 410px) {
        button > div {
          height: 80px;
        }
      }
    `;
  }
  if (count === 10) {
    return css`
      grid-template-columns: repeat(auto-fill, minmax(20%,1fr));
      grid-auto-flow: dense;
      .f5,
      .f7 {
        grid-column-end: span 2;
      }
      button > div {
        height: 160px;
      }
      @media screen and (max-width: 900px) {
        button > div {
          height: 130px;
        }
      }
      @media screen and (max-width: 500px) {
        button > div {
          height: 100px;
        }
      }
       @media screen and (max-width: 400px) {
        button > div {
          height: 80px;
        }
      }
    `;
  }
  return css`
    button > div {
      height: 500px;
    }
  `;
};
