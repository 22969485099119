export default {
  activities_type: 'Тип',
  activities_type_placeholder: 'Выберите тип',
  activities_activity: 'Название',
  activities_activity_placeholder: 'Введите название',
  activities_period: 'Дата',
  period_placeholder: 'Год, период 2000-2002 или в свободной форме',
  activities_link: 'Ссылка',
  activities_link_placeholder: 'введите ссылку',
  activities_description: 'Описание',
  activities_description_placeholder: 'Введите',
  activities_delete: 'Удалить событие',
  activities_add: 'Добавить событие',
  history_type: 'Тип',
  history_type_placeholder: 'Выберите тип',
  history_date: 'Дата',
  history_price: 'Стоимость',
  history_price_placeholder: 'Введите цифры',
  history_location: 'Местоположение',
  history_location_placeholder: 'Введите адрес или наименование места',
  history_description: 'Описание',
  history_description_placeholder: 'Введите',
  history_currency: 'Валюта',
  history_delete: 'Удалить действие',
  history_add: 'Добавить действие',
  provenance_date: 'Дата',
  provenance_comment: 'Комментарий',
  provenance_comment_placeholder: 'Опишите событие',
  provenance_delete: 'Удалить провенанс',
  provenance_add: 'Добавить провенанс',
};
