export default {
  title_search: 'Поиск по запросу «{{ search }}» | Артократия',
  title: 'Поиск | Артократия',
  description: 'Страница поисковой выдачи. Социальная сеть для ценителей искусства - Артократия',
  description_search: 'Результаты поиска по запросу «{{ search }}». Социальная сеть для ценителей искусства - Артократия',
  by_request_empty: 'Поиск по запросу «{{ search }}» не дал результатов',
  by_request: 'Результаты поиска по запросу - «{{ search }}»',
  alert: 'Необходимо создать запрос',
  placeholder: 'Введите запрос',
  btn: 'Найти',
  collections: 'Коллекции',
  all_collections: 'Все коллекции по запросу',
  artworks: 'Работы',
  all_artworks: 'Все работы по запросу',
  educations: 'Образовательные курсы',
  all_educations: 'Все образовательные курсы',
  artists: 'Художники',
  all_artists: 'Все художники по запросу',
  exhibitions: 'Выставки',
  all_exhibitions: 'Все выставки по запросу',
  institutions: 'Музеи',
  all_institutions: 'Все институции по запросу',
};
