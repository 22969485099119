export default {
  work: 'работы',
  work_1: 'работа',
  work_2: 'работ',
  works: 'работ',
  year: 'год',
  years_2: 'года',
  years: 'лет',
  user: 'пользователя',
  users: 'пользователей',
  album: 'альбома',
  albums_2: 'альбомов',
  albums: 'альбомов',
  collection: 'коллекции',
  collections: 'коллекций',
  compilation: 'подборки',
  compilations: 'подборок',
  exhibition: 'выставки',
  exhibitions: 'выставок',
  institution: 'музея',
  institutions: 'музеев',
  day: 'дня',
  days: 'дней',
  comment: 'Комментарий',
  of_comment: 'Комментария',
  comments: 'Комментариев',
  month: 'месяц',
  month_2: 'месяца',
  months: 'месяцев',
  lecture: 'лекция',
  lecture_2: 'лекции',
  lectures: 'лекций',
};
