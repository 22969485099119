import { gql } from '@apollo/client';

export const GQL_GET_HOME = gql`
  query GetHome ($section: String!) {
    home(section: $section) {
      _id
      name
      color
      description
      horizontal
      section
      button
      button_url
      status
      order
      image {
        key
      }
      cover {
        key
      }
    }
  }
`;
