import { gql } from '@apollo/client';

const institutionFragment = gql`
  fragment InstitutionFragment on Institution {
    _id
    likes
    name
    description
    owner_id
    variant
    allow_me
    location_id
    contacts
    url
    status
    myLike
    images {
      _id
      key
      fileName
      format
    }
    favorite {
      _id
    }
    curator {
      _id
      name
      username
      avatar {
        _id
        key
        fileName
      }
    }
    avatar {
      _id
      key
      fileName
    }
    location {
      _id
      name
      address
      coordinates
      place_id
      user_id
    }
  }
`;

const fragmentAll = gql`
  fragment InstitutionsAllFragment on InstitutionsAll {
    total
    tariffs {
      count
      color
      name
      close_text
    }
    meta {
      title
      description
      created_at
      updated_at
      page_name
      page_description
    }
    items {
      _id
      name
      likes
      variant
      allow_me
      location_id
      url
      status
      favorite {
        _id
      }
      images {
        _id
        key
        fileName
        format
      }
      avatar {
        _id
        key
        fileName
      }
      location {
        _id
        name
        address
        coordinates
        place_id
        user_id
      }
    }
  }
`;

export const GQL_UPDATE_INSTITUTION = gql`
  mutation UpdateInstitution($data: InstitutionInput) {
    updateInstitution(data: $data) {
      ...InstitutionFragment
    }
  }
  ${institutionFragment}
`;

export const GQL_REMOVE_INSTITUTION = gql`
  mutation RemoveInstitution($_id: String!) {
    removeInstitution(_id: $_id)
  }
`;

export const GQL_PUBLISH_INSTITUTION = gql`
  mutation PublishInstitution($_id: String!, $status: Int) {
    publishInstitution(_id: $_id, status: $status)
  }
`;

export const GQL_GET_INSTITUTIONS_ALL = gql`
  query GetInstitutionsAll($my: Boolean, $all: Boolean, $query: QueryItems) {
    institutionsAll(my: $my, all: $all, query: $query) {
      ...InstitutionsAllFragment
    }
  }
  ${fragmentAll}
`;

export const GQL_GET_INSTITUTION = gql`
  query GetInstitution($_id: ID, $url: String, $edit: Boolean, $redirect: Boolean) {
    institution(_id: $_id, url: $url, edit: $edit, redirect: $redirect) {
      ...InstitutionFragment
    }
  }
  ${institutionFragment}
`;
