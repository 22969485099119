export default {
  to_fix: 'Комментарий из карточки',
  category: 'Категория',
  article_number: 'Артикул',
  article_placeholder: 'Введите артикул',
  condition: 'Состояние',
  condition_placeholder: 'Введите состояние',
  circulation: 'Тираж',
  address: 'Адрес',
  fragility: 'Хрупкость',
  history: 'История действий',
  activities: 'Выставки, публикации, аукционы',
  weight: 'Вес',
  insurance: 'Страховка',
  insurance_status: 'Статус страховки',
  insured: 'Есть',
  insured_not: 'Отсутствует',
  certificate: 'Наличие сертификата подлинности',
  certificate_yes: 'Есть',
  certificate_no: 'Отсутствует',
  trans_store: 'Особенности хранения и транспортировки',
  trans_store_placeholder: 'Введите состояние',
  digitization: 'Оцифровка (наличие хорошей фотографии)',
  digitization_yes: 'Есть',
  digitization_no: ' Отсутствует',
  notes: 'Заметки',
  notes_placeholder: 'Описание',
  files: 'Файлы и документы к публикации',
  paperwork: 'Оформление',
  paperwork_placeholder: 'Описание',
  provenance_name: 'Провенанс',
  history_name: 'История',
  documents_name: 'Документы',
  catalog: 'Каталог работ',
  share_label: 'Поделиться работой',
  meta_artist_name: 'Художник {{ name }}',
  meta_collection_name: 'Владелец {{ name }}',
  meta_artwork_name: 'Произведение искусства {{ name }}',
  meta_description: 'Художник {{ name }} - работы в коллекциях',
  step_1_info: 'Заполните этот шаг, чтобы работа появилась в коллекции. Она сохранится и будет видна только вам. Чтобы показать её другим пользователям, заполните также шаг 2.',
  step_2_info: 'Заполнив эти данные, вы сможете опубликовать работу, если захотите. Она станет доступна для просмотра пользователям в вашей коллекции.',
  step_3_info: 'Это приватная информация, касающаяся работы: артикул, стоимость, состояние и пр. Добавлять её можно по собственному желанию, и видна она будет в личном кабинете только Вам.',
  artist_step_1_info: 'Заполните этот шаг, чтобы работа появилась в списке ваших работ. Она сохранится и будет видна только вам. Чтобы показать её другим пользователям, заполните также шаг 2.',
  artist_step_2_info: 'Заполнив эти данные, вы сможете опубликовать работу, если захотите. Она станет доступна для просмотра пользователям в карточке художника.',
  my_collection: 'Моя коллекция',
  my_artwork: 'Моя работа',
  my_artworks: 'Мои работы',
  photos: 'Фото работы',
  photos_link: 'ссылка на фотобанк',
  publish_name: 'работу',
  name: 'Обновление работы {{ name }}',
  add: 'Добавление работы',
  info: 'Информация о работе',
  no_name: 'Работа без названия',
  field_name: 'Название работы',
  name_placeholder: 'Введите название',
  name_placeholder_ru: 'Введите название (на русском)',
  name_placeholder_en: 'Enter title in English (необязательно)',
  link_placeholder: 'Введите ссылку',
  field_artist: 'Автор работы',
  artist_placeholder: 'Начните вводить художника',
  artist_untitled: 'Художник неизвестен',
  field_album: 'Альбом',
  album_info: 'Выберите в каком из ваших альбомов будет храниться экспонат',
  no_album: 'Без альбома',
  info_name: 'Информация о работе',
  view: 'Просмотр',
  edit: 'Редактировать',
  field_category: 'Категория размещения',
  select_category: 'Выберите категорию',
  circulation_placeholder: 'Введите номер экземпляра для тиражной работы, например: 2/300',
  material_tech: 'Материал, техника',
  import_tech: 'Техника Исполнения из импорта, вы можете перевести их в теговую систему, затем удалить в этом поле',
  material_tech_placeholder: 'Выберите технику',
  field_price: 'Стоимость',
  price_placeholder: 'Введите стоимость работы',
  currency: 'Валюта',
  field_size: 'Размер работы',
  field_description: 'О работе',
  desc_placeholder: 'Введите описание',
  desc_placeholder_en: 'Description in english (необязательно)',
  field_period: 'Период создания',
  period_placeholder: 'Год, период 2000-2002 или в свободной форме',
  additional: 'Это приватная информация, касающаяся работы: <b>артикул</b>, <b>стоимость</b>, <b>состояние</b> и пр. <br/> Добавлять её можно по собственному желанию, и видна она будет в личном кабинете только Вам.',
  additional_label: 'Будет видно только вам',
  additional_name: 'Дополнительные данные',
  additional_add: 'Добавить дополнительные данные',
  back: 'Назад к шагу',
  next: 'Далее',
  private_info: 'Приватная информация, видна только Вам',
  select_variant: 'Выберите для кого опубликовать работу',
  cant_published: 'Вы не можете опубликовать работу так как нет фото',
  set_exhibition: 'Выберите или создайте выставку к которой будете прикреплять работы',
  attach: 'Добавлено',
  show_attached: 'Показать добавленные работы',
  show_all: 'Показать все работы',
  go_exhibition: 'Выберите или создайте выставку!',
  no_selected: 'Нет ни одной выбранной работы',
  artist: 'Работы художника',
  market: 'Маркет',
  find_artist: 'Найдите себя или создайте новую карточку художника',
  send_artist_order: 'Отправить заявку на управление карточкой художника',
  artist_order_requested: 'Ваш запрос отправлен, ожидайте уведомления. Мы рассмотрим вашу заявку в течении дня',
  price: 'Стоимость',
  bye: 'Купить',
  price_help: 'Стоимость используется только для Маркета, если вы продаёте работу. Если вы не указали цену, она будет отображаться как «Цена по запросу»',
  not_necessary: 'необязательно',
  price_request: 'Цена по запросу',
  conversation: 'Комментарии к работе',
  show_location: 'Указать местоположение',
  location_select_info: 'Вы можете выбрать из своих локаций или создать новую. Чтобы создать, введите название и нажмите «Добавить»',
  type_my_location: 'Выбрать из моих',
  short_dimension: 'Ед',
  confirmed_exhibition: 'Работа подтверждена к участию в мероприятии',
  price_name: 'Введите актуальную стоимость',
  price_info: 'Рекомендуем указать точную стоимость или диапазон, это сильно повышает шанс продажи работы',
  price_set: 'Задать',
  price_one: 'Точная стоимость',
  price_range: 'Диапазон',
  add_price: 'Указать стоимость',
  for_all: 'Для всех включая гостей',
  for_friends: 'Только для моих друзей',
  for_view: 'Видимость работы',
  for_view_info: 'Эта функция только для коллекционных работ. Если вы собираетесь публиковать в Маркет, работа станет общедоступной для всех',
};
