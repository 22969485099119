import PropTypes from 'prop-types';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { positions, Provider, transitions } from 'react-alert';
import { useTranslation } from './components/hooks/useTranslation';

import {
  ERROR,
  ERROR_ALERT,
  INFO,
  INFO_ALERT,
  SUCCESS,
  SUCCESS_ALERT,
  WARNING,
  WARNING_ALERT,
} from './helpers/colors';

// eslint-disable-next-line react/no-unstable-nested-components
const AlertTemplate = ({ options, message, close }) => {
  const { t } = useTranslation();
  let icon = '';
  let background = '';
  let color = '';
  const boxShadow = '0 0 5px rgba(0,0,0,0.4)';
  switch (options.type) {
    case 'info':
      background = INFO_ALERT;
      color = INFO;
      icon = t('alert_information', { ns: 'index' });
      break;
    case 'warning':
      background = WARNING_ALERT;
      color = WARNING;
      icon = t('alert_warning', { ns: 'index' });
      break;
    case 'danger':
    case 'error':
      background = ERROR_ALERT;
      color = ERROR;
      icon = t('alert_error', { ns: 'index' });
      break;
    case 'success':
      background = SUCCESS_ALERT;
      color = SUCCESS;
      icon = t('alert_success', { ns: 'index' });
      break;
    default:
      background = SUCCESS_ALERT;
      color = SUCCESS;
      icon = t('alert_success', { ns: 'index' });
      break;
  }
  return (
    <div
      style={{
        background,
        boxShadow,
        padding: 20,
        display: 'block',
        minWidth: '100%',
        maxWidth: isMobile ? '100%' : 340,
        width: isMobile ? '100%' : 340,
        fontSize: 14,
        lineHeight: '20px',
        borderRadius: '4px',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <div
          style={{ color }}
        >
          {icon}
        </div>
        <button
          type="button"
          style={{
            position: 'relative',
            zIndex: 1,
            fontSize: 35,
            padding: 0,
            fontWeight: 100,
            lineHeight: 0,
            opacity: 0.4,
          }}
          onClick={close}
        >
          &times;
        </button>
      </div>
      {message}
    </div>
  );
};

const AlertProvider = ({ children }) => {
  const optionsAlert = {
    position: positions.BOTTOM_LEFT,
    timeout: 7000,
    offset: '0',
    transition: transitions.SCALE,
    containerStyle: {
      left: isMobile ? 0 : 80,
      position: 'fixed',
      zIndex: 1100,
      minHeight: '88px !important',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      maxWidth: isMobile ? '100%' : 340,
      width: isMobile ? '100%' : 340,
      pointerEvents: 'all',
      bottom: isMobile ? 0 : 30,
      alignItems: isMobile ? 'stretch' : 'center',
    },
  };

  return (
    <Provider template={AlertTemplate} {...optionsAlert}>
      {children}
    </Provider>
  );
};

AlertTemplate.propTypes = {
  options: PropTypes.object.isRequired,
  message: PropTypes.any.isRequired,
  close: PropTypes.func.isRequired,
};

AlertProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AlertProvider;
