export default {
  title: 'АРТОКРАТИЯ - РЕГИСТРАЦИЯ',
  name: 'Регистрация',
  info: 'Заполните данные и вам на почту придёт письмо с ссылкой активации',
  email: 'Email',
  email_placeholder: 'Введите ваш E-mail',
  password: 'Пароль',
  password_placeholder: 'Придумайте пароль',
  recommendation: 'Рекомендации по надежности',
  strong: 'Пароль надежный',
  length: 'Длинна пароля не менее 8 символов',
  lowercase_characters: 'Наличие строчных символов',
  uppercase_characters: 'Наличие заглавных символов',
  numbers_special_characters: 'Наличие цифр и спецсимволов',
  confirm_password: 'Подтверждение пароля',
  confirm_password_placeholder: 'Повторите пароль',
  passwords_are_match: 'Пароли совпадают',
  passwords_are_not_match: 'Пароли не совпадают',
  services: 'Войти через сервисы',
  have_account: 'Уже есть аккаунт?',
  authorization: 'Авторизоваться',
  phone: 'Телефон',
  phone_placeholder: 'Ведите телефон',
  not_correct_phone: 'Некорректный номер телефона',
  registration: 'Зарегистрироваться',
  complete_title: 'АРТОКРАТИЯ - ЗАВЕРШЕНИЕ РЕГИСТРАЦИИ',
  complete_name: 'Завершающий этап регистрации',
  complete_info: 'Представьтесь и выберите роль, чтобы завершить регистрацию',
  complete_user: 'Имя Фамилия',
  complete_role: 'Выберите свою роль в Артократии. Чем точнее вы определитесь с ролью, тем приятнее будет ваш опыт на платформе и тем легче вам будет выстраивать общение с другими пользователями. Роль можно сменить в любой момент.',
  complete_username_placeholder: 'Введите Ваше имя',
  complete_label_amateur: 'Ценитель искусства',
  complete_label_dealer: 'Арт-Дилер',
  complete_label_gallery: 'Галерея',
  complete_label_curator: 'Куратор',
  complete_label_collector: 'Коллекционер',
  complete_btn: 'Завершить регистрацию',
  hello_name: 'Добро пожаловать в Артократию, {{ name }}!',
  hello_text: 'Покупайте и продавайте искусство, находите новых людей в команду своего проекта, знакомьтесь с профессионалами креативной индустрии, участвуйте в цифровых выставках, узнавайте новости арт-мира и получайте новый опыт и знания. ',
  hello_go: 'Начать',
};
