/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, FormControl, InputGroup, Row,
} from 'react-bootstrap';
import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { ACTIVE, DARK } from '../../helpers/colors';
import { useTranslation } from '../hooks/useTranslation';
import Icon from '../Icon';
import { LANG_RU } from '../../locales';

moment.locale(LANG_RU);

const ArtDateRange = ({
  field,
  form: { errors, setFieldValue },
  label,
  placeholderStart,
  placeholderEnd,
  groupClassName,
  className,
  prepend,
  column,
}) => {
  let valueStart = '';
  let valueEnd = '';
  const { t } = useTranslation();
  if (!placeholderStart) {
    placeholderStart = t('date', { ns: 'form' });
  }
  if (!placeholderEnd) {
    placeholderEnd = t('date', { ns: 'form' });
  }
  const [visible, setVisible] = useState(false);

  const startDate = _.get(field, 'value[0]') ? _.get(field, 'value[0]') * 1000 : null;
  const endDate = _.get(field, 'value[1]') ? _.get(field, 'value[1]') * 1000 : null;

  if (!!startDate && !!endDate) {
    valueStart = moment(+startDate).format('DD.MM.YY');
    valueEnd = moment(+endDate).format('DD.MM.YY');

    if (moment(+startDate).isSame(moment(+endDate))) {
      valueStart = moment(+startDate).format('DD.MM.YY');
      valueEnd = '';
    }
  }

  const ranges = {
    startDate: +startDate,
    endDate: +endDate,
    key: 'selection',
  };

  return (
    <Form.Group
      as={Row}
      controlId={field.name}
      className={`d-flex ${column ? 'flex-column' : 'flex-row'} ${groupClassName}`}
    >
      {!!label && (
        <Form.Label column md={column ? 12 : 3}>
          {label}
        </Form.Label>
      )}

      <Col md={label && !column ? 9 : 12}>
        <InputGroup className={groupClassName}>
          {!!prepend && (
            <InputGroup>
              <InputGroup.Text>{prepend}</InputGroup.Text>
            </InputGroup>
          )}

          <FormControl
            className={className}
            placeholder={placeholderStart}
            value={valueStart}
            readOnly
          />

          {valueEnd && (
            <FormControl
              className={className}
              placeholder={placeholderEnd}
              value={valueEnd}
              readOnly
            />
          )}

          <Button type="button" onClick={() => setVisible(true)}>
            <Icon icon="calendar" colorFill={DARK} width={20} height={20} colorHover={ACTIVE} />
          </Button>
          {visible && (
            <Calendar>
              <Header>
                <Name>{t('select_date', { ns: 'form' })}</Name>
                <Close onClick={() => setVisible(false)} type="button">
                  &times;
                </Close>
              </Header>
              <DateRange
                startDatePlaceholder={t('start', { ns: 'form' })}
                endDatePlaceholder={t('end', { ns: 'form' })}
                rangeColors={[ACTIVE]}
                color={ACTIVE}
                editableDateInputs
                onChange={(item) => {
                  const values = [_.get(item, 'selection.startDate') / 1000];

                  if (
                    !moment(_.get(item, 'selection.startDate')).isSame(
                      _.get(item, 'selection.endDate'),
                    )
                  ) {
                    values[1] = _.get(item, 'selection.endDate') / 1000;
                  } else {
                    values[1] = _.get(item, 'selection.startDate') / 1000;
                  }

                  setFieldValue(field.name, values);
                }}
                moveRangeOnFirstSelection={false}
                ranges={[ranges]}
                locale={locales[LANG_RU]}
              />
              <ButtonSave onClick={() => setVisible(false)} type="button">
                {t('select', { ns: 'action' })}
              </ButtonSave>
            </Calendar>
          )}

          <Form.Control.Feedback type="invalid">{errors[field.name]}</Form.Control.Feedback>
        </InputGroup>
      </Col>
    </Form.Group>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;

const ButtonSave = styled.button`
  border: 1px solid ${ACTIVE};
  color: ${ACTIVE};
  float: right;
  padding: 0.3em 0.5em;
  &:hover {
    color: white;
    background-color: ${ACTIVE};
  }
`;

const Name = styled.div`
  font-weight: bold;
`;

const Close = styled.button`
  padding: 0;
  font-weight: 100;
  font-size: 50px;
  line-height: 0;
`;

const Calendar = styled.div`
  position: absolute;
  padding: 10px 20px;
  background: #fff;
  right: 0;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

const Button = styled.button`
  padding: 0;
  border-bottom: 1px solid #e0e5f1;
  border-radius: 0;
`;

ArtDateRange.defaultProps = {
  label: null,
  placeholderStart: '',
  placeholderEnd: '',
  groupClassName: '',
  prepend: '',
  column: false,
  className: '',
};

ArtDateRange.propTypes = {
  field: PropTypes.object.isRequired,
  className: PropTypes.string,
  form: PropTypes.shape({
    errors: PropTypes.object,
    setFieldValue: PropTypes.func,
  }).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholderStart: PropTypes.string,
  placeholderEnd: PropTypes.string,
  groupClassName: PropTypes.string,
  prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  column: PropTypes.bool,
};

export default ArtDateRange;
