/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ACTIVE, DARK } from '../helpers/colors';
import {
  abuse,
  addUser,
  apple,
  arrowOut,
  ban,
  bars,
  book,
  burger,
  calendar,
  clock,
  column,
  comment,
  contact,
  copy,
  document,
  download,
  edit,
  education,
  educationHead,
  email,
  exhibition,
  eye,
  eyeClose,
  favorite,
  fb,
  feather,
  file,
  globe,
  google,
  heart,
  info,
  insta,
  layouts,
  link,
  loader,
  location,
  lock,
  login,
  logout,
  market,
  message,
  museum,
  news,
  notification,
  ok,
  paperClip,
  phone,
  picture,
  plus,
  points,
  qr,
  remove,
  reply,
  search,
  send,
  settings,
  share,
  shine,
  smile,
  star,
  starNormal,
  stars,
  tg,
  th,
  tw,
  upload,
  user,
  users,
  verified,
  video,
  vk,
  wa,
  www,
  zipper,
} from './icons';

const getIcon = (icon, colorStroke, colorFill, colorFillIn) => {
  const params = { colorStroke, colorFill, colorFillIn };
  switch (icon) {
    case 'abuse':
      return abuse(params);
    case 'addUser':
      return addUser(params);
    case 'apple':
      return apple(params);
    case 'arrowOut':
      return arrowOut(params);
    case 'ban':
      return ban(params);
    case 'bars':
      return bars(params);
    case 'book':
      return book(params);
    case 'burger':
      return burger(params);
    case 'calendar':
      return calendar(params);
    case 'clock':
      return clock(params);
    case 'column':
      return column(params);
    case 'comment':
      return comment(params);
    case 'contact':
      return contact(params);
    case 'copy':
      return copy(params);
    case 'document':
      return document(params);
    case 'download':
      return download(params);
    case 'edit':
      return edit(params);
    case 'education':
      return education(params);
    case 'educationHead':
      return educationHead(params);
    case 'email':
      return email(params);
    case 'exhibition':
      return exhibition(params);
    case 'eye':
      return eye(params);
    case 'eyeClose':
      return eyeClose(params);
    case 'favorite':
      return favorite(params);
    case 'fb':
      return fb(params);
    case 'feather':
      return feather(params);
    case 'file':
      return file(params);
    case 'globe':
      return globe(params);
    case 'google':
      return google(params);
    case 'heart':
      return heart(params);
    case 'info':
      return info(params);
    case 'insta':
      return insta(params);
    case 'layouts':
      return layouts(params);
    case 'link':
      return link(params);
    case 'loader':
      return loader(params);
    case 'location':
      return location(params);
    case 'lock':
      return lock(params);
    case 'login':
      return login(params);
    case 'logout':
      return logout(params);
    case 'market':
      return market(params);
    case 'message':
      return message(params);
    case 'museum':
      return museum(params);
    case 'news':
      return news(params);
    case 'notification':
      return notification(params);
    case 'ok':
      return ok(params);
    case 'paperclip':
      return paperClip(params);
    case 'phone':
      return phone(params);
    case 'picture':
      return picture(params);
    case 'plus':
      return plus(params);
    case 'points':
      return points(params);
    case 'qr':
      return qr(params);
    case 'remove':
      return remove(params);
    case 'reply':
      return reply(params);
    case 'search':
      return search(params);
    case 'send':
      return send(params);
    case 'settings':
      return settings(params);
    case 'share':
      return share(params);
    case 'shine':
      return shine(params);
    case 'smile':
      return smile(params);
    case 'star':
      return star(params);
    case 'starNormal':
      return starNormal(params);
    case 'stars':
      return stars(params);
    case 'tg':
      return tg(params);
    case 'th':
      return th(params);
    case 'tw':
      return tw(params);
    case 'upload':
      return upload(params);
    case 'user':
      return user(params);
    case 'users':
      return users(params);
    case 'verified':
      return verified(params);
    case 'video':
      return video(params);
    case 'vk':
      return vk(params);
    case 'wa':
      return wa(params);
    case 'www':
      return www(params);
    case 'zipper':
      return zipper(params);
    default:
      return false;
  }
};

const Icon = ({
  icon,
  color,
  className,
  colorStroke,
  colorFill,
  colorHover,
  colorFillIn,
  viewBox,
  noHover,
  active,
  tooltip,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const stroke = active ? color : hover && !noHover ? colorHover : colorStroke;
  const fill = active ? color : hover && !noHover ? colorHover : colorFill;
  return (
    <svg
      role="img"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`${className || ''}${tooltip ? ' info-svg' : ''}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox || getIcon(icon, stroke, fill, colorFillIn).viewBox}
      color={!active && hover && !noHover ? colorHover : color}
      {...props}
    >
      {tooltip && <title>{tooltip}</title>}
      {getIcon(icon, stroke, fill, colorFillIn).data}
    </svg>
  );
};

Icon.defaultProps = {
  className: null,
  viewBox: null,
  color: ACTIVE,
  colorFill: ACTIVE,
  colorStroke: ACTIVE,
  colorHover: DARK,
  colorFillIn: 'transparent',
  height: 20,
  width: 20,
  noHover: false,
  active: false,
  tooltip: '',
};

Icon.propTypes = {
  className: PropTypes.string,
  tooltip: PropTypes.string,
  noHover: PropTypes.bool,
  active: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  // This array need if i use css`` in color
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  colorFill: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  colorStroke: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  colorFillIn: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  viewBox: PropTypes.string,
  colorHover: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Icon;
