import { gql } from '@apollo/client';

const fragment = gql`
  fragment ArtworkFragment on Artwork {
    _id
    url
    name
    age
    likes
    myLike
    to_fix
    price
    price_from
    price_to
    currency
    is_market
    author_name
    categories_id
    is_market
    moderated
    on_trust
    expired
    albums_id
    subcategories_id
    technique
    location_id
    description
    ex_name
    ex_artist
    digitization
    certificate
    owner_id
    sizeType
    photoLink
    status
    dates
    created
    circulation
    article_number
    condition
    fragility
    paperwork
    insurance
    certificate
    digitization
    trans_store
    status
    notes
    access
    updated_at
    created_at
    expired
    location {
      _id
      address
      name
    }
    owner {
      _id
      name
      username
      avatar {
        _id
        key
        fileName
        format
      }
    }
    favorite {
      _id
    }
    artist_id
    artist {
      _id
      url
      name
      status
    }
    images {
      _id
      key
      fileName
      format
      order
      width
      height
    }
    files {
      _id
      key
      fileName
      format
    }
    categories {
      _id
      name
    }
    subcategories {
      _id
      name
    }
    weight {
      value
      dimension
    }
    albums {
      _id
      name
      artworks_id
    }
    sizes {
      type
      figure
      diameter
      dimension
      wh
      depth
      height
      width
      free
    }
    activities {
      type
      name
      notes
      dates
      link
    }
    provenance {
      description
      dates
    }
    activities {
      type
      name
      notes
      dates
      link
    }
    history {
      type
      price
      currency
      place
      notes
      dates
    }
  }
`;

const fragmentAll = gql`
  fragment ArtworkAllFragment on ArtworksAll {
    total
    min_price
    max_price
    meta {
      title
      description
      created_at
      updated_at
      page_name
      page_description
    }
    items {
      _id
      url
      name
      likes
      myLike
      to_fix
      age
      price
      price_from
      price_to
      is_market
      moderated
      on_trust
      expired
      currency
      categories_id
      subcategories_id
      technique
      location_id
      description
      ex_name
      ex_artist
      owner_id
      sizeType
      photoLink
      status
      dates
      created
      circulation
      article_number
      condition
      fragility
      status
      notes
      access
      owner {
        _id
        name
        username
        avatar {
          _id
          key
          fileName
          format
        }
      }
      favorite {
        _id
      }
      artist_id
      artist {
        _id
        url
        name
        status
      }
      images {
        _id
        key
        fileName
        format
        order
        width
        height
      }
      subcategories {
        _id
        name
      }
      weight {
        value
        dimension
      }
      sizes {
        type
        figure
        diameter
        wh
        dimension
        depth
        height
        width
        free
      }
    }
  }
`;

export const GQL_UPDATE_ARTWORK_PRICE = gql`
  mutation UpdateArtworkPrice($_id: String!, $price: Int, $price_from: Int, $price_to: Int, $currency: String) {
    updateArtworkPrice(_id: $_id, price: $price, price_from: $price_from, price_to: $price_to, currency: $currency)
  }
`;

export const GQL_UPDATE_ARTWORK = gql`
  mutation UpdateArtwork($data: ArtworkInput) {
    updateArtwork(data: $data) {
      ...ArtworkFragment
    }
  }
  ${fragment}
`;

export const GQL_REMOVE_ARTWORK = gql`
  mutation RemoveArtwork($_id: String!) {
    removeArtwork(_id: $_id)
  }
`;

export const GQL_PAY_ARTWORK = gql`
  mutation PayArtwork($_id: String!, $fast: Boolean) {
    payArtwork(_id: $_id, fast: $fast)
  }
`;

export const GQL_PUBLISH_ARTWORK = gql`
  mutation PublishArtwork($_id: String!, $action: String!, $place: String!, $on_trust: Boolean) {
    publishArtwork(_id: $_id, action: $action, place: $place, on_trust: $on_trust)
  }
`;

export const GQL_SIMPLE_PUBLISH_ARTWORK = gql`
  mutation PublishSimpleArtwork($_id: String!) {
    publishSimpleArtwork(_id: $_id)
  }
`;

export const GQL_GET_SELECTION_ARTWORKS = gql`
  query GetArtworkSelection($type: String!) {
    artworkSelection(type: $type) {
      ...ArtworkFragment
    }
  }
  ${fragment}
`;

export const GQL_GET_ARTWORKS_ALL = gql`
  query GetArtworksAll(
  $my: Boolean,
  $all: Boolean,
  $section: String,
  $query: QueryItems,
  $album_id: String,
  $artist_id: String,
  $owner_id: String,
  $only_market: Boolean,
  $online_museum: Boolean
  $artworks: [String]) {
    artworksAll(
      my: $my,
      all: $all,
      query: $query,
      section: $section,
      album_id: $album_id,
      owner_id: $owner_id,
      artist_id: $artist_id,
      artworks: $artworks,
      only_market: $only_market,
      online_museum: $online_museum
    ) {
      ...ArtworkAllFragment
    }
  }
  ${fragmentAll}
`;

export const GQL_GET_ARTWORK = gql`
  query GetArtwork($_id: ID, $url: String, $edit: Boolean, $redirect: Boolean) {
    artwork(_id: $_id, url: $url, edit: $edit, redirect: $redirect) {
      ...ArtworkFragment
    }
  }
  ${fragment}
`;

export const GQL_IMPORT_ARTWORK = gql`
  mutation ImportArtwork($data: ArtworkImport) {
    importArtwork(data: $data)
  }
`;

export const GQL_IMG_ORDER_ARTWORK = gql`
  mutation ChangeImageOrder($images: [InputImage]) {
    changeImageOrder(images: $images)
  }
`;
