import React from 'react';

const qr = ({ colorFill }) => ({
  viewBox: '0 0 18 19',
  data: (
    <g>
      <path
        fill={colorFill}
        d={`M0,16.141c0,1.072,0.873,1.945,1.945,1.945h14.109c1.072,0,1.945-0.873,1.945-1.945V9.613H0V16.141z
              M12.011,14.148h1.673c0.229,0,0.414-0.186,0.414-0.414v-1.673c0-0.697,1.055-0.698,1.055,0v1.673c0,0.811-0.658,1.469-1.469,1.469
              h-1.673C11.312,15.203,11.312,14.148,12.011,14.148z M2.848,12.062c0-0.697,1.055-0.698,1.055,0v1.673
              c0,0.229,0.186,0.414,0.414,0.414h1.673c0.698,0,0.698,1.055,0,1.055H4.316c-0.81,0-1.469-0.658-1.469-1.469V12.062z`}
      />
      <path
        fill={colorFill}
        d={`M16.055,0.086c-3.707,0,8.299,0-14.109,0C0.873,0.086,0,0.959,0,2.031v6.527h18V2.031
              C18,0.959,17.127,0.086,16.055,0.086z M5.989,3.953H4.316c-0.228,0-0.414,0.186-0.414,0.414V6.04c0,0.698-1.055,0.698-1.055,0V4.367
              c0-0.81,0.659-1.469,1.469-1.469h1.673C6.687,2.898,6.687,3.953,5.989,3.953z M15.152,6.04c0,0.698-1.055,0.698-1.055,0V4.367
              c0-0.228-0.186-0.414-0.414-0.414h-1.673c-0.698,0-0.698-1.055,0-1.055h1.673c0.811,0,1.469,0.659,1.469,1.469V6.04z`}
      />
    </g>
  ),
});

export default qr;
