import React from 'react';

const comment = ({ colorFill }) => ({
  viewBox: '0 0 20 20',
  data: (
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={`M5.00117 1.82046C3.23386 1.82046 1.80117 3.25315 1.80117 5.02046V11.0205C1.80117 12.7878 3.23386
        14.2205 5.00117 14.2205C5.443 14.2205 5.80117 14.5786 5.80117 15.0205L5.80117 17.3124L9.28902
        14.4059C9.4328 14.2861 9.61402 14.2205 9.80117 14.2205H15.0012C16.7685 14.2205 18.2012
        12.7878 18.2012 11.0205V5.02046C18.2012 3.25315 16.7685 1.82046 15.0012 1.82046H5.00117ZM0.201172
        5.02046C0.201172 2.36949 2.35021 0.220459 5.00117 0.220459H15.0012C17.6521 0.220459 19.8012 2.36949
        19.8012 5.02046V11.0205C19.8012 13.6714 17.6521 15.8205 15.0012 15.8205H10.0908L5.51332 19.635C5.27485
        19.8338 4.94296 19.8766 4.66185 19.7449C4.38074 19.6133 4.20117 19.3309 4.20117 19.0205L4.20117
        15.7541C1.931 15.3732 0.201172 13.3989 0.201172 11.0205V5.02046Z`}
        fill={colorFill}
      />
      <path
        d={`M6.62617 7.77046C6.62617 8.39178 6.12249 8.89546 5.50117 8.89546C4.87985 8.89546 4.37617 8.39178
        4.37617 7.77046C4.37617 7.14914 4.87985 6.64546 5.50117 6.64546C6.12249 6.64546 6.62617
        7.14914 6.62617 7.77046Z`}
        fill={colorFill}
      />
      <path
        d={`M11.1262 7.77046C11.1262 8.39178 10.6225 8.89546 10.0012 8.89546C9.37985 8.89546 8.87617
        8.39178 8.87617 7.77046C8.87617 7.14914 9.37985 6.64546 10.0012 6.64546C10.6225 6.64546 11.1262
        7.14914 11.1262 7.77046Z`}
        fill={colorFill}
      />
      <path
        d={`M15.6262 7.77046C15.6262 8.39178 15.1225 8.89546 14.5012 8.89546C13.8799 8.89546 13.3762 8.39178
        13.3762 7.77046C13.3762 7.14914 13.8799 6.64546 14.5012 6.64546C15.1225 6.64546 15.6262
        7.14914 15.6262 7.77046Z`}
        fill={colorFill}
      />
    </g>
  ),
});

export default comment;
