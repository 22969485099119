import { gql } from '@apollo/client';

const fragment = gql`
  fragment ArtUploadFragment on ArtUpload {
    _id
  }
`;

export const GQL_REMOVE_UPLOAD = gql`
  mutation RemoveUpload($_id: String!, $kind: String!, $file_type: String!) {
    removeUpload(_id: $_id, kind: $kind, file_type: $file_type) {
      ...ArtUploadFragment
    }
  }
  ${fragment}
`;
