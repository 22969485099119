/* eslint-disable react/no-array-index-key,no-nested-ternary,max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import _ from 'lodash';
import styled from 'styled-components';
import { Field } from 'formik';

import { DIMENSIONS, FIGURES, TYPES } from '../../helpers/atworks';
import { useTranslation } from '../hooks/useTranslation';
import ArtSelect from './ArtSelect';

const ArtDimensions = ({
  form, form: { setFieldValue }, name, push, remove, start,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({});

  const isNumberKey = (e) => {
    const key = _.get(e, 'key', '');
    const value = _.get(e, 'target.value', '');
    if (key.indexOf('.') !== -1) {
      if (value.length === 0) {
        e.preventDefault();
        return false;
      }
      return true;
    }
    if (!key.match(/^\d+(\.\d{0,2})?$/)) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  const onChange = (propertyName, value, isNumber = false) => {
    let newText = null;
    if (value) {
      newText = value.trim();
      if (isNumber) {
        setValues({ ...values, [propertyName]: newText });
        if (newText.match(/^\d+(\.\d+)?$/)) {
          setFieldValue(propertyName, +newText);
        }
      } else {
        setValues({ ...values, [propertyName]: newText });
        setFieldValue(propertyName, newText);
      }
    } else {
      setValues({ ...values, [propertyName]: null });
      setFieldValue(propertyName, null);
    }
  };

  useEffect(() => {
    setValues(_.get(form, 'values'));
  }, [_.get(form, 'values')]);

  let firstIndex = 0;

  if (start && _.get(values, name) && _.isArray(values[name]) && !_.isEmpty(values[name])) {
    values[name].forEach((size, key) => {
      firstIndex = key;
      if (size.free || size.type === 'free') {
        firstIndex = key + 1;
      }
    });
  }

  return (
    <Form.Group controlId={name} className="mb-0">
      {start
        && _.get(values, 'sizeType') === 'specific'
        && _.get(values, `${name}[${firstIndex}].type`) !== 'free' && (
          <SizeItem
            start={start ? 1 : 0}
            key={`artwork${name}-${firstIndex}`}
            exclusive={
              _.get(values, `${name}[${firstIndex}].figure`) === 'circle'
              || _.get(values, `${name}[${firstIndex}].figure`) === 'square'
                ? 1
                : 0
            }
            free={0}
          >
            <Form.Control className="hidden" name={`${name}[${firstIndex}].type`} value="clear" />
            <Field
              component={ArtSelect}
              column
              className="form-control-lg w-100 border-bottom-0"
              groupClassName="mb-0"
              name={`${name}[${firstIndex}].figure`}
              placeholder={t('figure', { ns: 'dimension' })}
              options={Object.keys(FIGURES).map((key) => ({
                label: FIGURES[key],
                value: key,
              }))}
              value={_.get(values, `${name}[${firstIndex}].figure`)}
              onChange={(e) => onChange(`${name}[${firstIndex}].figure`, _.get(e, 'target.value', ''))}
            />
            {_.get(values, `${name}[${firstIndex}].figure`) === 'circle' && (
              <Form.Control
                name={`${name}[${firstIndex}].diameter`}
                placeholder={t('diameter', { ns: 'dimension' })}
                className="form-control form-control-lg"
                value={_.get(values, `${name}[${firstIndex}].diameter`)}
                onKeyPress={isNumberKey}
                onChange={(e) => onChange(`${name}[${firstIndex}].diameter`, _.get(e, 'target.value', ''), true)}
                disabled={_.get(values, 'sizeType') !== 'specific'}
              />
            )}
            {_.get(values, `${name}[${firstIndex}].figure`) === 'square' && (
              <Form.Control
                name={`${name}[${firstIndex}].wh`}
                placeholder={t('wh', { ns: 'dimension' })}
                className="form-control form-control-lg"
                value={_.get(values, `${name}[${firstIndex}].wh`)}
                onKeyPress={isNumberKey}
                onChange={(e) => onChange(`${name}[${firstIndex}].wh`, _.get(e, 'target.value', ''), true)}
                disabled={_.get(values, 'sizeType') !== 'specific'}
              />
            )}
            {_.get(values, `${name}[${firstIndex}].figure`) !== 'circle'
              && _.get(values, `${name}[${firstIndex}].figure`) !== 'square' && (
                <>
                  <Form.Control
                    name={`${name}[${firstIndex}].height`}
                    placeholder={t('height', { ns: 'dimension' })}
                    className="form-control form-control-lg"
                    value={_.get(values, `${name}[${firstIndex}].height`)}
                    onKeyPress={isNumberKey}
                    onChange={(e) => onChange(`${name}[${firstIndex}].height`, _.get(e, 'target.value', ''), true)}
                    disabled={_.get(values, 'sizeType') !== 'specific'}
                  />
                  <Form.Control
                    name={`${name}[${firstIndex}].width`}
                    placeholder={t('width', { ns: 'dimension' })}
                    className="form-control form-control-lg"
                    value={_.get(values, `${name}[${firstIndex}].width`)}
                    onKeyPress={isNumberKey}
                    onChange={(e) => onChange(`${name}[${firstIndex}].width`, _.get(e, 'target.value', ''), true)}
                    disabled={_.get(values, 'sizeType') !== 'specific'}
                  />
                </>
            )}
            <Form.Control
              name={`${name}[${firstIndex}].depth`}
              placeholder={t('depth', { ns: 'dimension' })}
              className="form-control form-control-lg"
              value={_.get(values, `${name}[${firstIndex}].depth`)}
              onKeyPress={isNumberKey}
              onChange={(e) => onChange(`${name}[${firstIndex}].depth`, _.get(e, 'target.value', ''), true)}
              disabled={_.get(values, 'sizeType') !== 'specific'}
            />
            <Field
              component={ArtSelect}
              column
              className="form-control-lg w-100 border-bottom-0"
              groupClassName="mb-0"
              name={`${name}[${firstIndex}].dimension`}
              placeholder={t('short', { ns: 'dimension' })}
              options={Object.keys(DIMENSIONS).map((key) => ({
                label: DIMENSIONS[key],
                value: key,
              }))}
              value={_.get(values, `${name}[${firstIndex}].dimension`, 'cm')}
              onChange={(e) => onChange(`${name}[${firstIndex}].dimension`, _.get(e, 'target.value', ''))}
            />
          </SizeItem>
      )}
      {!start
        && _.get(values, 'sizeType', 'specific') === 'specific'
        && _.get(values, `${name}`, []).map((item, index) => (
          <SizeArea key={`artwork${name}-${index}`}>
            <TopSelectors free={_.get(values, `${name}[${index}].type`) === 'free' ? 1 : 0}>
              <Field
                component={ArtSelect}
                column
                className="form-control-lg w-100 border-bottom-0"
                groupClassName="mb-0"
                name={`${name}[${index}].type`}
                placeholder={t('type', { ns: 'dimension' })}
                options={Object.keys(TYPES).map((key) => ({
                  label: TYPES[key],
                  value: key,
                }))}
                value={_.get(values, `${name}[${index}].dimension`)}
                onChange={(e) => onChange(`${name}[${index}].dimension`, _.get(e, 'target.value', ''))}
              />
              {_.get(values, `${name}[${index}].type`) !== 'free' && (
                <Field
                  component={ArtSelect}
                  column
                  className="form-control-lg w-100 border-bottom-0"
                  groupClassName="mb-0"
                  name={`${name}[${index}].figure`}
                  placeholder={t('figure', { ns: 'dimension' })}
                  options={Object.keys(FIGURES).map((key) => ({
                    label: FIGURES[key],
                    value: key,
                  }))}
                  value={_.get(values, `${name}[${index}].figure`)}
                  onChange={(e) => onChange(`${name}[${index}].figure`, _.get(e, 'target.value', ''))}
                />
              )}
            </TopSelectors>
            <SizeItem
              exclusive={
                _.get(values, `${name}[${index}].figure`) === 'circle'
                || _.get(values, `${name}[${index}].figure`) === 'square'
                  ? 1
                  : 0
              }
              index={_.get(values, `${name}`, []).length - index}
              free={_.get(values, `${name}[${index}].type`) === 'free' ? 1 : 0}
            >
              {_.get(values, `${name}[${index}].type`) === 'free' && (
                <Form.Control
                  name={`${name}[${index}].free`}
                  placeholder={t('free', { ns: 'dimension' })}
                  className="form-control form-control-lg"
                  value={_.get(values, `${name}[${index}].free`)}
                  onChange={(e) => onChange(`${name}[${index}].free`, _.get(e, 'target.value', ''))}
                />
              )}
              {_.get(values, `${name}[${index}].figure`) === 'circle'
                && _.get(values, `${name}[${index}].type`) !== 'free' && (
                  <Form.Control
                    name={`${name}[${index}].diameter`}
                    placeholder={t('diameter', { ns: 'dimension' })}
                    className="form-control form-control-lg"
                    value={_.get(values, `${name}[${index}].diameter`)}
                    onKeyPress={isNumberKey}
                    onChange={(e) => onChange(
                      `${name}[${firstIndex}].diameter`,
                      _.get(e, 'target.value', ''),
                      true,
                    )}
                    disabled={_.get(values, 'sizeType') !== 'specific'}
                  />
              )}
              {_.get(values, `${name}[${index}].figure`) === 'square'
                && _.get(values, `${name}[${index}].type`) !== 'free' && (
                  <Form.Control
                    name={`${name}[${index}].wh`}
                    placeholder={t('wh', { ns: 'dimension' })}
                    className="form-control form-control-lg"
                    value={_.get(values, `${name}[${index}].wh`)}
                    onKeyPress={isNumberKey}
                    onChange={(e) => onChange(`${name}[${firstIndex}].wh`, _.get(e, 'target.value', ''), true)}
                    disabled={_.get(values, 'sizeType') !== 'specific'}
                  />
              )}
              {_.get(values, `${name}[${index}].figure`) !== 'circle'
                && _.get(values, `${name}[${index}].figure`) !== 'square'
                && _.get(values, `${name}[${index}].type`) !== 'free' && (
                  <>
                    <Form.Control
                      name={`${name}[${index}].height`}
                      placeholder={t('height', { ns: 'dimension' })}
                      className="form-control form-control-lg"
                      value={_.get(values, `${name}[${index}].height`)}
                      onKeyPress={isNumberKey}
                      onChange={(e) => onChange(
                        `${name}[${firstIndex}].height`,
                        _.get(e, 'target.value', ''),
                        true,
                      )}
                      disabled={_.get(values, 'sizeType') !== 'specific'}
                    />
                    <Form.Control
                      name={`${name}[${index}].width`}
                      placeholder={t('width', { ns: 'dimension' })}
                      className="form-control form-control-lg"
                      value={_.get(values, `${name}[${index}].width`)}
                      onKeyPress={isNumberKey}
                      onChange={(e) => onChange(`${name}[${firstIndex}].width`, _.get(e, 'target.value', ''), true)}
                      disabled={_.get(values, 'sizeType') !== 'specific'}
                    />
                  </>
              )}
              {_.get(values, `${name}[${index}].type`) !== 'free' && (
                <>
                  <Form.Control
                    name={`${name}[${index}].depth`}
                    placeholder={t('depth', { ns: 'dimension' })}
                    className="form-control form-control-lg"
                    value={_.get(values, `${name}[${index}].depth`)}
                    onKeyPress={isNumberKey}
                    onChange={(e) => onChange(`${name}[${firstIndex}].depth`, _.get(e, 'target.value', ''), true)}
                    disabled={_.get(values, 'sizeType') !== 'specific'}
                  />
                  <Field
                    component={ArtSelect}
                    column
                    className="form-control-lg w-100 border-bottom-0"
                    groupClassName="mb-0"
                    name={`${name}[${index}].dimension`}
                    placeholder={t('name', { ns: 'dimension' })}
                    options={Object.keys(DIMENSIONS).map((key) => ({
                      label: DIMENSIONS[key],
                      value: key,
                    }))}
                    value={_.get(values, `${name}[${index}].dimension`, 'cm')}
                    onChange={(e) => onChange(`${name}[${index}].dimension`, _.get(e, 'target.value', ''))}
                  />
                </>
              )}
              <Remove onClick={() => remove(index)}>&times;</Remove>
            </SizeItem>
          </SizeArea>
        ))}

      <div className="d-flex flex-column mt-4 flex-md-row justify-content-between align-items-center">
        {!start && (
          <Button
            variant="outline-primary"
            size="12"
            disabled={_.get(values, 'sizeType', 'specific') !== 'specific'}
            className="col-md-auto"
            onClick={() => push({ type: 'clear' })}
          >
            {t('add_type', { ns: 'dimension' })}
          </Button>
        )}
        <div className="d-flex mt-5 mt-md-0">
          <Form.Check
            type="checkbox"
            name="sizeType"
            id="sizeType-checkbox"
            label={t('flexible', { ns: 'dimension' })}
            value={_.get(values, 'sizeType')}
            checked={_.get(values, 'sizeType', 'specific') === 'flexible'}
            onChange={() => onChange(
              'sizeType',
              _.get(values, 'sizeType', 'specific') === 'flexible' ? 'specific' : 'flexible',
            )}
          />
          <Form.Check
            type="checkbox"
            name="sizeType"
            className="ms-5"
            id="sizeTypeDisabled-checkbox"
            label={t('disabled', { ns: 'dimension' })}
            value={_.get(values, 'sizeType')}
            onChange={() => onChange(
              'sizeType',
              _.get(values, 'sizeType', 'specific') === 'disabled' ? 'specific' : 'disabled',
            )}
            checked={_.get(values, 'sizeType') === 'disabled'}
          />
        </div>
      </div>
    </Form.Group>
  );
};

const Remove = styled.button`
  font-size: 40px;
  font-weight: 100;
  padding: 0;
  margin: 0;
`;

const TopSelectors = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 10px;
  grid-template-columns: ${({ free }) => (free ? '1fr' : '1fr 1fr')};
  @media screen and (max-width: 420px) {
    grid-template-columns: 1fr;
  }
`;

const SizeArea = styled.div`
  margin-top: 40px;
  display: block;
`;

const SizeItem = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 10px;
  grid-template-columns: ${({ free, start, exclusive }) => {
    if (free) {
      return '1fr 0.1fr';
    }
    if (start) {
      return '1fr 1fr 1fr 1fr 0.5fr';
    }
    if (exclusive) {
      return '0.7fr 0.7fr 0.7fr 0.5fr';
    }
    return '0.7fr 0.7fr 0.7fr 0.7fr 0.5fr';
  }};
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  z-index: ${({ index }) => index + 1};
  @media screen and (max-width: 812px) {
    grid-template-columns: 1fr;
  }
`;

ArtDimensions.defaultProps = {
  name: '',
  start: false,
};

ArtDimensions.propTypes = {
  form: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  name: PropTypes.string,
  start: PropTypes.bool,
};

export default ArtDimensions;
