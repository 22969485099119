export default {
  name: 'Еденица',
  figure: 'Форма работы',
  wh: 'ВШ',
  diameter: 'Д',
  height: 'В',
  width: 'Ш',
  depth: 'Г',
  short: 'Ед',
  type: 'Выберите тип',
  free: 'Свободный размер',
  add_type: 'Добавить тип размера',
  flexible: 'Варьируются',
  disabled: 'Без размера',
};
