import React from 'react';

const contact = ({ colorFill }) => ({
  viewBox: '0 0 20 20',
  data: (
    <g>
      <circle
        fill="none"
        stroke={colorFill}
        strokeWidth="2"
        strokeMiterlimit="10"
        cx="9.062"
        cy="9.271"
        r="7.938"
      />
      <circle
        fill="none"
        stroke={colorFill}
        strokeWidth="2"
        strokeMiterlimit="10"
        cx="9.084"
        cy="9.126"
        r="4.167"
      />
    </g>
  ),
});

export default contact;
