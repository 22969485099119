import React from 'react';

const arrowOut = ({ colorFill }) => ({
  viewBox: '0 0 13 12',
  data: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={`M2.91339 0.180104L11.4944 1.0382C12.021 1.09086 12.4052 1.56042 
        12.3525 2.08699L11.4944 10.668C11.4417 11.1946 10.9722 11.5788 
        10.4456 11.5261C9.91904 11.4734 9.53486 11.0039 9.58751 10.4773L10.2154 
        4.19813L1.46562 11.3571L0.25208 9.87385L9.00189 2.71491L2.7227 
        2.08699C2.19613 2.03434 1.81195 1.56478 1.8646 1.0382C1.91726 
        0.511631 2.38682 0.127446 2.91339 0.180104Z`}
        fill={colorFill}
      />
  ),
});

export default arrowOut;
