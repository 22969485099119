import { gql } from '@apollo/client';

const lectureFragment = gql`
  fragment LectureFragment on Lecture {
    _id
    url
    name
    anons
    description
    read
    lecturers {
      _id
      username
      name
    }
    education {
      _id
      name
      url
      lectures {
        _id
        url
        started_at
        ended_at
      }
    }
    video_file {
      _id
      fileName
      format
      key
    }
    files {
      fileName
      format
      key
    }
    video {
      name
      description
    }
    cover {
      key
    }
  }
`;

export const GQL_GET_LECTURE = gql`
  query GetLecture($_id: ID, $url: String) {
    lecture(_id: $_id, url: $url) {
      ...LectureFragment
    }
  }
  ${lectureFragment}
`;

export const GQL_LECTURE_READ = gql`
  mutation SetLectureRead($lecture_id: String!, $education_id: String!) {
    lectureRead(education_id: $education_id, lecture_id: $lecture_id)
  }
`;
