export default {
  title: 'АРТОКРАТИЯ - ВХОД',
  name: 'Авторизация',
  info: 'Заполните поля для входа в личный кабинет',
  username: 'Почта - логин',
  username_placeholder: 'Введите почту или логин',
  password: 'Пароль',
  password_placeholder: 'Введите пароль',
  login_btn: 'Войти',
  forgot_password: 'Забыли пароль?',
  registration: 'Зарегистрироваться',
  error_username: 'Проверьте логин или email',
  error_password: 'Проверьте пароль',
  no_account: 'Нет аккаунта?',
};
