/* eslint-disable max-len */
import React from 'react';

const vk = ({ colorFill }) => ({
  viewBox: '0 0 20 12',
  data: (
    <path
      fill={colorFill}
      d={`M17.2926 11.247H15.3274C14.5837 11.247 14.3538 10.6651 13.0174 9.33267C11.8533 8.22418
            11.3384 8.07454 11.0521 8.07454C10.651 8.07454 10.536 8.1876 10.536 8.73187V10.4777C10.536
            10.9477 10.3839 11.2293 9.1241 11.2293C7.04394 11.2293 4.73389 9.99001 3.11236 7.67992C0.669348
            4.30123 0 1.76611 0 1.24069C0 0.959133 0.114939 0.696421 0.668222 0.696421H2.63345C3.12926 0.696421
            3.32083 0.921444 3.51239 1.44687C4.48486 4.20701 6.10753 6.62907 6.77575 6.62907C7.02365 6.62907
            7.13859 6.516 7.13859 5.89746V3.04199C7.06197 1.72732 6.35543 1.61536 6.35543 1.14647C6.35543
            0.920336 6.547 0.695312 6.85125 0.695312H9.94332C10.3636 0.695312 10.5158 0.920336 10.5158
            1.40807V5.25786C10.5158 5.67022 10.7073 5.82098 10.8211 5.82098C11.069 5.82098 11.2798
            5.67022 11.7373 5.22017C13.1503 3.66163 14.1611 1.25843 14.1611 1.25843C14.2952 0.97687
            14.524 0.714156 15.0209 0.714156H16.9861C17.5777 0.714156 17.7118 1.01345 17.5777 1.42692C17.3298
            2.55425 14.9251 5.89524 14.9251 5.89524C14.7155 6.23333 14.6389 6.38298 14.9251 6.75987C15.1347
            7.04142 15.8221 7.62338 16.2807 8.14881C17.1202 9.0877 17.7682 9.87584 17.9406 10.4212C18.1321
            10.9644 17.8459 11.247 17.2926 11.247Z`}
    />
  ),
});

export default vk;
