export default {
  title: '{{ name }} - {{ role }} на Артократии{{ path }}',
  description: '{{ name }} - {{ role }}{{ path }} | Пользователь социальной сети для ценителей искусства Артократия',
  profile_label: 'Данные профиля',
  profile_companies: 'Компании',
  share_label: 'Поделиться карточкой',
  share_description: 'Карточка пользователя {{ name }} в социальной сети для ценителей искусства - Артократия',
  go_to_collection: 'Перейти к странице пользователя',
  wright_message: 'Написать',
  about: 'О себе',
  contacts: 'Контакты',
  contacts_placeholder: 'Введите контакт',
  add_contact: 'Добавить контакт',
  cant_contact: 'Пользователь закрыл возможность общения',
  your_card: 'Это ваша карточка',
  as_artist: 'Пользователь представляет художника',
  verified: 'Учётная запись подтверждена',
  can_bye: 'Можно купить',
  in_collections: 'В коллекции',
  art_all: 'Все работы',
  friends: 'Друзья',
  subscribers: 'Подписчики',
  in_favorites: 'В избранном',
  in_favorite: 'В избранное',
  my_favorites: 'Моё избранное',
  my_locations: 'Мои локации',
  more: 'Ещё',
  share: 'Поделиться',
  block: 'Заблокировать',
  abuse: 'Пожаловаться',
  only_register: 'Просмотр только для зарегистрированных пользователей',
  edit: 'Редактировать',
  information: 'информацию',
  look_like_user: 'Посмотреть как пользователь',
  look_like_guest: 'Посмотреть как гость',
  back_edit: 'Вернуться к редактированию',
  posts: 'Публикации',
  events: 'События',
  about_him: 'О пользователе',
  about_info: 'Инфо',
  field_collection_info: 'Заполнить информацию о коллекции',
  show_all: 'Показать полностью',
  user_empty: 'Нет информации',
  was: 'Последний визит',
  online: 'Онлайн',
};
