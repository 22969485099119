import React from 'react';

const ok = () => ({
  viewBox: '0 0 15 18',
  data: (
    <path
      d={`M13.202,11.882C12.228,12.5,10.887,12.745,10,12.838l0.745,0.732l2.713,2.715c0.993,1.018-0.622,
            2.607-1.625,1.625 c-0.681-0.689-1.677-1.686-2.714-2.725l-2.714,2.722c-1.003,
            0.979-2.617-0.626-1.615-1.626c0.69-0.69,1.675-1.687,2.714-2.714 l0.733-0.732C7.361,
            12.741,6,12.511,5.014,11.877c-1.16-0.746-1.668-1.184-1.22-2.099C4.057,9.26,4.779,8.822,
            5.735,9.576 c0,0,1.292,1.028,3.376,1.028c2.084,0,3.376-1.028,3.376-1.028c0.956-0.751,1.676-0.316,
            1.942,0.202 C14.871,10.693,14.362,11.131,13.202,11.882z M4.495,4.613C4.495,2.063,6.567,0,9.108,
            0c2.54,0,4.614,2.063,4.614,4.613 c0,2.541-2.074,4.603-4.614,4.603C6.567,9.216,4.495,7.154,4.495,
            4.613L4.495,4.613z M6.842,4.613c0,1.249,1.017,2.267,2.266,2.267 s2.266-1.018,
            2.266-2.267c0-1.258-1.018-2.276-2.266-2.276S6.842,3.355,6.842,4.613z`}
    />
  ),
});

export default ok;
