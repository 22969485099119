import React from 'react';

const message = ({ colorFill, colorStroke }) => ({
  viewBox: '0 0 25 25',
  data: (
    <g>
      <path
        d={`M23.8453 12.3611C23.8499 14.1253 23.4377 15.8655 22.6423 17.4402C21.6993
        19.3271 20.2495 20.9143 18.4554 22.0238C16.6612 23.1333 14.5937 23.7214
        12.4842 23.7222C10.72 23.7268 8.97977 23.3147 7.4051 22.5193L1.12312
        23.7222L2.32602 17.4402C1.53066 15.8655 1.11849 14.1253 1.12308
        12.3611C1.1239 10.2517 1.71201 8.18406 2.82153 6.38994C3.93105
        4.59582 5.51816 3.14604 7.4051 2.20298C8.97977 1.40761 10.72
        0.995439 12.4842 1.00004H13.1525C15.9384 1.15374 18.5698 2.32964
        20.5427 4.30259C22.5157 6.27554 23.6916 8.90689 23.8453 11.6928V12.3611Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="6"
        y="11"
        width="3"
        height="3"
        rx="1.5"
        fill={colorFill}
      />
      <rect
        x="11.2305"
        y="11"
        width="3"
        height="3"
        rx="1.5"
        fill={colorFill}
      />
      <rect
        x="16.4609"
        y="11"
        width="3"
        height="3"
        rx="1.5"
        fill={colorFill}
      />
    </g>
  ),
});

export default message;
