import React from 'react';

const wa = ({ colorFill }) => ({
  viewBox: '0 0 20 20',
  data: (
    <path
      fill={colorFill}
      d={`M15.311,3.883c-1.655-1.658-3.857-2.571-6.2-2.571c-4.832,0-8.765,3.933-8.765,8.765c0,1.545,0.403,
            3.052,1.169,4.383 L0.271,19l4.646-1.221c1.28,0.699,2.72,1.066,4.188,1.066H9.11c4.83,0,8.849-3.932,
            8.849-8.764C17.959,7.739,16.963,5.542,15.311,3.883z M9.11,17.37c-1.31,
            0-2.594-0.353-3.711-1.016l-0.265-0.156L2.38,16.92l0.734-2.689l-0.174-0.276c-0.73-1.161-1.112-2.5-1.112-3.877c0-4.015,
            3.269-7.284,7.288-7.284c1.946,0,3.773,0.757,5.147,2.135c1.373,1.379,2.22,3.207,2.214,5.153C16.477,14.1,
            13.126,17.37,9.11,17.37z M13.105,11.914c-0.217-0.111-1.295-0.641-1.497-0.711c-0.198-0.075-0.345-0.111-0.492,
            0.111c-0.146,0.219-0.564,0.709-0.693,0.86c-0.127,0.145-0.257,0.164-0.475,
            0.054c-1.287-0.643-2.132-1.148-2.981-2.604C6.742,9.236,7.192,9.264,7.61,8.428c0.071-0.147,
            0.036-0.274-0.02-0.383C7.536,7.934,7.097,6.855,6.916,6.418C6.738,5.99,6.557,6.05,6.421,6.042C6.296,
            6.034,6.149,6.034,6.004,6.034c-0.147,0-0.383,0.056-0.584,0.273c-0.202,0.22-0.766,0.75-0.766,1.828c0,
            1.077,0.785,2.121,0.891,2.266c0.111,0.146,1.544,2.357,3.743,3.31c1.39,0.598,1.935,0.65,
            2.628,0.548c0.424-0.063,1.296-0.529,1.478-1.043c0.181-0.513,0.181-0.951,0.126-1.041C13.469,
            12.074,13.324,12.02,13.105,11.914z`}
    />
  ),
});

export default wa;
