export default {
  no_dialogs: 'У вас пока нет диалогов',
  not_delete: 'Это действие не удаляет диалог полностью',
  info_delete: `Он будет удален полностью в случае, если каждый участник сделает это. 
  Для восстановления, собеседнику или Вам достаточно начать переписку`,
  no_messages: 'Нет сообщений',
  sure_delete: 'Вы действительно хотите удалить это сообщение?',
  enter_message: 'Введите сообщение',
  error: 'Произошла ошибка. Повторите попытку позже',
  loading: 'Загрузка чата...',
  my: 'КАБИНЕТ - МОИ СООБЩЕНИЯ',
  name: 'Чаты',
  find_friends: 'Найти друзей',
  find_chats: 'Искать по чатам',
  not_found: 'Ничего не найдено',
  administrator: 'Связаться с администрацией',
  hello: 'Здравствуйте',
  select_dialog: 'Выберите в левой части диалог, чтобы начать общение',
  start_dialog: 'Начните диалог с {{ name }}',
  files: 'Файлы',
  hello_to_bye: 'Здравствуйте, {{ name }}! Меня заинтересовала Ваша работа {{ link }}',
  was: 'Последний визит',
  was_read: 'Прочитано',
  not_read: 'Доставлено',
  guest: 'Гость',
  need_text: 'Необходимо ввести текст',
  need_reload: 'Перезагрузите страницу',
  can_help: 'Если у вас возникли какие либо вопросы напишите нам в чат. Мы постараемся помочь.',
  can_messenger: 'Также вы можете воспользоваться нашим чатами в мессенджерах',
  chat_name: 'Чат с поддержкой',
  you_right: 'Вы пишите',
  support_right: 'Поддержка',
  send: 'Отправить',
  input_message: 'Введите сообщенние',
};
