import React from 'react';

const login = ({ colorStroke }) => ({
  viewBox: '0 0 20 23',
  data: (
    <>
      <path
        d={`M18.3334 21.1958V19.0225C18.3334 17.8696
        17.8768 16.7641 17.0642 15.9489C16.2515 15.1337
        15.1493 14.6758 14 14.6758H5.33334C4.18407
        14.6758 3.08187 15.1337 2.26921 15.9489C1.45655
        16.7641 1 17.8696 1 19.0225V21.1958`}
        stroke={colorStroke}
        strokeWidth="2"
        fill="transparent"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={`M9.6673 10.3301C12.0605 10.3301 14.0006
        8.38399 14.0006 5.98339C14.0006 3.58279 12.0605
        1.63672 9.6673 1.63672C7.27407 1.63672 5.33398
        3.58279 5.33398 5.98339C5.33398 8.38399 7.27407
        10.3301 9.6673 10.3301Z`}
        stroke={colorStroke}
        strokeWidth="2"
        fill="transparent"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export default login;
