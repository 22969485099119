import React from 'react';

const starNormal = ({ colorStroke, colorFill }) => ({
  viewBox: '0 0 16 16',
  data: (
    <path
      d={`M8.00065 1.3335L10.0607 5.50683L14.6673 6.18016L11.334 
        9.42683L12.1207 14.0135L8.00065 11.8468L3.88065 14.0135L4.66732 
        9.42683L1.33398 6.18016L5.94065 5.50683L8.00065 1.3335Z`}
      stroke={colorStroke}
      fill={colorFill}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});

export default starNormal;
