/* eslint-disable no-param-reassign,react/prop-types */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { components as ComponentSelect } from 'react-select';
import Creatable from 'react-select/creatable';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import styled from 'styled-components';

import {
  ACTIVE,
  ACTIVE_LIGHT,
  DARK,
  DEFAULT_EXTRA,
  MAIN_GRAY,
  WARNING,
  WARNING_LIGHT,
} from '../../helpers/colors';
import { useTranslation } from '../hooks/useTranslation.jsx';
import ListWidget from '../ListWidget';
import {
  REG_ALL_CAPITALIZE,
  REG_FIRST_CAPITALIZE,
  REG_LOWCASE,
  REG_NORMAL,
  REG_UPPERCASE,
} from '../../helpers/main';

function arrayMove(array, from, to) {
  const arr = array.slice();
  arr.splice(to < 0 ? arr.length + to : to, 0, arr.splice(from, 1)[0]);
  return arr;
}

const SortableMultiValue = SortableElement((props) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };
  return <ComponentSelect.MultiValue {...props} innerProps={innerProps} />;
});
const SortableSelect = SortableContainer(Creatable);

const MultiSelectSort = ({
  field,
  form: { touched, errors, setFieldValue },
  noOptionsMessage,
  loadingMessage,
  label,
  placeholder,
  disabled,
  isLoading,
  groupClassName,
  prepend,
  className,
  fieldAs,
  column,
  options,
  isClearable,
  onCreateOption,
  onInputChange,
  loadOptions,
  listWidget,
  register,
}) => {
  const { t } = useTranslation();
  if (!noOptionsMessage) {
    noOptionsMessage = t('no_variants', { ns: 'action' });
  }
  if (!loadingMessage) {
    loadingMessage = t('loading', { ns: 'index' });
  }
  const value = [];
  const [selected, setSelected] = useState(value);

  if (_.isArray(options) && _.isArray(field.value)) {
    // We know that can do simple but this need it for sorting
    options.forEach((op) => {
      field.value.forEach((fv, i) => {
        if (fv === op.value) {
          value[i] = op;
        }
      });
    });
  }

  useEffect(() => {
    setSelected(value);
  }, [value.length]);

  const CustomOption = (props) => {
    const { isSelected, innerProps, data } = props;
    const type = !!data.type && data.type === 'user';
    let color = (isSelected ? '#FFFFFF' : false);
    if (typeof data.sts !== 'undefined') {
      color = data.sts ? color : DEFAULT_EXTRA;
    }
    return (
      <Option
        {...props}
        style={{
          backgroundColor: (isSelected ? ACTIVE_LIGHT : false),
          color,
        }}
        {...innerProps}
      >
        {!!data.image && (
          <SelectImage
            rounded={type}
            src={`/files/${data.image}`}
          />
        )}
        {data.label}
      </Option>
    );
  };

  const MultiValueLabel = (props) => {
    const { data } = props;
    if (typeof data.sts !== 'undefined' && !data.sts) {
      return (
        <span className="moderation" title={t('moderation', { ns: 'action' })}>
          <ComponentSelect.MultiValueLabel {...props} />
        </span>
      );
    }
    return (<ComponentSelect.MultiValueLabel {...props} />);
  };

  const onChange = (selectedOptions) => {
    setSelected(selectedOptions);
    if (_.isArray(selectedOptions) && !_.isEmpty(selectedOptions)) {
      const prepareValue = [];
      selectedOptions.forEach((opt) => prepareValue.push(opt.value));
      setFieldValue(field.name, prepareValue);
    } else {
      setFieldValue(field.name, []);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex);
    setSelected(newValue);
    if (_.isArray(newValue) && !_.isEmpty(newValue)) {
      const prepareValue = [];
      newValue.forEach((opt) => prepareValue.push(opt.value));
      setFieldValue(field.name, prepareValue);
    } else {
      setFieldValue(field.name, []);
    }
  };

  let transform = REG_NORMAL;
  let isFirstCapital = 0;

  switch (register) {
    case REG_LOWCASE:
      transform = REG_LOWCASE;
      break;
    case REG_UPPERCASE:
      transform = REG_UPPERCASE;
      break;
    case REG_FIRST_CAPITALIZE:
      transform = REG_NORMAL;
      isFirstCapital = 1;
      break;
    case REG_ALL_CAPITALIZE:
      transform = REG_ALL_CAPITALIZE;
      break;
    default:
      transform = REG_NORMAL;
      break;
  }

  const getColor = (data, single = false) => {
    let bg = '#FFFFFF';
    let color = single ? 'inherit' : `${ACTIVE} !important`;
    let border = single ? 0 : `1px solid ${ACTIVE}`;
    if (typeof data.sts !== 'undefined') {
      bg = data.sts ? bg : WARNING_LIGHT;
      color = data.sts ? color : `${WARNING} !important`;
      border = data.sts ? border : `1px solid ${WARNING}`;
    }
    return { bg, color, border };
  };

  const colourStyles = {
    control: (provided, state) => ({
      borderBottom: state.isFocused ? `1px solid ${ACTIVE}` : `1px solid ${MAIN_GRAY}`,
      borderRadius: 0,
      fontSize: 20,
      fontWeight: 300,
      marginTop: 0,
      marginRight: 10,
      minWidth: 60,
      padding: '0 0 13px',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
        padding: '0 0 3px',
      },
    }),
    multiValueRemove: (base) => ({
      ...base,
      backgroundColor: '#FFFFFF',
      border: 0,
      '&:hover, &:focus': {
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        border: 0,
        color: '#000',
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      fontWeight: 400,
      fontSize: 20,
      padding: 0,
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
      },
    }),
    option: (provided, state) => ({
      color: state.isSelected ? '#FFFFFF' : DARK,
      background: state.isSelected ? ACTIVE_LIGHT : MAIN_GRAY,
      fontSize: 20,
      padding: '25px 6px',
      textTransform: transform,
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
      textTransform: transform,
    }),
    multiValue: (styles, { data }) => ({
      ...styles,
      fontSize: 20,
      backgroundColor: '#FFFFFF',
      border: getColor(data).border,
      borderRadius: 2,
      color: getColor(data).color,
      padding: 0,
      fontWeight: 400,
      textTransform: transform,
      ' .moderation > div': {
        color: getColor(data).color,
      },
      cursor: 'grab',
      '&:active': {
        cursor: 'grabbing',
      },
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
      },
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      padding: '0 3px',
      fontWeight: 400,
      fontSize: 20,
      borderRadius: 4,
      color: getColor(data, true).color,
      textTransform: transform,
      '> .moderation > div': {
        color: getColor(data, true).color,
      },
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
      },
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      padding: 0,
      fontSize: 20,
      fontWeight: 400,
      color: ACTIVE,
      textTransform: transform,
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      fontWeight: 300,
      fontSize: 20,
      color: '#B8BFCF',
      position: 'absolute',
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
      },
    }),
    indicatorSeparator: () => null,
    dropdownIndicator: (provided, state) => ({
      color: state.isFocused ? ACTIVE : DARK,
    }),
  };

  const components = listWidget ? {
    Option: CustomOption,
    MenuList: ListWidget,
    MultiValue: SortableMultiValue,
    MultiValueLabel,
  } : {
    Option: CustomOption,
    MultiValueLabel,
    MultiValue: SortableMultiValue,
  };

  const customFilter = (option, searchText) => {
    let data = _.get(option, 'label') || _.get(option, 'name') || _.get(option, 'text');
    if (typeof data === 'string' && searchText) {
      data = data.trimStart();
      const query = searchText.split(' ');
      if (query.length > 1) {
        const reverse = searchText.toLowerCase().split(' ').reverse().join(' ');
        return data.toLowerCase().includes(searchText.toLowerCase())
          || data.toLowerCase().includes(reverse)
          || (data.toLowerCase().includes(_.get(query, '[0]').toLowerCase())
            && data.toLowerCase().includes(_.get(query, '[1]').toLowerCase()));
      }
      return data.toLowerCase().includes(searchText.toLowerCase());
    }
    return true;
  };

  return (
    <Form.Group
      as={Row}
      controlId={field.name}
      className={`${column ? 'flex-column' : 'flex-row'} ${groupClassName}`}
    >
      {!!label && (
        <Form.Label
          column
          md={column ? 12 : 3}
        >
          {label}
        </Form.Label>
      )}

      <ColStyle transform={isFirstCapital} md={label && !column ? 9 : 12}>
        <InputGroup className={disabled ? 'disabled' : ''}>
          {!!prepend && (
            <InputGroup>
              <InputGroup.Text>
                {prepend}
              </InputGroup.Text>
            </InputGroup>
          )}
          <SortableSelect
            axis="xy"
            onSortEnd={onSortEnd}
            distance={4}
            getHelperDimensions={({ node }) => node.getBoundingClientRect()}
            value={selected}
            onChange={onChange}
            closeMenuOnSelect={false}
            onInputChange={onInputChange}
            components={components}
            placeholder={placeholder}
            loadOptions={loadOptions}
            isClearable={isClearable}
            disabled={disabled}
            isLoading={isLoading}
            isDisabled={isLoading || disabled}
            name={field.name}
            options={options}
            className={`${className} multi ${disabled && 'disabled'}`}
            as={fieldAs}
            isInvalid={touched[field.name] && errors[field.name]}
            isMulti
            filterOption={customFilter}
            noOptionsMessage={() => noOptionsMessage}
            loadingMessage={() => loadingMessage}
            onCreateOption={onCreateOption}
            formatCreateLabel={(inputText) => (
              <Add>
                <Plus />
                {t('add', { ns: 'action' })}
                {' "'}
                {inputText}
                {'"?'}
              </Add>
            )}
            isValidNewOption={(inputOption) => !!inputOption}
            styles={colourStyles}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#D7E3EB',
                primary: ACTIVE,
              },
            })}
          />
          <Form.Control.Feedback type="invalid">
            {errors[field.name]}
          </Form.Control.Feedback>
        </InputGroup>
      </ColStyle>
    </Form.Group>
  );
};

const ColStyle = styled(Col)`
${({ transform }) => transform && (`
div[type="option"] {
    display: block;
}
div {
  text-transform:lowercase;
  &:first-letter {text-transform:uppercase}
}
`)}
`;

const Add = styled.div`
display: flex;
`;

const Plus = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 30px;
height: 30px;
border-radius: 50%;
background-color: ${MAIN_GRAY};
margin-right: 10px;
&:before, &:after {
  z-index: 2;
  content: " ";
  position: relative;
  display: block;
  width: 2.5px;
  height: 10px;
  background-color: ${ACTIVE};
  border-radius: 3px;
}
&:before {
  width: 2.5px;
  height: 10px;
  right: -5px;
}
&:after {
  height: 2.5px;
  width: 10px;
  left: -1px;
}
`;

const Option = styled.div`
color: inherit;
cursor: default;
display: flex;
flex-direction: row;
height: 40px;
font-size: 20px;
align-items: center;
padding: 4px 12px;
width: 100%;
user-select: none;
-webkit-tap-highlight-color: rgba(0,0,0,0);
box-sizing: border-box;
&:hover{
   background-color: #D7E3EB;
}
&:focus{
   background-color: ${ACTIVE_LIGHT};
}
`;

const SelectImage = styled.img`
background-position: center;
background-repeat: no-repeat;
border-radius: ${(props) => (props.rounded ? '50%' : '0')};
position: relative;
z-index: 100;
margin-right: 10px;
width: 30px;
height: 30px;
`;

MultiSelectSort.defaultProps = {
  register: REG_NORMAL,
  noOptionsMessage: '',
  loadingMessage: '',
  disabled: false,
  label: '',
  isLoading: false,
  groupClassName: '',
  prepend: '',
  className: 'w-100 select-control',
  onCreateOption: () => {},
  onInputChange: () => {},
  loadOptions: () => {},
  isClearable: false,
  fieldAs: 'div',
  options: [],
  listWidget: false,
};

MultiSelectSort.propTypes = {
  listWidget: PropTypes.bool,
  register: PropTypes.string,
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  form: PropTypes.object.isRequired,
  noOptionsMessage: PropTypes.string,
  loadingMessage: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  groupClassName: PropTypes.string,
  prepend: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  fieldAs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  column: PropTypes.bool.isRequired,
  options: PropTypes.array,
  isClearable: PropTypes.bool,
  onCreateOption: PropTypes.func,
  onInputChange: PropTypes.func,
  loadOptions: PropTypes.func,
};

export default MultiSelectSort;
