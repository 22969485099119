/* eslint-disable import/no-extraneous-dependencies,react/forbid-prop-types,no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useSubscription } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { usePrevious } from 'react-use';
import styled from 'styled-components';

import {
  GQL_GET_DIALOGS_ALL,
  GQL_GET_MESSAGE_COUNT,
  GQL_MESSAGE_NEW,
} from '../../../graphql/queries';
import { DARK, DEFAULT_LIGHT } from '../../../helpers/colors';
import { clearHtmlTags } from '../../../helpers/main';

const TIMEOUT_VISIBLE = 10000;
const TEXT_LENGHT = 200;

const NewMessages = ({ me }) => {
  const history = useHistory();
  const location = useLocation();
  if (location.pathname.includes('/account/messages') || !_.get(me, '_id')) {
    return null;
  }

  const [items, setItems] = useState([]);

  const prev = usePrevious(items) || [];

  const { refetch } = useQuery(GQL_GET_MESSAGE_COUNT);

  const { refetch: dialogsRefetch } = useQuery(GQL_GET_DIALOGS_ALL, {
    variables: { user_id: me._id },
  });
  const { data: dataSubscription } = useSubscription(GQL_MESSAGE_NEW, {
    variables: { receiver_id: _.get(me, '_id') },
  });
  const messageNew = _.get(dataSubscription, 'messageNew');

  // add message
  useEffect(() => {
    if (messageNew) {
      const audio = new Audio('/sounds/beep.mp3');
      refetch();
      dialogsRefetch();
      audio.load();
      audio.play();
      setItems((prevItems) => [...prevItems, { ...messageNew, show: false }]);
    }
    return () => {
      setItems([]);
    };
  }, [messageNew]);

  useEffect(() => {
    if (items.length > prev.length && !_.last(items).show) {
      // show the latest message
      setItems((prevItems) => [
        ...prevItems.filter((i) => i._id !== _.last(prevItems)._id),
        { ..._.last(prevItems), show: true },
      ]);
      // remove old messages from state
      setTimeout(() => {
        setItems((prevItems) => prevItems.filter((i) => moment().diff(moment(+i.created_at), 'seconds') < 5));
      }, TIMEOUT_VISIBLE);
    }
  }, [items]);

  return (
    <ToastArea>
      {items.map(
        (item) => item.show && (
        <Toast key={`notificationMessage-${item._id}`}>
          <ToastInfo>
            <UserInfo>
              <UserImage
                src={
                      _.get(item, 'user.avatar.key', '')
                        ? `/files/${_.get(item, 'user.avatar.key', '')}`
                        : '/images/no-avatar.jpg'
                    }
                alt={_.get(item, 'user.name')}
              />
              <div>
                {_.get(item, 'user.name')}
                <Time>{moment.unix(item.created_at).fromNow()}</Time>
              </div>
            </UserInfo>
            <ButtonClose
              type="button"
              onClick={() => {
                setItems((prevItems) => [
                  ...prevItems.map((i) => ({
                    ...i,
                    show: i._id === item._id ? false : i.show,
                  })),
                ]);
              }}
            >
              &times;
            </ButtonClose>
          </ToastInfo>
          <ToastContent onClick={() => history.push(`/account/messages/${item.dialog_id}`)}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {!item.text ? 'Прислал(а) новый файл' : `${
              _.get(item, 'text').length > TEXT_LENGHT
                ? `${clearHtmlTags(_.get(item, 'text')).substring(0, TEXT_LENGHT)}...`
                : clearHtmlTags(_.get(item, 'text'))
            }`}
          </ToastContent>
        </Toast>
        ),
      )}
    </ToastArea>
  );
};

const ButtonClose = styled.button`
  z-index: 1600;
  position: absolute;
  right: 0;
  top: 6px;
  font-size: 45px;
  display: block;
  padding: 10px;
  color: #000;
  border: 0;
  font-weight: 100;
  line-height: 0;
`;

const Time = styled.div`
  margin-top: -10px;
  font-size: 12px;
  color: ${DEFAULT_LIGHT};
`;

const ToastArea = styled.div`
  position: fixed;
  width: 100%;
  max-width: 400px;
  right: 20px;
  top: 20px;
  z-index: 1101;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 10px;
  @media screen and (max-width: 812px) {
    right: 0;
    top: 30px;
  }
`;

const UserImage = styled.img`
  max-width: 40px;
  max-height: 40px;
  margin-right: 10px;
  border-radius: 50%;
`;

const Toast = styled.div`
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  background: white;
  box-shadow: 0 0 4px 1px #a7a7a7;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const ToastInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ToastContent = styled.button`
  padding: 0;
  font-size: 80%;
  line-height: 1.2;
  text-align: left;
  color: ${DARK};
`;

NewMessages.propTypes = {
  me: PropTypes.object.isRequired,
};

export default NewMessages;
