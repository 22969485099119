import _ from 'lodash';

import config from '../config';
import action from './ru/action';
import album from './ru/album';
import albums from './ru/albums';
import alerts from './ru/alerts';
import artist from './ru/artist';
import artists from './ru/artists';
import artwork from './ru/artwork';
import artworks from './ru/artworks';
import chat from './ru/chat';
import collection from './ru/collection';
import collections from './ru/collections';
import comments from './ru/comments';
import compilation from './ru/compilation';
import create from './ru/create';
import declination from './ru/declination';
import dimension from './ru/dimension';
import dropFiles from './ru/dropFiles';
import education from './ru/education';
import educations from './ru/educations';
import emoji from './ru/emoji';
import exhibition from './ru/exhibition';
import exhibitions from './ru/exhibitions';
import favorites from './ru/favorites';
import filter from './ru/filter';
import form from './ru/form';
import friendship from './ru/friendship';
import index from './ru/index';
import info from './ru/info';
import institution from './ru/institution';
import institutions from './ru/institutions';
import link from './ru/link';
import location from './ru/location';
import login from './ru/login';
import market from './ru/market';
import metaspace from './ru/metaspace';
import news from './ru/news';
import notification from './ru/notification';
import notification_modal from './ru/notification_modal';
import notifications from './ru/notifications';
import offer from './ru/offer';
import order from './ru/order';
import payment from './ru/payment';
import posts from './ru/posts';
import promo from './ru/promo';
import reset from './ru/reset';
import search from './ru/search';
import secret from './ru/secret';
import settings from './ru/settings';
import signup from './ru/signup';
import status from './ru/status';
import upload from './ru/upload';
import user from './ru/user';
import users from './ru/users';
import validate from './ru/validate';
import viewingRoom from './ru/viewingRoom';

export const LANG_RU = 'ru';

export const EN_HOST = _.get(config, 'hostname');
export const RU_HOST = _.get(config, 'hostname');

export const EN_DOMAIN = `${_.get(config, 'schema')}://${EN_HOST}`;
export const RU_DOMAIN = `${_.get(config, 'schema')}://${RU_HOST}`;

export const localeRu = {
  action,
  album,
  albums,
  alerts,
  artist,
  artists,
  artwork,
  artworks,
  chat,
  collection,
  collections,
  comments,
  compilation,
  create,
  declination,
  dimension,
  dropFiles,
  education,
  educations,
  emoji,
  exhibition,
  exhibitions,
  favorites,
  filter,
  form,
  friendship,
  index,
  info,
  institution,
  institutions,
  link,
  location,
  login,
  market,
  metaspace,
  news,
  notification,
  notification_modal,
  notifications,
  offer,
  order,
  payment,
  posts,
  promo,
  reset,
  search,
  secret,
  settings,
  signup,
  status,
  upload,
  user,
  users,
  validate,
  viewingRoom,
};
