import React from 'react';

const www = ({ colorStroke, colorFill }) => ({
  viewBox: '0 0 20 21',
  data: (
    <g>
      <path
        d={`M10 19.6953C14.9706 19.6953 19 15.6659 19 10.6953C19 5.72475 14.9706 1.69531
            10 1.69531C5.02944 1.69531 1 5.72475 1 10.6953C1 15.6659 5.02944 19.6953 10 19.6953Z`}
        stroke={colorStroke}
        fill={colorFill}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={`M10.0004 1.69531C12.2515 4.15983 13.5309 7.35814 13.6004 10.6953C13.5309 14.0325 12.2515
            17.2308 10.0004 19.6953C7.74924 17.2308 6.46991 14.0325 6.40039 10.6953C6.46991
            7.35814 7.74924 4.15983 10.0004 1.69531V1.69531Z`}
        stroke={colorStroke}
        fill={colorFill}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 10.7031H19"
        stroke={colorStroke}
        fill={colorFill}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default www;
