export default {
  no_artist: 'Нет художников',
  no_artworks: 'Нет работ',
  no_artists_by_letter: 'На букву «{{ letter }}» художников не найдено',
  no_artists_by_letter_and_request: 'По запросу «{{ request }}» и букве «{{ letter }}» художников не найдено',
  new_popular_name: 'Новые художники на Артократии',
  new_selling_name: 'Работы можно приобрести',
  one_artist: 'художника',
  many_artists: 'художников',
  my: 'Мои художники',
  title: 'КАБИНЕТ - МОИ ХУДОЖНИКИ',
  suggest_edit: 'Предложить правки',
  suggest_success: 'Спасибо! Мы получили Ваше сообщение',
  suggest_artist: 'Предложить правки для художника',
  suggest_placeholder: 'Введите чтобы вы хотели добавить или исправить',
};
