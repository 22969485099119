import { gql } from '@apollo/client';

const fragmentAll = gql`
fragment NewsAllFragment on NewsAll {
   total
   meta {
     title
     description
     created_at
     updated_at
     page_name
     page_description
   }
   items {
     _id
     url
     name
     anons
     main
     myLike
     important
     editor
     home
     type
     created_at
     tariffs {
       name
       color
     }
     authors {
       _id
       username
       name
     }
     image {
       _id
       type
       ownerId
       rank
       description
       name
     }
   }
}`;

export const GQL_GET_NEWS_ALL = gql`
query GetNewsAll($my: Boolean, $type: Int, $variants: [String], $section: String, $query: QueryItems, $tag: String) {
    newsAll(my: $my, type: $type, variants: $variants, section: $section, query: $query, tag: $tag) {
      ...NewsAllFragment
    }
  }
  ${fragmentAll}
`;

export const GQL_GET_NEWS = gql`
  query GetNews($url: String) {
    news(url: $url) {
      _id
      url
      name
      type
      anons
      description
      likes
      myLike
      views
      created_at
      updated_at
      only_reg
      status
      can_view
      tags {
        _id
        name
        url
      }
      tariffs {
        name
        name_for
        close_text
        color
      }
      authors {
        _id
        username
        name
      }
      news {
        _id
        url
        name
        anons
        images {
          _id
          type
          ownerId
          rank
          description
          name
        }
      }
      favorite {
        _id
      }
      images {
        _id
        type
        ownerId
        rank
        description
        name
      }
      meta {
        title
        description
      }
    }
  }
`;

export const GQL_GET_SECTION_NEWS = gql`
  query GetSectionNews($section: Int, $limit: Int) {
    sectionNews(section: $section, limit: $limit) {
      _id
      url
      name
      type
      myLike
      created_at
      updated_at
      image {
        _id
        type
        ownerId
        rank
        description
        name
      }
    }
  }
`;
