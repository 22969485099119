export default {
  crumbs: 'Выставки',
  publish: 'Мероприятие',
  my_name: 'Моя выставка',
  title: 'Мероприятие «{{ name }}» | Артократия',
  description: 'Мероприятие - {{ name }}{{ dates }}. Социальная сеть для ценителей искусства - Артократия',
  count_artworks: 'В мероприятие включено ',
  type: 'Тип выставки',
  type_placeholder: 'Выберите тип',
  date: 'Дата проведения',
  date_placeholder: 'Выберите период',
  time: 'Время проведения',
  select_days: 'Выберите дни',
  location: 'Местоположение',
  work_time_date: 'Время работы',
  work_days: 'Рабочие дни',
  address: 'Адрес',
  no_address: 'Адрес не указан',
  gallery: 'Галерея',
  curator: 'Куратор',
  others: 'Другие события куратора',
  share_label: 'Поделиться выставкой',
  share_description: 'Выставка - {{ name }}{{ dates }}. Социальная сеть для ценителей искусства - Артократия',
  files: 'Дополнительные материалы',
  sure_delete: 'Вы уверены, что хотите удалить выставку {{ name }}?',
  recovery_impossible: 'Восстановление невозможно',
  empty_text: 'У вас еще нет созданной выставки',
  empty_create: 'Желаете создать?',
  empty_add: 'Добавить выставку',
  cabinet_title: 'КАБИНЕТ - МОИ ВЫСТАВКИ',
  my: 'Мои выставки',
  create: 'Создать выставку',
  search_placeholder: 'Начните вводить название',
  why_not: 'у нее нет фото и необходимой информации',
  alert_delete_use: 'Вы не можете удалить это мероприятие, так как у него есть заявки или оно используется в произведениях',
  alert_delete_moderation: 'Вы не можете совершать действия над элементом, так как он на модерации',
  alert_publish: 'Вы не можете отправить на публикацию, так как нет фото или описания',
  requests: 'Есть заявки',
  requests_switch_off: 'Заявки отключены',
  requests_name: 'Заявки на выставку',
  information: 'Информация о выставке',
  offer_artworks: 'Предложения произведений',
  user_offer_artworks: 'предлагает следующие работы',
  work_name: 'Название работы',
  was_save: 'Вы успешно сохранили выставку!',
  update: 'Обновить выставку {{ name }}',
  add: 'Добавить выставку',
  name: 'Название',
  name_placeholder: 'Введите название выставки',
  institution: 'Институция',
  institution_info: 'Выберите институцию из существующих или добавьте новую. Подробную информацию об институции можно заполнить в разделе Музеи',
  institution_placeholder: 'Введите название институции',
  desc: 'Описание выставки',
  desc_placeholder: 'Введите описание',
  contacts: 'Контактная информация',
  curator_visibility: 'Отображение вас как куратора в мероприятии',
  curator_hide: 'Не показывать',
  curator_show: 'Показывать',
  images: 'Фотографии мероприятия',
  images_info: 'Каждая фотография должна быть хорошего качества, не менее 1 000 х 512 px и не более 5 МБ. Первое фото основное для карточек',
  files_info: 'Презентация и другие документы, которые вы хотите донести до пользователей, каждый файл не более 10 МБ',
  can_offer: 'Коллекционеры могут отправлять работы',
  cant_offer: 'Нет',
  online: 'Вид мероприятия',
  online_placeholder: 'Выберите вариант мероприятия',
  pictures: 'Обложка мероприятия',
  pictures_info: 'Обложкка мероприятия должна быть хорошего качества, не менее 1 000 х 512 px и не более 2 МБ',
  artworks: 'Работы на мероприятии ({{ total }})',
  artworks_placeholder: 'Введите название или художника',
  clear_artworks: 'Убрать все работы',
  sure_clear: 'Вы уверены, что хотите очистить все работы из выставки?',
  clear_info: 'Вы всегда можете собрать заново',
  offer_name: 'Работы прикрепленные к мероприятию',
  access_message: 'Выставка доступна только по ссылке, не передавайте её никому, помните о конфиденциальности передаваемых данных',
  moderated: 'Отправка мероприятия на модерацию',
  moderated_info: 'Вы уверены, что хотите отправить мероприятие на модерацию, без публикации?',
  send_offer: 'Отправка предложений коллекционерам',
  offer_info: 'Вы уверены, что хотите отправить предложение участия работ в мероприятии коллекционерам добавленных работ?',
  is_online: 'Онлайн',
  is_offline: 'Офлайн',
  empty_to_delete: 'У вас нет прикреплённых работ, чтобы их удалить',
  empty_ability_delete: 'У вас нет больше работ с возможностью удаления',
  deleted_works: 'Удалено {{ count }} {{ declination }}',
  sent_offer_collectors: 'Вы отправили предложение коллекционерам {{ count }} {{ declination }}, ожидайте ответа',
  empty_attached: 'У вас нет прикреплённых работ',
  previously_sent: 'Вы ранее уже направили предложения всем участникам, ожидайте ответа',
  sent_moderation: 'Вы отправили мероприятие на модерацию, ожидайте ответа',
  fill_name: 'Заполните название',
  fill_description: 'Заполните описание',
  attach_picture: 'Загрузите изображение',
  fill_dates: 'Установите даты проведения',
  cant_change_type_sent_works: 'Вы не можете менять тип мероприятия, так как у вас есть отправленные работы коллекционерам',
  cant_change_type_view_works: 'Так как статус мероприятия допускает просмотр другими пользователями, вы не можете менять его тип',
  select_online: 'Выберите работы с Артократии, которые хотите чтобы были у Вас в онлайн выставке',
  select_offer: 'Выберите работы с Артократии, которые планируете пригласить к участию в выставке',
  after_attach_send_offer: 'После добавления работ вы сможете направить запрос, на участие работ в выставке, коллекционерам и получить от них ответ.',
  attach_for_view_online: 'Вы можете к онлайн выставке прикрепить работы, которые будут отображены под ней. Также имеется возможность опубликовать её с доступом по ссылке без публикации',
  to_send_offer: 'Чтобы отправить предложения',
  sent_yet: 'Вы отправили уже предложения для {{ count }} {{ declination }}',
  await_after_sent: 'После отправки предложений, ожидайте подтверждения работ или связи от коллекционеров. Не удаляйте работы, и не меняйте статус публикации иначе коллекционеры не смогут связаться или подтвердить участие работ в мероприятии',
  send_offers: 'Отправить владельцам работ предложение принять участие в мероприятии',
  share_link: 'Вы можете поделиться ссылкой на мероприятие, но доступ возможен только по ней, при изменении варианта публикации, ссылка будет удалена.',
  before_offer_moderation: 'Чтобы получить возможность выслать предложения коллекционерам прикреплённых работ, с доступом к мероприятию по ссылке, отправьте её на модерацию без публикации.',
  for_send_moderation: 'Чтобы отправить на модерацию без публикации, для отправки предложений',
  send_moderation: 'Отправить на модерацию без публикации',
  on_moderation: 'Мероприятие на модерации, после успешного завершения будет доступна возможность рассылки предложений',
  to_get_opportunity_offer: 'Чтобы получить возможность выслать предложения коллекционерам прикреплённых работ, можно отправить мероприятие на публикацию или создать доступ по ссылке и затем послать на модерацию. Для похождения модерации мероприятие должно быть максимально заполнено',
  after_attach_online_info: 'После добавления работ вы сможете создать доступ по ссылке или опубликовать для всех онлайн мероприятие',
  provided_access_event: 'Вы предоставили доступ к мероприятию по ссылке. Чтобы удалить доступ выберите «Снять с публикации»',
  publish_all: 'Чтобы опубликовать для всех',
  title_confirm: 'Подтверждение участия всех работ',
  message_confirm: 'Вы уверены, что хотите подтвердить все работы?',
  alert_confirm: 'Все работы подтверждены, ожидайте связи куратора или напишите ему сами',
  title_confirm_selected: 'Подтверждение участия выбранных работ',
  message_confirm_selected: 'Вы уверены, что хотите подтвердить только выбранные работы? Остальные будут удалены',
  alert_confirm_selected_yes: 'Выбранные работы подтверждены, ожидайте связи куратора или напишите ему сами',
  alert_confirm_selected_no: 'Вы не выбрали работ!',
  title_remove: 'Удаление всех работ из заявки',
  message_remove: 'Вы уверены, что хотите удалить все работы из заявки?',
  alert_remove: 'Все работы удалены из заявки',
  your_works: 'Ваши работы работы на мероприятии',
  selected_works: 'Выбрано {{ count }} {{ declination }}',
  confirm_all: 'Подтвердить все работы',
  confirm_selected: 'Подтвердить участие выбранных',
  remove_all: 'Снять все работы с участия',
  select_action: 'Выберите действие',
  show_my: 'Показать только мои работы',
  show_all: 'Показать все работы',
  all: 'Мои выставки',
  location_show: 'Указать местоположение',
  location_type_select_info: 'Вы можете записать в свободной форме только название местоположения. Место сохранится в Ваших локациях',
  location_type_my: 'Выбрать из моих',
  until: 'До',
  close_add: 'Публикуйте выставки с помощью тарифа <em>{{ name }}</em> подписки на Артократию',
  hidden_tariff: 'Изучить тарифы',
};
