import React from 'react';

const shine = ({ colorFill, colorFillIn }) => ({
  viewBox: '0 0 21 20',
  data: (
    <g>
      <path
        fill={colorFill}
        d="M3.693,3.05v15.899c0,0.781,0.909,1.201,1.521,0.732L11,15.262l5.786,4.42
        c0.612,0.469,1.521,0.049,1.521-0.732V3.05c0-0.518-0.426-0.929-0.942-0.929H4.636C4.12,2.121,3.693,2.532,3.693,3.05z
        M11.795,13.958c-0.467-0.358-1.123-0.358-1.59,0l-4.979,3.805V3.632h11.55v14.131L11.795,13.958z"
      />
      <path
        id="fillIn"
        fill={colorFillIn}
        d="M3.693,19.032V3.133c0-0.517,0.426-0.929,0.942-0.929h12.729c0.517,0,0.942,0.412,0.942,0.929v15.899
        c0,0.781-0.908,1.201-1.521,0.733L11,15.345l-5.786,4.421C4.602,20.233,3.693,19.813,3.693,19.032z"
      />
    </g>
  ),
});

export default shine;
