export default {
  connect: 'Присоединиться к проекту',
  contact: 'Связаться с менеджером',
  hello_moderator: 'Здравствуйте! Хочу узнать подробнее про проект Viewing Rooms. Стоимость аренды {{ rent_price }} ₽',
  hello_moderator_name: 'Здравствуйте! Хочу узнать подробнее про проект Viewing Rooms - «{{ name }}». Стоимость аренды {{ rent_price }} ₽',
  send_success: 'Ваша заявка успешно отправлена!',
  add: 'Арендовать готовое пространство Viewing room «{{ name }}»',
  name: 'Ваше имя',
  name_placeholder: 'Введите имя',
  phone: 'Телефон',
  phone_placeholder: 'Введите номер телефона',
  message: 'Расскажите о вашем проекте',
  message_placeholder: 'Введите текст',
  booking: 'Арендовать пространство',
  agreement: 'Нажимая кнопку «отправить» я даю согласие',
  agreement_link: 'на обработку моих персональных данных',
  thanks_user: 'Спасибо, {{ name }}! Мы получили всю необходимую информацию. Сегодня наш менеджер свяжется с вами.',
  thanks: 'Спасибо! Мы получили всю необходимую информацию. Сегодня наш менеджер свяжется с вами по указанному телефону',
};
