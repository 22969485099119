import React from 'react';

const addUser = ({ colorStroke }) => ({
  viewBox: '0 0 26 26',
  data: (
    <g>
      <path
        d={`M5.90912 22.4545C5.90912 19.191 
      8.55471 16.5454 11.8182 16.5454H18.9091C22.1726 
      16.5454 24.8182 19.191 24.8182 22.4545V23.6363H5.90912V22.4545Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="15.3636"
        cy="8.27268"
        r="4.72727"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.18182 13H7.09091M4.13637 10.0454V15.9545"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default addUser;
