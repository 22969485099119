import { gql } from '@apollo/client';

const fragment = gql`
  fragment CommentFragment on Comment {
    _id
    text
    model
    modelId
    replyId
    mainId
    likes
    status
    created_at
    updated_at
    owner {
      _id
      name
      username
      avatar {
        _id
        key
        fileName
      }
    }
    children {
      _id
      text
      model
      modelId
      replyId
      likes
      reply {
        id
        user {
          _id
          name
        }
      }
      owner {
        _id
        name
        username
        avatar {
          _id
          key
          fileName
        }
      }
      status
      created_at
      updated_at
    }
  }
`;

export const GQL_GET_COMMENTS_ALL = gql`
  query GetCommentsAll($modelId: ID, $modelName: String) {
    commentsAll(modelId: $modelId, modelName: $modelName) {
      ...CommentFragment
    }
  }
  ${fragment}
`;

export const GQL_UPDATE_COMMENT = gql`
  mutation UpdateComment($data: CommentInput) {
    updateComment(data: $data) {
     ...CommentFragment
    }
  }
  ${fragment}
`;

export const GQL_REMOVE_COMMENT = gql`
  mutation RemoveComment($_id: String!) {
    removeComment(_id: $_id)
  }
`;

export const GQL_COMMENT_NEW = gql`
  subscription GetCommentNew($receiver_id: ID!) {
    commentNew(receiver_id: $receiver_id) {
      url
      text
      replyId
      created_at
      owner {
        _id
        name
        username
        avatar {
           _id
          key
          fileName
        }
      }
    }
  }
`;

export const GQL_LIKE_PROCESS_COMMENT = gql`
  mutation UpdateCommentLike($_id: String!) {
    updateCommentLike(_id: $_id)
  }
`;
