export const DARK = '#171717';
export const DARK_NIGHT = '#050436';
export const DEFAULT_DARK = '#303030';
export const DEFAULT = '#4F4F4F';
export const DEFAULT_LIGHT = '#6E6E6E';
export const DEFAULT_EXTRA = '#878787';
export const DARK_WHITE = '#BCBCBC';
export const LIGHT_WHITE = '#FAFAFA';
export const BACK_GROUND = '#F3F3F3';
export const MAIN_GRAY = '#E0E5F1';
export const ACTIVE_DARK = '#232095';
export const ACTIVE = '#2B28CB';
export const ACTIVE_LIGHT = '#2f2cee';
export const ACTIVE_EXTRA = '#3330ff';
// STATUSES
export const INFO = '#378bc0';
export const ERROR = '#AD1313';
export const SUCCESS = '#53B43B';
export const SUCCESS_DARK = '#034200';
export const WARNING = '#F6532D';
export const WARNING_LIGHT = '#ffdec2';
// ALERTS BG
export const INFO_ALERT = '#D5E3FE';
export const ERROR_ALERT = '#FFDCDC';
export const SUCCESS_ALERT = '#ECF9EF';
export const WARNING_ALERT = '#f9ebd8';
// DASH LINE FOR BOXES
export const DASH_LINE = '/images/dash.svg';

export const getStatusColor = (status) => {
  switch (status) {
    case 1:
      return SUCCESS;
    case -1:
      return ERROR;
    case 2:
      return WARNING;
    default:
      return ERROR;
  }
};
