import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { ACTIVE_DARK, DEFAULT_LIGHT, LIGHT_WHITE } from '../helpers/colors';
import Icon from './Icon';

const ModalCloseButton = ({ onHide, loading }) => (
  <ModalClose
    type="button"
    disabled={loading}
    onClick={onHide}
  >
    <Icon
      icon="remove"
      colorStroke={DEFAULT_LIGHT}
      colorHover={ACTIVE_DARK}
      width={18}
      height={18}
    />
  </ModalClose>
);

const ModalClose = styled.button`
  padding: 10px;
  background-color: ${LIGHT_WHITE};
  border-radius: 10px;
  position: absolute;
  right: 20px;
  top: 20px;
`;

ModalCloseButton.defaultProps = {
  loading: false,
};

ModalCloseButton.propTypes = {
  onHide: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default ModalCloseButton;
