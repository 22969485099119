import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';
import Select from 'react-select';
import _ from 'lodash';
import {
  ACTIVE,
  ACTIVE_LIGHT,
  DARK,
  DARK_WHITE,
  DEFAULT,
} from '../helpers/colors';
import {
  REG_ALL_CAPITALIZE,
  REG_FIRST_CAPITALIZE,
  REG_LOWCASE,
  REG_NORMAL,
  REG_UPPERCASE,
} from '../helpers/main';

const SelectForm = ({
  noOptionsMessage,
  loadingMessage,
  label,
  placeholder,
  disabled,
  isLoading,
  className,
  fieldAs,
  options,
  multiple,
  isSearchable,
  loadOptions,
  menuPlacement,
  onChange,
  value,
  register,
  maxWidth,
  placeholderStyle,
  controlStyle,
  valueStyle,
  async,
  defaultOptions,
}) => {
  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomOption = (props) => {
    const {
      isSelected, innerProps, data, isDisabled,
    } = props;
    const type = !!data.type && data.type === 'user';
    return isDisabled ? (
      <Option
        disabled={1}
        style={{
          backgroundColor: false,
          color: DEFAULT,
        }}
      >
        {!!data.image && (
          <SelectImage
            rounded={type}
            src={`/files/${data.image}`}
          />
        )}
        {data.label}
      </Option>
    ) : (
      <Option
        disabled={0}
        style={{
          backgroundColor: (isSelected ? ACTIVE_LIGHT : false),
          color: (isSelected ? '#FFFFFF' : false),
        }}
        {...innerProps}
      >
        {!!data.image && (
          <SelectImage
            rounded={type}
            src={`/files/${data.image}`}
          />
        )}
        {data.label}
      </Option>
    );
  };

  CustomOption.defaultProps = {
    isDisabled: false,
  };

  CustomOption.propTypes = {
    isSelected: PropTypes.bool.isRequired,
    innerProps: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool,
  };

  let transform = REG_NORMAL;
  let isFirstCapital = 0;

  switch (register) {
    case REG_LOWCASE:
      transform = REG_LOWCASE;
      break;
    case REG_UPPERCASE:
      transform = REG_UPPERCASE;
      break;
    case REG_FIRST_CAPITALIZE:
      transform = REG_NORMAL;
      isFirstCapital = 1;
      break;
    case REG_ALL_CAPITALIZE:
      transform = REG_ALL_CAPITALIZE;
      break;
    default:
      transform = REG_NORMAL;
      break;
  }

  const colourStyles = {
    control: () => ({ // provided, state
      // borderBottom: state.isFocused ? `1px solid ${ACTIVE}` : `1px solid ${DARK_WHITE}`,
      borderRadius: 0,
      fontSize: 20,
      fontWeight: 300,
      marginTop: 0,
      marginRight: 15,
      padding: '0 0 15px',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      justifyContent: 'space-between',
      ...controlStyle,
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
        padding: '0 0 3px',
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: 20,
      fontWeight: 400,
      flex: 'inherit',
      width: '100%',
      padding: 0,
      ...valueStyle,
    }),
    multiValueRemove: (base) => ({
      ...base,
      backgroundColor: '#FFFFFF',
      border: 0,
      '&:hover, &:focus': {
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        border: 0,
        color: '#000',
      },
    }),
    multiValue: (styles) => ({
      ...styles,
      border: `1px solid ${ACTIVE}`,
      fontSize: 20,
      backgroundColor: '#FFFFFF',
      borderRadius: 2,
      color: ACTIVE,
      padding: 0,
      fontWeight: 400,
      textTransform: transform,
      ...valueStyle,
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      padding: 0,
      fontSize: 20,
      fontWeight: 'bold',
      color: ACTIVE,
      textTransform: transform,
      ...valueStyle,
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
      },
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      padding: '5px 0',
      fontSize: 14,
      whiteSpace: 'pre-wrap',
      lineHeight: 1.3,
      color: ACTIVE,
      backgroundColor: '#FFFFFF',
      fontWeight: 400,
      textTransform: transform,
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      fontWeight: 300,
      fontSize: 20,
      color: '#B8BFCF',
      position: 'absolute',
      ...placeholderStyle,
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
      },
    }),
    option: (provided, state) => ({
      color: state.isSelected ? '#FFFFFF' : DARK,
      background: state.isSelected ? ACTIVE_LIGHT : DARK_WHITE,
      fontSize: 20,
      padding: '25px 6px',
      textTransform: transform,
      '@media screen and (max-width: 812px)': {
        fontSize: 14,
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
      textTransform: transform,
    }),
    indicatorsContainer: () => ({
      display: 'flex',
    }),
    indicatorSeparator: () => null,
    dropdownIndicator: (provided, state) => ({
      color: state.isFocused ? ACTIVE : DARK,
      position: 'relative',
      top: 2,
    }),
  };

  const Component = async ? AsyncSelect : Select;

  return (
    <FilterSelect transform={isFirstCapital} maxWidth={maxWidth}>
      {label}
      <Component
        isSearchable={isSearchable}
        menuPlacement={menuPlacement}
        components={{ Option: CustomOption }}
        placeholder={placeholder}
        loadOptions={loadOptions}
        defaultOptions={defaultOptions}
        disabled={disabled}
        isLoading={isLoading}
        isDisabled={isLoading || disabled}
        value={value}
        options={options}
        className={`${className} ${multiple ? 'multi' : ''} ${disabled && 'disabled'}`}
        as={fieldAs}
        isMulti={multiple}
        onChange={onChange}
        noOptionsMessage={() => noOptionsMessage}
        loadingMessage={() => loadingMessage}
        styles={colourStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#D7E3EB',
            primary: ACTIVE,
          },
        })}
      />
    </FilterSelect>
  );
};

const FilterSelect = styled.div`
width: 100%;
max-width: ${(props) => props.maxWidth}px;
flex: 2 0;
${({ transform }) => transform && (`
div[type="option"] {
    display: block;
}
div {
  text-transform:lowercase;
  &:first-letter {text-transform:uppercase}
}
`)}
`;

const Option = styled.div`
color: inherit;
cursor: default;
display: flex;
flex-direction: row;
height: ${({ data }) => (data && _.get(data, 'image') ? '60px' : 'auto')};
font-size: inherit;
align-items: center;
padding: 4px 12px;
width: 100%;
user-select: none;
-webkit-tap-highlight-color: rgba(0,0,0,0);
box-sizing: border-box;
&:hover{
   background-color: #D7E3EB;
}
&:focus{
   background-color: ${ACTIVE_LIGHT};
}
${({ disabled }) => (disabled ? `
   cursor: not-allowed;
   color: #333 !imortant;
   &:hover, &:focus {
     background-color: transparent !imortant;
   }
` : '')};
`;

const SelectImage = styled.img`
    background-position: center;
    background-repeat: no-repeat;
    border-radius: ${({ rounded }) => (rounded ? '50%' : '0')};
    position: relative;
    z-index: 100;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    ${({ rounded }) => (rounded && 'height: 40px;width: 40px;')}
`;

SelectForm.defaultProps = {
  register: REG_NORMAL,
  noOptionsMessage: '',
  loadingMessage: '',
  disabled: false,
  maxWidth: 350,
  label: '',
  isLoading: false,
  className: 'w-100 select-control',
  loadOptions: () => {},
  onChange: () => {},
  fieldAs: 'div',
  multiple: false,
  options: [],
  isSearchable: false,
  menuPlacement: 'bottom',
  value: '',
  placeholderStyle: {},
  controlStyle: {},
  valueStyle: {},
  async: false,
  defaultOptions: [],
};

SelectForm.propTypes = {
  menuPlacement: PropTypes.string,
  register: PropTypes.string,
  maxWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isSearchable: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
  loadingMessage: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  valueStyle: PropTypes.object,
  controlStyle: PropTypes.object,
  placeholderStyle: PropTypes.object,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  fieldAs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  options: PropTypes.array,
  multiple: PropTypes.bool,
  loadOptions: PropTypes.func,
  onChange: PropTypes.func,
  async: PropTypes.bool,
  defaultOptions: PropTypes.array,
};

export default SelectForm;
