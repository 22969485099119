export default {
  name_feedback: 'Форма обратной связи',
  input_name: 'Введите имя',
  input_email: 'Введите email',
  name: 'Имя',
  text: 'Текст',
  input_feedback: 'Введите обращение',
  phone: 'Телефон',
  required_field: 'Обязательное поле',
  error_number_format: 'Неверный формат номера',
  error_email_format: 'Неверный формат email',
  input_format_phone: 'Введите телефон в формате {{ phone }}',
  revert_image: 'Разворот фото',
  select_date: 'Выберите одну дату или период',
  start: 'Начало',
  end: 'Окончание',
  date: 'Дата',
  time_from: 'Время от',
  time_to: 'Время до',
};
