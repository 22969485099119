import { useState, useCallback, useEffect } from 'react';

const cache = new Map();

const defaultOptions = {
  cacheKey: '',
  refetch: false,
};

const useAsyncData = () => {
  const [data, setData] = useState({
    data: null,
    error: null,
    loading: false,
  });

  useEffect(() => () => {
    setData({
      data: null,
      error: null,
      loading: false,
    });
  }, []);

  const run = useCallback(async (asyncFn, options = {}) => {
    try {
      // Merge the default options with the options passed in
      const { cacheKey, refetch } = { ...defaultOptions, ...options };

      const result = { data: null, error: null, loading: false };

      // If we have a cache key and not requesting new data, then return the cached data
      if (!refetch && cache.has(cacheKey)) {
        result.data = cache.get(cacheKey);
      } else {
        setData({ ...result, loading: true });
        const res = await asyncFn();
        result.data = res;
        if (cacheKey) {
          cache.set(cacheKey, res);
        }
      }

      setData(result);
    } catch (error) {
      const result = { data: null, error, loading: false };
      setData(result);
    }
  }, []);

  return {
    ...data,
    run,
  };
};

export default useAsyncData;
