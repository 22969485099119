export default {
  login: 'Войти',
  logout: 'Выход',
  main: 'Главная',
  yes: 'Да',
  no: 'Нет',
  from: 'От',
  to: 'До',
  currency: 'Валюта',
  account: 'Аккаунт',
  your_account: 'Ваш текущий аккаунт',
  loading: 'Загружаем...',
  await_check: 'Подождите, проверяем...',
  my_collection: 'Моя коллекция',
  registration: 'Регистрация',
  alert_information: 'Информация',
  alert_warning: 'Предупреждение',
  alert_error: 'Внимание',
  alert_success: 'Успешно',
  go_to_page: 'Перейти на страницу',
  go_to_home: 'Перейти на главную',
  no_page: 'Страница не найдена, либо ещё не создана',
  error: 'Ошибка',
  error_access: 'Доступ запрёщн',
  login_or_register: 'Войдите или зарегистрируйтесь',
  button_add_artwork: 'Разместить работу',
  button_add_collection: 'Разместить коллекцию',
  button_add_exhibition: 'Добавить выставку',
  menu_news: 'Новости',
  menu_profile: 'Мой профиль',
  menu_favourites: 'Избранное',
  menu_collection: 'Коллекция',
  menu_collections: 'Коллекции',
  menu_my_artworks: 'Мои работы',
  menu_educations: 'Мои курсы',
  menu_artworks: 'Работы',
  menu_albums: 'Альбомы',
  menu_artists: 'Художники',
  menu_tariffs: 'Тарифы',
  menu_exhibitions: 'Выставки',
  menu_my_exhibitions: 'Мои выставки',
  menu_museums: 'Мои музеи',
  menu_contacts: 'Контакты',
  menu_locations: 'Локации',
  menu_notifications: 'Уведомления',
  menu_messages: 'Сообщенния',
  menu_settings: 'Настройки',
  menu_users: 'Пользователи',
  menu_friends: 'Друзья',
  apply: 'Обратиться',
  reset: 'Отмена',
  draft: 'Доступно только мне',
  draft_name: 'Черновик',
  input_text: 'Введите текст',
  collector: 'Коллекционер',
  collectors: 'Коллекционеры',
  artist_role: 'Художник',
  curator: 'Куратор',
  curators: 'Кураторы',
  dealer: 'Арт - Дилер',
  dealers: 'Арт - Дилеры',
  gallery: 'Галерея',
  galleries: 'Галереи',
  specialist: 'Специалист',
  specialists_all: 'Специалисты',
  amateur: 'Любитель',
  friends: 'Друзья',
  follow_me: 'Отслеживают',
  i_follow: 'Отслеживаю',
  he_follow: 'Отслеживает',
  blocked: 'Заблокирован',
  send: 'Отправить',
  qr_code: 'QR код страницы',
  qr_question: 'Узнать как считать QR-код',
  close: 'Закрыть',
  contact: 'Связаться',
  all_rights: 'Все права защищены',
  show: 'Показать',
  show_more: 'Показать ещё',
  hide: 'Скрыть',
  no_name: 'Без названия',
  no_indicate: 'не указано',
  no_artist: 'Автор неизвестен',
  no_params: 'Нет обязательных параметров!',
  no_owner: 'Владелец неизвестен',
  no_image: 'Нет изображения',
  show_map: 'Показать карту',
  hide_map: 'Скрыть карту',
  more: 'Подробнее',
  image: 'Картинка',
  access_all: 'Все включая гостей',
  status_all: 'Все',
  status_published: 'Опубликовано',
  status_edit: 'Редактирование',
  status_moderation: 'Модерация',
  not_necessary: 'необязательно',
  not_found: 'Страница не найдена, либо ещё не создана',
  error_chunk: 'Это приложение было обновлено, пожалуйста, нажмите на это сообщение, чтобы увидеть последние изменения',
  error_all: 'Произошла ошибка, пожалуйста, нажмите на это сообщение, чтобы обновить страницу',
  file_to_big: 'Файл {{ name }} - {{ size }} MB слишком большой, допустимый размер {{ maxSize }}',
  file_type: 'Расширение загружаемого файла {{ name }} не допустимо!',
  file_exist: 'Такой файл {{ name }} уже существует',
  age_label: 'Контент 18+',
  age_warning: 'Контент имеет возрастные ограничения',
  age_button: 'Мне есть 18',
  photo: 'Фото {{ num }}',
  preview: 'Превью {{ num }}',
  calendar: 'Календарь',
  hide_warning: 'Просмотр только для зарегистрированных пользователей',
  interesting: 'Интересно?',
  interesting_text: 'Просмотр контента доступен только для зарегистрированных пользователей Артократии. Авторизуйтесь на платформе и получите неограниченный доступ к интересным материалам об искусстве.',
  abuse: 'Жалоба на «{{ name }}»',
  abuse_placeholder: 'Опишите в чём заключается жалоба',
  share: 'Поделиться в ленте «{{ name }}»',
  share_placeholder: 'Введите текст сообщения',
  share_another: 'Поделиться в других соц.сетях',
  editor_info: 'Информация по редактору и поддерживаемым форматам загружаемых файлов',
  publish: 'Опубликовать',
  menu_subscribe: 'Расширить тариф',
  button_add_post: 'Написать пост',
  button_add_gallery: 'Добавить галерею',
  search: 'Поиск',
  authorisation: 'Авторизируйтесь чтобы получить доступ к материалам',
  auth_error: 'Email занят',
  auth_error_message: 'Пользователь с данным email уже существует. Пожалуйста, авторизуйтесь или восстановите свой пароль',
  google_auth: 'Войти или зарегистрироваться с помощью аккаунта Google',
};
