export default {
  accessible_art: 'Доступное искусство',
  accessible_art_info: '* Всё то, что до 50 000₽',
  top_artists_art: 'Топ',
  new_art: 'Новинки месяца',
  top_artists: 'Художники',
  top_artists_info: '* Наше всё',
  top_institution: 'Институции',
  top_institutions_info: '* Здесь находятся работы, представленные галереями',
  how_connect: 'Как попасть на наш Маркет?',
  need_user: 'Нужно всего лишь нажать на кнопку и выбрать тариф',
  need_guest: 'Нужно всего лишь нажать на кнопку, зарегистрироваться и выбрать тариф',
  publish_art: 'Публиковать свои работы',
  top_collectors: 'Коллекционеры',
  top_collectors_info: '* Те, кто ценят по настоящему',
  top_dealers: 'Дилеры',
  top_dealers_info: '* Те, кто продает то, что вы сотворили',
  watch_all: 'Смотреть все работы на продажу',
};
