import React from 'react';

const picture = ({ colorStroke }) => ({
  viewBox: '0 0 16 16',
  data: (
    <g>
      <path
        d={`M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 
        13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 
        14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d={`M6.13411 7.111C6.87049 7.111 7.46745 6.51405 7.46745 
        5.77767C7.46745 5.04129 6.87049 4.44434 6.13411 4.44434C5.39773 
        4.44434 4.80078 5.04129 4.80078 5.77767C4.80078 6.51405 5.39773 7.111 6.13411 7.111Z`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0006 9.99984L10.6673 6.6665L3.33398 13.9998"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default picture;
