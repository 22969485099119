import { gql } from '@apollo/client';

const fragment = gql`
  fragment SubcategoryFragment on Subcategory {
      _id
      user_id
      status
      name
      description
  }
`;

export const GQL_GET_SUBCATEGORIES_ALL = gql`
  query GetSubcategoriesAll {
    subcategoriesAll {
      _id
      user_id
      status
      name
      description
    }
  }
`;

export const GQL_UPDATE_SUBCATEGORY = gql`
  mutation UpdateSubcategory($data: SubcategoryInput) {
    updateSubcategory(data: $data) {
      ...SubcategoryFragment
    }
  }
  ${fragment}
`;

export const GQL_GET_SUBCATEGORY = gql`
   query GetSubcategory($_id: ID) {
    subcategory(_id: $_id) {
      ...SubcategoryFragment
    }
  }
  ${fragment}
`;
