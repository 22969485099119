export default {
  title: 'АРТОКРАТИЯ - СБРОС ПАРОЛЯ',
  name: 'Восстановление пароля',
  info: 'Чтобы восстановить пароль, укажите E-mail',
  email: 'Email',
  email_placeholder: 'Введите ваш E-mail',
  btn: 'Восстановить',
  send_btn: 'Отправляем ссылку...',
  complete_title: "АРТОКРАТИЯ - СМЕНА ПАРОЛЯ'",
  complete_name: 'Завершение сброса пароля',
  complete_password: 'Пароль',
  complete_password_placeholder: 'Введите новый пароль',
  complete_repeat_password: 'Повторите пароль',
  complete_repeat_password_placeholder: 'Повторите пароль',
  complete_btn: 'Завершить сброс пароля',
};
