import React from 'react';

const paperClip = ({ colorStroke }) => ({
  viewBox: '0 0 20 20',
  data: (
    <g>
      <path
        d={`M18.4569 9.43839L10.4401 17.4644C9.45795 18.4476 8.1259
          19 6.73697 19C5.34804 19 4.016 18.4476 3.03387 17.4644C2.05175
          16.4811 1.5 15.1476 1.5 13.7571C1.5 12.3665 2.05175 11.033 3.03387
          10.0497L11.0507 2.02375C11.7055 1.36825 12.5935 1 13.5194 1C14.4454
          1 15.3334 1.36825 15.9882 2.02375C16.6429 2.67924 17.0108 3.56829
          17.0108 4.4953C17.0108 5.42231 16.6429 6.31135 15.9882 6.96685L7.96262
          14.9928C7.63524 15.3206 7.19123 15.5047 6.72825 15.5047C6.26527 15.5047
          5.82126 15.3206 5.49388 14.9928C5.16651 14.6651 4.98259 14.2206 4.98259
          13.7571C4.98259 13.2935 5.16651 12.849 5.49388 12.5213L12.9001 5.11537`}
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default paperClip;
