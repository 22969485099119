import React from 'react';

const insta = ({ colorStroke, colorFill }) => ({
  viewBox: '0 0 19 19',
  data: (
    <g>
      <path
        d={`M14.4707 0.879883H5.4707C2.98542 0.879883 0.970703 2.8946 0.970703 5.37988V14.3799C0.970703 16.8652
              2.98542 18.8799 5.4707 18.8799H14.4707C16.956 18.8799 18.9707 16.8652 18.9707 14.3799V5.37988C18.9707
              2.8946 16.956 0.879883 14.4707 0.879883Z`}
        fill={colorStroke}
      />
      <path
        d={`M13.5727 9.31315C13.6838 10.0622 13.5558 10.8271 13.2071 11.4993C12.8583 12.1714 12.3065 12.7164
              11.6301 13.0569C10.9538 13.3973 10.1873 13.5158 9.43969 13.3955C8.6921 13.2752 8.00147 12.9222 7.46604
              12.3868C6.93061 11.8514 6.57764 11.1607 6.45735 10.4131C6.33705 9.66555 6.45554 8.89906 6.79598
              8.22269C7.13641 7.54632 7.68145 6.99452 8.35357 6.64577C9.02569 6.29702 9.79066 6.16908 10.5397
              6.28015C11.3037 6.39345 12.0111 6.74947 12.5572 7.29563C13.1034 7.84179 13.4594 8.54912 13.5727 9.31315Z`}
        stroke={colorFill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9231 4.92987H14.9328"
        stroke={colorFill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

    </g>
  ),
});

export default insta;
