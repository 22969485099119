/* eslint-disable array-callback-return,no-restricted-syntax,
 no-bitwise,no-mixed-operators,no-nested-ternary,no-useless-escape,max-len */
import cyrillicToTranslit from 'cyrillic-to-translit-js/CyrillicToTranslit';
import parse, { domToReact } from 'html-react-parser';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { isMobile, isSafari } from 'react-device-detect';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Icon from '../components/Icon';
import config from '../config';
import { LANG_RU, RU_HOST } from '../locales';

moment.locale(LANG_RU);

export const GALLERY_WRAPPER = 'rgba(0, 0, 0, 0.4)';
export const STATUS_DELETED = -1;
export const STATUS_NOT_PUBLISHED = 0;
export const STATUS_PUBLISHED = 1;
export const STATUS_MODERATION = 2;
export const STATUS_EMPTY = 3;
export const STATUS_EMPTY_FILTER = 4;
export const STATUS_EMPTY_SEARCH = 5;
export const STATUS_EMPTY_SF = 6;
export const STATUS_REJECTED = 7;

export const TYPE_JOURNAL = 1;
export const TYPE_GUIDE = 2;
// Need to show news item in section
export const SECTION_ARTWORK_UPDATE = 1;
export const SECTION_ARTIST_UPDATE = 2;
export const SECTION_EXHIBITION_UPDATE = 3;
export const SECTION_INSTITUTION_UPDATE = 4;
export const SECTION_ALBUM_UPDATE = 5;
export const SECTION_ACCOUNT_NEWS = 6;
export const SECTION_COMPANIES = 7;

export const USER_ADMIN = 'admin';
export const USER_MODERATOR = 'moderator';

export const USER_AMATEUR = 'amateur';
export const USER_ARTIST = 'artist';
export const USER_CURATOR = 'curator';
export const USER_COLLECTOR = 'collector';
export const USER_GUEST = 'guest';
export const USER_GALLERY = 'gallery';
export const USER_ART_DEALER = 'dealer';

export const URL_GUIDELINES = 'guidelines';
export const URL_JOURNAL = 'journal';
export const NEWS_SECTION = 'news';

export const TOP_ARTWORKS = 'top';

export const SELF_ARTWORKS = 'artocratia';
export const NEW_ARTWORKS = 'new';
export const AVAILABLE_ARTWORKS = 'available';

export const PUBLISH_GUESTS = 'guests';
export const PUBLISH_FRIENDS = 'friends';
export const PUBLISH_COLLECTION = 'publish_collection';
export const PUBLISH_MARKET = 'publish_market';
export const UNPUBLISH = 'unpublish';
export const PAY_PUBLISH = 'pay_publish_market';
export const PUBLISH_LINK = 'by_link';

export const CURRENCY_RUB = 'rub';
export const CURRENCY_DOL = 'dollar';
export const CURRENCY_EUR = 'euro';

export const ARTWORK_SECTION = 'artwork';
export const EXHIBITION_SECTION = 'exhibition';
export const ARTIST_SECTION = 'artist';
export const ART_DEALER_SECTION = 'dealer';
export const GALLERY_SECTION = 'gallery';
export const INSTITUTION_SECTION = 'institution';
export const COLLECTION_SECTION = 'collection';
export const COLLECTOR_SECTION = 'collector';
export const COMPILATION_SECTION = 'compilation';
export const USER_SECTION = 'user';
export const ALBUM_SECTION = 'album';
export const POST_SECTION = 'post';
export const COMMENT_SECTION = 'comment';
export const COMPILATION_MODEL = 'compilation';
export const COMPILATIONS_MODEL = 'compilations';
export const ARTWORKS_SECTION = 'artworks';
export const EXHIBITIONS_SECTION = 'exhibitions';
export const ARTISTS_SECTION = 'artists';
export const INSTITUTIONS_SECTION = 'institutions';
export const COLLECTIONS_SECTION = 'collections';
export const USERS_SECTION = 'users';
export const ALBUMS_SECTION = 'albums';
export const POSTS_SECTION = 'posts';
export const COMMENTS_SECTION = 'comments';
export const EDUCATIONS_SECTION = 'educations';
export const EDUCATION_SECTION = 'education';
export const MARKET_SECTION = 'art-market';

export const TARIFF_MODEL = 'tariff';

export const PROMO_PAGE = 'promo_page';
export const ORDER_SECTION = 'order';

export const ACTION_FRIENDSHIP = 'friendship';
export const ACTION_ANSWER_FRIENDSHIP = 'answer_friendship';
export const ACTION_OFFER_COLLECTOR = 'offer_collector';
export const ACTION_OFFER_REPLY_CURATOR = 'reply_offer_curator';
export const ACTION_OFFER_WORKS = 'offer_artworks';
export const ACTION_REPLY_OFFER_WORKS = 'reply_offer_artworks';
export const ACTION_COMMENTS = 'notification_comments';
export const ACTION_LIKES = 'notification_likes';
export const ACTION_POSTS = 'notification_posts';
export const UNLIMIT = 'unlimit';

export const SECTION_MAIN = 0;
export const SECTION_ARTWORKS = 1;
export const SECTION_ARTISTS = 2;
export const SECTION_INSTITUTIONS = 3;
export const SECTION_EXHIBITIONS = 4;
export const SECTION_COLLECTIONS = 5;
export const SECTION_COMPILATION = 6;
export const SECTION_ARTIST = 7;
export const SECTION_MARKET = 8;
export const SECTION_MAIN_MARKET = 9;
export const SECTION_METAVERSE = 10;
export const SECTION_VIEWING_ROOM = 11;
// for selectors
export const REG_LOWCASE = 'lowcase';
export const REG_UPPERCASE = 'uppercase';
export const REG_FIRST_CAPITALIZE = 'first_capital';
export const REG_ALL_CAPITALIZE = 'capitalize';
export const REG_NORMAL = 'none';

export const META_ARTIST = 'artist';
export const META_CREATOR = 'creator';
export const META_COLLECTOR = 'collector';
export const META_PRODUCER = 'producer';
export const META_DESIGNER = 'designer';
export const META_MARKETER = 'marketer';
export const META_DEVELOPER = 'developer';
export const META_BRAND = 'brand';

export const TIME_ONLINE = 60; // Seconds
// Double of ./users

const EVENT_TAB = 'events';
const ABOUT_TAB = 'about';
const ALBUM_TAB = 'albums';
const COLLECTION_TAB = 'collection';
const MARKET_TAB = 'market';

export const videoFormat = [
  'mpeg',
  'ogg',
  'webm',
  '3g2',
  '3gp',
  'asf',
  'asx',
  'avi',
  'flv',
  'divx',
  'mov',
  'mp4',
  'm4p',
  'mpg',
  'rm',
  'swf',
  'wmv',
  'qt',
];

export const mimeTypes = [
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/x-png',
  'image/png',
  'image/svg+xml',
  'image/tiff',
];

export const mimeTypesMap = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': '.png',
  'image/tiff': ['.tif', '.tiff'],
  'image/gif': '.gif',
};

export const mimeTypesImageFormat = [
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/x-png',
  'image/png',
  'image/svg+xml',
  'image/tiff',
];

export const mimeTypesVideoFile = [
  'video/mp4', // mp4
  'video/ogg', // ogg
  'video/webm', // webm
];

export const mimeTypesFile = [
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/x-png',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'audio/mp4', // mp4
  'audio/mpeg', // mpeg
  'audio/aac', // acc
  'audio/x-ms-wma', // wma
  'audio/vnd.wave', // wav
  'audio/wav', // wav
  'image/svg+xml', // svg
  'text/plain', // txt
  'video/mpeg', // mpeg
  'video/mp4', // mp4
  'video/ogg', // ogg
  'video/x-m4v',
  'video/quicktime', // quicktime
  'video/webm', // webm
  'video/x-ms-wmv', // wmv
  'text/csv', // scv
  'application/msword', // word
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // '.docx',
  'application/vnd.ms-excel', // '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // '.xlsx',
  'application/vnd.ms-powerpoint', // '.ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // '.pptx',
  'application/pdf', // pdf
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  'application/vnd.ms-excel', // xls
];

export const mimeTypesFileMap = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': '.png',
  'image/gif': 'gif',
  'image/pjpeg': '.pjpg',
  'image/x-png': '.png',
  'image/tiff': '.tiff',
  'audio/mp4': 'mp4',
  'audio/mpeg': 'mpeg',
  'audio/aac': 'acc',
  'audio/x-ms-wma': 'wma',
  'audio/vnd.wave': 'wav',
  'audio/wav': 'wav',
  'image/svg+xml': 'svg',
  'text/plain': 'txt',
  'video/mpeg': 'mpeg',
  'video/mp4': 'mp4',
  'video/ogg': 'ogg',
  'video/quicktime': 'quicktime',
  'video/webm': 'webm',
  'video/x-ms-wmv': 'wmv',
  'text/csv': 'scv',
  'application/msword': 'word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
  'application/vnd.ms-excel': '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
  'application/vnd.ms-powerpoint': '.ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
  'application/pdf': 'pdf',
};

export const CURRENCIES = [
  { value: 'rub', label: '₽' },
  { value: 'dollar', label: '$' },
  { value: 'euro', label: '€' },
];

export const CURRENCIES_OBJ = {
  rub: '₽',
  dollar: '$',
  euro: '€',
};

/**
 * @description name splitter on first name, last name and middle name
 * @param {string} name Like John Smith
 * @return {{firstName: string, lastName: string, middleName: string}}
 */
export const splitName = (name) => {
  let firstName = 'Имя';
  let lastName = 'Фамилия';
  let middleName = 'Отчество';
  if (name && _.isString(name)) {
    let names = name.trim();
    names = names.replace(/  +/g, ' ');
    names = names.split(/\s{1,}/);
    if (names.length) {
      let index = 0;
      for (let i = 0; i < names.length; i += 1) {
        if (_.has(names, i) && _.get(names, i)) {
          const n = _.get(names, i, '').trim();
          if (!!n && index < 3) {
            if (index === 0) {
              firstName = _.get(names, i, '');
            } else if (index === 1) {
              lastName = _.get(names, i, '');
            } else if (index === 2) {
              middleName = _.get(names, i, '');
            }
            index += 1;
          }
        }
      }
    }
  }
  return {
    firstName, lastName, middleName,
  };
};

/**
 * @name StatusOnline
 * @param {Number} last Unix date
 * @return {Object}
 */
export const statusOnline = (last) => {
  /**
   * @param {Boolean} online Status
   * @param {String|null} text Text - was time from now
   */
  if (!_.isInteger(last)) {
    return { online: false, text: null };
  }
  const timeNow = Math.floor(Date.now() / 1000);
  // More then 1 days hide all status
  if (last <= timeNow - 60 * 60 * 24) {
    return { online: false, text: null };
  }
  return { online: (last >= timeNow - TIME_ONLINE), text: moment.unix(last).fromNow() };
};

/**
 * @name renderStringToComponents
 * @description replace special text in brackets {some} to components
 * @param {String} string Text for process
 * @param {Object} components look bottom renderComponents
 * @param Description with styled component to render string text with dangerouslySetInnerHTML
 * @return {*[]}
 * example
 * const renderComponents = {
    '{program}': renderProgram(),
    '{lecturers}': renderLecturers(),
    '{whatGive}': renderTextBlock(item.what_give),
    '{whatLearn}': renderTextBlock(item.what_learn),
    '{whoSuit}': renderTextBlock(item.who_suit),
  };
 const components = renderStringToComponents(_.get(item, 'description'), renderComponents);
 // In return
 <View>{components.map((component) => component)}</View>
 */
export const renderStringToComponents = (string, components, Description) => {
  if (!string || !_.isObject(components) || _.isEmpty(components)) {
    return [];
  }
  const componentsResult = [];
  let description = string;

  if (string) {
    const parts = string.match(/<p>{[^}]*}<\/p>/gi);
    if (!_.isEmpty(parts)) {
      for (let i = 0; i < parts.length; i += 1) {
        let part = _.get(parts, i, '').trim();
        const texts = description.split(part);
        if (part.includes('<p>') || part.includes('</p>')) {
          part = part.replace('<p>', '').replace('</p>', '');
        }
        if (!_.isEmpty(texts)) {
          if (_.get(texts, 0)) {
            componentsResult.push(<Description
              key={`description-${i}`}
              dangerouslySetInnerHTML={{ __html: _.get(texts, 0, '') }}
            />);
          }
          componentsResult.push(_.get(components, part));
          description = _.get(texts, 1, '');
        }
      }
    }
  }
  return componentsResult;
};

const __domToJsx = (str, key, component, index, hasMore = false) => {
  const i = index;
  const next = i + 1;
  const memo = [];
  if (str.includes(key)) {
    const parts = str.split(key);
    memo[i] = _.get(parts, '0', '')
      // eslint-disable-next-line react/no-danger
      ? (<div dangerouslySetInnerHTML={{ __html: _.get(parts, '0', '') }} />)
      : null;
    memo[next] = component;
    const lastElement = _.get(parts, '1', null);
    if (lastElement) {
      if (hasMore) {
        // If has more last save to string
        memo[next + 1] = lastElement;
      } else {
        // If no has more keys then last html set do JSX
        // eslint-disable-next-line react/no-danger
        memo[next + 1] = (<div dangerouslySetInnerHTML={{ __html: lastElement }} />);
      }
    }
  }
  return memo;
};

/**
 * @param {String} str
 * @param {Object} replacementComponents
 * @example
 * const str = '<div>Hello {item} world!</div>';
 * patternsToJsx(str, {
 *   '{item}': (<Component />),
 * });
 * @return {*[]}
 */
export const patternsToJsx = (str, replacementComponents) => {
  let memo = [];
  const lenght = _.keys(replacementComponents).length;
  _.keys(replacementComponents).forEach((key, i) => {
    const index = memo.length > 0 ? memo.length - 1 : 0;
    const hasMore = lenght - 1 > i;
    const lastElement = memo.length > 0 ? _.get(memo, `${memo.length - 1}`, null) : false;
    if (memo.length > 0 && typeof lastElement === 'string') {
      memo = memo.filter((e, idx) => (idx !== memo.length - 1));
      const elements = __domToJsx(lastElement, key, replacementComponents[key], index - 1, hasMore);
      memo.push(...elements.filter((el) => !!el));
    } else {
      const elements = __domToJsx(str, key, replacementComponents[key], index, hasMore);
      memo.push(...elements.filter((el) => !!el));
    }
  });
  return memo;
};

/**
 * @name parseTextLinks
 * @description Function parse DOM links `a` to react-dom Link
 * @param {String} text DOM text
 * @return JSXElement
 */
export const parseTextLinks = (text) => {
  if (!text || !_.isString(text)) {
    return text;
  }
  const options = {
    replace: (element) => {
      const { name, attribs, children } = element;
      if (name === 'a' && attribs.href) {
        let url = attribs.href;
        if (url.includes('http')) {
          if (url.indexOf(RU_HOST) !== -1) {
            url = url.replace(/^.*\/\/[^\/]+/gi, '');
          } else {
            return element;
          }
        }
        delete attribs.target;
        return <Link {...attribs} to={url}>{domToReact(children)}</Link>;
      }
      return element;
    },
  };
  return parse(text, options);
};

/**
 * @name getLocationMeta
 * @description This function set string text from history.location query for meta tags for SEO
 * @return {string}
 */
export const getLocationMeta = () => {
  let result = '';
  let direction = '';
  const getDirection = (param) => `${param !== 'asc' ? 'убывание' : 'возрастание'}`;
  const getStringSort = (dir, name) => `${result ? ', ' : ' - '}сортировка на ${dir} по ${name}`;
  const getStringFilter = (name) => `${result ? ', ' : ' - '}фильтрация по ${name}`;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const query = Object.fromEntries(urlSearchParams.entries());
  if (!_.isEmpty(query)) {
    const page = _.get(query, 'page', null);
    const author = _.get(query, 'author_name', null);
    const created = _.get(query, 'created_at', null);
    const name = _.get(query, 'name', null);
    const started = _.get(query, 'started_at', null);
    const ended = _.get(query, 'ended_at', null);
    const categories = _.get(query, 'categories_id', null);
    const subcategories = _.get(query, 'subcategories_id', null);
    const artist = _.get(query, 'artist_id', null);
    const actual = _.get(query, 'actual', null);
    const type = _.get(query, 'type', null);
    const online = _.get(query, 'online', null);
    const dates = _.get(query, 'dates', null); // from - to
    const variants = _.get(query, 'dates', null);// museum type
    const letter = _.get(query, 'letter', null);
    if (page) {
      result += `${result ? ', ' : ' - '}страница ${page}`;
    }
    if (author) {
      direction = getDirection(author);
      result += getStringSort(direction, 'автору');
    }
    if (created) {
      direction = getDirection(created);
      result += getStringSort(direction, 'времени');
    }
    if (name) {
      direction = getDirection(name);
      result += getStringSort(direction, 'имени');
    }
    if (started) {
      direction = getDirection(started);
      result += getStringSort(direction, 'времени начала');
    }
    if (ended) {
      direction = getDirection(ended);
      result += getStringSort(direction, 'времени окончания');
    }
    let endRuString = categories ? 'категории' : '';
    endRuString += subcategories ? `${endRuString ? ', ' : ''}технике` : '';
    endRuString += artist ? `${endRuString ? ', ' : ''}художнику` : '';
    endRuString += actual ? `${endRuString ? ', ' : ''}актуальности` : '';
    endRuString += type ? `${endRuString ? ', ' : ''}типу` : '';
    endRuString += online ? `${endRuString ? ', ' : ''}статусу` : '';
    endRuString += dates ? `${endRuString ? ', ' : ''}дате ${dates}` : '';
    endRuString += variants ? `${endRuString ? ', ' : ''}варианту` : '';
    endRuString += letter ? `${endRuString ? ', ' : ''}букве «${letter}»` : '';

    if (endRuString) {
      result += getStringFilter(endRuString);
    }
  }
  return result;
};

export const isInViewport = (elem) => {
  const distance = elem.getBoundingClientRect();
  return (
    distance.top >= 0
    && distance.left >= 0
    && distance.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    && distance.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

/**
 * @name getTimeDateRange
 * @description get human date range
 * @param {int} start Start date unix
 * @param {int|null} end End date unix
 * @param {string} format Date format by moment
 * @param {string} nextFormat  Date format by moment for time range hh:mm
 * @return {string}
 */
export const getTimeDateRange = (start, end, format = 'LL', nextFormat = 'hh:mm') => {
  let date;
  if (!start && !end) {
    return '';
  }
  const date1 = moment.unix(start).format('DD-MM-YYYY');
  const date2 = moment.unix(end).format('DD-MM-YYYY');
  if (!end && date1) {
    date = `${moment.unix(start).format(format)}`;
  } else if (date1 === date2 && start !== end) {
    date = `${moment.unix(start).format(`${format} ${nextFormat}`)} - ${moment.unix(end).format(nextFormat)}`;
  } else {
    date = `${moment.unix(start).format(format)} - ${moment.unix(end).format(format)}`;
  }
  return date;
};

export const highlightFor = (element, className, seconds) => {
  element.classList.add(className);
  setTimeout(() => {
    element.classList.remove(className);
  }, (seconds * 1000));
};

/**
 * @name scrollElementId
 * @description This function look for scroll in to view element in list
 * To activate it you need to add link of list item next props
 * @param {string} id of element in list id={item._id}
 * @param {int} counter How iteration to find element to do closed param default 10
 * @param {string} block where to scroll "start", "center", "end", "nearest"
 * @param {string} behavior animation "auto", "smooth"
 * @example <Link to={{ pathname: '/go-page', state: { scrollTo: item._id } }}>...</Link>
 */

export const scrollElementId = (id, counter = 0, block = 'center', behavior = 'auto') => {
  let c = counter;
  const el = document.getElementById(id);
  if (el) {
    el.scrollIntoView({ block, behavior });
    highlightFor(el, 'high-light', 2);
  } else if (c < 10) {
    c += 1;
    setTimeout(() => scrollElementId(id, c, block, behavior), 500);
  }
};

export const isScrolledIntoView = (el) => {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;

  // Only completely visible elements return true:
  // Partially visible elements return true:
  // isVisible = elemTop < window.innerHeight && elemBottom >= 0;

  return (elemTop >= 100) && (elemBottom - 100 <= window.innerHeight);
};

export const clearHtmlTags = (str) => {
  if (typeof str === 'string') {
    return str.replace(/<style[^>]*>.*<\/style>/gm, '')
      .replace(/<script[^>]*>.*<\/script>/gm, '')
      .replace(/<[^>]+>/gm, '')
      .replace(/([\r\n]+ +)+/gm, '');
  }
  return '';
};

/**
 * @name insertInArray
 * @description insert in array custom element with position by index
 * @param {array} arr start array of elements
 * @param {number} index position of new item
 * @param {object|string|array} newItem new item
 * @return {array} new array with element
 */

export const insertInArray = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

/**
 * @name stripTags
 * @description Remove tags
 * @param {string} input dom or string value with tags
 * @return {string} without tags of new values
 */

export const stripTags = (input) => {
  if ((input === null) || (input === '') || (typeof input === 'undefined')) {
    return '';
  }
  const string = input.toString();
  return string.replace(/(<([^>]+)>)/ig, '');
};

/**
 * @function scrollIntoView
 * @description Native scrollTo with callback
 * @param {string} id of element to scroll
 * @param {string} behavior - Scroll animation smooth, auto
 */

export const scrollIntoView = (id, behavior = 'instant') => {
  const element = document.querySelector(id);
  if (element) {
    element.scrollIntoView({
      block: 'center', behavior,
    });
  }
};

export const imageUrlGenerate = (image, big = false, original = false) => {
  const smallSize = 500;
  const bigSize = 1000;
  if (image) {
    if (original) {
      return `${image.key}.${image.format}`;
    }
    if (isMobile) {
      return big ? `${bigSize}__${image.key}.jpeg` : `${smallSize}__${image.key}.jpeg`;
    }
    if (isSafari) {
      return big ? `${bigSize}__${image.key}.jpeg` : `${smallSize}__${image.key}.jpeg`;
    }
    return big ? `${bigSize}__${image.key}.webp` : `${smallSize}__${image.key}.webp`;
  }
  return '';
};

export const humanFileSize = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

/**
 * @name cutText
 * @function cutText
 * @param {string} text can be html text or simple
 * @param {int} limit count of characters or worlds if param words is true
 * @param {bool} words what will we cut off signs or words
 * @param {bool|null} limitChars you can limit both by words and by length
 * @return {string} text with dots if lenght more then limit
 */

export const cutText = (text, limit, words = false, limitChars = null) => {
  const wordsProcess = (arr, lim) => {
    const limitIn = lim;
    let textOut = [];
    arr.forEach((world, i) => {
      if ((lim - 1) >= i) {
        textOut[i] = world.trim();
      }
    });
    if (limitChars && textOut.join(' ').length > limitChars) {
      textOut = wordsProcess(textOut, limitIn - 1);
    }
    return textOut;
  };
  if (typeof text === 'string') {
    let cut = clearHtmlTags(text).trim();
    if (!words) {
      if (cut.length <= limit) { return cut; }
      cut = cut.slice(0, limit);
      return `${cut.trim()}...`;
    }
    const worldsArr = cut.split(' ');
    if (worldsArr.length <= limit) { return cut; }
    const textResult = wordsProcess(worldsArr, limit);
    return !_.isEmpty(textResult) ? `${textResult.join(' ')}...` : '';
  }
  return '';
};

export const parseLinks = (raw, regex = null) => {
  let text = '';
  const defaultRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  const links = raw.match(regex || defaultRegex);
  if (links && links.length) {
    const urlParsed = new URL(links[0]);
    const arrayPath = urlParsed.pathname.split('/');
    if (urlParsed.hostname === config.hostname && arrayPath.length === 3 && !!arrayPath[1]) {
      text = raw.replace(
        regex,
        (url) => `
               <a
                  rel="noopener noreferrer nofollow"
                  href="${url}">${cutText(url.replace(/http(s?):\/\//, ''), 30)}</a>
        `,
      );
    } else {
      text = raw.replace(
        regex,
        (url) => `
               <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href="${url}">${cutText(url.replace(/http(s?):\/\//, ''), 30)}</a>
        `,
      );
    }
    return `<div>${text}</div>`;
  }

  return raw;
};

export const validateEmail = (email) => {
  if (typeof email !== 'string') {
    return false;
  }
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validatePhone = (phone) => {
  if (typeof phone !== 'string') {
    return false;
  }
  const re = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
  return re.test(phone);
};

export const validateUrl = (url) => {
  if (typeof url !== 'string') {
    return false;
  }
  return url.includes('http://') || url.includes('www.') || url.includes('https://');
};

export const translitNameUrl = (text) => {
  const textNormalized = text
    .replace(',', '')
    .replace('.', '')
    .replace('_', '-')
    .replace('(', '')
    .replace(')', '')
    .replace('&', '')
    .replace('$', '')
    .replace('?', '')
    .replace('!', '')
    .replace(':', '')
    .replace('»', '')
    .replace('«', '')
    .replace('\'', '')
    .replace('-', '')
    .replace('–', '')
    .replace('..', '')
    .replace('...', '')
    .replace('....', '')
    .replace('.....', '')
    .replace('__', '')
    .replace('___', '');

  return cyrillicToTranslit().transform(textNormalized.trim().toLowerCase(), '-');
};

/**
 * @name isEmpty
 * @description This function to check any occurrences for emptiness
 * @param {mixed} values
 * @param {array} exceptions
 * @return bool
 */

export const isEmpty = (values, exceptions) => {
  const isFullArr = (val) => (Array.isArray(val) ? !!val.length : val);
  const isFullObj = (val) => (val.constructor === Object ? Object.entries(val).length !== 0 : val);
  let count = 0;
  for (const [key, value] of Object.entries(values)) {
    if (!exceptions.includes(key)) {
      if (!!value
        && isFullArr(value)
        && isFullObj(value) && value !== ''
        && value !== 'null'
      ) {
        count += 1;
      }
    }
  }

  return count === 0;
};

/**
 * @function generateImageUrl
 * @param { object } image return all items of image in object
 * @param { string } type folder in bucket folder on api, for example "items" or others
 * @param { string } variant of size image example  can be (original, preview, medium)
 * Look backend/common/models/News.php:186 for understand
 * @param { string } ext image extension
 * @returns { null|string } image or null
 *
 * @example generateImageUrl(image, 'items', 'medium');
 */

export const generateImageUrl = (image, type, variant, ext = 'jpg') => {
  if (!_.isEmpty(image)) {
    return `/bucket/${type}/${image.ownerId}/${image._id}/${variant}.${ext}`;
  }
  return null;
};

export const declinationName = (count = 0, words = []) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return words[(+count % 100 > 4 && +count % 100 < 20) ? 2 : cases[Math.min(+count % 10, 5)]];
};

export const textLinks = (inputText) => {
  if (!inputText || !_.isString(inputText)) {
    return inputText;
  }
  let replacedText = inputText;
  // URLs starting with http://, https://, or ftp://
  /* eslint-disable no-useless-escape */
  // eslint-disable-next-line max-len
  const replacePattern1 = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;
  replacedText = replacedText.replace(replacePattern1, (url) => `<a href="${url}">${cutText(url, 30)}</a>`);
  // URLs starting with www. (without // before it, or it'd re-link the ones done above)
  /* eslint-disable no-useless-escape */
  const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, (url) => `<a href="http://${url}">${cutText(url, 30)}</a>`);
  // Change email addresses to mailto:: links
  const replacePattern3 = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
};

export const getUserPathName = (path) => {
  switch (path) {
    case COLLECTION_TAB:
      return 'Предметы искусства в коллекции';
    case EVENT_TAB:
      return 'События';
    case MARKET_TAB:
      return 'Предметы искусства на продажу';
    case ABOUT_TAB:
      return 'Информация о человеке';
    case ALBUM_TAB:
      return 'Альбомы предметов искусства';
    default:
      return '';
  }
};

export const getUserTypeName = (type) => {
  switch (type) {
    case USER_ADMIN:
      return 'Администратор';
    case USER_MODERATOR:
      return 'Модератор';
    case USER_AMATEUR:
      return 'Любитель';
    case USER_COLLECTOR:
      return 'Коллекционер';
    case USER_CURATOR:
      return 'Куратор';
    case USER_ARTIST:
      return 'Художник';
    case USER_ART_DEALER:
      return 'Арт - Дилер';
    case USER_GALLERY:
      return 'Представитель галереи';
    default:
      return 'Любитель';
  }
};

export const iconParser = (string, text = false) => {
  let type = null;
  const types = [
    {
      regex: /^([0-9a-z-]+\.?)+$/i,
      type: 'WWW',
    },
    {
      regex: /vk.com/,
      type: 'VK',
    },
    {
      regex: /whatsapp.com/,
      type: 'WA',
    },
    {
      regex: /ok.ru/,
      type: 'OK',
    },
    {
      regex: /instagram.com/,
      type: 'INSTA',
    },
    {
      regex: /t.me/,
      type: 'TG',
    },
    {
      regex: /facebook.com/,
      type: 'FB',
    },
    {
      regex: /twitter.com/,
      type: 'TW',
    },
    {
      regex: /google.com/,
      type: 'GOOGLE',
    },
    {
      regex: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/,
      type: 'PHONE',
    },
    {
      regex: /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/,
      type: 'EMAIL',
    },
  ];

  for (const key of types) {
    if (key.regex.test(string)) { type = key.type; }
  }

  switch (type) {
    case 'VK':
      return !text ? (
        <Icon
          icon="vk"
          colorFill="#000"
          noHover
        />
      ) : 'ВКонтакте';
    case 'OK':
      return !text ? (
        <Icon
          icon="ok"
          colorFill="#000"
          noHover
        />
      ) : 'Одноклассники';
    case 'TG':
      return !text ? (
        <Icon
          icon="tg"
          colorFill="#000"
          noHover
        />
      ) : 'Телеграм';
    case 'FB':
      return !text ? (
        <Icon
          icon="fb"
          colorFill="#000"
          noHover
        />
      ) : 'FaceBook';
    case 'TW':
      return !text ? (
        <Icon
          icon="tw"
          colorFill="#000"
          noHover
        />
      ) : 'Twitter';
    case 'EMAIL':
      return !text ? (
        <Icon
          icon="email"
          colorFill="#fff"
          colorStroke="#000"
          noHover
        />
      ) : 'E-mail';
    case 'PHONE':
      return !text ? (
        <Icon
          icon="phone"
          colorFill="#fff"
          colorStroke="#000"
          noHover
        />
      ) : 'Телефон';
    case 'GOOGLE':
      return !text ? (
        <Icon
          icon="google"
          colorFill="#000"
          noHover
        />
      ) : 'Google';
    case 'INSTA':
      return !text ? (
        <Icon
          icon="insta"
          colorFill="#fff"
          colorStroke="#000"
          noHover
        />
      ) : 'Instagram';
    case 'WWW':
      return !text ? (
        <Icon
          icon="www"
          colorFill="#fff"
          colorStroke="#000"
          noHover
        />
      ) : 'Сайт';
    case 'WA':
      return !text ? (
        <Icon
          icon="wa"
          colorFill="#000"
          noHover
        />
      ) : 'WhatsApp';
    default:
      return !text ? (
        <Icon
          icon="contact"
          colorFill="#000"
          noHover
        />
      ) : 'Контакт';
  }
};

export const isEmptyObject = (variable, exception = []) => {
  let objectResult = {};
  const empty = (object) => {
    _.keys(object).forEach((key) => {
      if (!exception.includes(key) && key !== '__typename') {
        const localObj = object[key];
        if (_.isObject(localObj)) {
          empty(localObj);
        } else if (localObj) {
          objectResult = { ...objectResult, [key]: object[key] };
        }
      }
    });
  };
  if (_.isObject(variable)) {
    empty(variable);
  }
  if (_.isArray(variable)) {
    variable.forEach((v) => empty(v));
  }
  return _.isEmpty(objectResult);
};

export const removeTypename = (value) => {
  if (value === null || value === undefined) {
    return value;
  } if (Array.isArray(value)) {
    return value.map((v) => removeTypename(v));
  } if (typeof value === 'object') {
    const newObj = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== '__typename') {
        newObj[key] = removeTypename(v);
      }
    });
    return newObj;
  }
  return value;
};

/**
 * @name stringRegister
 * @param {string} str is word or sentence
 * @param {string} type of variant to parse: capital, capitalAll, uppercase, lowercase,
 * @param {boolean} coma remove last coma or not for example after join(',')
 * @return {string} world or sentence
 */
export const stringRegister = (str, type = 'capital', coma = false) => {
  let result = '';
  if (!str) {
    return '';
  }
  if (typeof str !== 'string') {
    return '';
  }
  if (type === 'capital') {
    const lower = str.toLowerCase();
    result = lower[0].toUpperCase() + lower.substring(1);
  }
  if (type === 'capitalAll') {
    const lower = str.toUpperCase();
    const words = lower.split(' ');
    const titleCaseWords = words.map((word) => word[0].toUpperCase() + word.substring(1));
    result = titleCaseWords.join(' ');
  }
  if (type === 'uppercase') {
    result = str.toUpperCase();
  }
  if (type === 'lowercase') {
    result = str.toLowerCase();
  }
  if (!coma && !!result) {
    result.replace(/,\s*$/, '');
  }
  return result;
};

export const sentenceCutter = (sentence, count, type = 'capital') => {
  let s = sentence;
  if (sentence) {
    s = sentence.split(' ');
    if (s.length && s.length > count) {
      const t = [];
      s.forEach((world, i) => (i <= (count - 1) && t.push(world)));
      s = `${stringRegister(t.join(' '), type)}...`;
    }
  }
  return s;
};

export const getCategoriesNames = (categories, count = null) => {
  if (Array.isArray(categories) && !!categories.length) {
    let i = 0;
    const names = [];
    categories.forEach((category) => {
      i += 1;
      if (count && i <= count && category.name) {
        names.push(category.name);
      }
      if (!count && category.name) {
        names.push(category.name);
      }
    });
    return !_.isEmpty(names) ? `${names.join(', ')}${count && categories.length > count ? '...' : ''}` : '';
  }
  return '';
};

export const getCurrency = (type) => CURRENCIES.map(
  (item) => (item.value === type ? item.label : ''),
);

export const getHostName = (url) => {
  const urlClear = url.replace('http://', '').replace('https://', '').replace('www.', '');
  return urlClear.split('/')[0];
};

export const cutWwwUrl = (url) => {
  if (typeof url !== 'string') {
    return false;
  }
  if (url.includes('www.') && !url.includes('http://') && !url.includes('https://')) {
    return `http://${url.replace('www.', '')}`;
  }
  return url;
};

export const contactParser = (contact, length = 30) => {
  if (typeof contact !== 'string') {
    return false;
  }

  return (
    <>
      {validateUrl(contact) && (
        <a href={cutWwwUrl(contact)} className="text-dark" target="_blank" rel="noopener noreferrer noopener">
          {cutText(getHostName(contact), length)}
        </a>
      )}
      {validateEmail(contact) && (
        <a href={`mailto:${contact}`} className="text-dark" target="_blank" rel="noopener noreferrer noopener">
          {cutText(contact, length)}
        </a>
      )}
      {validatePhone(contact) && (
        <a href={`callto:${contact}`} className="text-dark" target="_blank" rel="noopener noreferrer noopener">
          {cutText(contact, length)}
        </a>
      )}
      {!validateEmail(contact)
      && !validatePhone(contact)
      && !contact.includes('http://')
      && !contact.includes('www.')
      && !contact.includes('https://') && contact}
    </>
  );
};

export const getDomain = (url, subDomain = false) => {
  let clearUrl = url.replace(/(https?:\/\/)?(www.)?/i, '');

  if (!subDomain) {
    clearUrl = clearUrl.split('.');

    clearUrl = clearUrl.slice(clearUrl.length - 2).join('.');
  }

  if (clearUrl.indexOf('/') !== -1) {
    return clearUrl.split('/')[0];
  }

  return clearUrl;
};

export const Plus = styled.div`
position: relative;
width: 20px;
display: block;
margin-right: 20px;
&:before,
&:after {
  content: "";
  position: absolute;
  width: 20px;
  background-color: var(--art-active-light);
  height: 2px;
}
&:before {
  transform: rotate(90deg);
}
&:after {
  transform: rotate(180deg);
}
`;
