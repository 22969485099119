import {
  useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  GQL_CREATE_DIALOG,
  GQL_CREATE_MESSAGE,
  GQL_GET_MESSAGES_ALL,
  GQL_GET_SETTING,
  GQL_MESSAGE_NEW,
} from '../../../graphql/queries';
import { parseLinks, parseTextLinks } from '../../../helpers/main';
import { useTranslation } from '../../hooks/useTranslation';
import Icon from '../../Icon';

import ModalCloseButton from '../../ModlaCloseButton';

const ChatWindow = ({
  me, open, setOpen, initText,
}) => {
  if (typeof localStorage === 'undefined') {
    return '';
  }
  let temporary_id = localStorage.getItem('temporary_id');

  const { t } = useTranslation();

  const [chat, setChat] = useState(false);
  const [isNew, setNew] = useState(0);
  const [text, setText] = useState('');
  const [error, setError] = useState(null);
  const [dialog_id, setDialogId] = useState(null);

  const { data: raw } = useQuery(GQL_GET_SETTING, {
    variables: { code: 'art_user_id' },
    fetchPolicy: 'cache-first',
  });

  const { data: rawTg } = useQuery(GQL_GET_SETTING, {
    variables: { code: 'tg_chat_link' },
    fetchPolicy: 'cache-first',
  });

  const { data: rawWa } = useQuery(GQL_GET_SETTING, {
    variables: { code: 'wa_chat_link' },
    fetchPolicy: 'cache-first',
  });

  const [attachMessage] = useMutation(GQL_CREATE_MESSAGE);
  const [dialogProcess] = useMutation(GQL_CREATE_DIALOG);

  const [loadMessages, {
    subscribeToMore,
    loading,
    data,
    refetch,
  }] = useLazyQuery(GQL_GET_MESSAGES_ALL);

  const companion_id = _.get(raw, 'setting.value', null);

  useEffect(() => {
    if (open) {
      setChat(true);
    }
  }, [open]);

  useEffect(() => {
    if (initText) {
      setText(initText);
    }
  }, [initText]);

  useEffect(() => {
    let unsubscribe;
    if (dialog_id && !unsubscribe && chat) {
      unsubscribe = subscribeToMore({
        document: GQL_MESSAGE_NEW,
        variables: { dialog_id },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) {
            return prev;
          }
          const { messageNew } = subscriptionData.data;
          if (messageNew.user_id !== temporary_id
            && messageNew.user_id !== _.get(me, '_id', false)
          ) {
            if (!_.get(me, '_id')) {
              const audio = new Audio('/sounds/beep.mp3');
              audio.load();
              audio.play();
            }
            setNew((unread) => (unread + 1));
          }
          return { ...prev, messagesAll: [messageNew, ...prev.messagesAll] };
        },
      });
      return true;
    }
    return () => (unsubscribe ? unsubscribe() : {});
  }, [dialog_id]);

  useEffect(async () => {
    let dialogId = null;
    if (companion_id && chat) {
      await dialogProcess({
        variables: { companion_id, temporary_id: _.get(me, '_id', false) ? null : temporary_id },
        fetchPolicy: 'no-cache',
      }).then((response) => {
        if (_.get(response, 'data.createDialog._id')) {
          if (_.get(response, 'data.createDialog.temporary_id')) {
            if (!temporary_id || _.get(response, 'data.createDialog.temporary_id') !== temporary_id) {
              temporary_id = _.get(response, 'data.createDialog.temporary_id');
              localStorage.setItem('temporary_id', temporary_id);
            }
          }
          dialogId = _.get(response, 'data.createDialog._id');
          setDialogId(dialogId);
          loadMessages({
            variables: { dialog_id: dialogId, temporary_id: _.get(me, '_id', false) ? null : temporary_id },
            fetchPolicy: 'cache-and-network',
          });
        }
      });
    }
  }, [companion_id, chat]);

  const textProcess = (e) => {
    setText(_.get(e, 'target.value'));
    setError(null);
  };

  const sendMessage = async () => {
    if (!text) {
      setError(t('need_text', { ns: 'chat' }));
      return false;
    }
    if ((!temporary_id && !_.get(me, '_id', false)) || !dialog_id) {
      setError(t('need_reload', { ns: 'chat' }));
      return false;
    }
    await attachMessage({
      variables: {
        dialog_id,
        receiver_id: companion_id,
        user_id: _.get(me, '_id', false) || temporary_id,
        text,
        created_at: moment().unix(),
      },
    });
    await refetch();
    setText('');
    return true;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const openChat = () => {
    setChat(true);
    setNew(0);
  };

  const closeChat = () => {
    setOpen(false);
    setChat(false);
    setNew(0);
  };

  const messages = _.get(data, 'messagesAll', []);

  const telegramLink = _.get(rawTg, 'setting.value', null);

  const watsAppLink = _.get(rawWa, 'setting.value', null);

  const regex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

  return chat ? (
    <Window>
      <ChatTop>
        <ChatName>{t('chat_name', { ns: 'chat' })}</ChatName>
        <ModalCloseButton onHide={closeChat} />
      </ChatTop>
      <Messages $empty={_.isEmpty(messages) ? 1 : undefined}>
        {!_.isEmpty(messages) ? messages.map((message) => {
          const isMy = (message.user_id === _.get(me, '_id', false) || message.user_id === temporary_id);
          const createdAt = Date.now() / 1000 - message.created_at > 86400
            ? moment.unix(message.created_at).format('DD.MM.YY HH:mm')
            : moment.unix(message.created_at).fromNow();
          return (
            <Message key={_.get(message, '_id')} active={isMy ? 1 : undefined}>
              <MessageName>{isMy ? t('you_right', { ns: 'chat' }) : t('support_right', { ns: 'chat' })}</MessageName>
              <MessageText>{message.text && parseTextLinks(parseLinks(message.text, regex))}</MessageText>
              <MessageDate>{createdAt}</MessageDate>
            </Message>
          );
        }) : (
          <InfoBlock>
            <p>{t('can_help', { ns: 'chat' })}</p>
            <p>{t('can_messenger', { ns: 'chat' })}</p>
            <SocialLinks>
              <Telegram href={telegramLink} target="_blank" rel="noopener nofollow noreferrer">
                <Icon noHover icon="tg" width="20" height="20" colorFill="#ffffff" />
                Telegram
              </Telegram>
              <WatsApp href={watsAppLink} target="_blank" rel="noopener nofollow noreferrer">
                <Icon noHover icon="wa" width="20" height="20" colorFill="#ffffff" />
                Whatsapp
              </WatsApp>
            </SocialLinks>
          </InfoBlock>
        )}
      </Messages>
      <ChatBottom>
        <ChatForm
          onKeyUp={handleKeyPress}
          active={error ? 1 : undefined}
          placeholder={t('input_message', { ns: 'chat' })}
          value={text}
          onChange={textProcess}
        />
        {error && <Error>{error}</Error>}
        <Send disabled={loading} type="button" onClick={sendMessage}>{t('send', { ns: 'chat' })}</Send>
      </ChatBottom>
    </Window>
  ) : (
    <ButtonMessage type="button" onClick={openChat} active={isNew}>
      <Inside>
        <Q>{isNew || '?'}</Q>
        <Icon
          icon="message"
          noHover
          colorStroke="#ffffff"
          colorFill="#ffffff"
          width="22"
          height="22"
        />
      </Inside>
    </ButtonMessage>
  );
};

const ButtonMessage = styled.button`
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 100;
  background-color: ${({ active }) => (active ? css`var(--art-warning)` : css`var(--art-active)`)};
  color: white;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin-top: 45px;
  width: 69px;
  height: 69px;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.15);
  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
    background-color: var(--art-active-dark);
  }
  @media screen and (max-width: 600px) {
    right: 35px;
    bottom: 25px;
    display: block;
    width: 54px;
    height: 54px;
    min-width: 54px;
    min-height: 54px;
    padding: 0;
  }
`;

const Inside = styled.div`
  position: relative;
`;

const Q = styled.div`
  right: 3px;
  position: absolute;
  color: var(--art-active);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 15px;
  width: 15px;
`;

const Window = styled.aside`
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 20px;
  top: 20px;
  bottom: 20px;
  max-width: 400px;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.2);
  padding: 25px;
  z-index: 11000;
  border-radius: 15px;
  min-width: 300px;
  @media screen and (max-width: 500px) {
    right: 10px;
    left: 10px;
    top: 10px;
    bottom: 10px;
    max-width: inherit;
    width: auto;
  }
`;

const Error = styled.div`
  color: var(--art-error);
  font-size: 12px;
`;

const ChatTop = styled.div`
  display: flex;
  align-items: center;
  min-height: 45px;
  margin-bottom: 10px;
  flex: 0;
`;

const ChatName = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const Messages = styled.div`
  height: 100%;
  flex: 1;
  border: 1px solid #ddd;
  overflow-y: scroll;
  overflow-x: visible;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  ${({ $empty }) => {
    if ($empty) {
      return css`
        flex-direction: column;
        justify-content: center;
      `;
    }
    return css`flex-direction: column-reverse;`;
  }};
`;

const InfoBlock = styled.div`
  padding: 10px;
  color: var(--art-dark-white);
  text-align: center;
  line-height: 20px;
`;

const SocialLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin-top: 20px;
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;

const socialBtn = css`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: white;
  svg {
    margin-right: 10px;
  }
  &:hover, &:focus {
    text-decoration: none;
    color: white;
  }
`;

const Telegram = styled.a`
  ${socialBtn};
  background-color: var(--art-tg);
  &:hover, &:focus {
    background-color: var(--art-tg-dark);
  }
`;

const WatsApp = styled.a`
  ${socialBtn};
  background-color: var(--art-wa);
  &:hover, &:focus {
    background-color: var(--art-wa-dark);
  }
`;

const Message = styled.div`
  display: block;
  background-color: ${({ active }) => (active ? css`var(--art-main-gray)` : css`var(--art-back-ground)`)};
  text-align: ${({ active }) => (active ? 'left' : 'right')};
  padding: 0 15px;
  border-radius: ${({ active }) => (active ? '0 10px 10px 10px' : '10px 0 10px 10px')};
  margin-top: 10px;
  margin-left: ${({ active }) => (active ? 0 : 10)}px;
  margin-right: ${({ active }) => (active ? 10 : 0)}px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
`;

const MessageName = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
`;

const MessageText = styled.div`
  font-size: 14px;
  line-height: 18px;
  text-align: left;
`;

const MessageDate = styled.div`
  font-size: 12px;
  color: #656565;
  line-height: 30px;
`;

const ChatBottom = styled.div`
  flex: 0;
`;

const ChatForm = styled.textarea`
  font-size: 14px;
  border: 1px solid #e0e5f1;
  height: calc(1.5em + 0.75rem + 5px);
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
  background-color: var(--art-light-white);
  padding: 10px;
  line-height: 18px;
`;

const Send = styled.button`
  background: var(--art-active);
  color: white;
  width: 100%;
  margin-top: 10px;
  border-radius: 10px;
`;

ChatWindow.defaultProps = {
  me: {},
  open: false,
  setOpen: () => {},
  initText: null,
};

ChatWindow.propTypes = {
  me: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  initText: PropTypes.string,
};

export default ChatWindow;
