import { gql } from '@apollo/client';

export const GQL_GET_SETTING = gql`
  query GetSetting($code: String) {
    setting(code: $code) {
      value
      created_at
    }
  }
`;
