import React from 'react';

const ban = ({ colorStroke }) => ({
  viewBox: '0 0 16 16',
  data: (
    <g opacity="0.6">
      <circle
        cx="7.99998"
        cy="8.00004"
        r="5.94546"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
      />
      <path
        d="M4.06061 3.93945L12.3646 12.3627"
        stroke={colorStroke}
        fill="transparent"
        strokeWidth="1.2"
      />
    </g>
  ),
});

export default ban;
