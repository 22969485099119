import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from 'react-bootstrap';
import _ from 'lodash';
import { Field } from 'formik';
import ArtSelect from './ArtSelect';

const ArtWeight = ({
  t,
  field,
  form,
  disabled,
  groupClassName,
  options,
  className,
}) => (
  <Row className={groupClassName}>
    <Col lg={6} md={6} sm={6} xs={6} className="pe-0">
      <Field
        component={Field}
        name={`${field.name}.value`}
        className={`${className} w-100 form-control-lg`}
        disabled={disabled}
        placeholder={t('weight', { ns: 'artwork' })}
        value={_.get(form, `values.${field.name}.value`)}
        onChange={(e) => form.setFieldValue(`${field.name}.value`, e.target.value)}
      />
    </Col>
    <Col lg={6} md={6} sm={6} xs={6} className="ps-0">
      <Field
        component={ArtSelect}
        column
        className={`${className} w-100 form-control-lg`}
        placeholder={t('short_dimension', { ns: 'artwork' })}
        disabled={disabled}
        options={options.map((option) => ({
          label: option.label,
          value: option.value,
        }))}
        name={`${field.name}.dimension`}
        value={_.get(form, `values.${field.name}.dimension`)}
        onChange={(e) => form.setFieldValue(`${field.name}.dimension`, e.target.value)}
      />
    </Col>
  </Row>
);

ArtWeight.defaultProps = {
  disabled: false,
  groupClassName: '',
  className: '',
};

ArtWeight.propTypes = {
  t: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  groupClassName: PropTypes.string,
  className: PropTypes.string,
};

export default ArtWeight;
