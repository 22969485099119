import React from 'react';

const search = ({ colorStroke, colorFill }) => ({
  viewBox: '0 0 20 20',
  data: (
    <g>
      <circle
        fill="none"
        stroke={colorStroke}
        cx="8.09996"
        cy="8.09996"
        r="7.09996"
        strokeWidth="1.5"
      />
      <path
        fill={colorFill}
        stroke={colorFill}
        strokeWidth="0"
        d={`M14.2071 12.7929C13.8166 12.4024 13.1834 12.4024 12.7929 12.7929C12.4024 13.1834
        12.4024 13.8166 12.7929 14.2071L14.2071 12.7929ZM18.1929 19.6071C18.5834 19.9976
        19.2166 19.9976 19.6071 19.6071C19.9976 19.2166 19.9976 18.5834 19.6071 18.1929L18.1929
        19.6071ZM12.7929 14.2071L18.1929 19.6071L19.6071 18.1929L14.2071 12.7929L12.7929 14.2071Z`}
      />
    </g>
  ),
});

export default search;
