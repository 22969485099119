import { gql } from '@apollo/client';

const educationFragment = gql`
  fragment EducationFragment on Education {
    _id
    url
    name
    anons
    description
    bg_text_color
    redirect_success
    redirect_text
    button_register
    button_unregister
    registration
    status
    ended_at
    started_at
    created_at
    type
    read
    purchased
    closed
    lecture_count
    can_view
    lecturers {
      _id
      name
      username
      lecturer_info
      lecturer_image {
        key
      }
    }
    news {
      _id
      url
      name
      anons
      created_at
      authors {
        _id
        username
        name
      }
      images {
        _id
        type
        ownerId
        rank
        description
        name
      }
    }
    paid_modules {
      _id
      name
      lectures {
        _id
        name
      }
      description
      price_rub
      price_euro
    }
    modules {
      _id
      name
      lectures {
        _id
        name
      }
      description
      price_rub
      price_euro
    }
    videoReviews {
      _id
      name
      owner {
        name
        _id
        username
        avatar {
          key
        }
      }
      cover {
        key
      }
      video {
        key
        mimeType
      }
    }
    reviews {
      _id
      description
      owner {
        name
        _id
        username
        avatar {
          key
        }
      }
    }
    educations {
      _id
      url
      name
      anons
      started_at
      cover {
        key
      }
      lecturers {
        _id
        name
        username
        lecturer_info
      }
      lectures {
        _id
        url
        name
        allowedTariffs {
          _id
          name
          tariff
        }
      }
      tags {
        _id
        name
        url
        description
      }
    }
    program {
      name
      description
      modules_id
    }
    faq {
      name
      description
    }
    what_give {
      name
      description
    }
    what_learn {
      name
      description
    }
    who_suit {
      name
      description
    }
    mainTariffs {
      name
      name_for
      close_text
      color
    }
    tariffs {
      _id
      name
      tariff
      price
      old_price
      price_euro
      old_price_euro
      text
      popular
      installments
      paragraph {
        name
      }
    }
    lectures {
      _id
      url
      name
      read
      started_at
      ended_at
      allow
      allowedTariffs {
        _id
        name
        tariff
      }
      cover {
        key
      }
      lecturers {
        _id
        username
        name
      }
    }
    tags {
      _id
      name
      url
      description
    }
    meta {
      title
      description
      created_at
      updated_at
    }
    mobile {
      key
    }
    cover {
      key
    }
  }
`;

const fragmentAll = gql`
  fragment EducationAllFragment on EducationsAll {
    total
    meta {
      title
      description
      created_at
      updated_at
      page_name
      page_description
      cover {
        key
        fileName
        format
      }
      avatar {
        key
      }
    }
    items {
      _id
      url
      name
      anons
      free_date
      status
      ended_at
      started_at
      created_at
      type
      read
      lecturers {
        name
      }
      lectures {
        _id
      }
      tags {
        _id
        name
        url
      }
      cover {
        key
      }
      mainTariffs {
        name
        name_for
        close_text
        color
      }
      tariffs {
        price
        price_euro
      }
    }
  }
`;

export const GQL_GET_EDUCATIONS_ALL = gql`
  query GetEducationsAll($query: QueryItems, $my: Boolean) {
    educationsAll(query: $query, my: $my) {
      ...EducationAllFragment
    }
  }
  ${fragmentAll}
`;

export const GQL_GET_EDUCATION = gql`
  query GetEducation($_id: ID, $url: String, $my: Boolean) {
    education(_id: $_id, url: $url, my: $my) {
      ...EducationFragment
    }
  }
  ${educationFragment}
`;

export const GQL_PAY_EDUCATION_PROGRESS = gql`
  mutation PayEducation($model_id: String!, $modules_id: [String], $mode: String!, $promo: String, $stripe: Boolean) {
    payEducation(model_id: $model_id, modules_id: $modules_id, mode: $mode, promo: $promo, stripe: $stripe)
  }
`;

export const GQL_SEND_EDUCATION_REVIEW = gql`
  mutation SendEducationReview($description: String!, $education_id: String!) {
    educationReview(description: $description, education_id: $education_id)
  }
`;

export const GQL_PROMO_EDUCATION = gql`
  mutation SendPromoEducation($promo: String!, $tariff_id: String!) {
    promoEducation(promo: $promo, tariff_id: $tariff_id) {
      total
      total_euro
    }
  }
`;

export const GQL_GET_EDUCATION_TAGS_ALL = gql`
  query GetEducationTagsAll {
    educationTagsAll {
      _id
      name
    }
  }
`;
