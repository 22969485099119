export default {
  name: 'Выставки',
  my_name: 'Мои выставки',
  no_items: 'Ничего не найдено',
  placeholder_search: 'Искать среди {{ total }}',
  no_request_result: 'По запросу «{{ request }}» ничего не найдено',
  no_filter_result: 'По выбранной фильтрации ничего не найдено',
  no_request_filter_result: 'По выбранной фильтрации и запросу «{{ request }}» ничего не найдено',
  count_artworks: 'В мероприятие включено ',
  until: 'До',
  main_name: 'Выставочные мероприятия',
  time: 'Сегодня {{ date }}',
  last: 'Last call',
  editor: 'Выбор редакции',
  current: 'Новые офлайн',
  online: 'Новые онлайн',
  view_all: 'Смотреть все',
  weekend: 'Эти выходные',
};
