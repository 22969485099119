export default {
  notifications: 'Оповещения',
  cabinet_title: 'Личный кабинет - Оповещения',
  all: 'Все уведомления',
  participation_requests: 'Запросы на участие',
  friend_requests: 'Запросы в друзья',
  comments: 'Комментарии',
  likes: 'Нравится',
  about_likes: 'лайки',
  about_comments: 'комментарии',
  about_friendships: 'заявки в друзья',
  about_offers: 'предложения',
  no_items: 'У вас пока нет оповещений {{ text }}',
  no_more_items: 'Больше уведомлений нет',
  mark_all_read: 'Отметить все прочитанными',
  mark_read: 'Отметить прочитанным',
  mark_unread: 'Отметить непрочитанным',
  remove_marked: 'Удалить отмеченное',
  remove_all: 'Удалить все',
  remove_all_name: 'все уведомления',
  remove_alert: 'Восстановление невозможно',
  offered_friendship: 'предложил дружбу',
  answer_friendship: 'принял вашу заявку в друзья',
  send_comment: 'оставил новый комментарий',
  send_post: 'опубликовал новый пост',
  marked_artwork: 'поставил лайк вашей работе',
  marked_institution: 'поставил лайк вашей институции',
  marked_album: 'поставил лайк вашему альбому',
  marked_exhibition: 'поставил лайк вашему мероприятию',
  marked_you: 'поставил лайк вашей странице',
  text: 'Текст',
  is_read: 'Прочитано',
  is_new: 'Новое',
  confirm_artworks: 'обновил заявку на участие работ в мероприятии',
  request_artworks: 'запросил участие работ в мероприятии',
  confirm_exhibitions: 'подтвердил заявку на участие работ в мероприятии',
  request_exhibitions: 'запросил произведения на мероприятие',
  request_info: 'Чтобы посмотреть работы, подтвердить или отказать, перейдите к мероприятию',
  confirm_info: 'Чтобы посмотреть подтверждённые работы, перейдите к мероприятию',
  message_reply_offer_works: 'Вами была удалена связанная заявка, но всё еще возможно связаться с куратором',
  message_reply_offer_exhibitions: 'Вами была удалена связанная заявка, но всё еще возможно связаться с коллекционером',
  message_offer_exhibitions: 'Он удалил запрос работы на выставки, но вы все еще можете связаться с ним',
  message_offer_works: 'Он удалил предложение работ на выставку, но вы все еще можете связаться с ним',
  remove_somebody: 'Предложение удалено кем-то',
  post: 'к вашему посту',
};
