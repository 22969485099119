export default {
  name: 'Уведомления',
  email: 'email для уведомлений',
  no_email: 'У вас нет ни одного email',
  select_all: 'Выбрать все',
  on_email: 'На email',
  check_enter: 'Вход в учетную запись',
  check_offer: 'Приглашения на выставки/участия',
  check_comments: 'Новые комментарии к работам',
  check_messages: 'Новые сообщения',
  check_posts: 'Новые посты друзей',
  check_friendships: 'Новые запросы в друзья',
  check_news: 'Новости и предложения Артократии',
};
