export default {
  your_project: 'Уникальный проект',
  help_project: 'Возможность с нуля создать свой цифровой проект или представить уже готовый на нашей платформе',
  your_room: 'Viewing Rooms',
  help_room: 'Возможность арендовать готовое цифровое пространство для своего проекта',
  add_project: 'Присоединиться к проекту',
  spaces: 'Пространства',
  send_success: 'Ваша заявка успешно отправлена!',
  add: 'Создать уникальный проект с Artocratia Metaverse',
  name: 'Ваше имя',
  name_placeholder: 'Введите ваше имя',
  phone: 'Телефон',
  phone_placeholder: 'Введите номер телефона',
  message: 'Расскажите о вашем проекте',
  message_placeholder: 'Введите текст',
  role: 'Ваша роль',
  artist: 'Художник',
  creator: 'Креатор',
  collector: 'Коллекционер',
  brand: 'Бренд',
  producer: 'Продюсер',
  designer: 'Дизайнер',
  marketer: 'Маркетолог',
  developer: 'Разработчик / IT специалист',
  thanks: 'Спасибо, скоро наш менеджер свяжется с вами',
  agreement: 'Нажимая кнопку «отправить» я даю согласие',
  agreement_link: 'на обработку моих персональных данных',
  info: 'После заполнения и отправки заявки с вами лично свяжется Марина Альвитр, основатель Артократии',
  thanks_user: 'Спасибо! Ваша анкета отправлена. В ближайшее время на Артократии с вами свяжется Марина Альвитр для обсуждения вашего проекта.',
  hello_moderator: 'Здравствуйте! Мне интересно сотрудничество/создание проекта в метавселенной Артократии',
};
